import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {LanguageData} from "./types";
import {RootState} from "../../store";
import {fetchLandingsListLanguages} from "../../requests/landingsList/languages";

export interface LandingsListLanguagesState {
    languages: PaginatedData<LanguageData> | null;
    status: RequestStatuses;
}

const initialState: LandingsListLanguagesState = {
    languages: null,
    status: RequestStatuses.idle,
};

export const getLandingsListLanguages = createAsyncThunk(
    'brands/fetchLandingsListLanguages',
    async (filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            const defaultFilters = { per_page: '1000' };

            return await fetchLandingsListLanguages({ ...defaultFilters, ...filters });
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const landingsListLanguagesSlice = createSlice({
    name: 'landingsListLanguages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingsListLanguages.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandingsListLanguages.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.languages = action.payload;
            });
    },
});

export const selectLandingsListLanguages = (state: RootState) => state.landingsListLanguages.languages?.data ?? [];
export const selectLandingsListLanguagesStatus = (state: RootState) => state.landingsListLanguages.status;


export default landingsListLanguagesSlice.reducer;