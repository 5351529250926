import {Dialog} from "@mui/material";
import {styled} from "@mui/material/styles";

const ResetDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        textAlign: 'left',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '0'
    },
    '& .MuiDialogActions-root': {
        textAlign: 'center'
    },
    '& .MuiDialog-paper': {
        maxWidth: '796px',
        padding: '36px 36px 34px 36px',
        width: "488px",
        boxSizing: "border-box"
    },
    '&.change-redirect-type-modal .MuiDialogTitle-root': {
        padding: '0 24px',
        marginBottom: '16px',
    },
    ".MuiDivider-root": {
        width: "100%",
        margin: "24px 0",
    },
    ".MuiDialogActions-root" : {
        padding: 0,
    }
}));

export default ResetDialog;