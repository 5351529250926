import React, {Dispatch, useCallback, useEffect, useState} from "react";
import './CreateShortlink.scss';
import {Button, DialogContent, DialogTitle, Divider, TextField, Typography, DialogContentText, DialogActions} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import StyledDialog from "../../modals/Dialog/StyledDialog";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    CreateShortUrlArguments,
    ShortenerDomain,
    ShortUrlData,
    ShortUrlSettings
} from "../../../app/reducers/prism/types";
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";
import {Config} from "../../Form/types";
import {Grid} from "@mui/material/";
import FormGrid from "../../Form/FormGrid/FormGrid";
import {TYPE_BUTTON, TYPE_DIVIDER, TYPE_RADIO, TYPE_SELECT, TYPE_TEXT, TYPE_TYPOGRAPHY} from "../../Form/constants";
import {createShortlink, updateShortlink} from "../../../app/reducers/prism/shortUrlsSlice";
import {getShortenerDomains, selectShortenerDomains} from "../../../app/reducers/prism/shortenerDomainsSlice";
import {FormSelect} from "../../Form/formInputs/Select/FormSelect";
import {AppFilterValue} from "../../Table/types";
import RadioGroupBase from "../../inputs/RadioGroupBase/RadioGroupBase";
import {generateUniqueString} from "../../Helpers/stringHelpers";
import {useNavigate} from "react-router-dom";
import {PayloadAction, SerializedError} from "@reduxjs/toolkit";

export interface ICreateShortlinkProps {
    handleClose: () => void;
    shortlinkData?: ShortUrlData | null;
    formEdit?: boolean;
    setUploadData?: Dispatch<React.SetStateAction<boolean>>;
    showNotification?: () => void;
}

interface ShortlinkData {
    domain_id: string;
    slug: string;
    target_url: string;
    title: string;
    with_loader: boolean;
}

interface IObjectValidation {
    hasError: boolean,
    errorText: string
}

export interface ValidationData {
    domain_id: IObjectValidation;
    slug: IObjectValidation;
    target_url: IObjectValidation;
    title: IObjectValidation;
    with_loader: IObjectValidation;
}

interface IErrors {
    [key: string]: string[];
}

const CreateShortlink: React.FC<ICreateShortlinkProps> = (props): JSX.Element => {
    const {handleClose, shortlinkData, formEdit, setUploadData, showNotification} = props;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [dataDispatch, setDataDispatch] = useState<ShortlinkData>({
        target_url: shortlinkData?.target_url ? shortlinkData.target_url : '',
        title: shortlinkData?.title ? shortlinkData.title : '',
        with_loader: shortlinkData?.with_loader ? shortlinkData.with_loader : false,
        slug: shortlinkData?.slug ? shortlinkData.slug : generateUniqueString(7),
        domain_id: shortlinkData?.domain_id ? String(shortlinkData.domain_id) : '1',
    });
    const [dataDispatchTemp, setDataDispatchTemp] = useState<ShortlinkData>({
        domain_id: '',
        slug: '',
        target_url: '',
        title: '',
        with_loader: false
    });
    const [validationData, setValidationData] = useState<ValidationData>({
        domain_id: {
            hasError: false,
            errorText: ''
        },
        slug: {
            hasError: false,
            errorText: ''
        },
        target_url: {
            hasError: false,
            errorText: ''
        },
        title: {
            hasError: false,
            errorText: ''
        },
        with_loader: {
            hasError: false,
            errorText: ''
        }
    });
    const [activeRedirectType, setActiveRedirectType] = useState<string>(
        shortlinkData?.with_loader
            ? t('shortener.shortlink_page.redirect_type_302')
            : t('shortener.shortlink_page.redirect_type_301')
    );
    const [newSlug, setNewSlug] = useState<string>('');
    const [openChangeSlugDialog, setOpenChangeSlugDialog] = useState<boolean>(false);
    const shortenerDomains = useAppSelector(selectShortenerDomains);
    const dispatch = useAppDispatch();

    const validationForm = (name: string, value: string) => {
        if (name === 'title' && value.length === 0) {
            return {
                hasError: true,
                errorText: t('shortener.modals.errors.name_empty')
            };
        }

        if (name === 'title' && value.length > 50) {
            return {
                hasError: true,
                errorText: t('shortener.modals.errors.name_long')
            };
        }

        if (name === 'target_url' && value.length === 0) {
            return {
                hasError: true,
                errorText: t('shortener.modals.errors.target_url_empty')
            };
        }

        if (name === 'target_url' && value.length > 255) {
            return {
                hasError: true,
                errorText: t('shortener.modals.errors.target_url_long')
            };
        }

        return {
            hasError: false,
            errorText: ''
        };
    }

    const setErrors = (errors: IErrors, setValidationData: Dispatch<React.SetStateAction<ValidationData>>) => {
        let target_url = '';
        let title = '';
        let domain_id = '';

        if (errors.target_url) {
            target_url = errors.target_url.map((error: string) => error).join(' ');
        }

        target_url && setValidationData(prevState => {
            return {
                ...prevState,
                target_url: {
                    hasError: true,
                    errorText: target_url
                }
            }
        });

        if (errors.title) {
            title = errors.title.map((error: string) => error).join(' ');
        }

        title && setValidationData(prevState =>{
            return {
                ...prevState,
                title: {
                    hasError: true,
                    errorText: title
                }
            }
        });

        if (errors.slug) {
            domain_id = errors.slug.map((error: string) => error).join(' ');
        }

        domain_id && setValidationData((prevState: ValidationData) => {
            return {
                ...prevState,
                domain_id: {
                    hasError: true,
                    errorText: domain_id
                },
                slug: {
                    hasError: true,
                    errorText: ''
                }
            }
        });
    }

    const handleErrorResponse = (
        response: PayloadAction<any, string, { arg: ShortUrlSettings | CreateShortUrlArguments; requestId: string; requestStatus: "fulfilled"; }, never>
            | PayloadAction<unknown, string, ({ arg: ShortUrlSettings; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; }), SerializedError>,
        setValidationData: Dispatch<React.SetStateAction<ValidationData>>,
        handleClose: () => void,
        setUploadData: Dispatch<React.SetStateAction<boolean>> | undefined
    ) => {
        if (response?.payload && response.payload.responseStatus === 400) {
            const errors = response.payload.messages;
            setErrors(errors, setValidationData);
        } else {
            handleClose();
            if (setUploadData) {
                setUploadData(true);
                showNotification && showNotification();
            } else {
                navigate(`/shortener/${response.payload?.id}` ?? '/shortener');
            }
        }
    }

    const getOptionFromAvailableParam = () => {
        if (!shortenerDomains) {
            return [];
        }
        return shortenerDomains?.map((domain: ShortenerDomain) => ({label: domain.url, value: domain.id}))
    };

    const selectChange = useCallback((option: AppFilterValue) => {
        setValidationData(prevState => {
            return {
                ...prevState,
                domain_id: {
                    hasError: false,
                    errorText: ''
                },
                slug: {
                    hasError: false,
                    errorText: ''
                }
            }
        });
        option !== null
            ? setDataDispatch((prevState) => {
                return {...prevState, domain_id: String(option)};
            })
            : setDataDispatch({...dataDispatch, domain_id: ''});
    }, []);

    const handleChangeRedirectType = (value: string) => {
        if (value !== activeRedirectType) {
            setDataDispatch({...dataDispatch, with_loader: !dataDispatch.with_loader});
            setActiveRedirectType(value);
        }
    }

    const checkIsDisabled = () => {
        let isDisabled = false;
        let isEmpty = false;
        for (let data in validationData) {
            if (validationData[data as keyof typeof validationData].hasError) {
                isDisabled = true;
            }
        }
        for (let data in dataDispatch) {
            if (dataDispatch[data as keyof typeof dataDispatch] === '') {
                isEmpty = true;
            }
        }
        return isDisabled || isEmpty;
    }

    const handleCloseChangeSlugDialog = () => {
        setDataDispatch(dataDispatchTemp);
        setOpenChangeSlugDialog(false);
    }

    const handleConfirmChangeSlug = async () => {
        setOpenChangeSlugDialog(false);
        const response = await dispatch(updateShortlink(createDataObj()));

        handleErrorResponse(response, setValidationData, handleClose, setUploadData);
    }

    const createDataObj = () => {
        if (shortlinkData?.title === dataDispatch?.title) {
            return {
                short_url_id: shortlinkData!.id,
                target_url: dataDispatch?.target_url,
                with_loader: dataDispatch?.with_loader,
                domain_id: dataDispatch?.domain_id,
                slug: dataDispatch?.slug
            }
        }
        return {
            short_url_id: shortlinkData!.id,
            target_url: dataDispatch?.target_url,
            title: dataDispatch?.title,
            with_loader: dataDispatch?.with_loader,
            domain_id: dataDispatch?.domain_id,
            slug: dataDispatch?.slug
        }
    }

    const handleCreate = async () => {
        let response;

        if (formEdit && shortlinkData?.slug === dataDispatch?.slug) {
            response = await dispatch(updateShortlink(createDataObj()));
            handleErrorResponse(response, setValidationData, handleClose, setUploadData);
        } else if (formEdit && shortlinkData?.slug !== dataDispatch?.slug) {
            setOpenChangeSlugDialog && setOpenChangeSlugDialog(true)
            setNewSlug && setNewSlug(dataDispatch?.slug);
            setDataDispatchTemp(dataDispatch)
        } else {
            response = await dispatch(createShortlink(dataDispatch));
            handleErrorResponse(response, setValidationData, handleClose, setUploadData);
        }
    }

    const configs: Config[] = [
        {
            type: 'text',
            field: 'title',
            label: t('shortener.table.columns.title'),
            col: 12,
            defaultValue: '',
            class: 'text-field'
        },
        {
            type: 'text',
            field: 'target_url',
            label: t('shortener.table.columns.target_url'),
            col: 12,
            defaultValue: '',
            class: 'text-field',
        },
        {
            type: 'select',
            field: 'domain_id',
            placeholder: t('shortener.form.domain'),
            value: '',
            label: 'value',
            options: getOptionFromAvailableParam(),
            col: 6,
            handleChange: selectChange,
            defaultValue: dataDispatch.domain_id,
            class: 'select-domain'
        },
        {
            type: 'text',
            field: 'slug',
            label: t('shortener.form.slug'),
            col: 6,
            class: 'text-field'
        },
        {
            label: t("shortener.form.redirect_type"),
            type: 'typography',
            variant: 'body2',
            class: 'splitting-block-title',
            col: 12
        },
        {
            type: 'radio',
            field: 'with_loader',
            label: 'Choose 1 type of redirect rules',
            radioButtons: [
                {
                    value: t('shortener.shortlink_page.redirect_type_301'),
                    label: t('shortener.shortlink_page.redirect_type_301'),
                    hint: t('shortener.shortlink_page.redirect_type_301_hint')
                },
                {
                    value: t('shortener.shortlink_page.redirect_type_302'),
                    label: t('shortener.shortlink_page.redirect_type_302'),
                    hint: t('shortener.shortlink_page.redirect_type_302_hint')
                }
            ],
            handleClick: handleChangeRedirectType,
            col: 12
        },
        {
            type: 'divider',
            label: '',
            col: 12,
        },
        {
            type: 'button',
            label: t('shortener.form.save'),
            buttonType: 'button',
            col: 6,
            buttonClass: 'BtnPurple create-shortlink-btn',
            handleClick: handleCreate,
            disabled: checkIsDisabled()
        },
        {
            type: 'button',
            label: t('common.forms.cancel'),
            buttonType: 'button',
            col: 6,
            buttonClass: 'BtnGrey create-shortlink-btn',
            handleClick: handleClose
        },
    ];

    useEffect(() => {
        dispatch(getShortenerDomains());
    }, [dispatch]);

    return (
        <>
            <StyledDialog className="create-shortlink" open={true} onClose={handleClose}>
                <div className='close-button'>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
                <DialogTitle>{formEdit ? t('shortener.form.edit_shortlink') : t('shortener.form.create_shortlink')}</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                        className="form-fields"
                        sx={{marginLeft: '-24px', width: 'calc(100% + 24px)'}}
                    >
                        {configs.map((config, index: number) => {
                            if (config.type === TYPE_TEXT) {
                                const err = validationData[config.field as keyof typeof validationData].hasError;
                                const errText = validationData[config.field as keyof typeof validationData].errorText;

                                return (
                                    <FormGrid config={config} key={index}>
                                        <TextField
                                            className="base-form-field"
                                            id={config.field}
                                            label={t(config.label)}
                                            variant='outlined'
                                            size='small'
                                            InputProps={config.props}
                                            type={config.inputType}
                                            name={config.field}
                                            onChange={(event) => {
                                                setDataDispatch({
                                                    ...dataDispatch,
                                                    [config.field]: event.target.value
                                                });
                                                config.field === 'slug'
                                                    ? setValidationData({
                                                        ...validationData,
                                                        [config.field]: validationForm(config.field, event.target.value),
                                                        domain_id: {
                                                            hasError: false,
                                                            errorText: ''
                                                        }
                                                    })
                                                    : setValidationData({
                                                        ...validationData,
                                                        [config.field]: validationForm(config.field, event.target.value)
                                                    });
                                            }}
                                            value={dataDispatch[config.field as keyof typeof dataDispatch]}
                                            error={err}
                                            helperText={err && errText}
                                            disabled={config.disabled}
                                        />
                                    </FormGrid>
                                )
                            }
                            if (config.type === TYPE_SELECT) {
                                const err = validationData[config.field as keyof typeof validationData].hasError;
                                const errText = validationData[config.field as keyof typeof validationData].errorText;

                                return (
                                    <FormGrid config={config} key={config.type + index}>
                                        <FormSelect
                                            fieldName={config.field}
                                            placeholder={config.placeholder}
                                            options={config.options}
                                            defaultOptionValue={config.defaultValue}
                                            onBlur={() => {
                                            }}
                                            onChangeHandler={(value) => {
                                                config.handleChange && config.handleChange(value);
                                            }}
                                            error={err}
                                            helperText={err && errText}
                                        />
                                    </FormGrid>
                                )
                            }
                            if (config.type === TYPE_BUTTON) {
                                return (
                                    <FormGrid config={config} key={index}>
                                        <Button
                                            fullWidth
                                            type={config.buttonType}
                                            variant='contained'
                                            color='secondary'
                                            onClick={config.handleClick}
                                            className={config.buttonClass}
                                            disabled={config.disabled}
                                        >
                                            {t(config.label)}
                                        </Button>
                                    </FormGrid>
                                )
                            }
                            if (config.type === TYPE_DIVIDER) {
                                return (
                                    <FormGrid config={config} key={config.type + index}>
                                        <Divider sx={{width: '100%'}}/>
                                    </FormGrid>
                                )
                            }
                            if (config.type === TYPE_RADIO) {
                                return (
                                    <FormGrid config={config} key={config.type + index}>
                                        <RadioGroupBase
                                            activeRadio={activeRedirectType}
                                            handleRadioChange={config.handleClick}
                                            radioGroupContent={config.radioButtons}
                                        />
                                    </FormGrid>
                                )
                            }
                            if (config.type === TYPE_TYPOGRAPHY) {
                                return (
                                    <FormGrid config={config} key={config.type + index}>
                                        <Typography
                                            variant={config.variant}
                                            sx={config.styles}
                                            align={config.align}
                                            onClick={config.handleClick}
                                        >
                                            {config.label}
                                        </Typography>
                                    </FormGrid>
                                )
                            }
                        })}
                    </Grid>
                </DialogContent>
            </StyledDialog>
            <StyledDialog className="change-redirect-type-modal" open={openChangeSlugDialog}
                          onClose={handleCloseChangeSlugDialog}>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseChangeSlugDialog}
                    className="close-cross"
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    {t('shortener.modals.change_slug')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('shortener.modals.change_slug_description')} <b>{newSlug}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={"BtnPurple"}
                        onClick={handleConfirmChangeSlug}
                    >
                        {t('shortener.modals.change')}
                    </Button>
                    <Button
                        className={"BtnGrey"}
                        onClick={handleCloseChangeSlugDialog}
                    >
                        {t("statistic.trackers.cancel")}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    );
}

export default CreateShortlink;