import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {UserData} from "./types";
import {fetchLogin, fetchSelfUser, fetchPasswordReset} from "../../requests/session";

export const TOKEN_STORAGE_KEY = 'Authorization';

export interface SessionState {
  selfUser: UserData | null;
  isAuthenticated: boolean;
  token: string | null;
  status: RequestStatuses;
  errors: {
    email?: string,
    password?: string
  }
  loginStatus: RequestStatuses;
}

const initialState: SessionState = {
  selfUser: null,
  isAuthenticated: Boolean(localStorage.getItem(TOKEN_STORAGE_KEY)),
  token: localStorage.getItem(TOKEN_STORAGE_KEY),
  status: RequestStatuses.idle,
  errors: {
    email: undefined,
    password: undefined
  },
  loginStatus: RequestStatuses.idle
};

export const getUserInfo = createAsyncThunk(
  'session/fetchSelfUser',
  async (filters: UrlParams | undefined, {rejectWithValue}) => {
    try {
      return await fetchSelfUser(filters);
    } catch (err) {
      return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
    }
  }
);

export const login = createAsyncThunk(
  'session/fetchLogin',
  async (credentials: UrlParams, {rejectWithValue}) => {
    try {
      return await fetchLogin(credentials);
    } catch (err) {
      return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
    }
  }
);
export const resetPassword = createAsyncThunk(
  'session/fetchPasswordReset',
  async (credentials: UrlParams, {rejectWithValue}) => {
    try {
      return await fetchPasswordReset(credentials);
    } catch (err) {
      return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
    }
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true;
      state.token = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem(TOKEN_STORAGE_KEY)
      state.isAuthenticated = false;
      state.token = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.status = RequestStatuses.success;
        state.selfUser = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.loginStatus = RequestStatuses.loading;
        // TODO make error handling more universal
        state.errors = {
          email: undefined,
          password: undefined
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.loginStatus = RequestStatuses.success;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        // @ts-ignore // TODO delete later
        if (action.payload.messages.credentials) {
          // @ts-ignore // TODO delete later
          state.errors.email = action.payload.messages.credentials;
          state.loginStatus = RequestStatuses.failed;
        }
      })
    ;
  },
});

export const selectSelfUser = (state: RootState) => state.sessions.selfUser;
export const selectToken = (state: RootState) => state.sessions.token;
export const selectIsAuth = (state: RootState) => state.sessions.isAuthenticated;
export const selectLoginErrors = (state: RootState) => state.sessions.errors;

export const {logout} = sessionSlice.actions;
export default sessionSlice.reducer;
