import React, {KeyboardEventHandler, useEffect, useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {Box, Button} from "@mui/material";
import './Search.css';
import {AppFilters, ColumnConfigWithRender, SearchConfig, UrlParams} from "../types";
import {useTranslation} from "react-i18next";

export interface SearchProps<Data, Handlers> {
  columnsConfig: ColumnConfigWithRender<Data, Handlers>[];
  additionalSearches: SearchConfig<Data>[];
  addFilters: (filters: AppFilters) => void;
  urlFilters: UrlParams;
  defaultSearchField?: string;
}

type SearchesType<Data, Handlers> = Array<SearchConfig<Data> | ColumnConfigWithRender<Data, Handlers>>;

export default function Search<Data, Handlers>(props: SearchProps<Data, Handlers>) {
  const {t} = useTranslation();
  const {columnsConfig, addFilters, additionalSearches = [], urlFilters, defaultSearchField} = props;
  const [searchBy, setSearchBy] = useState<string>(defaultSearchField ?? '');
  const [search, setSearch] = useState<string>('');
  const handleChange = (event: SelectChangeEvent) => {
    setSearchBy(event.target.value);
  };

  const handleInput = (event: { target: { value: string; }; }) => {
    setSearch(event.target.value);
  };

  const handleSearch: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      if (search === '') {
        addFilters({[searchBy]: null})
      } else {
        addFilters({[searchBy]: search})
      }
    }
  }

  const resetSearch = () => {
    setSearchBy('');
    setSearch('');

    const emptyObj: AppFilters = {};
    searchedColumns.forEach(column => emptyObj[column.field] = null);
    addFilters(emptyObj)
  };

  const defaultColumns = columnsConfig.filter(columnConfig => columnConfig.search);

  const searchedColumns: SearchesType<Data, Handlers> = [...defaultColumns, ...additionalSearches]

  const urlSearchByConfig = searchedColumns.find(column => urlFilters[column.field]);
  let defaultValue: string | null = null;
  if (urlSearchByConfig) {
    defaultValue = urlFilters[urlSearchByConfig.field];
  }

  useEffect(() => {
    if (urlSearchByConfig) {
      setSearchBy(urlSearchByConfig.field);
    }
    if (defaultValue !== null) {
      setSearch(defaultValue);
    }
  }, [defaultValue])

  if (!searchedColumns.length) {
    return null;
  }

  return (
      <Box className='search-container' sx={{display: 'flex', alignItems: 'center'}}>
        <FormControl size='small'>
          <InputLabel id="input-label">{t('common.tracker_filters.search')}</InputLabel>
          <Select
              labelId="input-label"
              value={searchBy}
              label="Search"
              onChange={handleChange}
              sx={{minWidth: '100px'}}
          >
            {searchedColumns.map(column => (
                <MenuItem key={column.field} value={column.field}>{t(column.headerName)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
            sx={{minWidth: '200px'}}
            size='small'
            label={t('common.tracker_filters.search_here')}
            value={search}
            onChange={handleInput}
            onKeyDown={handleSearch}
        />
        <Button
            size='small'
            className="reset-search-button"
            variant="contained"
            color='secondary'
            onClick={resetSearch}
        >
          <span className="reset-search-text">{t('common.tracker_filters.reset')}</span>
        </Button>
      </Box>
  );
}