import BaseTableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import React, {Dispatch} from "react";
import {ColumnConfigWithRender, DataRow, HandlerCondition} from "../types";
import {get, isBoolean, isNull, isUndefined, isNumber, isNil} from "lodash";

export interface RowProps<Data, Handlers> {
    refresh: () => void,
    selected: Array<string | number>,
    row: Data,
    uniqueField: string,
    index: number,
    columnsConfig: ColumnConfigWithRender<Data, Handlers>[]
    setSelected: Dispatch<Array<string | number>>,
    fieldHandlers?: HandlerCondition<Data, Handlers>
}

function Row<Data extends DataRow, Handlers>(props: RowProps<Data, Handlers>) {
    const {selected, row, uniqueField, setSelected, columnsConfig, refresh, fieldHandlers} = props;

    const handleClick = (event: React.MouseEvent<unknown>, name: string | number) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: Array<string | number> = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name: string | number) => selected.indexOf(name) !== -1;
    const getUniqueFieldValue = (row: Data, uniqueField: string): string | number => {
        const uniqueFieldValue = row[uniqueField];
        if (typeof uniqueFieldValue === 'string' || typeof uniqueFieldValue === 'number') {
            return uniqueFieldValue;
        }
        throw new Error('Unique field should be string or number');
    };

    const uniqueFieldValue = getUniqueFieldValue(row, uniqueField);

    const isItemSelected = isSelected(uniqueFieldValue);

    const getValue = (row: Data, fieldName: string) => {
        const value = get(row, fieldName);
        if (isBoolean(value) || isNumber(value)) {
            return String(value);
        }
        if (isNil(value)) {
            return '';
        }
        return String(value);
    };

    const cols = columnsConfig.filter(coll => coll.field !== 'actions');


    return (
        <BaseTableRow
            hover
            onClick={(event) => handleClick(event, uniqueFieldValue)}
            role="checkbox"
            aria-checked={isItemSelected}
            selected={isItemSelected}
        >
            {cols.map((cell, index) => {
                if (cell.render) {
                    return (
                        <TableCell key={cell.field + index}>
                            <div
                                style={{width: 'max-content', ...(cell.bold ? {fontWeight: 'bold'} : {})}}>{cell.render(row, cell, refresh, fieldHandlers)}</div>
                        </TableCell>
                    )
                }
                return (
                    <TableCell key={cell.field + index}>
                        <div style={{maxWidth: '400px', ...(cell.bold ? {fontWeight: 'bold'} : {})}}>
                            {getValue(row, cell.field)}
                        </div>
                    </TableCell>
                );
            })}
        </BaseTableRow>
    );
}

export {Row};
