import {PaginatedData} from "../../types";
import {CreateLandingBody, NewLandingData, UpdateLandingBody} from "./types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {fetchLandings} from "../../requests/landingsList/landings";
import {createLandingRequest, updateLandingRequest} from "../../requests/landingsList/landings";

interface LandingsState {
    landings: PaginatedData<NewLandingData> | null;
    searchLandings: PaginatedData<NewLandingData> | null;
    status: RequestStatuses;
}

const initialState: LandingsState = {
    landings: null,
    searchLandings: null,
    status: RequestStatuses.idle,
};

export const getLandings = createAsyncThunk(
    'landings/getLandings',
    async (filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            return await fetchLandings(filters);
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const landingsListSlice = createSlice({
    name: 'landingsList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandings.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandings.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.landings = action.payload;
            });
    },
});

export const createLanding = createAsyncThunk(
    'landing/createLanding',
    async (body: CreateLandingBody) => {
        return await createLandingRequest(body);
    }
);

export const updateLanding = createAsyncThunk(
    'landing/updateLanding',
    async (body: UpdateLandingBody) => {
        return await updateLandingRequest(body);
    }
);

export const selectSearchLandings = (state: RootState) => state.landingsList.searchLandings?.data ?? [];
export const selectLandingsTemp = (state: RootState) => state.landingsList.landings;
export const selectLandings = createSelector(selectLandingsTemp, (data) => {
    if ( data ) {
        return {
            data: data.data,
            query_parameters: {
                count: data.meta.totalRowCount,
                page: data.meta.pageNumber,
                per_page: data.meta.pageSize,
                sort_field: data.meta.sortField,
                sort_order: data.meta.sortOrder
            }
        }
    } else {
        return data;
    }
})
export const selectLanding = (state: RootState, id?: string): NewLandingData | undefined | null => {
    if (!id) {
        return null;
    }
    return state.landingsList.landings?.data?.find((landing) => ( landing.id === id ));
}

export default landingsListSlice.reducer;