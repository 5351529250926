import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import {SvgIcon} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from "react";
import {useTranslation} from "react-i18next";
import {ColumnConfigWithRender, DataRow} from "../types";
import './CellName.scss'

export interface CellNameProps<Data extends DataRow, Handlers> {
  headCell: ColumnConfigWithRender<Data, Handlers>;
}

export function CellName<Data extends DataRow, Handlers>(props: CellNameProps<Data, Handlers>) {
  const {t} = useTranslation();
  const {headCell} = props;

  return (
    <div className="cell-name-container">
      <span className="cell-name-title">{!headCell.noHeader ? t(headCell.headerName) : ''}</span>
      <Tooltip
        sx={{position:"absolute",top:"-10px"}}
        title={headCell?.tooltipTitle
          ? <div dangerouslySetInnerHTML={{__html: headCell?.tooltipTitle || ''}}/>
          : ''
        }
      >
        <Box component="span" sx={{display: 'flex',position:"absolute",right: "0",top:"0"}}>
          {headCell.questionMark && <InfoOutlinedIcon className="hint" />}
        </Box>
      </Tooltip>
    </div>
  )
}