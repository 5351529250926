import {AutocompleteOption} from "../inputs/baseAutocomplete/types";
export const getDataByKey = (arr: {key: number | string, value: any }[], key: number) => {
    return arr.find(item => item.key === key);
}

export const getNewValueArray = (arr: {key: number | string, value: any }[]) => {
    return arr.map(item => item.value);
}

export const getStringValueByKey = (arr: {key: number | string, value: any }[], key: number) => {
    // console.log(arr)
    const item = arr.find(item => item.key === key);
    return item && item.value ? item.value : "";
}
export const getKeyByValue = (arr: {key: any, value: any }[], key: number) => {
    const item = arr.find(item => item.key === key);
    return item && item.value ? item.value : "";
}

export const findOptionByValue = (options: AutocompleteOption[], targetValue: string | number | null): AutocompleteOption | null => {
    const foundOption = options.find(option => option.value === targetValue);

    return foundOption ? foundOption : null;
}

export const filterOptionsByArray = (options: AutocompleteOption[], values: string , multiple: boolean): AutocompleteOption | AutocompleteOption[] => {
    // Split the input string into an array of values
    const valueArray = values.split(',').map((value) => value.trim());

    // Filter the options based on whether their value is included in the valueArray
    const filteredOptions = options.filter((option) =>
        option.value !== null && valueArray.includes(option.value.toString())
    );
    // Return the filtered options as an array or a single object, depending on the length
    return filteredOptions.length === 1 && !multiple ? filteredOptions[0] : filteredOptions;
};

export const sortBySameLevelKey = (arr: {key: number | string, value: any }[]) => {
    arr.sort((a, b) => {
        if (a["key"] < b["key"]) {
            return -1;
        }
        if (a["key"] > b["key"]) {
            return 1;
        }
        return 0;
    });
    return arr;
};