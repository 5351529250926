import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {fetchUsers} from "../../requests/users";
import {UserTeam} from "./types";

export interface UsersState {
  searchUsers: PaginatedData<UserTeam> | null;
  status: RequestStatuses;
}

const initialState: UsersState = {
  searchUsers: null,
  status: RequestStatuses.idle,
};

export const getSearchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (filters: UrlParams | undefined, { rejectWithValue }) => {
    try {
      return await fetchUsers(filters);
    } catch (err) {
      return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSearchUsers.pending, (state) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(getSearchUsers.fulfilled, (state, action) => {
        state.status = RequestStatuses.success;
        state.searchUsers = action.payload;
      });

  },
});

export const selectSearchUsers = (state: RootState) => {
  return state.users.searchUsers?.data ?? []
};
export const selectUsersStatus = (state: RootState) => state.users.status;


export default usersSlice.reducer;
