import {isBoolean, isNil, isObject} from "lodash";
import {AutocompleteOption, GetOptionField} from "./types";
import {AppFilterValue, DataRow} from "../../Table/types";

export const multiplyDataToFilter = (options: AutocompleteOption[]): AppFilterValue => {
    if (options.length === 0) {
        return null;
    }
    return options.map(option => option.value).join(',')
};


export const dataToFilter = (option: AutocompleteOption | null): AppFilterValue => {
    if (option === null) {
        return null;
    }
    const value = option.value;
    if (isBoolean(value)) {
        return value ? 'true' : 'false';
    }

    if (isNil(value)) {
        return null;
    }

    if (isObject(value)) {
        throw new Error('filter value should be a string or number type')
    }

    return value;
};

export const getOptionValue: GetOptionField = (option: DataRow | null, optionValue: string) => String(option?.[optionValue]);
export const getOptionLabel: GetOptionField = (option: DataRow | null, optionLabel: string) => String(option?.[optionLabel]);


export const transformDataToAutocomplete = (data: DataRow[] | null, optionLabel: string, optionValue: string): AutocompleteOption[] => {
    if (!data) {
        return [];
    }

    return data.map(item => ({
        value: getOptionValue(item, optionValue),
        label: getOptionLabel(item, optionLabel),
    }))
}

