import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {fetchLandingsListProducts} from "../../requests/landingsList/products";
import {NewProductData} from "./types";
import {RootState} from "../../store";

export interface LandingsListProductsState {
    product: PaginatedData<NewProductData> | null;
    status: RequestStatuses;
}

const initialState: LandingsListProductsState = {
    product: null,
    status: RequestStatuses.idle,
};

export const getLandingsListProducts = createAsyncThunk(
    'products/fetchLandingsListProducts',
    async (filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            const defaultFilters = { pageSize: '1000' };

            return await fetchLandingsListProducts({ ...defaultFilters, ...filters });
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const landingsListProductsSlice = createSlice({
    name: 'landingsListProducts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingsListProducts.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandingsListProducts.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.product = action.payload;
            });
    },
});

export const selectLandingsListProducts = (state: RootState) => state.landingsListProduct.product?.data ?? [];
export const selectLandingsListProductsStatus = (state: RootState) => state.landingsListProduct.status;
export const selectLandingsListProductsObj = createSelector(selectLandingsListProducts, (data) => {
    if ( !!data.length ) {
        return data.reduce((obj: any, item: { [key: string]: any }) =>{
            return {
                ...obj,
                [`${item.id}`]: item,
            };
        }, {})
    } else {
        return data;
    }
});

export default landingsListProductsSlice.reducer;