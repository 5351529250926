import React, {Dispatch, useEffect} from 'react';
import './AppliedFilterBar.scss';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import {AppFilters} from "../types";
import {FilterTitleChip} from "./FilterTitleChip";
import {FiltersFromObj} from "./types";
import {singleChipListFromObj} from "./constants";

interface FiltersProps {
    filtersList: string[];
    fieldName: string;
    addFilters: (filters: AppFilters) => void;
    setShowBorder: Dispatch<boolean>;
    tableName?: string;
    setFiltersListToRemove?: Dispatch<FiltersFromObj>;
}

export function FilterWithNotUrlData(props: FiltersProps) {
    const {t} = useTranslation();
    const {filtersList, fieldName, addFilters, setShowBorder, tableName, setFiltersListToRemove} = props;

    const renderTitle = (fieldName?: string) => {
        switch (tableName){
            case 'statistics':
                return t('statistic.table.columns.'+fieldName);
            default:
                return t('common.tracker_filters.'+fieldName);
        }
    }

    useEffect(() => {
        setShowBorder(filtersList.length > 0);
    }, [filtersList, setShowBorder]);

    if (singleChipListFromObj.includes(fieldName) && filtersList.length > 0) {
        return (
            <Box className="filter-chip__block">
                <Box className="filter-chip__block-title">
                    {renderTitle(fieldName)}
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {filtersList.map((filter, index) => (
                        <FilterTitleChip
                            key={filter + index}
                            fieldName={fieldName}
                            urlFilter={filter}
                            addFilters={addFilters}
                            setFiltersListToRemove={setFiltersListToRemove}
                        />
                    ))}
                </Box>
            </Box>
        );
    }

    return null;
}
