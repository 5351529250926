import React, {Dispatch, useState} from 'react';
import './DropdownTransferList.scss'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {ClickAwayListener} from "@mui/material";
import TransferList from "./TransferList/TransferList";
import {useTranslation} from "react-i18next";
import {AdditionalFilters} from "../Table/ControlBarWrapper/ControlBarStatistics/types";

export interface IDropdownTransferList {
    additionalFilters: AdditionalFilters[];
    setAdditionalFilters: Dispatch<AdditionalFilters[]>;
}

const DropdownTransferList: React.FC<IDropdownTransferList> = (props): JSX.Element => {
    const {setAdditionalFilters, additionalFilters} = props;
    const {t} = useTranslation();
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [activeOption, setActiveOption] = useState<number | null>(null);

    const handleOpenDropdown = () => {
        setOpenDropdown(prevState => !prevState);
    }

    const handleClickAway = () => {
        setOpenDropdown(false);
        setActiveOption(null);
    }

    const handleMouseEnter = (index: number) => {
        setActiveOption(index);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="dropdown">
                <div
                    className={openDropdown ? 'dropdown-btn dropdown-btn-open' : 'dropdown-btn'}
                    onClick={handleOpenDropdown}
                >
                    <AddCircleIcon/>
                    <p>{t('common.filters.aditional_filtres')}</p>
                </div>

                <div className={openDropdown ? 'options-list options-list-open' : 'options-list'}>
                    {additionalFilters.length && additionalFilters?.map((filter, index) => {
                        return (
                            <div
                                key={filter.filterName}
                                className={index === activeOption ? "option active" : "option"}
                                onMouseEnter={() => handleMouseEnter(index)}
                            >
                                {t('statistic.table.columns.'+ filter.filterName)}
                                <TransferList
                                    filters={filter.filters}
                                    setAdditionalFilters={setAdditionalFilters}
                                    filterName={filter.filterName}
                                    additionalFilters={additionalFilters}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </ClickAwayListener>
    );
}

export default DropdownTransferList;