import React from 'react';
import {
    DirectUrlFormState,
    LandingFormState,
    SplittingFormState,
    DirectUrlFormValidation,
    LandingFormValidation,
    SplittingFormValidation, dateRangesType
} from "../type";
import RenderFormFields from "./RenderFormFields/RenderFormFields";
import {Node} from "../../types";
import {TrackerData} from "../../../../app/reducers/prism/types";

interface FormRuleConfigurationProps {
    formName: string;
    values: Array<SplittingFormState | LandingFormState| DirectUrlFormState>;
    handleInput: (name: string, value: string, index: number, formName: string) => void;
    handleSetFieldValue: (field: string, formName: string, value: string, index: number) => void;
    errors?: Array<SplittingFormValidation | DirectUrlFormValidation | LandingFormValidation>;
    handleDeleteSplittingBlock: (index: number) => void;
    ruleEdit: Node | null;
    scheduleEdit: TrackerData | null;
    blockId: number,
}

function FormRuleConfiguration(props: FormRuleConfigurationProps) {
    const {
        blockId,
        formName,
        values,
        handleInput,
        handleSetFieldValue,
        errors,
        handleDeleteSplittingBlock,
        ruleEdit,
        scheduleEdit
    } = props

    let renderFormBlock;

    if (values) {
        renderFormBlock = values.map((item: SplittingFormState | LandingFormState| DirectUrlFormState, i: number) =>
            <RenderFormFields
                key={i}
                blockId={blockId}
                valuesItem={i}
                values={values}
                handleInput={handleInput}
                formName={formName}
                handleSetFieldValue={handleSetFieldValue}
                errors={errors}
                handleDeleteSplittingBlock={handleDeleteSplittingBlock}
                ruleEdit={ruleEdit}
                scheduleEdit={scheduleEdit}
            />
        )
    }

    return (
        <>
            {renderFormBlock}
        </>
    );
}

export default FormRuleConfiguration;
