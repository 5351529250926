import React, {Dispatch, useEffect, useState} from 'react';
import {TrackerData} from "../../../app/reducers/prism/types";
import {SmartlinkEditModalStyled} from "./styles";
import DarkTooltip from "../../DarkTooltip/DarkTooltip";
import {useAppDispatch} from "../../../app/hooks";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TextField from "@mui/material/TextField";
import {updateTracker, updateTrackerSettings} from "../../../app/reducers/prism/trackersSlice";
import CustomAlert from "../../Alert/CustomAlert";


interface RuleTreeProps {
    tracker: TrackerData;
    setOpen: Dispatch<React.SetStateAction<boolean>>;
}

const SmartlinkEditModal: React.FC<RuleTreeProps> = (props): JSX.Element => {
    const {t} = useTranslation();
    const {tracker, setOpen} = props;
    const [values, setValues] = useState<{name: string, traffic_back_url: string}>({name: "", traffic_back_url: ""});
    const [saveAlert, setSaveAlert] = useState<string>("");
    const dispatch = useAppDispatch();
    useEffect(() => {
        setValues({name: tracker?.title ?? "", traffic_back_url: tracker?.settings?.traffic_back_url ?? ''})
    }, [tracker]);

    const handleClose = () => {
        setOpen(false)
    }

    const validateName = () => {
        if (values?.name === "" || values?.name === undefined) {return ""}
        const regex = /^[A-Za-z0-9-_\.]+$/;
        if (!regex.test(values.name)) {
            return t("smartlinks.modal_rule_configuration.error_special_chars");
        }
        if ( values.name.length > 120) {
            return t("smartlinks.modal_rule_configuration.error_120_max");
        }
        if ( saveAlert.length > 0) {
            return saveAlert;
        }
        return "";
    }
    const validateTrafficBackUrl = () => {
        if (values.traffic_back_url === ""){return true}
        try {
            new URL(values.traffic_back_url);
            return true;
        } catch (error) {
            return false;
        }
    }

    const onSubmitHandler = async () => {
        if (tracker?.settings) {
            await dispatch(updateTrackerSettings({
                tracker_id: tracker.settings.tracker_id,
                traffic_back_url: values.traffic_back_url
            }));
        }
        const newTracker = {
            ...tracker,
            title: values.name
        };
        const response = await dispatch(updateTracker(newTracker));
        if (response.payload.responseStatus === 400) {
            setSaveAlert(response.payload.messages.title);
        } else {
            setOpen(false);
        }
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({name: e.target.value, traffic_back_url: values.traffic_back_url});
        setSaveAlert("");
    };
    const handleChangeTrafficBackUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({name: values.name, traffic_back_url: e.target.value})
    };

    return (
        <>
            <SmartlinkEditModalStyled open={true} onClose={handleClose}>
                <div className="container">
                    <div className="close-button">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <DialogTitle>{t('smartlinks.edit.title')}</DialogTitle>
                    <DialogContentText className={"subheading"} sx={{fontSize: '16px'}}>
                        {t('smartlinks.edit.subtitle')}
                        <DarkTooltip  placement="top" title={"test"}>
                            <InfoOutlinedIcon sx={{marginLeft: "6px"}} fontSize="small" className="editable_text__tooltip"/>
                        </DarkTooltip>
                    </DialogContentText>
                    <DialogContent>
                        <TextField
                            error={validateName() !== ""}
                            name="from"
                            fullWidth
                            label={t('smartlinks.edit.name_label')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>handleChangeName(e)}
                            value={values?.name}
                            helperText={validateName()}
                        />
                        <TextField
                            error={!validateTrafficBackUrl()}
                            name="from"
                            fullWidth
                            label={t('smartlinks.edit.traffic_back_url_label')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>handleChangeTrafficBackUrl(e)}
                            value={values?.traffic_back_url}
                            helperText={!validateTrafficBackUrl() ? t("smartlinks.modal_rule_configuration.error_wrong_url") : ""}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button
                        onClick={onSubmitHandler}
                        disabled={(validateName().length !== 0) || !validateTrafficBackUrl()}
                        className="BtnPurple" >
                        {t('common.buttons.save')}
                    </Button>
                    <Button onClick={handleClose} className="BtnGrey">{t('common.buttons.cancel')}</Button>
                    </DialogActions>
                </div>
            </SmartlinkEditModalStyled>
        </>
    )
}

export default SmartlinkEditModal;