import {UrlParams} from "../types";
import {useNavigate} from "react-router-dom";
import qs from "query-string";
import {useEffect} from "react";

export function useFiltersNavigate(filters: UrlParams) {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(filters);
    const newSearch = qs.parse(urlParams.toString());
    const newSearchString = qs.stringify(newSearch);

    useEffect(() => {
        navigate('?' + newSearchString, {replace: false});
    }, [navigate, newSearchString]);
}
