import React from 'react';
import './Sidebar.scss';
import CustomListItem from "./CustomListItem";
import List from "@mui/material/List";
import {MenuItemType} from "./types";

interface SideBarProps {
    menuItems: MenuItemType[]
    open: boolean;
    active: { active: string, parent: string };
    setActive: (active: string, parent: string) => void;
}

function CustomList(props: SideBarProps) {
    const {
        menuItems,
        open,
        active,
        setActive
    } = props;

    return (
        <List>
            {menuItems.map((item, index) => (
                <CustomListItem
                    menuItem={item}
                    open={open}
                    active={active}
                    setActive={setActive}
                    key={index}
                />
            ))}
        </List>
    );
}

export default CustomList;