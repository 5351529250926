const Routes = {
    root: '/',
    login: '/login',
    error: '/error/:code',
    statistic: '/statistic',
    trackers: '/trackers',
    tracker_detail: '/trackers/:trackerId',
    default_trackers: '/default-trackers',
    landings: '/landings',
    products: '/products',
    shortener: '/shortener',
    shortener_detail: '/shortener/:shortlinkId',
    site_checks: '/sites/checks/:targetId',
    site_detail: '/sites/check/detail/:checkId',
    site_statistic: '/sites/statistic/:targetId',
    logout: '/logout'
};

export default Routes;