import {PaginatedData} from "../../types";
import {CreateLandingArguments, LandingData, UpdateLandingArguments} from "./types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {createLandingRequest, updateLandingRequest, fetchLandings} from "../../requests/landings";

export interface LandingsState {
    landings: PaginatedData<LandingData> | null;
    searchLandings: PaginatedData<LandingData> | null;
    status: RequestStatuses;
}

const initialState: LandingsState = {
    landings: null,
    searchLandings: null,
    status: RequestStatuses.idle,
};

export const getLandings = createAsyncThunk(
    'landings/fetchLandings',
    async (filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            return await fetchLandings(filters);
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const landingsSlice = createSlice({
    name: 'landings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandings.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandings.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.landings = action.payload;
            });

    },
});

export const createLanding = createAsyncThunk(
    'landing/createLanding',
    async ({brandId, title, path, label, teamId}: CreateLandingArguments) => {
        return await createLandingRequest(brandId, title, path, label, teamId);
    }
);

export const updateLanding = createAsyncThunk(
    'landing/updateLanding',
    async ({landingId, brandId, title, path, label, teamId}: UpdateLandingArguments) => {
        return await updateLandingRequest(landingId, brandId, title, path, label, teamId);
    }
);

export const selectSearchLandings = (state: RootState) => state.landings.searchLandings?.data ?? [];
export const selectLandings = (state: RootState) => state.landings.landings;
export const selectLanding = (state: RootState, id?: number): LandingData | undefined | null => {
    if (!id) {
        return null;
    }
    return state.landings.landings?.data?.find((landing) => ( landing.id === id ));
}

export default landingsSlice.reducer;