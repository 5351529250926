import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {Language, Device, Country, City, Region} from "../../../componets/Smartlink/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    fetchCountries,
    fetchCity,
    fetchLanguages,
    fetchDevices,
    fetchRegions
} from "../../requests/smartlinkNodes";
import {RootState} from "../../store";

export interface SmartlinkNodes {
    Countries: { data: Country[] } | null;
    Languages: { data: Language[] } | null;
    City: City[] | null;
    Devices: { data: Device[] } | null;
    Regions: Region[] | null;
    CountriesStatus: RequestStatuses;
    LanguagesStatus: RequestStatuses;
    CityStatus: RequestStatuses;
    DevicesStatus: RequestStatuses;
    RegionsStatus: RequestStatuses;
}

const initialState: SmartlinkNodes = {
    Countries: null,
    Languages: null,
    City: null,
    Devices: null,
    Regions: null,
    CountriesStatus: RequestStatuses.idle,
    LanguagesStatus: RequestStatuses.idle,
    CityStatus: RequestStatuses.idle,
    DevicesStatus: RequestStatuses.idle,
    RegionsStatus: RequestStatuses.idle,
};

export const getSmartlinkNodesCountries = createAsyncThunk(
    'smartlinkNodes/fetchSmartlinkNodesCountries',
    async ( filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            return await fetchCountries();
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getSmartlinkNodesLanguages = createAsyncThunk(
    'smartlinkNodes/fetchSmartlinkNodesLanguages',
    async ( filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            return await fetchLanguages();
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getSmartlinkNodesDevices = createAsyncThunk(
    'smartlinkNodes/fetchSmartlinkNodesDevices',
    async ( filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            return await fetchDevices();
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getSmartlinkNodesCity = createAsyncThunk(
    'smartlinkNodes/fetchSmartlinkNodesCity',
    async ({alternatenames, country_code, filter_region}: {alternatenames: string, country_code: string, filter_region: string}) => {
        return await fetchCity(alternatenames, country_code, filter_region);
    }
);

export const getSmartlinkNodesRegions = createAsyncThunk(
    'smartlinkNodes/fetchSmartlinkNodesRegions',
    async ({asciiname, country_code}: {asciiname: string, country_code: string}) => {
        return await fetchRegions(asciiname, country_code);
    }
);

export const smartlinkNodesSlice = createSlice({
    name: 'smartlinkNodes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSmartlinkNodesCountries.pending, (state) => {
                state.CountriesStatus = RequestStatuses.loading;
            })
            .addCase(getSmartlinkNodesCountries.fulfilled, (state, action) => {
                state.CountriesStatus = RequestStatuses.success;
                state.Countries = action.payload;
            })
            .addCase(getSmartlinkNodesLanguages.pending, (state) => {
                state.LanguagesStatus = RequestStatuses.loading;
            })
            .addCase(getSmartlinkNodesLanguages.fulfilled, (state, action) => {
                state.LanguagesStatus = RequestStatuses.success;
                state.Languages = action.payload;
            })
            .addCase(getSmartlinkNodesDevices.pending, (state) => {
                state.DevicesStatus = RequestStatuses.loading;
            })
            .addCase(getSmartlinkNodesDevices.fulfilled, (state, action) => {
                state.DevicesStatus = RequestStatuses.success;
                state.Devices = action.payload;
            })
            .addCase(getSmartlinkNodesCity.pending, (state) => {
                state.CityStatus = RequestStatuses.loading;
            })
            .addCase(getSmartlinkNodesCity.fulfilled, (state, action) => {
                state.CityStatus = RequestStatuses.success;
                state.City = action.payload;
            })
            .addCase(getSmartlinkNodesRegions.pending, (state) => {
                state.RegionsStatus = RequestStatuses.loading;
            })
            .addCase(getSmartlinkNodesRegions.fulfilled, (state, action) => {
                state.RegionsStatus = RequestStatuses.success;
                state.Regions = action.payload;
            })
    },
});

export const selectSmartlinkNodesCountries = (state: RootState) => state.smartlinkNodes.Countries;
export const selectSmartlinkNodesLanguages = (state: RootState) => state.smartlinkNodes.Languages;
export const selectSmartlinkNodesDevices = (state: RootState) => state.smartlinkNodes.Devices;
export const selectSmartlinkNodesCity = (state: RootState) => state.smartlinkNodes.City;
export const selectSmartlinkNodesRegions = (state: RootState) => state.smartlinkNodes.Regions;

export default smartlinkNodesSlice.reducer;
