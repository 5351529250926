import React, {useCallback, useRef} from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import {AppFilters, AppFilterValue, UrlParams} from "../types";
import {AutocompleteFilter} from "../filterInputs/Autocomplete/AutocompleteFilter";
import {FilterConfig, FilterConfigType} from "./types";
import {DateTimeRangeFilter} from "../filterInputs/DateTimePicker/DateTimeRangeFilter";
import {SelectFilter} from "../filterInputs/Select/SelectFilter";
import './Filters.css';
import { useTranslation } from "react-i18next";

interface FiltersProps {
    filtersConfig: FilterConfig[];
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    clearFilters: () => void;
}

export function Filters(props: FiltersProps) {
    const { t } = useTranslation();
    const {filtersConfig, urlFilters, addFilters, clearFilters} = props;
    const {current: defaultFilters} = useRef<UrlParams>(urlFilters);

    const addFiltersCallBack = useCallback(addFilters, [addFilters]);

    const onChangeHandler = useCallback((field: string) => (optionValue: AppFilterValue | null) =>
            addFiltersCallBack({[field]: optionValue}),
        [addFiltersCallBack]
    );
    const defaultDateFilters = {from: urlFilters['date[from]'], to: urlFilters['date[to]']};

    return (
        <div className="table-filters">
            {filtersConfig.map((config) => {
                if (config.type === FilterConfigType.select) {
                    return (
                        <SelectFilter
                            key={config.field}
                            fieldName={config.field}
                            defaultOptionValue={config.defaultValue}
                            valueMutator={config.valueMutator}
                            onChangeHandler={onChangeHandler(config.field)}
                            placeholder={config.placeholder}
                            options={config.options}
                            urlFilters={urlFilters}
                        />
                    );
                }

                if (config.type === FilterConfigType.autocomplete) {
                    return (
                        <AutocompleteFilter
                            key={config.field}
                            onChangeHandler={(option) => {
                                onChangeHandler(config.field)(option)
                            }}
                            optionLabel={config.optionLabel}
                            optionValue={config.optionValue}
                            optionKey={config.field}
                            placeholder={config.placeholder}
                            asyncAction={config.asyncAction}
                            dataSelector={config.dataSelector}
                            multiselect={config.multiselect}
                            statusSelector={config.statusSelector}
                            defaultFilters={defaultFilters}
                            values={urlFilters}
                        />
                    );
                }

                if (config.type === FilterConfigType.dateRange) {
                    return (
                        <DateTimeRangeFilter
                            key={config.type}
                            urlFilters={urlFilters}
                            defaultDateFilters={defaultDateFilters}
                            onChangeHandler={addFilters}
                        />
                    );
                }

                return null;
            })}
            <Button
                size='small'
                className="clear-filters-button"
                variant="contained"
                color='secondary'
                onClick={clearFilters}
            >
                <span className="clear-filters-text">{t('common.tracker_filters.reset_all')}</span>
                <DeleteIcon sx={{width: '12px', height: '12px'}}/>
            </Button>
        </div>
    );
}
