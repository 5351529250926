import React, {useState, useEffect, useId} from 'react';
import './Statistics.scss';
import Table from "../Table/Table";
import {
    botClicksCount,
    browser,
    click2Fd,
    click2Reg,
    company,
    landing,
    language,
    os,
    platform,
    product,
    geo,
    traffic_type,
    reg2Fd,
    region,
    smartLink,
    team,
    period,
    unique_clicks,
    regs,
    first_deposit,
    second_deposits
} from "./columns";
import {ColumnConfig, ColumnConfigWithRender} from "../Table/types";
import {useAppSelector} from "../../app/hooks";
import TablePage from "../Page/TablePage";
import {useTranslation} from "react-i18next";
import {ItemProps} from "../MultiSelect/MultiSelect";
import cloneDeep from 'lodash/cloneDeep';
import {createReport, selectReports} from "../../app/reducers/prism/reportSlice";
import {ReportsData} from "../../app/reducers/prism/types";
import {selectSelfUser} from "../../app/reducers/prism/sessionSlice";
import Tooltip from "../Tooltip/Tooltip";
import CustomAlert from "../Alert/CustomAlert";
import {PaginatedData} from "../../app/types";
import {statisticsColumnsWithoutTotal} from "./constants";
import theme from "../UI/Theme";

type StatisticHandlersList = {};

function Statistics() {
    const {t} = useTranslation();
    const data = useAppSelector(selectReports);
    const selfUser = useAppSelector(selectSelfUser);
    const isAdmin = selfUser?.role.alias === 'admin';
    const title = t("statistic.general_report");
    const description = t("statistic.brands.tables.header.description");
    const dynamicColsCount = isAdmin ? 13 : 12;
    const totalKey = useId();
    const [trackersStatisticCol, setTrackersStatisticCol] = useState<ColumnConfig[]>([]);
    const [statisticTableColumns, setStatisticTableColumns] = useState<ItemProps[]>([]);
    const [isHideTableBody, setIsHideTableBody] = useState<boolean>(false);
    const [showInfoAlert, setShowInfoAlert] = useState<boolean>(true);
    const [updatedData, setUpdatedData] = useState<PaginatedData<ReportsData> | null>(data);
    const [firstColOnTable, setFirstColOnTable] = useState<string>('');
    const commonColumns: ColumnConfigWithRender<ReportsData, StatisticHandlersList>[] = [
        {
            ...team,
            render: (row, cell, refresh, handlers) => {
                return row.team ? <>{row.team}</> : <></>;
            },
        },
        {
            ...smartLink,
            render: (row, cell, refresh, handlers) => {
                return (
                    <>
                        {row.smartLinkInternalId && <p className="smart-link-id">ID {<>{row.smartLinkInternalId}</>}</p>}
                        <p className="smart-link-name">{<>{row.smartLinkName}</>}</p>
                    </>
                );
            },
        },
        {
            ...product,
            render: (row, cell, refresh, handlers) => {
                return row.product ? <>{row.product}</> : <></>;
            },
        },
        {
            ...geo,
            render: (row, cell, refresh, handlers) => {
                return row.countryCode ? <>{row.countryCode}</> : <></>;
            },
        },
        {
            ...region,
            render: (row, cell, refresh, handlers) => {
                return row.region ? <>{row.region}</> : <></>;
            },
        },
        {
            ...platform,
            render: (row, cell, refresh, handlers) => {
                return row.platform ? <>{row.platform}</> : <></>;
            },
        },
        {
            ...os,
            render: (row, cell, refresh, handlers) => {
                return row.os ? <>{row.os}</> : <></>;
            },
        },
        {
            ...browser,
                render: (row, cell, refresh, handlers) => {
                return row.browser ? <>{row.browser}</> : <></>;
            },
        },
        {
            ...language,
            render: (row, cell, refresh, handlers) => {
                return row.language ? <>{row.language}</> : <></>;
            },
        },
        {
            ...landing,
            render: (row, cell, refresh, handlers) => {
                if (!row.redirectUrl) {
                    return <></>;
                }
                return React.isValidElement(row.redirectUrl)
                    ? <>{row.redirectUrl}</>
                    : <Tooltip
                        url={row.redirectUrl.startsWith('https://') ? row.redirectUrl : `https://${row.redirectUrl}`}
                        styles={{ color: theme.palette.success.main }}
                        numberLetters={15}
                    />;
            }
        },
        {
            ...traffic_type,
            render: (row, cell, refresh, handlers) => {
                return row.trafficType ? <>{row.trafficType}</> : <></>;
            },
        },
        {
            ...period,
            render: (row, cell, refresh, handlers) => {
                return (
                    <>
                        {row.date && Array.isArray(row.date)
                            ? row.date[0] === row.date[1]
                                ? row.date[0]
                                : `${row.date[0]} - ${row.date[1]}`
                            : (row.date && !Array.isArray(row.date)) && row.date}
                    </>
                );
            },
        },
        {
            ...botClicksCount,
            render: (row, cell, refresh, handlers) => {
                return (
                    <>
                        {row.totalClicksCount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </>
                );
            },
        },
        {
            ...unique_clicks,
            render: (row, cell, refresh, handlers) => {
                return (
                    <>
                        {row.uniqueClicksCount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </>
                );
            },
        },
        regs,
        first_deposit,
        second_deposits,
        {
            ...click2Reg,
            render: (row, cell, refresh, handlers) => {
                return (
                    row.click2Reg !== null && row.click2Reg !== undefined
                    ? <>{row.click2Reg + '%'}</>
                    : <></>
                );
            },
        },
        {
            ...click2Fd,
            render: (row, cell, refresh, handlers) => {
                return (
                    row.click2Fd !== null && row.click2Fd !== undefined
                        ? <>{row.click2Fd + '%'}</>
                        : <></>
                );
            },
        },
        {
            ...reg2Fd,
            render: (row, cell, refresh, handlers) => {
                return (
                    row.reg2Fd !== null && row.reg2Fd !== undefined
                        ? <>{row.reg2Fd + '%'}</>
                        : <></>
                );
            },
        }
    ];

    const statisticColumns: ColumnConfigWithRender<ReportsData, StatisticHandlersList>[] = isAdmin
        ? [
            {
                ...company,
                render: (row, cell, refresh, handlers) => {
                    return row.company ? <>{row.company}</> : <></>;
                }
            },
            ...commonColumns
        ]
        : [...commonColumns];
    const leftCols = cloneDeep(statisticColumns).splice(0, dynamicColsCount);
    const rightCols = cloneDeep(statisticColumns).splice(dynamicColsCount);

    const commonOptions = [
        {id: "smartLinkId", content: t("statistic.table.columns.smart_link_id_name"), disabled: false},
        {id: "teamId", content: t("statistic.table.columns.teams"), disabled: false},
        {id: "productId", content: t("statistic.table.columns.products"), disabled: false},
        {id: "countryCode", content: t("statistic.table.columns.countryCode"), disabled: false},
        {id: "region", content: t("statistic.table.columns.region"), disabled: false},
        {id: "platform", content: t("statistic.table.columns.platform"), disabled: false},
        {id: "os", content: t("statistic.table.columns.os"), disabled: false},
        {id: "browser", content: t("statistic.table.columns.browser"), disabled: false},
        {id: "language", content: t("statistic.table.columns.language"), disabled: false},
        {id: "redirectUrl", content: t("statistic.table.columns.landing"), disabled: false},
        {id: "trafficType", content: t("statistic.table.columns.trafficType"), disabled: false},
        {id: "day", content: t("statistic.table.columns.day"), disabled: false},
        {id: "week", content: t("statistic.table.columns.week"), disabled: false},
        {id: "month", content: t("statistic.table.columns.month"), disabled: false}
    ]
    const options = isAdmin
        ? [
            ...commonOptions,
            {id: "companyId", content: t("statistic.table.columns.companies"), disabled: false}
        ]
        : commonOptions;

    const generateTotalRow = (data: PaginatedData<ReportsData>) => {
        const row = { ...data.total, key: totalKey };
        if (firstColOnTable && !Object.keys(data.total).includes(firstColOnTable) && !statisticsColumnsWithoutTotal.includes(firstColOnTable)) {
            row[firstColOnTable] = <p>{t("statistic.table.total")}</p>;
        }
        return row;
    }

    useEffect(() => {
        if (statisticTableColumns.length > 0) {
            let tempLeftCol: ColumnConfig[] = [];
            statisticTableColumns.forEach(col => {
                leftCols.forEach(item => {
                    if (col.id === item.field) {
                        tempLeftCol.push(item);
                    }
                    const datePeriods = ['day', 'week', 'month'];
                    if (datePeriods.some(period => period === col.id) && item.field === 'date') {
                        tempLeftCol.push(item);
                    }
                    if (col.id === 'smartLinkId' && item.field === 'smartLinkName') {
                        tempLeftCol.push(item);
                    }
                    if (col.id === 'companyId' && item.field === 'company') {
                        tempLeftCol.push(item);
                    }
                    if (col.id === 'teamId' && item.field === 'team') {
                        tempLeftCol.push(item);
                    }
                    if (col.id === 'productId' && item.field === 'product') {
                        tempLeftCol.push(item);
                    }
                })
            })

            setTrackersStatisticCol([...tempLeftCol, ...rightCols]);
        } else {
            setTrackersStatisticCol(statisticColumns);
        }
    }, [statisticTableColumns]);

    useEffect(() => {
        if (data) {
            const dataTemp = {
                ...data,
                data: [generateTotalRow(data), ...data.data],
            };
            setUpdatedData(dataTemp);
        }
    }, [data, firstColOnTable]);

    useEffect(() => {
        setIsHideTableBody(true);
    }, []);

    return (
        <div className="page-left-offset">
            <TablePage
                title={title}
                description={description}
            />
            <Table
                tableName="statistics"
                columnsConfig={trackersStatisticCol}
                requestHandler={createReport}
                data={updatedData}
                uniqueField='key'
                tableColumns={options}
                setStatisticTableColumns={setStatisticTableColumns}
                isHideTableBody={isHideTableBody}
                setIsHideTableBody={setIsHideTableBody}
                columnsConfigForCustomize={statisticColumns}
                setFirstColOnTable={setFirstColOnTable}
            />
            <CustomAlert
                type="info"
                title={t('statistic.for_updating_report')}
                status={showInfoAlert}
                onClose={() => setShowInfoAlert(false)}
            />
        </div>
    );
}

export default Statistics;