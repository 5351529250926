import {BaseColumn, ColumnConfig} from "./types";

export const baseColumn: BaseColumn = {
    numeric: false,
    customisable: true,
};

export const bulk_check: ColumnConfig = {
    ...baseColumn,
    field: 'bulk',
    headerName: '',
};