import React, {Dispatch, useEffect, useRef} from 'react';
import {Box} from "@mui/material";
import BaseSelectWithInput from "../../inputs/BaseSelectWithInput";
import {validateRuleList, validateRuleTypeList} from "../ModalRuleConfiguration/ruleHelpers";
import {AllNodeRulesNames, Node, NodeRuleName, RelationsData, typesRules} from "../types";
import {AutocompleteOption} from "../../inputs/baseAutocomplete/types";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {TrackerData} from "../../../app/reducers/prism/types";
import {useTranslation} from "react-i18next";
import {isArray} from "lodash";
import {SmartlinkRuleFormBlockStyled} from "./styles";

interface SmartlinkRuleFormBlock {
    treeData: { nodes: Node[], relations: RelationsData[] } | null;
    parentType: string | null;
    parentId: number | null;
    activeRuleValue: AutocompleteOption | AutocompleteOption[] | null;
    setActiveRuleValue: Dispatch<React.SetStateAction<AutocompleteOption | AutocompleteOption[] | null>>;
    activeRule: string | null;
    setActiveRule: Dispatch<React.SetStateAction<string | null>>;
    isMultipleTypes: () => boolean;
    handleInputCreate: (value: string) => void;
    nodeTypesOptions: AutocompleteOption[];
    nodeTypesValue: AutocompleteOption | AutocompleteOption[] | null;
    setNodeTypesValue: Dispatch<React.SetStateAction<AutocompleteOption | AutocompleteOption[] | null>>;
    setNodeTypes: Dispatch<React.SetStateAction<string>>;
    ruleEdit: Node | null;
    scheduleEdit: TrackerData | null;
}

const SmartlinkRuleFormBlock: React.FC<SmartlinkRuleFormBlock> = (props): JSX.Element => {
    const {t} = useTranslation();
    const {
        treeData,
        parentType,
        parentId,
        activeRuleValue,
        setActiveRuleValue,
        activeRule,
        setActiveRule,
        isMultipleTypes,
        handleInputCreate,
        nodeTypesOptions,
        nodeTypesValue,
        setNodeTypesValue,
        ruleEdit,
        scheduleEdit,
        setNodeTypes
    } = props;

    const allNodeRulesNames = AllNodeRulesNames.map((nodeRuleName: NodeRuleName) => ({
        label: t(nodeRuleName.label),
        value: nodeRuleName.value
    }));

    const handleChangeRule = (value: AutocompleteOption | AutocompleteOption[] | null) => {
        if (!isArray(value)) {
            setActiveRule(String(value?.value));
        }

        if (value === null) {
            setActiveRule(null);
        }
    }

    const transformValues = (values: AutocompleteOption | AutocompleteOption[] | null) => {
        if (isArray(values)) {
            return values.map((item: AutocompleteOption) => item.value).join();
        }

        if (values === null) {
            return "";
        }

        return String(values.value)
    }

    const ref = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: "center" });
    }, [])

    return (
        <SmartlinkRuleFormBlockStyled>
            <Box className="row smartlink-rule-form-block" ref={ref}>
                <Box className="smartlink-rule-form">
                                    <span className="smartlink-rule-form__condition">
                                        {t('smartlinks.create_form.if')}
                                    </span>
                    <BaseSelectWithInput
                        value={activeRuleValue}
                        className="autocomplete-rule"
                        fieldName="rule_select"
                        optionsList={validateRuleList(parentType, parentId, allNodeRulesNames, treeData)}
                        placeholder={"Select rule"}
                        onChange={(event, value) => {
                            setNodeTypesValue([]);
                            handleChangeRule(value);
                            setActiveRuleValue(value)
                        }}
                        disabled={ruleEdit !== null || scheduleEdit !== null}
                    />
                    {activeRule !== typesRules.single &&
                        <Box className="smartlink-rule-form__switch">
                            <Box className="smartlink-rule-form__switch-variant active">
                                {t('smartlinks.create_form.is')}
                            </Box>
                            {/*<Box className="smartlink-rule-form__switch-variant">*/}
                            {/*    {t('smartlinks.create_form.is_not')}*/}
                            {/*</Box>*/}
                        </Box>}
                    {activeRule !== typesRules.single &&
                        <BaseSelectWithInput
                            value={nodeTypesValue ?? []}
                            onChange={(e, values: AutocompleteOption | AutocompleteOption[] | null) => {
                                setNodeTypesValue(values);
                                setNodeTypes(transformValues(values));
                            }}
                            fieldName="rule_select_type"
                            optionsList={validateRuleTypeList(parentType, parentId,nodeTypesOptions, treeData)}
                            placeholder={"Select type"}
                            className="autocomplete-type"
                            abbreviateName={activeRule === typesRules.geo}
                            handleInputCreate={handleInputCreate}
                            multiple={isMultipleTypes()}
                            // disabled={activeRule === null}
                            disableCloseOnSelect={isMultipleTypes()}
                            checkboxes
                        />
                    }
                    <span
                        className={"smartlink-rule-form__condition" + (activeRule === typesRules.single ? ' single' : '')}>
                                        {t('smartlinks.create_form.then')}
                                    </span>
                </Box>
                <KeyboardBackspaceIcon className="smartlink-rule-form__arrow"/>
            </Box>
        </SmartlinkRuleFormBlockStyled>
    )
};

export default SmartlinkRuleFormBlock;