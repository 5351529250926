import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {Dictionary, DictionaryGeos, DictionaryLandings, DictionaryLanguages, DictionaryTrafficTypes} from "./types";
import {
    fetchDictionaryProducts,
    fetchDictionaryCompany,
    fetchDictionaryOs,
    fetchDictionaryGEOs,
    fetchDictionaryRegions,
    fetchDictionaryPlatforms,
    fetchDictionaryBrowsers,
    fetchDictionaryLanguages,
    fetchDictionaryTrafficTypes,
    fetchDictionaryTeams,
    fetchDictionaryLandings,
    fetchDictionarySmartlink
} from "../../requests/dictionaries";

export interface ShortUrlsState {
    products: Dictionary[] | null;
    companies: Dictionary[] | null;
    os: Dictionary[] | null;
    teams: Dictionary[] | null;
    geos: DictionaryGeos[] | null;
    regions: Dictionary[] | null;
    platforms: Dictionary[] | null;
    browsers: Dictionary[] | null;
    languages: DictionaryLanguages[] | null;
    trafficTypes: DictionaryTrafficTypes[] | null;
    landings: DictionaryLandings[] | null;
    smartlink: Dictionary[] | null;
    statusProducts: RequestStatuses;
    statusCompanies: RequestStatuses;
    statusOs: RequestStatuses;
    statusTeams: RequestStatuses;
    statusGeos: RequestStatuses;
    statusRegions: RequestStatuses;
    statusPlatforms: RequestStatuses;
    statusBrowsers: RequestStatuses;
    statusLanguages: RequestStatuses;
    statusTrafficTypes: RequestStatuses;
    statusLandings: RequestStatuses;
    statusSmartlink: RequestStatuses;
}

const initialState: ShortUrlsState = {
    products: null,
    companies: null,
    os: null,
    teams: null,
    geos: null,
    regions: null,
    platforms: null,
    browsers: null,
    languages: null,
    trafficTypes: null,
    landings: null,
    smartlink: null,
    statusProducts: RequestStatuses.idle,
    statusCompanies: RequestStatuses.idle,
    statusOs: RequestStatuses.idle,
    statusTeams: RequestStatuses.idle,
    statusGeos: RequestStatuses.idle,
    statusRegions: RequestStatuses.idle,
    statusPlatforms: RequestStatuses.idle,
    statusBrowsers: RequestStatuses.idle,
    statusLanguages: RequestStatuses.idle,
    statusTrafficTypes: RequestStatuses.idle,
    statusLandings: RequestStatuses.idle,
    statusSmartlink: RequestStatuses.idle
};

export const getDictionaryProducts = createAsyncThunk(
    'dictionaries/Products',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryProducts(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryCompanies = createAsyncThunk(
    'dictionaries/Companies',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryCompany(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryTeams = createAsyncThunk(
    'dictionaries/Teams',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryTeams(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryOs = createAsyncThunk(
    'dictionaries/Os',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryOs(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryGeos = createAsyncThunk(
    'dictionaries/Geos',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryGEOs(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryRegions = createAsyncThunk(
    'dictionaries/Regions',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryRegions(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryPlatforms = createAsyncThunk(
    'dictionaries/Platforms',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryPlatforms(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryBrowsers = createAsyncThunk(
    'dictionaries/Browsers',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryBrowsers(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryLanguages = createAsyncThunk(
    'dictionaries/Languages',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryLanguages(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryTrafficTypes = createAsyncThunk(
    'dictionaries/TrafficTypes',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryTrafficTypes(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionaryLandings = createAsyncThunk(
    'dictionaries/Landings',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionaryLandings(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getDictionarySmartlink = createAsyncThunk(
    'dictionaries/Smartlink',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchDictionarySmartlink(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const dictionariesSlice = createSlice({
    name: 'dictionaries',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDictionaryProducts.pending, (state) => {
                state.statusProducts = RequestStatuses.loading;
            })
            .addCase(getDictionaryProducts.fulfilled, (state, action) => {
                state.statusProducts = RequestStatuses.success;
                state.products = action.payload;
            })
            .addCase(getDictionaryCompanies.pending, (state) => {
                state.statusCompanies = RequestStatuses.loading;
            })
            .addCase(getDictionaryCompanies.fulfilled, (state, action) => {
                state.statusCompanies = RequestStatuses.success;
                state.companies = action.payload;
            })
            .addCase(getDictionaryOs.pending, (state) => {
                state.statusOs = RequestStatuses.loading;
            })
            .addCase(getDictionaryOs.fulfilled, (state, action) => {
                state.statusOs = RequestStatuses.success;
                state.os = action.payload;
            })
            .addCase(getDictionaryGeos.pending, (state) => {
                state.statusGeos = RequestStatuses.loading;
            })
            .addCase(getDictionaryGeos.fulfilled, (state, action) => {
                state.statusGeos = RequestStatuses.success;
                state.geos = action.payload;
            })
            .addCase(getDictionaryRegions.pending, (state) => {
                state.statusRegions = RequestStatuses.loading;
            })
            .addCase(getDictionaryRegions.fulfilled, (state, action) => {
                state.statusRegions = RequestStatuses.success;
                state.regions = action.payload;
            })
            .addCase(getDictionaryPlatforms.pending, (state) => {
                state.statusPlatforms = RequestStatuses.loading;
            })
            .addCase(getDictionaryPlatforms.fulfilled, (state, action) => {
                state.statusPlatforms = RequestStatuses.success;
                state.platforms = action.payload;
            })
            .addCase(getDictionaryBrowsers.pending, (state) => {
                state.statusBrowsers = RequestStatuses.loading;
            })
            .addCase(getDictionaryBrowsers.fulfilled, (state, action) => {
                state.statusBrowsers = RequestStatuses.success;
                state.browsers = action.payload;
            })
            .addCase(getDictionaryLanguages.pending, (state) => {
                state.statusLanguages = RequestStatuses.loading;
            })
            .addCase(getDictionaryLanguages.fulfilled, (state, action) => {
                state.statusLanguages = RequestStatuses.success;
                state.languages = action.payload;
            })
            .addCase(getDictionaryTrafficTypes.pending, (state) => {
                state.statusTrafficTypes = RequestStatuses.loading;
            })
            .addCase(getDictionaryTrafficTypes.fulfilled, (state, action) => {
                state.statusTrafficTypes = RequestStatuses.success;
                state.trafficTypes = action.payload;
            })
            .addCase(getDictionaryTeams.pending, (state) => {
                state.statusTeams = RequestStatuses.loading;
            })
            .addCase(getDictionaryTeams.fulfilled, (state, action) => {
                state.statusTeams = RequestStatuses.success;
                state.teams = action.payload;
            })
            .addCase(getDictionaryLandings.pending, (state) => {
                state.statusLandings = RequestStatuses.loading;
            })
            .addCase(getDictionaryLandings.fulfilled, (state, action) => {
                state.statusLandings = RequestStatuses.success;
                state.landings = action.payload;
            })
            .addCase(getDictionarySmartlink.pending, (state) => {
                state.statusTeams = RequestStatuses.loading;
            })
            .addCase(getDictionarySmartlink.fulfilled, (state, action) => {
                state.statusSmartlink = RequestStatuses.success;
                state.smartlink = action.payload;
            })
    },
});

export const selectDictionaryProducts = (state: RootState) => state.dictionaries.products;
export const selectDictionaryCompanies = (state: RootState) => state.dictionaries.companies;
export const selectDictionaryOs = (state: RootState) => state.dictionaries.os;
export const selectDictionaryGeosTemp = (state: RootState) => state.dictionaries.geos;
export const selectDictionaryGeos = createSelector(selectDictionaryGeosTemp, (data) => {
    if (data) {
        return data.map(item => ({id: item.id, name: item.country_code}));
    } else {
        return data;
    }
})
export const selectDictionaryRegions = (state: RootState) => state.dictionaries.regions;
export const selectDictionaryPlatforms = (state: RootState) => state.dictionaries.platforms;
export const selectDictionaryBrowsers = (state: RootState) => state.dictionaries.browsers;
export const selectDictionaryTeams = (state: RootState) => state.dictionaries.teams;
export const selectDictionaryLanguagesTemp = (state: RootState) => state.dictionaries.languages;
export const selectDictionaryLanguages = createSelector(selectDictionaryLanguagesTemp, (data) => {
    if (data) {
        return data.map(item => ({id: item.id, name: item.code}));
    } else {
        return data;
    }
})
export const selectDictionaryTrafficTypesTemp = (state: RootState) => state.dictionaries.trafficTypes;
export const selectDictionaryTrafficTypes = createSelector(selectDictionaryTrafficTypesTemp, (data) => {
    if (data) {
        return data.map(item => ({id: item.id, name: item.type}));
    } else {
        return data;
    }
})
export const selectDictionaryLandingsTemp = (state: RootState) => state.dictionaries.landings;
export const selectDictionaryLandings = createSelector(selectDictionaryLandingsTemp, (data) => {
    if (data) {
        return data.map(item => ({id: item.id, name: item.url}));
    } else {
        return data;
    }
})
export const selectDictionarySmartlinks = (state: RootState) => state.dictionaries.smartlink;

export default dictionariesSlice.reducer;
