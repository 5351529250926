import React, {useEffect, useState} from "react";
import './CreateTracker.scss';
import {
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import StyledDialog from "../../modals/Dialog/StyledDialog";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getSearchTeams, selectSearchTeams} from "../../../app/reducers/prism/teamsSlice";
import {createTracker, selectCreateTrackerErrors} from "../../../app/reducers/prism/trackersSlice";
import { selectSelfUser } from "../../../app/reducers/prism/sessionSlice";
import {UserTeam} from "../../../app/reducers/prism/types";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Form} from "../../Form/Form";
import {Config, SubmitHandler} from "../../Form/types";
import * as Yup from "yup";

export interface CreateTrackerProps {
    handleClose: () => void
}

export function CreateTracker ({handleClose}: CreateTrackerProps) {
    const { t } = useTranslation();
    const [teams, setTeams] = useState<UserTeam[]>([]);
    const selfUser = useAppSelector(selectSelfUser);
    const adminTeams = useAppSelector(selectSearchTeams);
    const langRegex = /^[A-Za-z0-9-_\.]+$/;
    const getAvailableTeams = () => (teams?.map(team => ({label: team.name, value: team.id})) ?? []);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        if (selfUser?.role.alias === 'admin') {
            dispatch(getSearchTeams({}));
        } else {
            setTeams(selfUser?.teams ?? [])
        }
    }, [selfUser?.role.alias, selfUser?.teams, dispatch])

    useEffect(() => {
       if (adminTeams && adminTeams.length > 0 && selfUser?.role.alias === 'admin') {
           setTeams(adminTeams);
       }
    }, [adminTeams, selfUser?.role.alias])

    const onSubmitHandler: SubmitHandler<{ name: string, team_id: string }> = async (values) => {
        const enabled = true;
        if (values.name !== undefined && values.team_id !== undefined) {
            const createdTrackerResponse = await dispatch(createTracker({
                trackerName: values.name,
                teamId: values.team_id,
                enabled
            }));

            if (createdTrackerResponse.payload.responseStatus === undefined) {
                navigate(('/trackers/' + createdTrackerResponse.payload.id ?? '/trackers'));
                handleClose();
            }
        }
    };

    const createConfigs: Config[] = [
        {
            type: 'text',
            field: 'name',
            label: t('common.tracker_filters.tracker_name'),
            col: 6,
            validation: Yup.string().matches(langRegex, 'Only English letters').required('Required')
        },
        {
            type: 'select',
            field: 'team_id',
            placeholder: t('common.forms.input.placeholders.creator_team'),
            value: 'value',
            label: 'label',
            col: 6,
            options: getAvailableTeams(),
            validation: Yup.string().required('Required')
        },
        // {
        //   type: 'checkbox',
        //   label: 'Add to favorites',
        //   field: 'favorites',
        //   defaultValue: '',
        //   styles: {mb: '20px', color: '#292621'},
        //   col: 6,
        // },
        {
            type: 'divider',
            label: '',
            col: 12,
        },
        {
            type: 'button',
            label: t('common.forms.create'),
            buttonType: 'submit',
            col: 5,
            buttonClass: 'BtnPurple',
            class: 'btn-wrap'
        },
        {
            type: 'button',
            label: t('common.forms.cancel'),
            buttonType: 'button',
            handleClick: handleClose,
            col: 5,
            buttonClass: 'BtnGrey',
            class: 'btn-wrap'
        },
    ];

    return (
        <>
            <StyledDialog open={true} onClose={handleClose} className="duplicate-tracker-modal">
                <div className="duplicate-tracker-modal-container">
                    <div className="close-button">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <DialogTitle className={"heading"}>{t('common.forms.create_tracker')}</DialogTitle>
                    <DialogContentText className={"subheading"} sx={{fontSize: '16px', color: '#BDB8AD'}}>
                        {t('common.forms.create_tracker_description')}
                    </DialogContentText>
                    <DialogContent>
                        <Form
                            configs={createConfigs}
                            onSubmitHandler={onSubmitHandler}
                            errorsSelector={selectCreateTrackerErrors}
                        />
                    </DialogContent>
                </div>
            </StyledDialog>
        </>
    );
}
