import React, {useCallback, useRef} from 'react';
import {AppFilters, AppFilterValue, UrlParams} from "../../types";
import {AutocompleteFilter} from "../../filterInputs/Autocomplete/AutocompleteFilter";
import {ControlBarConfig, ControlBarConfigType} from "../types";
import {Box, TextField} from '@mui/material/';
import './ControlBar.scss';
import {useTranslation} from "react-i18next";
import WhiteButton from "../WhiteButton/WhiteButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

interface BulkControlBarProps {
    controlBarConfig: ControlBarConfig[];
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    resetControlBar: () => void;
    buckList: number[];
}

export function BulkControlBar(props: BulkControlBarProps) {
    const {t} = useTranslation();
    const {
        controlBarConfig,
        urlFilters,
        addFilters,
        resetControlBar,
        buckList,
    } = props;
    const {current: defaultFilters} = useRef<UrlParams>(urlFilters);

    const addFiltersCallBack = useCallback(addFilters, [addFilters]);

    const onChangeHandler = useCallback((field: string) => (optionValue: AppFilterValue | null) =>
            addFiltersCallBack({[field]: optionValue}),
        [addFiltersCallBack]
    );

    const buckCounter: number = buckList.length;
    const bulkSaveState = buckCounter <= 0;

    const bulkSave = () => function () {
        resetControlBar();
    }

    return (
        <Box className="control-bar-wrapper">
            <Box className="table-control-bar">
                {controlBarConfig.map((config) => {
                    if (config.type === ControlBarConfigType.autocomplete) {
                        return (
                            <AutocompleteFilter
                                key={config.field}
                                onChangeHandler={(option) => {
                                    onChangeHandler(config.field)(option)
                                }}
                                optionLabel={config.optionLabel}
                                optionValue={config.optionValue}
                                optionKey={config.field}
                                placeholder={config.placeholder}
                                asyncAction={config.asyncAction}
                                dataSelector={config.dataSelector}
                                multiselect={config.multiselect}
                                statusSelector={config.statusSelector}
                                defaultFilters={defaultFilters}
                                values={urlFilters}
                            />
                        );
                    }
                })}
                <TextField name="comment" className="control-text-field"
                           label={t('brands.entity.edit.landings.form.comment')} variant="outlined"/>
            </Box>
            <Box className="right-block">
                <WhiteButton
                    name={t('brands.entity.edit.landings.page.cancel')}
                    onHandleClick={resetControlBar}
                 />
                <WhiteButton
                    name={t('brands.entity.edit.landings.page.save')}
                    onHandleClick={bulkSave}
                    disabled={bulkSaveState}
                    nameClass="controlBarSave"
                />
            </Box>
        </Box>
    )
}
