import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent'
    },
    '& .MuiTab-root': {
        opacity: 1
    },
    '& .Mui-selected': {
        color: '#f8ff13'
    },
});

export default StyledTabs