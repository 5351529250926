import {SelectOption, SelectValue} from "../../../Table/filters/types";
import {AppFilterValue} from "../../../Table/types";
import {BaseSelect} from "../../../inputs/BaseSelect";
import React from "react";

interface FormSelectProps {
    fieldName: string
    defaultOptionValue?: SelectValue;
    onChangeHandler: (option: AppFilterValue) => void;
    // onBlurHandler: (option: AppFilterValue) => void;
    onBlur: (e: React.FocusEvent<any>) => void;
    valueMutator?: (option: AppFilterValue) => AppFilterValue;
    placeholder: string;
    label?: string;
    value?: string;
    options: SelectOption[];
    questionMark?: boolean;
    tooltipTitle?: string;
    error?: boolean;
    helperText?: React.ReactNode;
}

export function FormSelect(props: FormSelectProps) {
    const {
        defaultOptionValue = '',
        onChangeHandler,
        valueMutator,
        options,
        placeholder,
        label = 'label',
        value = 'value',
        fieldName,
        onBlur,
        questionMark = false,
        tooltipTitle = '',
        error,
        helperText
    } = props;

    return (
      <BaseSelect
          key={fieldName}
          fieldName={fieldName}
          defaultOptionValue={defaultOptionValue}
          valueMutator={valueMutator}
          onChangeHandler={onChangeHandler}
          placeholder={placeholder}
          options={options}
          label={label}
          onBlur={onBlur}
          value={value}
          questionMark={questionMark}
          tooltipTitle={tooltipTitle}
          error={error}
          helperText={helperText}
          sx={{width: '100%'}}
      />
    );
}