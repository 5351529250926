import {createTheme, PaletteColor, PaletteOptions} from '@mui/material/styles';
import {Shadows} from "@mui/material/styles/shadows";

declare module "@mui/system/createTheme/createBreakpoints" {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xlg: true;
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        white: Palette['primary'];
        other: {
            main?: string;
            divider: string;
        }
    }
    interface PaletteOptions {
        white: PaletteOptions['primary'];
        other: {
            main?: string;
            divider: string;
        }
    }
}

const customShadows: Shadows = [
    'none',
    '0 0 0 1px #e0e0e0',
    '0px 1px 2px rgba(0, 0, 0, 0.24)',
    '0px 3px 6px rgba(0, 0, 0, 0.16)',
    '0px 3px 6px rgba(0, 0, 0, 0.23)',
    '0px 10px 20px rgba(0, 0, 0, 0.14)',
    '0px 6px 6px rgba(0, 0, 0, 0.10)',
    '0px 15px 25px rgba(0, 0, 0, 0.14)', '0px 5px 10px rgba(0, 0, 0, 0.06)',
    '0px 20px 40px rgba(0, 0, 0, 0.14)', '0px 5px 15px rgba(0, 0, 0, 0.05)',
    '0px 15px 25px rgba(0, 0, 0, 0.14)', '0px 5px 10px rgba(0, 0, 0, 0.06)',
    '0px 20px 40px rgba(0, 0, 0, 0.14)', '0px 5px 15px rgba(0, 0, 0, 0.05)',
    '0px 15px 25px rgba(0, 0, 0, 0.14)', '0px 5px 10px rgba(0, 0, 0, 0.06)',
    '0px 20px 40px rgba(0, 0, 0, 0.14)', '0px 5px 15px rgba(0, 0, 0, 0.05)',
    '0px 20px 40px rgba(0, 0, 0, 0.14)', '0px 5px 15px rgba(0, 0, 0, 0.05)',
    '0px 2px 4px rgba(0, 0, 0, 0.12)', '0px 2px 2px rgba(0, 0, 0, 0.24)',
    '0px 2px 4px rgba(0, 0, 0, 0.12)', '0px 2px 2px rgba(0, 0, 0, 0.24)'
];

let theme = createTheme({
    spacing: 8,
    palette: {
        mode: 'light',
        primary: {
            main: '#000',
            contrastText: '#ffffff',
            dark: '#008F5F',
            light: '#EFEDE9'
        },
        action: {
            active: '#7B756B'
        },
        secondary: {
            main: '#F5F4F1',
            light: '#555555',
            dark: '#000',
        },
        success: {
            main: '#009E69',
        },
        text: {
            primary: '#292621',
            secondary: '#575249',
        },
        error: {
            main: '#FF3333',
        },
        white: {
            main: '#fff',
            contrastText: '#ffffff',
            dark: '#BDB8AD',
        },
        other: {
            divider: '#EAEAEA',
        }
    },
    shadows: customShadows,
    typography: {
        fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1025,
            lg: 1200,
            xl: 1440,
            xlg: 1272,
        }
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingBottom: '15px'
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '0 8px 0 8px',
                    height: '20px',
                    fontSize: 'unset'
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    height: '17px'
                }
            }
        },
    }
})

theme = createTheme(theme,{
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.active,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.white.dark,
                        borderWidth: "1px",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.error.main,
                        borderWidth: "1px"
                    }
                },
                input: {
                    color: theme.palette.text.primary
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.BtnYellow": {
                        backgroundColor: "#F2F740",
                        color: theme.palette.text.primary,
                        boxShadow: "none"
                    },
                    "&.BtnYellow:hover": {
                        backgroundColor: "#E1F038",
                    },
                    "&.BtnGrey": {
                        backgroundColor: "#F5F4F1",
                        color: theme.palette.text.primary,
                        boxShadow: "none"
                    },
                    "&.BtnGrey:hover": {
                        backgroundColor: "#EFEDE9",
                    },
                    "&.BtnPurple": {
                        backgroundColor: "#61377a",
                        color: theme.palette.white.main,
                        boxShadow: "none"
                    },
                    "&.BtnPurple:hover": {
                        backgroundColor: "#492062",
                    },
                    "&.BtnPurpleOutlined": {
                        border: "1px solid #391151",
                        backgroundColor: theme.palette.white.main,
                        color: "#391151",
                        boxShadow: "none"
                    },
                    "&.BtnTransparent": {
                        backgroundColor: "transparent",
                        color: theme.palette.text.primary,
                        boxShadow: "none"
                    },
                    "&.BtnTransparent:hover": {
                        backgroundColor: "#EFEDE9",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "#F9F9F9!important",
                        color: theme.palette.white.dark
                    },
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Roboto"
                },
            },
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: 'h2',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h2',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'span',
                    body2: 'span',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "&.IconActive": {
                        color: theme.palette.success.main + '!important',
                    },
                    "&.IconDark": {
                        color: theme.palette.text.primary + '!important',
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
                paper: {
                    borderRadius: 0,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                    fontWeight: 400,
                    "&.green" : {
                        color: theme.palette.success.main,
                        textDecorationColor: theme.palette.success.main,
                    }
                },
            },
        }
    }
})

export default theme;
