import React, {Dispatch, useEffect, useState} from "react";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DroppableProvided,
    DropResult,
    DraggableProvided
} from 'react-beautiful-dnd';
import Chip from "./Chip/Chip";
import MenuItem from '@mui/material/MenuItem';
import "./MultiSelect.scss"
import {ClickAwayListener} from "@mui/material";

export interface MultiSelectProps {
    optionsProps: ItemProps[];
    maxCountChips?: number;
    onChangeEvent: (items: ItemProps[]) => void;
    setGroupFields: Dispatch<string[]>;
    isFirstLoad: boolean;
    loadedOptions: ItemProps[];
}

export interface ItemProps {
    id: string,
    content: string,
    disabled: boolean,
}

function MultiSelect({optionsProps, maxCountChips, onChangeEvent, setGroupFields, isFirstLoad, loadedOptions}: MultiSelectProps) {
    const [options, setOptions] = useState<ItemProps[]>(optionsProps);
    const [items, setItems] = useState<ItemProps[]>([]);
    const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false);

    const onDragEnd = (result: DropResult) => {
        const {destination, source} = result;
        if (!destination) {
            return;
        }
        setIsOpenOptions(false);
        const newItems = Array.from(items);
        const [removed] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, removed);
        setItems(newItems);
    };

    const checkItemsLength = (items: ItemProps[], options: ItemProps[]) => {
        const updatedOptions = options.map(option => ({
            ...option,
            disabled: maxCountChips && items.length >= maxCountChips ? true : items.some(item => item.id === option.id)
        }));
        setOptions(updatedOptions);
    };

    const checkIsSelectData = (items: ItemProps[], options: ItemProps[]) => {
        const datePeriods = ['day', 'week', 'month'];
        const hasDateItem = items.some(item => datePeriods.includes(item.id));
        if (hasDateItem) {
            const tempOptions = options.map(option => {
                if (datePeriods.includes(option.id)) {
                    return {
                        ...option,
                        disabled: true,
                    };
                }
                return option;
            });
            setOptions(tempOptions);
        }
    };

    const handleClickItem = (id: string) => {
        const selectedItem = options.filter(option => option.id === id);
        setItems([...items, ...selectedItem]);
        setIsOpenOptions(false);
    };

    const handleRemoveItem = (id: string) => {
        items.splice(items.findIndex(item => item.id === id), 1);
        setItems([...items]);
    }

    const openOptions = () => {
        setIsOpenOptions(true);
    }

    const handleClickAway = () => {
        setIsOpenOptions(false);
    }

    const renderOptions = () => {
        return (
            <div className="multi-select-options">
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        onClick={() => handleClickItem(option.id)}
                        disabled={option.disabled}
                    >
                        {option.content}
                    </MenuItem>
                ))}
            </div>
        )
    }

    useEffect(() => {
        onChangeEvent(items);
        checkItemsLength(items, options);
        const displayedFields = items.map(item => (item.id));
        setGroupFields(displayedFields);
        checkIsSelectData(items, options);
    }, [items]);

    useEffect(() => {
        isFirstLoad && setItems([options[0]]);
        loadedOptions.length && setItems(loadedOptions);
    }, []);

    return (
        <>
            <div className="multi-select-wrap">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided: DroppableProvided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="droppable-wrap"
                            >
                                {items.map((item: ItemProps, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                    >
                                        {(providedDraggable: DraggableProvided) => (
                                            <Chip
                                                providedDraggable={providedDraggable}
                                                item={item}
                                                handleRemoveItem={handleRemoveItem}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div style={{width: '100%'}}>
                        <div className="multi-select-field" onClick={openOptions}></div>
                        {isOpenOptions && renderOptions()}
                    </div>
                </ClickAwayListener>
            </div>
        </>
    );
}

export default MultiSelect;
