import React, {useCallback, useState} from 'react';
import {AutocompleteProps, AutocompleteValue, Popper, SvgIcon} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useDataRequest, useOptions} from "./hooks";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";
import {PopperProps} from "@mui/material/Popper";
import {DataRow, RequestStatuses, UrlParams} from "../../Table/types";
import {useAppSelector} from "../../../app/hooks";
import {AutocompleteOption} from "./types";
import {RootState} from "../../../app/store";
import {AsyncThunk} from "@reduxjs/toolkit";
import {isArray, omit} from "lodash";
import Tooltip from "@mui/material/Tooltip";
import {ReactComponent as QuestionIcon} from "../../../app/assets/img/icons/questionGray.svg";
import {useTranslation} from "react-i18next";

const Icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const CheckedIcon = <CheckBoxIcon fontSize="small"/>;

interface RenderedInputProps {
    params: AutocompleteRenderInputParams;
    loading: boolean;
    placeholder: string;
    error?: boolean;
    helperText?: React.ReactNode;
    questionMark?: boolean;
    tooltipTitle?: string;
    t?: string;
}

export const RenderedInput = (props: RenderedInputProps) => {
    const {t} = useTranslation();

    return (
        <TextField
            {...props.params}
            label={
                <React.Fragment>
                    {t(props.placeholder)}
                    {props.questionMark && props.tooltipTitle && <Tooltip
                        title={props.tooltipTitle}>
                        <SvgIcon
                            component={QuestionIcon}
                            sx={{verticalAlign: 'middle', paddingLeft: '4px'}}
                        />
                    </Tooltip>}
                </React.Fragment>
            }
            size='small'
            error={props.error}
            helperText={props.helperText}
            InputProps={{
                ...props.params.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {props.loading ? <CircularProgress color="inherit" size={20}/> : null}
                        {props.params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
        />
    )
}
export const RenderedOption = (props: {
    originalProps: React.HTMLAttributes<HTMLLIElement>,
    selected: boolean,
    label: string,
}) => {
    const {t} = useTranslation();

    return (
        <li {...props.originalProps}>
            <Checkbox
                icon={Icon}
                checkedIcon={CheckedIcon}
                style={{marginRight: 8}}
                checked={props.selected}
            />
            <div>{t(props.label)}</div>
        </li>
    )
}

interface BaseAutocompleteProps<Multiple extends boolean,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
    > extends Omit<AutocompleteProps<AutocompleteOption,
    Multiple,
    DisableClearable,
    FreeSolo>,
    'renderInput' | 'options' | 'value'> {
    dataSelector: (state: RootState) => DataRow[] | null;
    statusSelector: (state: RootState) => RequestStatuses;
    asyncAction: AsyncThunk<any, UrlParams | undefined, any>;
    defaultOptionValue?: Array<string | number | null>;
    optionValue?: string;
    optionLabel: string;
    multiple?: Multiple;
    questionMark?: boolean;
    tooltipTitle?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    paperWidth?: number
}

// TODO optimize with https://mui.com/material-ui/react-autocomplete/#virtualization
export function BaseAutocomplete<Multiple extends boolean,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
    >(props: BaseAutocompleteProps<Multiple, DisableClearable, FreeSolo>): JSX.Element {

    const {
        optionLabel,
        optionValue = 'id',
        dataSelector,
        statusSelector,
        asyncAction,
        defaultOptionValue,
        placeholder,
        multiple,
        questionMark = false,
        tooltipTitle = '',
        error,
        helperText,
        paperWidth
    } = props;
    const dataStatus = useAppSelector(statusSelector);
    const loading = dataStatus === RequestStatuses.loading;

    const [open, setOpen] = useState(false);

    useDataRequest(optionLabel, dataStatus, open, asyncAction, defaultOptionValue);
    const {options, value, setValue} = useOptions(dataSelector, optionValue, optionLabel, multiple, defaultOptionValue);

    const MemoPopper = useCallback((props: PopperProps) => {
        return <Popper {...props} style={{minWidth: 300}}/>
    }, []);

    const autocompleteProps: Omit<AutocompleteProps<AutocompleteOption, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> =
        omit(props, [
            'renderInput',
            'options',
            'value',
            'valuesIds',
            'dataSelector',
            'statusSelector',
            'asyncAction',
            'defaultOptionValue',
            'optionValue',
            'optionLabel',
            'error',
            'helperText',
            'questionMark',
            'tooltipTitle',
            'disabled',
            'paperWidth'
        ]);

    if (multiple && isArray(value)) {
        return (
            <Autocomplete
                {...autocompleteProps}
                disableCloseOnSelect={props.disableCloseOnSelect ?? true}
                size={props.size ?? "small"}
                multiple={true as Multiple}
                open={open}
                loading={loading}
                sx={props.sx ?? {width: '100%'}}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(event, value, reason, details) => {
                    if (isArray(value)) {
                        setValue(value);
                    }
                    if (props.onChange) {
                        props.onChange(event, value, reason, details)
                    }
                }}
                isOptionEqualToValue={props.isOptionEqualToValue ? props.isOptionEqualToValue : (option, value) => option.value === value.value}
                getOptionLabel={props.getOptionLabel ? props.getOptionLabel : (option: any) => option.label}
                PopperComponent={MemoPopper}
                value={value as AutocompleteValue<AutocompleteOption, Multiple, DisableClearable, FreeSolo>}
                options={options}
                renderOption={props.renderOption ? props.renderOption : (props, option, {selected}) => (
                    <RenderedOption
                        key={option.value}
                        originalProps={props}
                        selected={selected}
                        label={option.label}
                    />
                )}
                renderInput={(params) => (
                    <RenderedInput
                        params={params}
                        placeholder={placeholder ?? ''}
                        loading={loading}
                        error={error}
                        helperText={helperText}
                        questionMark={questionMark}
                        tooltipTitle={tooltipTitle}
                    />
                )}
            />
        );
    }
    const isNotArray = (value: AutocompleteValue<AutocompleteOption, Multiple, DisableClearable, FreeSolo> | AutocompleteOption): value is AutocompleteOption => {
        return !isArray(value) && value !== null;
    }

    return (
        <Autocomplete
            {...autocompleteProps}
            disabled={props.disabled}
            sx={{width: '100%'}}
            size={props.size ?? "small"}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={props.isOptionEqualToValue ? props.isOptionEqualToValue : (option, value) => option.value === value.value}
            getOptionLabel={props.getOptionLabel ? props.getOptionLabel : (option: any) => option.label}
            onChange={(event, value, reason, details) => {
                if (isNotArray(value)) {
                    setValue(value);
                }
                if (props.onChange) {
                    props.onChange(event, value, reason, details)
                }
            }}
            options={options}
            loading={loading}
            PopperComponent={(props) => <Popper {...props} style={{minWidth: 300}} placement="bottom-start"/>}
            renderInput={(params) => (
                <RenderedInput
                    params={params}
                    placeholder={placeholder ?? ''}
                    loading={loading}
                    error={error}
                    helperText={helperText}
                />
            )}
            componentsProps={{
                paper: {
                    sx: paperWidth ? {width: paperWidth} : {width: 300}
                }
            }}
        />
    );
}
