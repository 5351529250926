import React, {Dispatch, useEffect} from 'react';
import {Box} from '@mui/material/';
import {useTranslation} from "react-i18next";
import WhiteButton from "../WhiteButton/WhiteButton";
import Button from "@mui/material/Button";
import {getUserInfo} from "../../../../app/reducers/prism/sessionSlice";

interface CustomizeControlBarProps {
    resetControlBar: () => void;
    bufferColList: { [key: string]: number };
    tableName: string;
    setShowSavedAlert: Dispatch<boolean>;
}

export function CustomizeControlBar(props: CustomizeControlBarProps) {
    const {t} = useTranslation();
    const {
        resetControlBar,
        bufferColList,
        tableName,
        setShowSavedAlert
    } = props;

    const checkCustomizeSaveBtnState = () => {
        const tableList = JSON.parse(localStorage.getItem('tableList') ?? '[]');
        const tableProps = Object.keys(tableList[tableName]);
        const getPropertyBufferColList = Object.keys(bufferColList);

        return getPropertyBufferColList.length === tableProps.length &&
            getPropertyBufferColList.every(prop => tableProps.includes(prop));
    };
    
    const saveCustomisation = () => {
        const tableListString = localStorage.getItem('tableList') ?? '[]';
        let curList: any = JSON.parse(tableListString);
        const fieldIndex = tableName as any
        curList[fieldIndex] = {
            ...bufferColList
        }
        localStorage.setItem('tableList', JSON.stringify(curList));
        resetControlBar();
        setShowSavedAlert(true);
    }

    return (
        <Box className="control-bar-wrapper">
            <Box></Box>
            <Box className="right-block">
                <Button
                    className="BtnPurpleOutlined create-plus"
                    onClick={resetControlBar}
                    sx={{minWidth: "208px"}}
                >
                    {t('brands.entity.edit.landings.page.cancel')}
                </Button>
                <Button
                    className="BtnPurple create-plus"
                    onClick={saveCustomisation}
                    sx={{width: "208px"}}
                    disabled={checkCustomizeSaveBtnState()}
                >
                    {t('brands.entity.edit.landings.page.save')}
                </Button>
            </Box>
        </Box>
    )
}
