import React, {useState} from 'react';
import moment from 'moment';

import {DateTimeInput} from "./DateTimeInput";
import {defaultDateRanges} from "../../constants";
import {AppFilters, UrlParams} from "../../types";

interface DateTimeRangeInputProps {
    onChangeHandler: (filters: AppFilters) => void;
    defaultDateFilters: typeof defaultDateRanges;
    urlFilters: UrlParams;
}

export function DateTimeRangeFilter(props: DateTimeRangeInputProps) {
    const { onChangeHandler, defaultDateFilters } = props;

    const [dateRanges, setDateRanges] = useState<{ from: string, to: string }>(defaultDateFilters);

    const onChange = (fromOrTo: 'from' | 'to') => (value: string | null) => {
        if (value) {
            setDateRanges({...dateRanges, [fromOrTo]: value})
            onChangeHandler({...dateRanges, [`date[${fromOrTo}]`]: value});
        }
    }

    return (
        <React.Fragment>
            <DateTimeInput
                key='from'
                label='from'
                onChangeHandler={onChange('from')}
                defaultDate={moment(dateRanges.from)}
            />
            <DateTimeInput
                key='to'
                label='to'
                onChangeHandler={onChange('to')}
                defaultDate={moment(dateRanges.to)}
            />
        </React.Fragment>
    );
}