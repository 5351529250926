import {Map, TransformedNode, Node} from "../types";

export const DataFromBaseCompilation = (nodes: Node[]) => {
    let map: Map = {};
    let node: TransformedNode;
    let roots = [], i: number;
    let buffer: TransformedNode[] = [];
    let nodeKeySet = Object.keys(nodes);
    for (i = 0; i < nodeKeySet.length; i += 1) {
        map[nodes[i].id] = i;
        buffer[i] = {
            source: nodes[i].parent_id,
            target: nodes[i].id,
            id: nodes[i].id,
            details: nodes[i],
            children: [],
        };
    }
    for (i = 0; i < nodeKeySet.length; i += 1) {
        node = buffer[i];
        if (node.source) {
            buffer[map[node.source]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    roots.sort((a, b) => Number(a.id) - Number(b.id));
    return roots;
};