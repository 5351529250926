import React, {useState} from 'react';
import './Sidebar.scss';
import {useTranslation} from "react-i18next";
import {styled, Theme, CSSObject} from '@mui/material/styles';
import {Box, ClickAwayListener} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import CustomList from "./CustomList";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {logout, selectIsAuth, selectSelfUser} from "../../app/reducers/prism/sessionSlice";
import LogoutIcon from '@mui/icons-material/Logout';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import SwitchAccessShortcutOutlinedIcon from '@mui/icons-material/SwitchAccessShortcutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import {MenuItemType} from "./types";
import Button from "@mui/material/Button";
import Routes from "../../navigation/routes";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
});

const prismLink = process.env.REACT_APP_PRISM_URL;

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Sidebar() {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState({active: 'home', parent: ""});
    const isAuth = useAppSelector(selectIsAuth);
    const dispatch = useAppDispatch();
    const selfUser = useAppSelector(selectSelfUser);

    const handleToggle = () => {
        setOpen(!open);
    };

    const resetActive = (active: string, parent: string) => {
        setActive({active: active, parent: parent})
    }

    const handleClickOutside = () => {
        setOpen(false);
    }

    const menuItems: MenuItemType[] = [
        {
            link: Routes.trackers,
            linkType: "",
            dataName: "smart_links",
            icon: <AltRouteIcon/>,
            name: t("menu.smart_links")
        },
        {
            link: Routes.trackers,
            linkType: "",
            dataName: "all_smartlinks",
            name: t("menu.all_smartlinks"),
            parent: "smart_links"
        },
        {
            link:  "#",
            linkType: "",
            dataName: "default_rules",
            name: t("menu.default_rules"),
            parent: "smart_links"
        },
        {
            link: Routes.statistic,
            linkType: "tab",
            dataName: "statistic",
            icon: <AnalyticsOutlinedIcon/>,
            name: t("menu.statistic")
        },
        {
            link: Routes.landings,
            linkType: "",
            dataName: "landings",
            icon: <AutoAwesomeMosaicOutlinedIcon/>,
            name: t("menu.landings")
        },
        {
            link: Routes.shortener,
            linkType: "",
            dataName: "shortener",
            icon: <LinkOutlinedIcon/>,
            name: t("menu.shortener")
        },
        {
            link:  prismLink + "/statistics/shortener",
            linkType: "link",
            dataName: "shortener_statistic",
            name: t("menu.statistic"),
            parent: "shortener"
        },
        {
            link: Routes.products,
            linkType: "",
            dataName: "products",
            icon: <AutoAwesomeMotionOutlinedIcon/>,
            name: t("menu.products")
        },
        {
            link: "https://docs.google.com/document/d/1sng3mOGGdy-plaJJlK3pUMvSo3WMQZtk-7R2CTJ8tNQ/edit",
            linkType: "link",
            dataName: "help_center",
            icon: <HelpOutlineOutlinedIcon/>,
            name: t("menu.help_center"),
            separated: true
        },
    ]

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickOutside}>
            <Box sx={{display: 'flex'}}>
                <Box className="draw_header">
                    <Box className="menu_toggle"><MenuIcon onClick={handleToggle}/></Box>
                </Box>
                {isAuth &&
                <Drawer className={"sidebar" + (open ? '' : ' closed')} variant="permanent" open={open}>
                    <CustomList menuItems={menuItems} open={open} active={active} setActive={resetActive}/>
                    <Box className={"draw_footer" + (open ? '' : ' closed')}>
                        {open && prismLink != undefined &&
                         <>
                            <Box sx={{fontSize: "14px",fontWeight: 400, letterSpacing: "0.17px", marginLeft: "6px"}}>
                                {t('menu.switch_title')}
                            </Box>
                            <Button
                                className="BtnPurple switch"
                                startIcon={<SwitchAccessShortcutOutlinedIcon className="IconDark"/>}
                                onClick={()=>{window.location.href=prismLink}}
                            >
                                {t('menu.switch_to_old')}
                            </Button>
                        </>
                        }
                        <Box>
                            <LogoutIcon sx={{cursor: "pointer"}} onClick={() => dispatch(logout())}/>
                        </Box>
                        <Box className="email" sx={{marginTop: "18px", marginBottom: "32px", opacity: open ? 1 : 0}}>
                            <Typography sx={{fontSize: "14px"}}>{selfUser?.email ?? ''}</Typography>
                        </Box>
                    </Box>
                </Drawer>}
            </Box>
        </ClickAwayListener>
    );
}

export default Sidebar;
