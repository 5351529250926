import {PaginatedData} from "../../types";
import {ShortLink, TrackerData, TrackerSettings} from "./types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createTrackerByDefault as createTrackerRequest,
    fetchAvailableUtmParams,
    fetchShortlink,
    fetchTracker,
    fetchTrackers,
    fetchUpdateSettings,
    fetchUpdateTracker,
    setActivity,
    setAlgorithm,
    duplicateTracker
} from "../../requests/trackers";
import {RootState} from "../../store";

export interface TrackersState {
    trackers: PaginatedData<TrackerData> | null;
    tracker: TrackerData | null;
    availableParams: string[] | null;
    searchTrackers: PaginatedData<TrackerData> | null;
    status: RequestStatuses;
    createStatus: RequestStatuses;
    availableParamsStatus: RequestStatuses;
    shortlinkStatus: RequestStatuses;
    shortlink: ShortLink | null;
    searchStatus: RequestStatuses;
    duplicateStatus: RequestStatuses;
    errors: {
        name?: string,
        title?: string,
    }
}

const initialState: TrackersState = {
    trackers: null,
    tracker: null,
    searchTrackers: null,
    availableParams: null,
    shortlink: null,
    status: RequestStatuses.idle,
    createStatus: RequestStatuses.idle,
    availableParamsStatus: RequestStatuses.idle,
    shortlinkStatus: RequestStatuses.idle,
    searchStatus: RequestStatuses.idle,
    duplicateStatus: RequestStatuses.idle,
    errors: {
        name: undefined,
        title: undefined,
    }
};


export const getTrackers = createAsyncThunk(
    'trackers/fetchTrackers',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchTrackers(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);
export const getTracker = createAsyncThunk(
    'trackers/fetchTracker',
    async (trackerId: number) => {
        return await fetchTracker(trackerId);
    }
);
export const getSearchTrackers = createAsyncThunk(
    'trackers/fetchSearchTrackers',
    async (filters: UrlParams | undefined = {per_page: '1000'}, {rejectWithValue}) => {
        try {
            return await fetchTrackers(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

type SetTrackerActivityArguments = { trackerId: number, isActive: boolean };

export const setTrackerActivity = createAsyncThunk(
    'tracker/setTrackerActivity',
    async ({trackerId, isActive}: SetTrackerActivityArguments) => {
        return await setActivity(trackerId, isActive)
    }
)

type CreateTrackerArguments = { trackerName: string, teamId: string, enabled: boolean }

export const createTracker = createAsyncThunk(
    'tracker/createTracker',
    async ({trackerName, teamId, enabled}: CreateTrackerArguments, {rejectWithValue}) => {
        try {
            return await createTrackerRequest(trackerName, teamId, enabled)
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
)

export const getAvailableUtmParams = createAsyncThunk(
    'tracker/getAvailableUtmParams',
    async () => await fetchAvailableUtmParams()
)


export const updateTrackerSettings = createAsyncThunk(
    'tracker/updateSettings',
    async (trackerSettings: TrackerSettings) => await fetchUpdateSettings(trackerSettings.tracker_id, trackerSettings)
)

export const updateTracker= createAsyncThunk(
    'trackers/updateTracker',
    async (tracker: TrackerData, {rejectWithValue}) => {
        try {
            return await fetchUpdateTracker(tracker.id, tracker);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

// trackers/3720/shortlink

export const getShortlink = createAsyncThunk(
    'tracker/getShortlink',
    async (trackerId: number) => await fetchShortlink(trackerId)
)


//TODO: change when tracker page will ready
type SetTrackerAlgorithmArguments = {trackerId: number | string | undefined, algorithm: string};

export const setTrackerAlgorithm = createAsyncThunk(
    'tracker/setTrackerAlgorithm',
    async ({trackerId, algorithm}: SetTrackerAlgorithmArguments) => {
        return await setAlgorithm(trackerId, algorithm)
    }
)

type SetDuplicateTrackerArguments = {oldId: number, newId: number};

export const setDuplicateTracker = createAsyncThunk(
    'trackers/duplicateTracker',
    async ({oldId, newId}: SetDuplicateTrackerArguments, {rejectWithValue}) => {
        try {
            return await duplicateTracker(oldId, newId);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const trackersSlice = createSlice({
    name: 'trackers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTrackers.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getTrackers.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.trackers = action.payload;
            })
            .addCase(getTracker.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getTracker.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.tracker = action.payload;
            })
            .addCase(getSearchTrackers.pending, (state) => {
                state.searchStatus = RequestStatuses.loading;
            })
            .addCase(getSearchTrackers.fulfilled, (state, action) => {
                state.searchStatus = RequestStatuses.success;
                state.searchTrackers = action.payload;
            })
            .addCase(setTrackerActivity.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(setTrackerActivity.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
            })
            .addCase(createTracker.pending, (state) => {
                state.createStatus = RequestStatuses.loading;
            })
            .addCase(createTracker.fulfilled, (state, action) => {
                state.createStatus = RequestStatuses.success;
                state.tracker = action.payload;
            })
            .addCase(createTracker.rejected, (state, action) => {
                // @ts-ignore // TODO delete later
                if (action.payload.messages) {
                    // @ts-ignore // TODO delete later
                    state.errors.name = action.payload.messages.name;
                }
            })
            .addCase(getAvailableUtmParams.pending, (state) => {
                state.availableParamsStatus = RequestStatuses.loading;
            })
            .addCase(getAvailableUtmParams.fulfilled, (state, action) => {
                state.availableParamsStatus = RequestStatuses.success;
                state.availableParams = action.payload;
            })
            .addCase(getShortlink.pending, (state) => {
                state.shortlinkStatus = RequestStatuses.loading;
            })
            .addCase(getShortlink.fulfilled, (state, action) => {
                state.shortlinkStatus = RequestStatuses.success;
                state.shortlink = action.payload;
            })
            .addCase(setTrackerAlgorithm.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(setTrackerAlgorithm.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.tracker = action.payload;
            })
            .addCase(setDuplicateTracker.pending, (state) => {
                state.duplicateStatus = RequestStatuses.loading;
            })
            .addCase(setDuplicateTracker.fulfilled, (state) => {
                state.duplicateStatus = RequestStatuses.success;
            })
            .addCase(setDuplicateTracker.rejected, (state, action) => {
                 // @ts-ignore // TODO delete later
                 if (action.payload.messages) {
                    // @ts-ignore // TODO delete later
                    state.errors.name = action.payload.messages.name;
                 }
            });

    },
});

export const selectSearchTrackers = (state: RootState) => state.trackers.searchTrackers?.data ?? [];
export const selectTrackersData = (state: RootState) => state.trackers.trackers?.data ?? [];
export const selectSearchTrackersStatus = (state: RootState) => state.trackers.searchStatus;
export const selectTrackers = (state: RootState) => state.trackers.trackers;
export const selectTracker = (state: RootState) => state.trackers.tracker;
export const selectAvailableUtmParams = (state: RootState) => state.trackers.availableParams;
export const selectAvailableUtmParamsStatus = (state: RootState) => state.trackers.availableParamsStatus;
export const selectTrackersStatus = (state: RootState) => state.trackers.status;
export const selectShortlink = (state: RootState) => state.trackers.shortlink;
export const selectShortlinkStatus = (state: RootState) => state.trackers.shortlinkStatus;
export const selectCreateTrackerErrors = (state: RootState) => state.trackers.errors;


export default trackersSlice.reducer;