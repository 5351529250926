import {ColumnConfig} from "../Table/types";
import {baseColumn} from "../Table/columns";

export const company: ColumnConfig = {
    ...baseColumn,
    field: 'company',
    headerName: 'statistic.table.columns.company',
    filterable: false,
};

export const team: ColumnConfig = {
    ...baseColumn,
    field: 'team',
    headerName: 'statistic.table.columns.teams',
    filterable: false,
};

export const smartLink: ColumnConfig = {
    ...baseColumn,
    field: 'smartLinkName',
    headerName: 'statistic.table.columns.smart_link_id_name',
    sortable: true,
    filterable: false,
};

export const smartLinkId: ColumnConfig = {
    ...baseColumn,
    field: 'smartLinkId',
    headerName: 'statistic.table.columns.smart_link_id',
    filterable: false,
};

export const product: ColumnConfig = {
    ...baseColumn,
    field: 'product',
    headerName: 'statistic.table.columns.products',
    filterable: false,
};

export const geo: ColumnConfig = {
    ...baseColumn,
    field: 'countryCode',
    headerName: 'statistic.table.columns.countryCode',
    sortable: true,
    filterable: false,
};

export const region: ColumnConfig = {
    ...baseColumn,
    field: 'region',
    headerName: 'statistic.table.columns.region',
    sortable: true,
    filterable: false,
};

export const platform: ColumnConfig = {
    ...baseColumn,
    field: 'platform',
    headerName: 'statistic.table.columns.platform',
    sortable: true,
    filterable: false,
};

export const device: ColumnConfig = {
    ...baseColumn,
    field: 'device',
    headerName: 'statistic.table.columns.device',
    filterable: false,
};

export const os: ColumnConfig = {
    ...baseColumn,
    field: 'os',
    headerName: 'statistic.table.columns.os',
    sortable: true,
    filterable: false,
};

export const browser: ColumnConfig = {
    ...baseColumn,
    field: 'browser',
    headerName: 'statistic.table.columns.browser',
    sortable: true,
    filterable: false,
};

export const language: ColumnConfig = {
    ...baseColumn,
    field: 'language',
    headerName: 'statistic.table.columns.language',
    sortable: true,
    filterable: false,
};

export const isEp: ColumnConfig = {
    ...baseColumn,
    field: 'isEp',
    headerName: 'statistic.table.columns.isEP',
    filterable: false,
};

export const landing: ColumnConfig = {
    ...baseColumn,
    field: 'redirectUrl',
    headerName: 'statistic.table.columns.landing',
    sortable: true,
    filterable: false,
};

export const traffic_type: ColumnConfig = {
    ...baseColumn,
    field: 'trafficType',
    headerName: 'statistic.table.columns.trafficType',
    filterable: false,
};

export const period: ColumnConfig = {
    ...baseColumn,
    field: 'date',
    headerName: 'statistic.table.columns.period',
    sortable: true,
    filterable: false,
};

export const botClicksCount: ColumnConfig = {
    ...baseColumn,
    field: 'totalClicksCount',
    headerName: 'statistic.table.columns.clicks',
    sortable: true,
    filterable: false,
};

export const unique_clicks: ColumnConfig = {
    ...baseColumn,
    field: 'uniqueClicksCount',
    headerName: 'statistic.table.columns.unique_clicks',
    sortable: true,
    filterable: false,
};

export const regs: ColumnConfig = {
    ...baseColumn,
    field: 'registrationsCount',
    headerName: 'statistic.table.columns.regs',
    sortable: true,
    filterable: false,
};

export const first_deposit: ColumnConfig = {
    ...baseColumn,
    field: 'firstDepositsCount',
    headerName: 'statistic.table.columns.first_deposit',
    sortable: true,
    filterable: false,
};

export const second_deposits: ColumnConfig = {
    ...baseColumn,
    field: 'secondDepositsCount',
    headerName: 'statistic.table.columns.second_deposits',
    sortable: true,
    filterable: false,
};

export const click2Reg: ColumnConfig = {
    ...baseColumn,
    field: 'click2Reg',
    headerName: 'statistic.table.columns.click_2_reg',
    sortable: true,
    filterable: false,
};

export const click2Fd: ColumnConfig = {
    ...baseColumn,
    field: 'click2Fd',
    headerName: 'statistic.table.columns.click_2_fd',
    sortable: true,
    filterable: false,
};

export const reg2Fd: ColumnConfig = {
    ...baseColumn,
    field: 'reg2Fd',
    headerName: 'statistic.table.columns.reg_2_fd',
    sortable: true,
    filterable: false,
};