import React, {useEffect, useState} from 'react';
import {Config} from "../../../../Form/types";
import FormGrid from "../../../../Form/FormGrid/FormGrid";
import {Button, SvgIcon, TextField, Typography, Grid, Box} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {ReactComponent as QuestionIcon} from "../../../../../app/assets/img/icons/questionGray.svg";
import {FormAutocomplete} from "../../../../Form/formInputs/Autocomplete/FormAutocomplete";
import {useTranslation} from "react-i18next";
import {
    DirectUrlFormState,
    LandingFormState,
    SplittingFormState,
    DirectUrlFormValidation,
    LandingFormValidation,
    SplittingFormValidation, dateRangesType
} from "../../type";
import {
    TYPE_AUTOCOMPLETE,
    TYPE_BUTTON,
    TYPE_SELECT_WITH_INPUT,
    TYPE_TEXT,
    TYPE_TYPOGRAPHY
} from "../../../../Form/constants";
import {AutocompleteOption} from "../../../../inputs/baseAutocomplete/types";
import {isArray} from "lodash";
import BaseSelectWithInput from "../../../../inputs/BaseSelectWithInput";
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {
    getLandingsByProduct,
    getSearchProducts,
    selectProductsStatus,
    selectSearchProducts
} from "../../../../../app/reducers/prism/brandsSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";
import {regexUrl} from "../../ModalRuleConfiguration";
import DeleteModal from "../../../../modals/DeleteModal/DeleteModal";
import {
    Node,
    RULE_LANDING,
    RULE_SPLITTING,
    RULE_DIRECT_URL
} from "../../../types";
import {TrackerData} from "../../../../../app/reducers/prism/types";
import {value} from "../../../../Trackers/columns";
import moment from "moment";
import {divideLink} from "../../ruleHelpers";

interface RenderFormFieldsProps {
    valuesItem: number;
    formName: string;
    values: Array<SplittingFormState | LandingFormState | DirectUrlFormState>;
    handleInput: (name: string, value: string, index: number, formName: string) => void;
    handleSetFieldValue: (field: string, formName: string, value: any, index: number) => void;
    errors?: Array<SplittingFormValidation | DirectUrlFormValidation | LandingFormValidation>;
    handleDeleteSplittingBlock: (index: number) => void;
    ruleEdit: Node | null;
    scheduleEdit: TrackerData | null;
    blockId: number,
}

function RenderFormFields(props: RenderFormFieldsProps) {
    const {
        blockId,
        valuesItem,
        values,
        handleInput,
        formName,
        handleSetFieldValue,
        errors,
        handleDeleteSplittingBlock,
        ruleEdit,
        scheduleEdit
    } = props

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    let configs: Config[] = [];
    const [brandList, setBrandList] = useState<AutocompleteOption[]>([]);
    const [landingSlugList, setLandingSlugList] = useState<AutocompleteOption[]>([]);
    const [selectWithInputLandingValue, setSelectWithInputLandingValue] = useState<AutocompleteOption[] | AutocompleteOption | null>(null);
    const [selectWithInputBrandValue, setselectWithInputBrandValue] = useState<AutocompleteOption[] | AutocompleteOption | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [url, setUrl] = useState<string | undefined>('');
    const [landingBrandId, setLandingBrandId] = useState<string | undefined>('');
    const [landingPath, setLandingPath] = useState<string | undefined>('');
    const options = useAppSelector(selectSearchProducts);

    const brandsChangeHandler = async (option: AutocompleteOption, first?: boolean) => {
        const res = await dispatch(getLandingsByProduct({brandId: String(option.value)}));
        let defaultOption = null;
        setLandingBrandId(res.payload.url);
        const arr = res.payload.landings.map((item: any) => {
            if (ruleEdit !== null && first === true) {
                if (formName === RULE_LANDING || formName === RULE_SPLITTING) {
                    if (values[valuesItem].landing_id === item.id) {
                        setLandingPath(item.path);
                        defaultOption = {label: item.path, value: item.id};
                    }
                }
            }

            if (scheduleEdit !== null && first === true) {
                if (scheduleEdit.redirect_type === RULE_LANDING || scheduleEdit.redirect_type === RULE_SPLITTING) {
                    if (values[valuesItem].landing_id === item.id) {
                        setLandingPath(item.path);
                        defaultOption = {label: item.path, value: item.id};
                    }
                }
            }

            return {label: item.path, value: item.id};
        });
        setLandingSlugList(arr);
        setSelectWithInputLandingValue(defaultOption);
    }

    const brandsListHandler = async () => {
        const res = await dispatch(getSearchProducts());
        const prodList = res.payload.data.map((item: any) => {
            if (ruleEdit !== null) {
                if (values[valuesItem].brand_id === item.id) {
                    setselectWithInputBrandValue({value: item.id.toString(), label: item.name});
                    brandsChangeHandler({value: item.id.toString(), label: item.name}, true);
                }
            }
            if (scheduleEdit !== null) {
                if (values[valuesItem].brand_id === item.id) {
                    setselectWithInputBrandValue({value: item.id.toString(), label: item.name});
                    brandsChangeHandler({value: item.id.toString(), label: item.name}, true);
                }
            }
            return {label: item.name, value: item.id};
        });
        setBrandList(prodList);
    }

    const onPreviewClick = () => {
        window.open(url);
    }

    const getProductUrl = (id: number) => {
        const option = options.find(option => option.id === id);

        if (option?.url) {
            return option?.url;
        }

        return ''
    }

    useEffect(() => {
        if (formName === RULE_LANDING || formName === RULE_SPLITTING) {
            brandsListHandler();
            if(values[valuesItem].brand_id === ""){
                setselectWithInputBrandValue(null);
                setSelectWithInputLandingValue(null);
            }
        }
        if (formName === RULE_DIRECT_URL) {
            if (values[valuesItem] !== null) {
                let inputValue: string = "";
                if (typeof values[valuesItem].url === "string") {
                    inputValue = values[valuesItem].url as string;
                }

                handleInput('url', inputValue, valuesItem, formName);
            }
        }
        if(formName === RULE_SPLITTING){
            if (values !== null && values[valuesItem] !== null) {
                let inputValue = values[valuesItem].probability;
                if (typeof inputValue === 'number') {
                    inputValue = inputValue.toString();
                }
                let probability: string = typeof inputValue === 'string' ? inputValue : "";
                handleInput('probability', probability, valuesItem, formName);
            }
        }
    }, [values]);

    useEffect(() => {
        landingBrandId && landingPath ? setUrl(landingBrandId + landingPath) : setUrl('');
    }, [landingBrandId, landingPath]);

    const checkDirectUrl = () => {
        return url && url?.length > 8 && regexUrl.test(url!);
    }

    const splittingConfig: Config[] = [
        {
            label: t("smartlinks.modal_rule_configuration.landing"),
            type: 'typography',
            variant: 'body2',
            class: 'splitting-block-title',
            col: 6
        },
        {
            type: 'button',
            label: t('smartlinks.modal_rule_configuration.delete_landing'),
            buttonType: 'button',
            col: 6,
            buttonClass: 'btn-add-delete btn-delete-additional-fields',
            buttonIcon: <DeleteIcon/>,
            class: 'btn-delete-landing',
            handleClick: handleDeleteSplittingBlock
        },
        {
            type: 'text',
            field: 'probability',
            label: t('smartlinks.modal_rule_configuration.probability'),
            col: 6,
            defaultValue: '',
            class: 'text-field modal-rule-configuration-field',
        },
        {
            type: 'selectWithInput',
            field: 'brand_id',
            label: t('smartlinks.modal_rule_configuration.product'),
            col: 6,
            class: 'modal-rule-configuration-field-padding-left-small autocomplete-landing-brand-id',
            optionsList: brandList,
            value: selectWithInputBrandValue,
            onChangeHandler: (event, value: AutocompleteOption[] | AutocompleteOption | null) => {
                setselectWithInputBrandValue(value);
                setSelectWithInputLandingValue([]);
                handleSetFieldValue('brand_id', formName, getAutocompleteValue(value), valuesItem);
                if (!isArray(value) && value !== null) {
                    brandsChangeHandler(value);
                } else if (value === null) {
                    setLandingSlugList([]);
                }
                setLandingBrandId(getProductUrl(Number(getAutocompleteValue(value))));
                setLandingPath('');
            }
        },
        {
            type: 'selectWithInput',
            field: 'landing_id',
            label: t('smartlinks.modal_rule_configuration.landing_slug'),
            col: 5,
            class: 'modal-rule-configuration-field-padding-left-small autocomplete-landing-path',
            optionsList: landingSlugList,
            value: selectWithInputLandingValue,
            onChangeHandler: (event, value: AutocompleteOption[] | AutocompleteOption | null) => {
                if (value !== null) {
                    setSelectWithInputLandingValue(value);
                    handleSetFieldValue('landing_id', formName, getAutocompleteValue(value), valuesItem);
                    if (!isArray(value)) {
                        setLandingPath(value?.label);
                    }
                } else {
                    setUrl('');
                    setSelectWithInputLandingValue(null);
                    setLandingPath('');
                    handleSetFieldValue('landing_id', formName, '', valuesItem);
                }
            }
        },
        {
            type: 'button',
            label: '',
            buttonType: 'button',
            col: 1,
            buttonClass: 'landing-preview-btn',
            buttonIcon: <LaunchIcon/>,
            handleClick: onPreviewClick,
            class: 'landing-preview-btn-field',
            disabled: !url
        }
    ];
    const directUrlConfig: Config[] = [
        {
            type: 'text',
            field: 'url',
            label: t('smartlinks.trackers_list.entity.edit_form.general_tracker.node_map_form.schedule_form.table.columns.url'),
            col: 11,
            defaultValue: '',
            validation: t("smartlinks.modal_rule_configuration.error_wrong_url"),
            class: 'text-field modal-rule-configuration-field-big landing-preview-url'
        },
        {
            type: 'button',
            label: '',
            buttonType: 'button',
            col: 1,
            buttonClass: 'landing-preview-btn',
            buttonIcon: <LaunchIcon/>,
            class: 'landing-preview-btn-field',
            handleClick: onPreviewClick,
            disabled: !checkDirectUrl()
        }
    ];
    const landingConfig: Config[] = [
        {
            type: 'selectWithInput',
            field: 'brand_id',
            label: t('smartlinks.modal_rule_configuration.product'),
            col: 6,
            class: 'modal-rule-configuration-field autocomplete-landing-brand-id',
            optionsList: brandList,
            value: selectWithInputBrandValue,
            onChangeHandler: (event, value: AutocompleteOption[] | AutocompleteOption | null) => {
                setselectWithInputBrandValue(value);
                setSelectWithInputLandingValue([]);
                handleSetFieldValue('brand_id', formName, getAutocompleteValue(value), valuesItem);
                if (!isArray(value) && value !== null) {
                    brandsChangeHandler(value);
                } else if (value === null) {
                    setLandingSlugList([]);
                }
                setLandingBrandId(getProductUrl(Number(getAutocompleteValue(value))));
                setLandingPath('');
                if (value === null) {
                    handleSetFieldValue('landing_id', formName, '', valuesItem);
                }
            }
        },
        {
            type: 'selectWithInput',
            field: 'landing_id',
            label: t('smartlinks.modal_rule_configuration.landing_slug'),
            col: 5,
            class: 'modal-rule-configuration-field-padding-left-small autocomplete-landing-path',
            optionsList: landingSlugList,
            value: selectWithInputLandingValue,
            onChangeHandler: (event, value: AutocompleteOption[] | AutocompleteOption | null) => {
                if (value !== null) {
                    setSelectWithInputLandingValue(value);
                    handleSetFieldValue('landing_id', formName, getAutocompleteValue(value), valuesItem);
                    if (!isArray(value)) {
                        setLandingPath(value?.label);
                    }
                } else {
                    setUrl('');
                    setSelectWithInputLandingValue(null);
                    setLandingPath('');
                    handleSetFieldValue('landing_id', formName, '', valuesItem);
                }
            }
        },
        {
            type: 'button',
            label: '',
            buttonType: 'button',
            col: 1,
            buttonClass: 'landing-preview-btn',
            buttonIcon: <LaunchIcon/>,
            class: 'landing-preview-btn-field',
            handleClick: onPreviewClick,
            disabled: !url
        }
    ];

    switch (formName) {
        case 'url':
            configs = directUrlConfig;
            break;
        case 'landing':
            configs = landingConfig;
            break;
        case 'splitting':
            configs = splittingConfig;
            break;
        default:
            configs = landingConfig;
            break;
    }

    const getAutocompleteValue = (value: AutocompleteOption | AutocompleteOption[] | null) => {
        if (isArray(value)) {
            return value.map((item: AutocompleteOption) => item.value);
        }

        if (value === null) {
            return '';
        }

        return value?.value;
    };

    return (
        <Grid
            container
            spacing={2}
            className="form-fields"
            sx={{marginLeft: '-24px', width: 'calc(100% + 24px)'}}
        >
            {configs.map((config, index: number) => {
                if (config.type === TYPE_TYPOGRAPHY) {
                    return (
                        <FormGrid config={config} key={index}>
                            <Typography
                                variant={config.variant}
                                sx={config.styles}
                                align={config.align}
                                onClick={config.handleClick}
                            >
                                {config.class?.includes('splitting-block-title')
                                    ? `${config.label} ${valuesItem + 1}`
                                    : config.label}
                            </Typography>
                        </FormGrid>
                    )
                }
                if (config.type === TYPE_TEXT) {
                    const value = values[valuesItem][config.field];
                    let err;
                    if (errors && errors.length > 0 && value && errors[valuesItem]) {
                        err = errors[valuesItem][config.field];
                    }

                    return (
                        <FormGrid config={config} key={index}>
                            <TextField
                                className="base-form-field"
                                id={config.field}
                                label={
                                    <React.Fragment>
                                        {t(config.label)}
                                        {config.questionMark && config.tooltipTitle && <Tooltip
                                            title={config.tooltipTitle}>
                                            <SvgIcon
                                                component={QuestionIcon}
                                                sx={{verticalAlign: 'middle', paddingLeft: '4px'}}
                                            />
                                        </Tooltip>}
                                    </React.Fragment>
                                }
                                variant='outlined'
                                size='small'
                                InputProps={config.props}
                                type={config.inputType}
                                name={config.field}
                                onChange={(event) => {
                                    if (config.class?.includes('landing-preview-url')) {
                                        setUrl(event.target.value);
                                    }
                                    const {name, value} = event.target;
                                    handleInput(name, value, valuesItem, formName);
                                }}
                                value={value}
                                error={err}
                                helperText={err && <>{config.validation}</>}
                                disabled={config.disabled}
                            />
                        </FormGrid>
                    )
                }
                if (config.type === TYPE_SELECT_WITH_INPUT) {
                    return (
                        <FormGrid config={config} key={index}>
                            <BaseSelectWithInput
                                value={config.value}
                                optionsList={config.optionsList}
                                placeholder={config.label}
                                disabled={config.disabled}
                                onChange={config.onChangeHandler ? config.onChangeHandler : () => {
                                }}
                            />
                        </FormGrid>
                    );
                }
                if (config.type === TYPE_BUTTON) {
                    return (
                        <Box key={index}
                             sx={{width: config.buttonClass?.includes('btn-delete-additional-fields') ? "50%" : "auto"}}>
                            <FormGrid config={config}>
                                <Button
                                    fullWidth
                                    type={config.buttonType}
                                    variant='contained'
                                    color='secondary'
                                    onClick={
                                        config.buttonClass?.includes('btn-delete-additional-fields')
                                            ? () => {
                                                setOpenModal(true);
                                            }
                                            : config.handleClick
                                    }
                                    className={config.buttonClass}
                                    disabled={config.disabled}
                                >
                                    {config.buttonIcon && config.buttonIcon}
                                    {t(config.label)}
                                </Button>
                            </FormGrid>
                            {openModal && (<DeleteModal
                                handleDelete={() => {
                                    handleDeleteSplittingBlock(valuesItem);
                                    setOpenModal(false);
                                }}
                                handleClose={() => setOpenModal(false)}
                                modalTitle={t('smartlinks.delete_modal.delete_splitting_title')}
                                modalSubtitle={t('smartlinks.delete_modal.delete_splitting_subtitle')}
                            />)}
                        </Box>
                    )
                }

            })}
        </Grid>
    )
}

export default RenderFormFields;
