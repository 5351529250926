import * as React from 'react';
import {useState} from 'react';
import {Link as RouterLink, matchPath, useLocation} from "react-router-dom";
import {Avatar, Box, Tab} from "@mui/material";
import PersonOutlineRounded from '@mui/icons-material/PersonOutlineRounded';
import {trim} from 'lodash';
import StyledTabs from "../Tabs/StyledTabs";
import {useTranslation} from "react-i18next";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import './LinkTabs.scss';
import Routes from "../../navigation/routes";

function LinkTabs() {
    const {t, i18n} = useTranslation();
    const [lang, setLang] = useState(i18n.language);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const setLanguageHandler = function (lang: 'ru' | 'en') {
        setLang(lang);
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
        setAnchorEl(null);
    }

    return (
        <StyledTabs
            className="linktabs"
            textColor="inherit"
            value={0}
            indicatorColor="primary"
            sx={{backgroundColor: '#141310', boxShadow: "none"}}
        >
            <Box sx={{flexGrow: 0, marginRight: 'auto'}}>
                <Tab
                    icon={<img src="/logo-mops.svg" alt="Prism logo"/>}
                    iconPosition="start"
                    value={Routes.trackers}
                    to={Routes.trackers}
                    component={RouterLink}
                    className="logo"
                    disableRipple
                />
            </Box>

            <Box className="header_left">

                {/*<Paper component="form" className="search">*/}
                {/*    <IconButton type="button" sx={{p: '10px'}} aria-label="search">*/}
                {/*        <SearchIcon/>*/}
                {/*    </IconButton>*/}
                {/*    <InputBase*/}
                {/*        sx={{ml: 1, flex: 1}}*/}
                {/*        placeholder="Search"*/}
                {/*        inputProps={{'aria-label': 'search'}}*/}
                {/*    />*/}
                {/*</Paper>*/}

                <Tab
                    label={lang !== 'en' ? 'RU' : 'EN'}
                    disableRipple
                    className="cur_lang"
                    onClick={handleClick}
                />
                <Menu
                    id="lang-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem className={lang === 'ua'? 'active':''}>UA</MenuItem>
                    <MenuItem className={lang === 'ru'? 'active':''} onClick={() => setLanguageHandler('ru')}>RU</MenuItem>
                    <MenuItem className={lang === 'en'? 'active':''} onClick={() => setLanguageHandler('en')}>EN</MenuItem>
                </Menu>
                {/*<Box>*/}
                {/*    <NotificationsIcon sx={{cursor: "pointer"}}/>*/}
                {/*</Box>*/}
            </Box>
        </StyledTabs>
    );
}

export default LinkTabs;