import {baseColumn} from "../Table/columns";
import {ColumnConfig} from "../Table/types";

export const tracker_list_actions: ColumnConfig = {
    ...baseColumn,
    field: 'actions',
    headerName: 'common.tracker_filters.actions',
};

export const status: ColumnConfig = {
    ...baseColumn,
    field: 'enabled',
    headerName: 'common.tracker_filters.status',
    sortable: false,
    tooltipTitle: 'Once you deactivate tracker its traffic follows <a href="/default-trackers" target="_blank">default smartlink</a> geo-rules',
    questionMark: true
};

export const tracker_id: ColumnConfig = {
    ...baseColumn,
    field: 'id',
    headerName: 'common.tracker_filters.tracker_id',
    sortable: true,
};

export const id: ColumnConfig = {
    ...baseColumn,
    field: 'id',
    headerName: 'ID',
    sortable: true,
};

export const name: ColumnConfig = {
    ...baseColumn,
    field: 'name',
    headerName: 'common.tracker_filters.name',
    sortable: true,
};

export const path: ColumnConfig = {
    ...baseColumn,
    field: 'path',
    headerName: 'path',
    sortable: false,
};

export const type: ColumnConfig = {
    ...baseColumn,
    field: 'type',
    headerName: 'type',
    sortable: false,
};

export const value: ColumnConfig = {
    ...baseColumn,
    field: 'value',
    headerName: 'value',
    sortable: false,
};


export const tracker_name: ColumnConfig = {
    ...baseColumn,
    field: 'name',
    headerName: 'common.tracker_filters.tracker_name',
    sortable: true,
};

export const team: ColumnConfig = {
    ...baseColumn,
    field: 'team.name',
    headerName: 'common.tracker_filters.team',
};

export const tracker_type: ColumnConfig = {
    ...baseColumn,
    field: 'type',
    headerName: 'common.tracker_filters.type',
    sortable: true,
};

export const owner: ColumnConfig = {
    ...baseColumn,
    field: 'owner.email',
    searchField: 'owner_id',
    headerName: 'common.tracker_filters.owner',
};

export const smartlink: ColumnConfig = {
    ...baseColumn,
    field: 'shortlink.shortlink_short',
    headerName: 'common.tracker_filters.tracker_url',
};

export const tracker_is_favorite: ColumnConfig = {
    ...baseColumn,
    field: 'favorite',
    headerName: '',
    customisable: false
};