import * as React from 'react';
import Switch from '@mui/material/Switch';
import {styled} from "@mui/material";
import {useEffect} from "react";

interface ToggleProps {
    manualOnChange?: boolean
    handler: (event: React.ChangeEvent<HTMLInputElement>) => void
    isChecked: boolean
}

export default function Toggle(props: ToggleProps) {
    const {isChecked, handler, manualOnChange} = props;
    const [checked, setChecked] = React.useState(isChecked);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!manualOnChange) {
            setChecked(event.target.checked);
        }
        handler(event);
    };

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked])

    const CustomSwitch = styled(Switch)(({theme}) => ({
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                color: theme.palette.white.main,
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.dark
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.white.main
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.light
        },
    }));

    return (
        <CustomSwitch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}