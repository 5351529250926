import React, {useEffect, useState} from 'react';
import Table from "../Table/Table";
import {
    shortener_name,
    short_link,
    full_url,
    owner,
    team,
    company,
    shorteners_list_actions,
    empty_col
} from "./columns";
import {ColumnConfigWithRender, FieldHandler, SearchConfig} from "../Table/types";
import {FilterConfig, FilterConfigType} from "../Table/AppliedFilterBar/types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {CompanyData, ShortUrlData, UserTeam} from "../../app/reducers/prism/types";
import CreateIcon from '@mui/icons-material/Create';
import {selectShortUrls, getShortUrls, deleteShortlink} from "../../app/reducers/prism/shortUrlsSlice";
import {selectCompanies, getCompanies} from "../../app/reducers/prism/companiesSlice";
import TablePage from "../Page/TablePage";
import CustomIconButton from "../CustomIconButton";
import {useTranslation} from "react-i18next";
import {selectSelfUser} from "../../app/reducers/prism/sessionSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import "./Shortlinks.scss";
import Tooltip from "../Tooltip/Tooltip";
import theme from "../UI/Theme";
import {getSearchTeams, selectSearchTeams, selectSearchTeamsStatus} from "../../app/reducers/prism/teamsSlice";
import {getSearchUsers, selectSearchUsers, selectUsersStatus} from "../../app/reducers/prism/usersSlice";
import {PaginatedData} from "../../app/types";
import DeleteModal from "../modals/DeleteModal/DeleteModal";
import DarkTooltip from "../DarkTooltip/DarkTooltip";

type ShortUrlHandlersList = {
    deleteShortlink: FieldHandler<ShortUrlData>
};

const filtersConfig: FilterConfig[] = [
    {
        type: FilterConfigType.autocomplete,
        field: 'owners',
        placeholder: 'shortener.table.columns.owner',
        optionLabel: 'email',
        asyncAction: getSearchUsers,
        dataSelector: selectSearchUsers,
        statusSelector: selectUsersStatus,
        multiselect: true,
    },
    {
        type: FilterConfigType.autocomplete,
        field: 'teams',
        placeholder: 'common.tracker_filters.team',
        optionLabel: 'name',
        asyncAction: getSearchTeams,
        dataSelector: selectSearchTeams,
        statusSelector: selectSearchTeamsStatus,
        multiselect: true,
    },
];

const additionalSearches: SearchConfig<ShortUrlData>[] = [
    {headerName: 'shortener.table.columns.title', field: 'title'},
    // {headerName: 'shortener.table.columns.short_url', field: 'short_url'},
    {headerName: 'shortener.table.columns.target_url', field: 'target_url'},
];

function Shortlinks() {
    const {t} = useTranslation();
    const data = useAppSelector(selectShortUrls);
    const dataCompanies = useAppSelector(selectCompanies);
    const selfUser = useAppSelector(selectSelfUser);
    const isAdmin = selfUser?.role.alias === 'admin';
    const dispatch = useAppDispatch();
    const title = t("menu.shortener");
    const description = t("shortener.description");
    const [openDialogDeleteShortener, setOpenDialogDeleteShortener] = useState(false);
    const [refreshData, setRefreshData] = useState(data);
    const [dialogDeleteShortenerText, setDialogDeleteShortenerText] = useState('');
    const [idForDelete, setIdForDelete] = useState<number>(0);
    const isAdminShortenerTableColumns: ColumnConfigWithRender<ShortUrlData, ShortUrlHandlersList>[] = [
        {
            ...shortener_name,
            render: (row, cell, refresh, handlers) => {
                if (row.title.length > 29) {
                    return (
                        <DarkTooltip title={row.title} placement="top">
                            <p>{row.title.slice(0, 29) + '...'}</p>
                        </DarkTooltip>
                    );
                }
                return (
                    <span>{row.title}</span>
                );
            },
        },
        {
            ...short_link,
            render: (row, cell, refresh, handlers) => {
                return <Tooltip url={row.short_url} styles={{color: theme.palette.success.main}} numberLetters={35}/>;
            },
        },
        {
            ...full_url,
            render: (row, cell, refresh, handlers) => {
                return <Tooltip url={row.target_url} styles={{color: theme.palette.action.active}} numberLetters={35}/>;
            },
        },
        owner,
        {
            ...team,
            render: (row, cell, refresh, handlers) => {
                let teams = '';
                if (row.createdBy?.teams?.length) {
                    teams = row.createdBy.teams.map((team: UserTeam) => team.name).join(',');
                }
                return <>{teams}</>;
            },
        },
        company,
        {
            ...shorteners_list_actions,
            render: (row, cell, refresh, handlers) => {
                return (
                    <React.Fragment key={`shortener_list_actions-${row.id}`}>
                        <CustomIconButton
                            title={t('shortener.table.actions.delete')}
                            component={DeleteIcon}
                            onClick={() => handlers?.deleteShortlink(row, cell, () => {})}
                        />
                        <CustomIconButton
                            title={t('shortener.table.actions.edit')}
                            component={CreateIcon}
                            to={`/shortener/${row.id}`}
                        />
                    </React.Fragment>
                )
            }
        }
    ];
    const isNotAdminShortenerTableColumns: ColumnConfigWithRender<ShortUrlData, ShortUrlHandlersList>[] = [
        {
            ...shortener_name,
            render: (row, cell, refresh, handlers) => {
                if (row.title.length > 29) {
                    return (
                        <DarkTooltip title={row.title} placement="top">
                            <p>{row.title.slice(0, 29) + '...'}</p>
                        </DarkTooltip>
                    );
                }
                return (
                    <span>{row.title}</span>
                );
            },
        },
        {
            ...short_link,
            render: (row, cell, refresh, handlers) => {
                return <Tooltip url={row.short_url} styles={{color: theme.palette.success.main}} numberLetters={35}/>;
            },
        },
        {
            ...full_url,
            render: (row, cell, refresh, handlers) => {
                return <Tooltip url={row.target_url} styles={{color: theme.palette.action.active}} numberLetters={35}/>;
            },
        },
        owner,
        empty_col,
        {
            ...shorteners_list_actions,
            render: (row, cell, refresh, handlers) => {
                return (
                    <React.Fragment key={`shortener_list_actions-${row.id}`}>
                        <CustomIconButton
                            title={t('shortener.table.actions.delete')}
                            component={DeleteIcon}
                            onClick={() => handlers?.deleteShortlink(row, cell, () => {})}
                        />
                        <CustomIconButton
                            title={t('shortener.table.actions.edit')}
                            component={CreateIcon}
                            to={`/shortener/${row.id}`}
                        />
                    </React.Fragment>
                )
            }
        }
    ];

    const handleDeleteShortener = async () => {
        const response = await dispatch(deleteShortlink(idForDelete));
        response && setOpenDialogDeleteShortener(false);
        dispatch(getShortUrls());
    };

    const addCompanyToData = (data: PaginatedData<ShortUrlData>) => {
        return data?.data.map((item: ShortUrlData) => {
            if (item.createdBy.teams && dataCompanies && item.createdBy.teams?.length) {
                let companyName: string[] = [];
                item.createdBy.teams.forEach((team) => {
                    const id = team.company_id;
                    const companyNameTemp = dataCompanies.filter((company: CompanyData) => company.id === id);
                    companyNameTemp.length && companyName.push(companyNameTemp[0]?.name);
                })
                return {...item, company_name: companyName.map(company => company).join(', ')};
            }
            return item;
        });
    }

    useEffect(() => {
        isAdmin && dispatch(getCompanies());
    }, [dispatch]);

    useEffect(() => {
        data && setRefreshData({data: addCompanyToData(data), query_parameters: data!.query_parameters});
    }, [data]);

    return (
        <div className={isAdmin ? "shortener-page shortener-page-admin" : "shortener shortener-page-user"}
             style={{marginLeft: '36px'}}>
            <TablePage
                title={title}
                description={description}
            />
            <Table
                tableName='shortener'
                filtersConfig={filtersConfig}
                columnsConfig={isAdmin ? isAdminShortenerTableColumns : isNotAdminShortenerTableColumns}
                requestHandler={getShortUrls}
                additionalSearches={additionalSearches}
                data={refreshData}
                uniqueField='id'
                defaultSearchField='title'
                fieldHandlers={{
                    deleteShortlink: (shortlink) => {
                        setDialogDeleteShortenerText(t('shortener.modal_delete_text') + '“' + shortlink.title + '” ?');
                        setOpenDialogDeleteShortener(true);
                        setIdForDelete(shortlink.id);
                    }
                }}
            />
            {openDialogDeleteShortener && <DeleteModal
                handleDelete={handleDeleteShortener}
                handleClose={() => setOpenDialogDeleteShortener(false)}
                modalTitle={t('shortener.table.actions.delete')}
                modalSubtitle={dialogDeleteShortenerText}
            />}
        </div>
    );
}

export default Shortlinks;