import {createAsyncThunk, createSlice, createSelector} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {RequestStatuses} from "../../../componets/Table/types";
import {createReportRequest} from "../../requests/report";
import {ReportsData} from "./types";

export interface ReportsState {
    reports: PaginatedData<ReportsData> | null;
    status: RequestStatuses;
    createStatus: RequestStatuses;
}

const initialState: ReportsState = {
    reports: null,
    status: RequestStatuses.idle,
    createStatus: RequestStatuses.idle,
};

export const createReport = createAsyncThunk(
    'landing/createLanding',
    async (body: any, {rejectWithValue}) => {
        try {
            return await createReportRequest(body);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createReport.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(createReport.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.reports = action.payload;
            })
    },
});

export const selectReportsStatus = (state: RootState) => state.shortUrls.status;
export const selectReportsTemp = (state: RootState) => state.reports.reports;
export const selectReports = createSelector(selectReportsTemp, (data) => {
    if (data) {
        return {
            data: data.data,
            query_parameters: {
                count: data.meta.totalRowCount,
                page: data.meta.pageNumber,
                per_page: data.meta.pageSize,
                sort_field: data.meta.sortField,
                sort_order: data.meta.sortOrder
            },
            total: data.total
        }
    } else {
        return data;
    }
})

export default reportsSlice.reducer;
