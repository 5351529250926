import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {fetchTrackerStatistic} from '../../requests/statistic';
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {StatisticRow} from "./types";


export interface TrackerStatisticState {
  trackersStatistic: PaginatedData<StatisticRow> | null;
  status: RequestStatuses;
}

const initialState: TrackerStatisticState = {
  trackersStatistic: null,
  status: RequestStatuses.idle,
};

export const getStatistic = createAsyncThunk(
  'statistic/fetchTrackers',
  async (filters: UrlParams | undefined, { rejectWithValue }) => {
    try {
      return await fetchTrackerStatistic(filters);
    } catch (err) {
      return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
    }
  }
);

export const trackerStatisticSlice = createSlice({
  name: 'trackerStatistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatistic.pending, (state) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(getStatistic.fulfilled, (state, action) => {
        state.status = RequestStatuses.success;
        state.trackersStatistic = action.payload;
      });

  },
});

export const selectTrackerStatistic = (state: RootState) => state.statistic.trackersStatistic;
export const selectTrackerStatisticStatus = (state: RootState) => state.statistic.status;


export default trackerStatisticSlice.reducer;
