import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {fetchShortUrls, createShortUrlDefault, updateShortUrl, fetchShortUrl, deleteShortUrl} from "../../requests/shortUrls";
import {CreateShortUrlArguments, ShortUrlData, ShortUrlSettings} from "./types";

export interface ShortUrlsState {
    shortUrls: PaginatedData<ShortUrlData> | null;
    shortUrl: ShortUrlData | null;
    status: RequestStatuses;
    createStatus: RequestStatuses;
    deleteStatus: RequestStatuses;
}

const initialState: ShortUrlsState = {
    shortUrls: null,
    shortUrl: null,
    status: RequestStatuses.idle,
    createStatus: RequestStatuses.idle,
    deleteStatus: RequestStatuses.idle,
};

export const getShortUrls = createAsyncThunk(
    'shortUrls/fetchShortUrls',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchShortUrls(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const createShortlink = createAsyncThunk(
    'shortUrls/createShortUrl',
    async ({domain_id, slug, target_url, title, with_loader}: CreateShortUrlArguments, {rejectWithValue}) => {
        try {
        return await createShortUrlDefault(domain_id, slug, target_url, title, with_loader);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
)

export const updateShortlink = createAsyncThunk(
    'shortUrls/updateShortUrl',
    async ({short_url_id, target_url, title, with_loader, domain_id, slug}: ShortUrlSettings, {rejectWithValue}) => {
        try {
        return await updateShortUrl({
            short_url_id,
            target_url,
            title,
            with_loader,
            domain_id,
            slug
        })
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
)

export const getShortUrl = createAsyncThunk(
    'shortUrls/fetchShortUrl',
    async (id: number, {rejectWithValue}) => {
        try {
        return await fetchShortUrl(id);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const deleteShortlink = createAsyncThunk(
    'shortUrls/deleteShortUrl',
    async (id: number, {rejectWithValue}) => {
        try {
            return await deleteShortUrl(id);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const shortUrlsSlice = createSlice({
    name: 'shortUrls',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getShortUrls.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getShortUrls.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.shortUrls = action.payload;
            })
            .addCase(createShortlink.pending, (state) => {
                state.createStatus = RequestStatuses.loading;
            })
            .addCase(createShortlink.fulfilled, (state, action) => {
                state.createStatus = RequestStatuses.success;
                state.shortUrl = action.payload;
            })
            .addCase(updateShortlink.pending, (state) => {
                state.createStatus = RequestStatuses.loading;
            })
            .addCase(updateShortlink.fulfilled, (state, action) => {
                state.createStatus = RequestStatuses.success;
            })
            .addCase(getShortUrl.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getShortUrl.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.shortUrl = action.payload;
            })
            .addCase(deleteShortlink.pending, (state) => {
                state.deleteStatus = RequestStatuses.loading;
            })
            .addCase(deleteShortlink.fulfilled, (state, action) => {
                state.deleteStatus = RequestStatuses.success;
            })
    },
});

export const selectShortUrlsStatus = (state: RootState) => state.shortUrls.status;
export const selectShortUrls = (state: RootState) => state.shortUrls.shortUrls;
export const selectShortUrl = (state: RootState) => state.shortUrls.shortUrl;

export default shortUrlsSlice.reducer;
