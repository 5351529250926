import { namespaces } from "./i18n.constants";
import tEn from './locales/en.json'
import tRu from './locales/ru.json'

export const en = {
  [namespaces.common]: tEn,
};

export const ru = {
  [namespaces.common]: tRu,
};

