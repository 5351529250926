export const availableFunnels = [
    {
        'id': 'open',
        'name': 'Open form',
    },
    {
        'id': 'modal',
        'name': 'Modal form',
    },
    {
        'id': 'landing',
        'name': 'Landing page',
    },
    {
        'id': 'app',
        'name': 'App install page',
    }
];
export const availableStatuses = [
    {
        'id': "active",
        'name': "Active",
    },
    {
        'id': "archived",
        'name': "Archived",
    },
];