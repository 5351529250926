import React from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";

interface FavouritesCheckboxProps {
    onHandleChange: () => void;
}

function FavouritesCheckbox({onHandleChange}: FavouritesCheckboxProps) {
    return (
        <div className="favourites-checkbox-wrap">
            {/*<FormControlLabel*/}
            {/*    label="Show only favorites"*/}
            {/*    control={*/}
            {/*        <Checkbox*/}
            {/*            checked={false}*/}
            {/*            onChange={onHandleChange}*/}
            {/*        />*/}
            {/*    }*/}
            {/*/>*/}
        </div>
    );
}

export default FavouritesCheckbox;
