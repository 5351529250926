import * as React from 'react';
import './CustomAlert.scss';
import {Box, Typography} from '@mui/material/';
import {SvgIcon} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

interface CustomAlertProps {
    type: 'success' | 'error' | 'info' | 'warning';
    title: string;
    content?: string;
    onClose?: () => void;
    status: boolean;
    position?: 'rightTop' | 'rightBottom' | 'leftTop' | 'leftBottom'
    zIndex?: string
}

export default function CustomAlert(props: CustomAlertProps) {
    const {
        type,
        title,
        content,
        onClose,
        status,
        position,
        zIndex
    } = props;

    let icon = CheckCircleOutlineIcon;
    if (type === 'error' || type === 'info') {
        icon = InfoOutlinedIcon;
    }
    if (type === 'warning') {
        icon = WarningAmberOutlinedIcon;
    }

    return (
        <Box sx={{display: status ? 'block' : 'none', zIndex: zIndex}} className={`custom-alert  ${type} ${position ? position : ''}`}>
            <Box className="first_row">
                <SvgIcon className="type_icon" component={icon} inheritViewBox/>
                <Typography className={`title ${onClose && 'title_small'}`}>
                    {title}
                </Typography>
                {onClose && <SvgIcon className="close" onClick={onClose} component={CloseIcon} inheritViewBox/>}
            </Box>
            {content && <Box className="content">{content}</Box>}
        </Box>
    );
}
