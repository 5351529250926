import {useEffect, useMemo, useRef, useState} from "react";
import {AsyncThunk} from "@reduxjs/toolkit";

import {DataRow, RequestStatuses, UrlParams} from "../../Table/types";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import { transformDataToAutocomplete } from "./helpers";
import { AutocompleteOption } from "./types";
import {isArray} from "lodash";

export function useDataRequest(
    optionLabel: string,
    dataStatus: RequestStatuses,
    open: boolean,
    asyncAction: AsyncThunk<any, UrlParams | undefined, any>,
    defaultOptionValues?: Array<string | number | null>,
) {
    const defaultFilters = useMemo(() => ({
        per_page: '1000',
        sort_field: optionLabel,
        sort_order: 'asc'
    }), [optionLabel]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if ((dataStatus === 'idle' && (open || defaultOptionValues?.length))) {
            dispatch(asyncAction(defaultFilters));
        }
    }, [asyncAction, dataStatus, defaultFilters, defaultOptionValues?.length, dispatch, open]);

}

export function useOptions(
    dataSelector: (state: RootState) => DataRow[] | null,
    optionValue: string,
    optionLabel: string,
    multiple?: boolean,
    defaultOptionValues?: Array<string | number | null>,
) {
    const options = useAppSelector(dataSelector);
    const defaultValue = defaultOptionValues ?? multiple ? [] : null;
    const [value, setValue] = useState<AutocompleteOption[] | AutocompleteOption | null>(defaultValue);
    const [transformedOptions, setTransformedOptions] =  useState(transformDataToAutocomplete(options, optionLabel, optionValue) ?? []);
    const isInitialized = useRef(false);

    useEffect(() => {
        setTransformedOptions(transformDataToAutocomplete(options, optionLabel, optionValue) ?? [])
    }, [optionLabel, optionValue, options])

    const isMultiplyValue = multiple && isArray(value);
    useEffect(() => {
        const newValue = isMultiplyValue
            ? transformedOptions.filter(option => defaultOptionValues?.includes(option.value))
            : transformedOptions.find(option => defaultOptionValues?.includes(option.value)) ?? null;

       if (!isInitialized.current && transformedOptions.length > 0) {
           setValue(newValue);
           isInitialized.current = true;
       }
    }, [multiple, transformedOptions, isMultiplyValue, defaultOptionValues]);

    return {
        options: transformedOptions,
        value,
        setValue
    };
}