import {AsyncThunk} from "@reduxjs/toolkit";
import {AppFilterValue, DataRow, RequestStatuses, UrlParams} from "../types";
import {RootState} from "../../../app/store";
import {PaginatedData} from "../../../app/types";

export enum FilterConfigType {
    // toggle = 'toggle',
    // toggleRange = 'toggleRange',
    autocomplete = 'autocomplete',
    select = 'select',
    dateRange = 'dateRange',
    title = 'title',
    // dateRangeWithToggle = 'dateRangeWithToggle'
}

export interface FilterConfigBase {
    type: FilterConfigType;
    field: string;
    placeholder?: string;
    multiselect?: boolean;
}

export interface FilterConfigAutocomplete extends FilterConfigBase {
    type: FilterConfigType.autocomplete;
    placeholder: string;
    optionLabel: string;
    optionValue?: string;
    asyncAction: AsyncThunk<any, UrlParams | undefined, any>;
    dataSelector: (state: RootState) => DataRow[] | null;
    statusSelector: (state: RootState) => RequestStatuses;
}

export interface FilterConfigTitle extends FilterConfigBase {
    type: FilterConfigType.title;
    placeholder: string;
    optionValue: string;
}

export interface FilterConfigBaseRange {
    // type: FilterConfigType.toggleRange | FilterConfigType.dateRange;
    type: FilterConfigType.dateRange;
    field?: string;
    placeholder?: string;
    // disabled?: boolean | undefined;
    // fromField: string;
    // toField: string;
}

export interface FilterConfigDateRange extends FilterConfigBaseRange {
    type: FilterConfigType.dateRange;
}

export type SelectValue = string | number | boolean | null | undefined;

export type SelectOption = { [key: string]: SelectValue };

export interface FilterConfigSelect extends FilterConfigBase {
    type: FilterConfigType.select;
    defaultValue?: SelectValue;
    placeholder: string;
    options?: SelectOption[];
    valueMutator?: (value: AppFilterValue) => AppFilterValue;
    // saveToBackEndAs: string;
    // labelField: string | labelFieldGetter;
    label?: string;
    value?: SelectValue;
    // hideClearButton?: boolean | undefined;
    // handler: Function;
}

export declare type FilterConfig =
    // | FilterConfigToggle
    | FilterConfigAutocomplete
    | FilterConfigSelect
    // | FilterConfigToggleRange
    | FilterConfigDateRange
    | FilterConfigTitle
    // | FilterConfigDateRangeWithToggle;