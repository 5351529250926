import {styled} from "@mui/material/styles";
import Box, {BoxProps} from "@mui/material/Box/Box";
import StyledDialog from "../../modals/Dialog/StyledDialog";

export const SmartlinkEditModalStyled = styled(StyledDialog)<BoxProps>(({theme}) => ({
    ".container": {
        width: "389px",
        paddingLeft: "36px",
        paddingRight: "36px",
        boxSizing: "border-box",
        ".MuiGrid-item": {
            paddingTop: "24px",
            height: "max-content",
        },
        "h2": {
          padding: 0,
        },
        ".subheading": {
            color: theme.palette.white.dark,
            fontSize: "16px:",
        },
        ".close-button": {
            float: "none",
            display: "flex",
            position: "absolute",
            right: "24px",
            top: "33px",
            "button": {
                width: "16px",
                height: "16px",
                "svg": {
                    width: "21px",
                    height: "21px",
                }
            },
        },
        ".MuiDialogContent-root": {
            padding: "27px 0 36px 0"
        },
        ".MuiDialogActions-root": {
            borderTop: "1px solid " + theme.palette.other.divider,
            padding: "24px 0 32px 0",
        },
        ".MuiButton-root": {
            width: "167px"
        },
        "TextField": {
            height: "40px",
            boxSizing: "border-box",
        },
        ".MuiTextField-root + .MuiTextField-root": {
            marginTop: "24px",
        },
        ".MuiFormHelperText-root": {
            margin: "0 0 0 14px",
            textAlign: "left",
        }
    }
}));

