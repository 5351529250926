import React, {useEffect, useState} from 'react';

import {AppFilterValue, UrlParams} from "../../types";
import {SelectOption, SelectValue} from "../../filters/types";
import './SelectFilter.css';
import {BaseSelect} from "../../../inputs/BaseSelect";
import { useTranslation } from "react-i18next";

interface FilterSelectProps {
    fieldName: string
    defaultOptionValue?: SelectValue;
    onChangeHandler: (option: AppFilterValue) => void;
    valueMutator?: (option: AppFilterValue) => AppFilterValue;
    placeholder: string;
    label?: string;
    value?: string;
    options?: SelectOption[],
    urlFilters: UrlParams;
    multiselect?: boolean;
    clearIcon?: boolean;
    sx?: object;
}

export function SelectFilter(props: FilterSelectProps) {
    const { t } = useTranslation();
    const {
        defaultOptionValue = '',
        onChangeHandler,
        valueMutator,
        options = [],
        placeholder,
        label = 'label',
        value = 'value',
        urlFilters,
        fieldName,
        clearIcon,
        sx
    } = props;

    const [inputValue, setInputValue] = useState(defaultOptionValue || '');

    useEffect(() => {
        if (!urlFilters[fieldName]) {
            setInputValue(defaultOptionValue || '');
        }
    }, [defaultOptionValue, fieldName, urlFilters])

    return (
        <BaseSelect
            key={fieldName}
            fieldName={fieldName}
            defaultOptionValue={defaultOptionValue}
            valueMutator={valueMutator}
            onChangeHandler={onChangeHandler}
            placeholder={placeholder}
            options={options}
            label={label}
            value={value}
            clearIcon={clearIcon}
            sx={sx}
        />
    )
}
