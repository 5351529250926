import React, {useState} from 'react';
import Table from "../Table/Table";
import {
    status,
    product_id,
    product_list_actions,
    product_name,
    domain,
    company,
    mirrors
} from "./columns";
import {ColumnConfigWithRender, FieldHandler, SearchConfig} from "../Table/types";
import {FilterConfig, FilterConfigType} from "../Table/AppliedFilterBar/types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {BrandData} from "../../app/reducers/prism/types";
import {Button, IconButton, DialogTitle, DialogContentText, Dialog, DialogActions, DialogContent} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Toggle from "../Toggle/Toggle";
import {getBooleanTableValue} from "../Table/helpers/helpers";
import {
    getSearchProducts,
    getProducts,
    selectSearchProducts,
    selectProducts, setProductActivity
} from "../../app/reducers/prism/brandsSlice";
import TablePage from "../Page/TablePage";
import CustomIconButton from "../CustomIconButton";
import {useTranslation} from "react-i18next";
import DarkTooltip from "../DarkTooltip/DarkTooltip";
import {selectSelfUser} from "../../app/reducers/prism/sessionSlice";
import IconLandings from "./icons/IconLandings";
import IconSmartlinks from "./icons/IconSmartlinks";
import "./Products.scss";

type BrandHandlersList = {
    toggle: FieldHandler<BrandData, (checked: boolean) => void>
};

const filtersConfig: FilterConfig[] = [
    {
        type: FilterConfigType.select,
        placeholder: 'common.tracker_filters.status',
        field: 'enabled',
        defaultValue: 'all',
        valueMutator: (value) => (value === 'all' ? null : value),
        options: [
            {label: 'common.filters.active.all', value: 'all'},
            {label: 'common.filters.active.active', value: true},
            {label: 'common.filters.active.not_active', value: false},
        ]
    }
];

const additionalSearches: SearchConfig<BrandData>[] = [
    {headerName: 'products.table.columns.id', field: 'id'},
    {headerName: 'products.table.columns.name', field: 'name'},
    {headerName: 'products.table.columns.url', field: 'url'},
];

function Products() {
    const {t} = useTranslation();
    const data = useAppSelector(selectProducts);
    const selfUser = useAppSelector(selectSelfUser);
    const isAdmin = selfUser?.role.alias === 'admin';
    const dispatch = useAppDispatch();
    const title = t("menu.products");
    const description = t("products.description");
    const textDeactivateProduct = t("products.table.actions.confirm_text_active");
    const textActivateProduct = t("products.table.actions.confirm_text_inactive");
    const titleDeactivateProduct = t("products.table.actions.deactivate_product");
    const titleActivateProduct = t("products.table.actions.activate_product");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogProductText, setDialogProductText] = useState('');
    const [dataDispatch, setDataDispatch] = useState({
        brandId: 0,
        name: '',
        url: '',
        help_url: '',
        refresh: () => {},
        checked: false
    });
    const isAdminProductsTableColumns: ColumnConfigWithRender<BrandData, BrandHandlersList>[] = [
        {
            ...status,
            filterable: true,
            getOptionsAction: getSearchProducts,
            dataSelector: selectSearchProducts,
            idField: 'enable',
            labelField: 'enabled',
            render: (row, cell, refresh, handlers) => (
                <Toggle
                    manualOnChange={true}
                    key={`toggle-${row.id}`}
                    isChecked={getBooleanTableValue(row, cell.field)}
                    handler={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handlers?.toggle(row, cell, refresh)(event.target.checked)
                    }
                />
            )
        },
        product_id,
        {
            ...product_name,
            filterable: true,
            render: (row, cell, refresh, handlers) => {
                if (row.name.length > 40) {
                    return (
                        <DarkTooltip title={row.name} placement="top">
                            <p>{row.name.slice(0, 40) + '...'}</p>
                        </DarkTooltip>
                    );
                }
                return (
                    <span>{row.name}</span>
                );
            },
        },
        domain,
        company,
        {
            ...mirrors,
            render: (row, cell, refresh, handlers) => {
                if (row.mirrors?.length) {
                    return (
                        <div className="mirror-chip mirror-chip-on">ON</div>
                    )
                }
                return (
                    <div className="mirror-chip mirror-chip-off">OFF</div>
                )
            },
        },
        {
            ...product_list_actions,
            render: (row, cell, refresh, handlers) => {
                return (
                    <React.Fragment key={`products_list_actions-${row.id}`}>
                        <CustomIconButton title={t('products.table.actions.edit')} component={CreateIcon}/>
                        <CustomIconButton title="" component={AssessmentIcon}/>
                        <CustomIconButton title="" component={IconLandings}/>
                        <CustomIconButton title="" component={IconSmartlinks}/>
                    </React.Fragment>
                )
            }
        }
    ];
    const isNotAdminProductsTableColumns: ColumnConfigWithRender<BrandData, BrandHandlersList>[] = [
        product_id,
        {
            ...product_name,
            filterable: true,
            render: (row, cell, refresh, handlers) => {
                if (row.name.length > 40) {
                    return (
                        <DarkTooltip title={row.name} placement="top">
                            <p>{row.name.slice(0, 40) + '...'}</p>
                        </DarkTooltip>
                    );
                }
                return (
                    <span>{row.name}</span>
                );
            },
        },
        domain,
        {
            ...mirrors,
            render: (row, cell, refresh, handlers) => {
                if (row.mirrors?.length) {
                    return (
                        <div className="mirror-chip mirror-chip-on">ON</div>
                    )
                }
                return (
                    <div className="mirror-chip mirror-chip-off">OFF</div>
                )
            },
        },
        {
            ...product_list_actions,
            render: (row, cell, refresh, handlers) => {
                return (
                    <React.Fragment key={`products_list_actions-${row.id}`}>
                        <CustomIconButton title={t('products.table.actions.view')} component={VisibilityIcon}/>
                        <CustomIconButton title="" component={AssessmentIcon}/>
                        <CustomIconButton title="" component={IconLandings}/>
                        <CustomIconButton title="" component={IconSmartlinks}/>
                    </React.Fragment>
                )
            }
        }
    ];

    const handleCloseDialog = async () => {
        setOpenDialog(false);
    };

    const handleAccept = async () => {
        await dispatch(
            setProductActivity(
                {
                    brandId: dataDispatch.brandId,
                    isActive: dataDispatch.checked,
                    name: dataDispatch.name,
                    url: dataDispatch.url,
                    help_url: dataDispatch.help_url
                }
            ));
        dataDispatch.refresh();
        setOpenDialog(false);
    };

    return (
        <div className={isAdmin ? "products-page products-page-admin" : "products-page products-page-user"} style={{marginLeft: '36px'}}>
            <TablePage
                title={title}
                description={description}
            />
            <Table
                tableName='products'
                filtersConfig={filtersConfig}
                columnsConfig={isAdmin ? isAdminProductsTableColumns : isNotAdminProductsTableColumns}
                requestHandler={getProducts}
                additionalSearches={additionalSearches}
                data={data}
                uniqueField='id'
                defaultSearchField='name'
                fieldHandlers={{
                    toggle: (brand, cell, refresh) => async (checked) => {
                        setDataDispatch(
                            {
                                brandId: brand.id,
                                url: brand.url,
                                name: brand.name,
                                help_url: brand.help_url,
                                refresh: refresh,
                                checked: checked,
                            }
                        )
                        const dialogText = brand.enabled === true
                            ? `${textDeactivateProduct}${brand.id}`
                            : `${textActivateProduct}${brand.id}`;

                        setDialogProductText(dialogText);
                        setOpenDialog(true);
                    }
                }}
            />
            <Dialog
                className="deactivate_smartlink"
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    className="close-cross"
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle id="alert-dialog-title">
                    {!dataDispatch.checked ? titleDeactivateProduct : titleActivateProduct}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{dialogProductText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={"yellow"}
                        onClick={handleAccept}
                        autoFocus
                    >
                        {!dataDispatch.checked ? t("common.forms.deactivate"):t("common.forms.activate")}
                    </Button>
                    <Button onClick={handleCloseDialog}>{t("products.cancel")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Products;