import i18next, { i18n as i18nInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "./i18n.constants";
import { en, ru } from "./i18n.translations";

const createI18n = (language: string): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);
  i18n.init({
    lng: language,
    fallbackLng: language,
    defaultNS: 'common',
    resources: {
      [languages.ru]: ru,
      [languages.en]: en,
    },
  });

  return i18n;
};
const localLang = localStorage.getItem('lang');
const curLng = localLang !== null && localLang === 'ru' ? languages.ru : languages.en;

export const i18n = createI18n(curLng);
