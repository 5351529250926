import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import {SetState} from "../types";

export function usePage(): [number, SetState<number>] {
    const [searchParams] = useSearchParams();

    const defaultPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1

    const [page, setPage] = useState<number>(defaultPage || 1);

    return [page, setPage];
}