import React, {Dispatch} from 'react';
import {AppFilters, ColumnConfigWithRender, SearchConfig, UrlParams} from '../types';
import {ControlBarConfig} from './types';
import {SmartlinksControlBar} from "./SmartlinksControlBar/SmartlinksControlBar";
import {LandingsControlBar} from "./LandingsControlBar/LandingsControlBar";
import "./ControlBarWrapper.scss";
import {ProductsControlBar} from "./ProductsControlBar/ProductsControlBar";
import {ShortenerControlBar} from "./ShortenerControlBar/ShortenerControlBar";
import {ControlBarStatistics} from "./ControlBarStatistics/ControlBar";
import {ItemProps} from "../../MultiSelect/MultiSelect";
import {FiltersFromObj} from "../AppliedFilterBar/types";

interface FiltersProps<Data, Handlers> {
    controlBarConfig: ControlBarConfig[];
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    chooseAllFavourites: () => void;
    state: 'controls' | 'customize' | 'bulk';
    customizeTable: () => void;
    resetControlBar: () => void;
    buckMode: () => void;
    buckList: number[];
    bufferColList: { [key: string]: number };
    tableName: string;
    columnsConfig: ColumnConfigWithRender<Data, Handlers>[];
    additionalSearches: SearchConfig<Data>[];
    onCreateClick?: (event?: React.MouseEvent<HTMLElement>) => void;
    defaultSearchField?: string;
    tableColumns?: ItemProps[];
    setStatisticTableColumns?: Dispatch<ItemProps[]>;
    setIsHideTableBody?: Dispatch<boolean>;
    setObjFilters: Dispatch<FiltersFromObj>;
    objFilters: FiltersFromObj;
    setClearObjFilters: Dispatch<boolean>;
    clearObjFilters: boolean;
    filtersListToRemove: FiltersFromObj;
    setReportAdditionalFilters: Dispatch<{[key: string]: any}>;
    showAlert?: (message: string) => void;
    page: number;
    setPage: Dispatch<number>;
}

export function ControlBarWrapper<Data, Handlers>(props: FiltersProps<Data, Handlers>) {
    const {
        state,
        tableName,
        urlFilters,
        addFilters,
        chooseAllFavourites,
        resetControlBar,
        customizeTable,
        buckMode,
        buckList,
        bufferColList,
        controlBarConfig,
        additionalSearches,
        columnsConfig,
        defaultSearchField,
        tableColumns,
        setStatisticTableColumns,
        setIsHideTableBody,
        setObjFilters,
        objFilters,
        setClearObjFilters,
        clearObjFilters,
        filtersListToRemove,
        setReportAdditionalFilters,
        onCreateClick,
        showAlert,
        page,
        setPage
    } = props;

    switch (tableName) {
        case 'landing_create':
            return (
                <LandingsControlBar
                    urlFilters={urlFilters}
                    addFilters={addFilters}
                    chooseAllFavourites={chooseAllFavourites}
                    state={state}
                    resetControlBar={resetControlBar}
                    customizeTable={customizeTable}
                    buckMode={buckMode}
                    buckList={buckList}
                    bufferColList={bufferColList}
                    controlBarConfig={controlBarConfig}
                    tableName={tableName}
                    showAlert={showAlert}
                />
            )
        case 'trackers':
            return (
                <SmartlinksControlBar
                    urlFilters={urlFilters}
                    addFilters={addFilters}
                    chooseAllFavourites={chooseAllFavourites}
                    additionalSearches={additionalSearches}
                    columnsConfig={columnsConfig}
                    defaultSearchField={defaultSearchField}
                />
            )
        case 'products':
            return (
                <ProductsControlBar
                    urlFilters={urlFilters}
                    addFilters={addFilters}
                    chooseAllFavourites={chooseAllFavourites}
                    additionalSearches={additionalSearches}
                    columnsConfig={columnsConfig}
                    tableName={tableName}
                    defaultSearchField={defaultSearchField}
                />
            )
        case 'shortener':
            return (
                <ShortenerControlBar
                    urlFilters={urlFilters}
                    addFilters={addFilters}
                    chooseAllFavourites={chooseAllFavourites}
                    additionalSearches={additionalSearches}
                    columnsConfig={columnsConfig}
                    tableName={tableName}
                    defaultSearchField={defaultSearchField}
                />
            )
        case 'statistics':
            return (
                <ControlBarStatistics
                    urlFilters={urlFilters}
                    addFilters={addFilters}
                    controlBarConfig={controlBarConfig}
                    tableColumns={tableColumns}
                    setStatisticTableColumns={setStatisticTableColumns}
                    setIsHideTableBody={setIsHideTableBody}
                    objFilters={objFilters}
                    setObjFilters={setObjFilters}
                    clearObjFilters={clearObjFilters}
                    setClearObjFilters={setClearObjFilters}
                    filtersListToRemove={filtersListToRemove}
                    setReportAdditionalFilters={setReportAdditionalFilters}
                    page={page}
                    setPage={setPage}
                    resetControlBar={resetControlBar}
                    tableName={tableName}
                    bufferColList={bufferColList}
                    customizeTable={customizeTable}
                    state={state}
                />
            )
        default:
            return null;
    }
}
