import React, {Dispatch, useEffect, useState} from 'react';
import './ModalRuleConfiguration.scss';
import {checkRangesOverlap, overlapDataValid, prepareCappingDataForSave, validateRangesChain} from "./ruleHelpers";
import {useTranslation} from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton} from "@mui/material";
import {useAppDispatch} from "../../../app/hooks";
import {
    capRangeVal,
    collectedDataTpl,
    dateRangesType,
    GeneralTrackerRequestBody,
    isSubmittingType,
    toggleVariants
} from "./type";
import {createGeneralTracker, createScheduleRow, editGeneralTracker, editScheduleRow} from "../../../app/reducers/prism/generalTrackersSlice";
import {visitsCreateUpdate} from "../../../app/reducers/prism/visitsSlice";
import FormFooter from "./FormRuleConfiguration/FormFooter/FormFooter";
import MainBlockMultiplier from "./MainBlockMultiplier/MainBlockMultiplier";
import CustomAlert from "../../Alert/CustomAlert";
import {getDataByKey, getNewValueArray, sortBySameLevelKey} from "../../Helpers/arrayHelper";
import ScheduleList from "./Schedule/ScheduleList";
import {Node} from "../types";
import {TrackerData} from "../../../app/reducers/prism/types";
import moment from "moment";

interface ModalRuleConfigurationProps {
    trackerId: number;
    parentId: number | null;
    activeRule: string | null;
    nodeTypes: string | string[];
    handleCloseRuleForm: () => void;
    handleEditSchedule: (row: TrackerData, type: string) => void;
    showHideSuccessfullyAlert: () => void;
    isSchedule: boolean;
    setUpdateTree: Dispatch<React.SetStateAction<number>>;
    updateTree: number;
    ruleEdit: Node | null;
    setRuleEdit: Dispatch<React.SetStateAction<Node | null>>;
    scheduleEdit: TrackerData | null;
    setScheduleEdit: Dispatch<React.SetStateAction<TrackerData | null>>;
    blockCounter: number[];
    setBlockCounter: Dispatch<React.SetStateAction<number[]>>;
    setClearData: Dispatch<React.SetStateAction<boolean>>;
    clearData: boolean;
}

export const regexUrl = new RegExp('(^https://)');

function ModalRuleConfiguration(props: ModalRuleConfigurationProps) {
    const {
        trackerId,
        activeRule,
        nodeTypes,
        parentId,
        handleCloseRuleForm,
        showHideSuccessfullyAlert,
        isSchedule,
        setUpdateTree,
        updateTree,
        ruleEdit,
        setRuleEdit,
        handleEditSchedule,
        scheduleEdit,
        setScheduleEdit,
        blockCounter,
        setBlockCounter,
        clearData,
        setClearData
    } = props;
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [openToggle, setOpenToggle] = useState<boolean>(false);
    const [data, setData] = useState<collectedDataTpl[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<isSubmittingType>([true]);
    const [from, setFrom] = useState<capRangeVal[]>([]);
    const [to, setTo] = useState<capRangeVal[]>([]);
    const [saveAlert, setSaveAlert] = useState<string>("");
    const defaultDateTo = moment().add(1,"day").format("YYYY-MM-DD");
    const [dateRanges, setDateRanges] = useState<dateRangesType>({dateFrom: moment().format("YYYY-MM-DD"), dateTo: defaultDateTo, timeFrom: "", timeTo: ""});
    let toggleType: toggleVariants = activeRule === 'unique' && nodeTypes === 'not_unique' ? 'capping' : 'hidden';
    if (isSchedule) {
        toggleType = 'schedule';
    }
    useEffect(() => {
        if(ruleEdit !== null && ruleEdit.visits !== null && nodeTypes === 'not_unique') {
            if (ruleEdit.visits.length > 0) {
                setOpenToggle(true);
                setBlockCounter(Array.from({ length: ruleEdit.visits.length }, (_, i) => i));
            }
        }
    }, [ruleEdit]);

    useEffect(() => {
        if(nodeTypes === 'unique' && openToggle) {
            setBlockCounter([0]);
            const newFrom = [...from];
            const newTo = [...to];
            const newData = [...data];
            setFrom([newFrom[0]]);
            setTo([newTo[0]]);
            setData([newData[0]]);
            setOpenToggle(false);
        }
    }, [nodeTypes]);

    const handleOpenCappingOrSchedule = () => {
        !openToggle ? setBlockCounter([0, 1]) : setBlockCounter([0]);
        if (openToggle) {
            const newFrom = [...from];
            const newTo = [...to];
            const newData = [...data];
            setFrom([newFrom[0]]);
            setTo([newTo[0]]);
            setData([newData[0]]);
        }
        setOpenToggle(prevState => (!prevState));
    }

    const handleAddCap = () => {
        const blocksValues = [...blockCounter];
        blocksValues.push(blockCounter.length)
        setBlockCounter(blocksValues);
        if(ruleEdit !== null){
            const newFrom = [...from];
            const newTo = [...to];
            newFrom[blockCounter.length] = {key: blockCounter.length, value: ""};
            newTo[blockCounter.length] = {key: blockCounter.length, value: ""};
            setFrom(newFrom);
            setTo(newTo);
        }
    }
    
    const handleClose = () => {
        handleCloseRuleForm()
    }

    const handleSubmit = async () => {
        if (!openToggle && toggleType !== 'schedule') {
            const dataSlot = getDataByKey(data, 0);
            if (dataSlot !== undefined) {
                let response;
                if(ruleEdit !== null) {
                    response = await dispatch(editGeneralTracker({trackerId: trackerId, ruleId: ruleEdit.id, body: dataSlot.value}));
                } else {
                    response = await dispatch(createGeneralTracker({trackerId: trackerId, body: dataSlot.value}));
                }
                if (response.payload.responseStatus === 400) {
                } else {
                    setClearData(!clearData);
                    handleCloseRuleForm();
                    showHideSuccessfullyAlert();
                    setRuleEdit(null);
                }
            }
        }
        if (openToggle && toggleType === 'capping') {
            let x_visits = getNewValueArray(sortBySameLevelKey(data));
            const body = prepareCappingDataForSave(x_visits,parentId);
            if (body !== undefined) {
                let response;
                if(ruleEdit !== null) {
                    response = await dispatch(editGeneralTracker({trackerId: trackerId, ruleId: ruleEdit.id, body: body}));
                } else {
                    response = await dispatch(createGeneralTracker({trackerId: trackerId, body: body}));
                }
                if (response) {
                    const responsVisit = await dispatch(visitsCreateUpdate({
                        RuleId: ruleEdit !== null ? ruleEdit.id : response.payload.id,
                        body: {x_visits: x_visits}
                    }));
                    if (response.payload.responseStatus === 400) {
                    } else {
                        setClearData(!clearData);
                        handleCloseRuleForm();
                        showHideSuccessfullyAlert();
                    }
                }
            }
        }
        if (toggleType === 'schedule') {
            const dataSlot = getDataByKey(data, 0);
            if (dataSlot !== undefined && parentId !== null) {
                let response;
                if(scheduleEdit !== null) {
                    response = await dispatch(editScheduleRow({ruleId: parentId, scheduleId: scheduleEdit.id, body: dataSlot.value}));
                } else {
                    response = await dispatch(createScheduleRow({ruleId: parentId, body: dataSlot.value}));
                }
                if (response) {
                    if (response.payload.responseStatus === 400) {
                        handleSaveAlert(response.payload.messages.start[0])
                    } else {
                        setClearData(!clearData);
                        showHideSuccessfullyAlert();
                        setUpdateTree(prevValue => prevValue + 1);
                        setScheduleEdit(null);
                        setDateRanges({dateFrom: moment().format("YYYY-MM-DD"), dateTo: defaultDateTo, timeFrom: "", timeTo: ""});
                    }
                }
            }
        }
    }
    useEffect(() => {
        if (ruleEdit !== null) {
            if(ruleEdit.visits) {
                const newFrom = [...from];
                const newTo = [...to];
                ruleEdit.visits.map((item: Node, i: number) => {
                    newFrom[i] = {key: i, value: item.from ?? "" };
                    newTo[i] = {key: i, value: item.to ?? ""};
                });
                setFrom(newFrom);
                setTo(newTo);
            }
        }
    }, []);

    const disableSubmit: () => boolean = () => {
        if (isSubmitting) {
            for (let prop in isSubmitting) {
                if (isSubmitting[prop]) {
                    return true;
                }
            }
            if (openToggle && toggleType === 'capping') {
                if (!overlapDataValid(from, to)) {
                    return true;
                } else {
                    if (validateRangesChain(from, to)) {
                        return true
                    }
                    return !checkRangesOverlap(from, to);
                }
            }
        }
        return false;
    }

    const handleDeleteBlock = (blockId: number) => {
        const newBlockCounter = [...blockCounter];
        const newFrom = [...from];
        const newTo = [...to];
        const newData = [...data];
        const {[blockId]: deleted, ...rest} = isSubmitting;
        newBlockCounter.splice(blockId, 1);
        newFrom.splice(blockId, 1);
        newTo.splice(blockId, 1);
        newData.splice(blockId, 1);
        setBlockCounter(newBlockCounter);
        setFrom(newFrom);
        setTo(newTo);
        setData(newData);
        setIsSubmitting(rest);
    }

    const handleSaveAlert = (error: string) => {
        setSaveAlert(error);
        setTimeout(function () {
            setSaveAlert("");
        }, 3000)
    }



    return (
        <div className="modal-rule-configuration-wrap">
            <Box className="modal-rule-configuration-header">
                <p className="modal-rule-configuration-header-title">{t('smartlinks.modal_rule_configuration.title')}</p>
                <p className="modal-rule-configuration-header-subtitle">{t('smartlinks.modal_rule_configuration.subtitle')}</p>
                <IconButton
                    edge="start"
                    onClick={handleClose}
                    aria-label="close"
                    className="modal-rule-configuration-header-close"
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
            {blockCounter.map((item: number, i: number) =>
                <MainBlockMultiplier
                    key={item}
                    blockId={item}
                    trackerId={trackerId}
                    parentId={parentId}
                    activeRule={activeRule}
                    nodeTypes={nodeTypes}
                    handleCloseRuleForm={handleCloseRuleForm}
                    showHideSuccessfullyAlert={showHideSuccessfullyAlert}
                    openToggle={openToggle}
                    dataSetter={setData}
                    oldData={data}
                    capNumber={Math.max(...blockCounter)}
                    handlerToggle={handleOpenCappingOrSchedule}
                    handleAddCap={handleAddCap}
                    setIsSubmitting={setIsSubmitting}
                    isSubmitting={isSubmitting}
                    toggleType={toggleType}
                    from={from}
                    to={to}
                    setFrom={setFrom}
                    dateRanges={dateRanges}
                    setDateRanges={setDateRanges}
                    setTo={setTo}
                    handleDeleteBlock={handleDeleteBlock}
                    ruleEdit={ruleEdit}
                    scheduleEdit={scheduleEdit}
                    clearData={clearData}
                    blockCounter={blockCounter}
                />
            )}
            <FormFooter
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                disabled={disableSubmit()}
            />
            {toggleType === 'schedule' && parentId !== null &&
            <ScheduleList
                ruleId={parentId}
                setUpdateTree={setUpdateTree}
                updateTree={updateTree}
                handleEditSchedule={handleEditSchedule}
            />
            }
            <CustomAlert
                type="error"
                title={t("smartlinks.modal_rule_configuration.caps_overlap_error")}
                status={overlapDataValid(from, to) && !checkRangesOverlap(from, to)}
            />
            <CustomAlert
                type="error"
                title={saveAlert}
                status={saveAlert !== ""}
            />
        </div>
    );
}

export default ModalRuleConfiguration;