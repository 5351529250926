export const generateUniqueString = (length: number) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}

export const cutString = (str: string, numChars: number): string => {
    if (str.length <= numChars) {
        return str;
    } else {
        return str.substring(0, numChars - 3) + "...";
    }
};

export function countForwardSymbols(str: string, symbol:string): number {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (str[i] === symbol) {
            count++;
        }
    }
    return count;
}