import {baseColumn} from "../Table/columns";
import {ColumnConfig} from "../Table/types";

export const status: ColumnConfig = {
    ...baseColumn,
    field: 'enabled',
    headerName: 'common.tracker_filters.status',
    sortable: false,
    tooltipTitle: 'Current status of Products (Active/Inactive)',
    questionMark: true
};

export const product_id: ColumnConfig = {
    ...baseColumn,
    field: 'id',
    headerName: 'products.table.columns.id',
    sortable: true
};

export const product_name: ColumnConfig = {
    ...baseColumn,
    field: 'name',
    headerName: 'products.table.columns.name',
    sortable: true
};

export const domain: ColumnConfig = {
    ...baseColumn,
    field: 'url',
    headerName: 'products.table.columns.url'
};

export const company: ColumnConfig = {
    ...baseColumn,
    field: 'company',
    headerName: 'products.table.columns.company'
};

export const mirrors: ColumnConfig = {
    ...baseColumn,
    field: 'mirrors',
    headerName: 'products.table.columns.mirrors'
};

export const product_list_actions: ColumnConfig = {
    ...baseColumn,
    field: 'actions',
    headerName: 'common.tracker_filters.actions',
};
