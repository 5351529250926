export const RULE_LANDING = 'landing';
export const RULE_SPLITTING = 'splitting';
export const RULE_DIRECT_URL = 'url';
export const RULE_CAPPING = 'capping';
export const RULE_SCHEDULE = 'schedule';

export interface Map {
    [key: string]: number;
}

export interface TransformedNode {
    children: TransformedNode[],
    source: string | null | number,
    target: number | string,
    id: number | string,
    details: {
        id: number | null
        type: string
    },
    redirect_type?: string
}

export interface RelationsData {
    id: string;
    label?: string | null;
    source: string;
    target: string;
}

export interface Node {
    id: number;
    parent_id: null | number;
    label: string;
    type: string;
    value: string;
    landing_url?: string;
    original_value: string;
    landing_id: number | null;
    package_id: number | null;
    url: string | null;
    redirect_value: string | null;
    redirect_type: string;
    brand_name: string;
    brand_id: number | null;
    is_default: boolean;
    path: { id: number, type: string }[];
    from?: string;
    to?: string;
    visits: Node[] | null;
    splitting: {
        id: number;
        brand_id: number;
        owner_id: null;
        modified_by: null;
        team_id: null;
        path: string;
        mobile_path: null;
        title: string;
        preview: null;
        label: null;
        url: string;
        probability: number;
    }[] | null;
    schedule_count: number;
}

export interface Language {
    code: string;
    name: string;
}

export interface Device {
    code: string;
    name: string;
}

export interface Country {
    code: string;
    name: string;
}

export interface City {
    id: number;
    geonameId: number;
    name: string;
    regionGeonameId: number;
    regionName: string;
    country: string;
}

export interface Region {
    id: number;
    geonameId: number;
    name: string;
    code: string;
    country_code: string;
}

export const typesRules = {
    geo: "geo",
    region: "region",
    city: "city",
    device: "device",
    language: "language",
    unique: "unique",
    isEp: "isEp",
    single: "single"
};

export interface State {
    id: string;
    state: boolean;
}

export interface NodeRuleName {
    label: string;
    value: string;
}

export const AllNodeRulesNames: NodeRuleName[] = [
    { label: 'smartlinks.modal_rule_configuration.rules_names.geo.label', value: typesRules.geo },
    { label: 'smartlinks.modal_rule_configuration.rules_names.region.label', value: typesRules.region },
    { label: 'smartlinks.modal_rule_configuration.rules_names.city.label', value: typesRules.city },
    { label: 'smartlinks.modal_rule_configuration.rules_names.device.label', value: typesRules.device },
    { label: 'smartlinks.modal_rule_configuration.rules_names.language.label', value: typesRules.language },
    { label: 'smartlinks.modal_rule_configuration.rules_names.unique.label', value: typesRules.unique },
    { label: 'smartlinks.modal_rule_configuration.rules_names.isEp.label', value: typesRules.isEp },
    { label: 'smartlinks.modal_rule_configuration.rules_names.single.label', value: typesRules.single },
];