import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#292621',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#292621',
        color: 'fff',
        fontSize: 10,
    },
}));

export default DarkTooltip;
