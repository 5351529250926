import React, { useState } from 'react';
import Table from "../Table/Table";
import {owner, status, team, tracker_type, tracker_id, tracker_list_actions, tracker_name, smartlink, tracker_is_favorite} from "./columns";
import {ColumnConfigWithRender, FieldHandler, SearchConfig} from "../Table/types";
import {FilterConfig, FilterConfigType} from "../Table/AppliedFilterBar/types";
import {
    getTrackers,
    selectTrackers, selectTrackersData,
    setTrackerActivity,
} from "../../app/reducers/prism/trackersSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {TrackerData} from "../../app/reducers/prism/types";
import {Button} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import Toggle from "../Toggle/Toggle";
import {getBooleanTableValue} from "../Table/helpers/helpers";
import {copyToClipboard} from "../Helpers/clipboard";
import {
    getSearchTeams,
    getSearchTeamsFromPrism,
    selectSearchTeams, selectSearchTeamsFromPrism,
    selectSearchTeamsStatus
} from "../../app/reducers/prism/teamsSlice";
import {getSearchUsers, selectSearchUsers, selectUsersStatus} from "../../app/reducers/prism/usersSlice";
import {CreateTracker} from "./CreateTracker/CreateTracker";
import {DuplicateTracker} from "./DuplicateTracker/DuplicateTracker";
import CustomAlert from "../Alert/CustomAlert";
import {ReactComponent as StarBorder} from "../../app/assets/img/icons/star-border.svg";
import Tooltip from "../Tooltip/Tooltip";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TablePage from "../Page/TablePage";
import CustomIconButton from "../CustomIconButton";
import { useTranslation } from "react-i18next";
import DarkTooltip from "../DarkTooltip/DarkTooltip";
import "./Trackers.scss";
import {selectSelfUser} from "../../app/reducers/prism/sessionSlice";

type TrackerHandlersList = {
    toggle: FieldHandler<TrackerData, (checked: boolean) => void>,
    copyTrackerLink: FieldHandler<TrackerData>,
};


const filtersConfig: FilterConfig[] = [
    {
        type: FilterConfigType.select,
        placeholder: 'common.tracker_filters.status',
        field: 'enabled',
        defaultValue: 'all',
        valueMutator: (value) => (value === 'all' ? null : value),
        options: [
            {label: 'common.filters.active.all', value: 'all'},
            {label: 'common.filters.active.active', value: true},
            {label: 'common.filters.active.not_active', value: false},
        ]
    },
    {
        type: FilterConfigType.autocomplete,
        field: 'teams',
        placeholder: 'common.tracker_filters.team',
        optionLabel: 'name',
        asyncAction: getSearchTeams,
        dataSelector: selectSearchTeams,
        statusSelector: selectSearchTeamsStatus,
        multiselect: true,
    },
    {
        type: FilterConfigType.autocomplete,
        field: 'owners',
        placeholder: 'common.tracker_filters.owner',
        optionLabel: 'email',
        asyncAction: getSearchUsers,
        dataSelector: selectSearchUsers,
        statusSelector: selectUsersStatus,
        multiselect: true,
    }
];



const additionalSearches: SearchConfig<TrackerData>[] = [
    {headerName: 'common.tracker_filters.general_tracker_url', field: 'general_tracker_url'},
    {headerName: 'admin.tracker_logs.tracker_schedule.table.columns.landing_name', field: 'landings'},
    {headerName: 'common.tracker_filters.products', field: 'brands'},
    // {headerName: 'GEO', field: 'geo'},
    {headerName: 'common.tracker_filters.tracker_url', field: 'url'},
];


function Trackers() {
    const { t } = useTranslation();
    const data = useAppSelector(selectTrackers);
    const dispatch = useAppDispatch();
    const selfUser = useAppSelector(selectSelfUser);

    const [openDialog, setOpenDialog] = useState(false);
    const [openCopyAlert, setOpenCopyAlert] = useState(false);
    const [dialogTrackerText, setDialogTrackerText] = useState('');
    const [dataDispatch, setData] = useState({
        trackerId: 0,
        refresh: () => {
        },
        checked: false
    });
    const [duplicateId, setDuplicateId] = useState<number | null>(null);

    const handleCloseDialog = async () => {
        setOpenDialog(false);
    };

    const handleCloseAlert = async () => {
        setOpenCopyAlert(false);
    };

    const handleAccept = async () => {
        await dispatch(setTrackerActivity({trackerId: dataDispatch.trackerId, isActive: dataDispatch.checked}))
        dataDispatch.refresh()
        setOpenDialog(false);
    };

    const handleOpenDuplicate = (id: number) => {
        setDuplicateId(id);
    };

    const handleCloseDuplicate = () => {
        setDuplicateId(null);
    };

    const setFavourite = (row: TrackerData) => {
        console.log(row);
    };

    const title = t("menu.trackers");
    const description = t("admin.traffic_control.trackers.description");
    const transVarActive = t("statistic.trackers.confirm_text_active");
    const transVarHeadActive = t("common.forms.deactivate_smartlink");
    const transVarInActive = t("statistic.trackers.confirm_text_inactive");
    const transVarHeadInActive = t("common.forms.activate_smartlink");

    const trackersTableColumns: ColumnConfigWithRender<TrackerData, TrackerHandlersList>[] = [
        // {
        //     ...tracker_is_favorite,
        //     sortable: false,
        //     render: (row, cell, refresh, handlers) => {
        //         return (
        //             <React.Fragment key={`landing_list_favourite-${row.id}`}>
        //                 <CustomIconButton
        //                     title={t('brands.entity.edit.landings.page.favourite')}
        //                     component={StarBorder}
        //                     onClick={() => {
        //                         setFavourite(row);
        //                     }}
        //                 />
        //             </React.Fragment>
        //         )
        //     }
        // },
        {
            ...status,
            filterable: true,
            getOptionsAction: getTrackers,
            dataSelector: selectTrackersData,
            idField: 'enable',
            labelField: 'enabled',
            render: (row, cell, refresh, handlers) => (
                <Toggle
                    manualOnChange={true}
                    key={`toggle-${row.id}`}
                    isChecked={getBooleanTableValue(row, cell.field)}
                    handler={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handlers?.toggle(row, cell, refresh)(event.target.checked)
                    }
                />
            )
        },
        // status,
        {...tracker_id, search: true},
        {
            ...tracker_name,
            search: true,
            render: (row, cell, refresh, handlers)=>{
                if(row.name.length > 40){
                    return (
                        <DarkTooltip title={row.name} placement="top">
                            <p>{row.name.slice(0, 40) + '...'}</p>
                        </DarkTooltip>
                    );
                }
                return (
                    <span>{row.name}</span>
                );
            },
        },
        {
            ...team,
            filterable: true,
            getOptionsAction: selfUser?.role.alias === 'admin' ? getSearchTeams : getSearchTeamsFromPrism,
            dataSelector: selfUser?.role.alias === 'admin' ? selectSearchTeams : selectSearchTeamsFromPrism,
            filterType: 'autocomplete',
            searchField: 'teams',
            labelField: 'name',
        },
        // tracker_type,
        {
            ...owner,
            filterable: true,
            getOptionsAction: getSearchUsers,
            dataSelector: selectSearchUsers,
            filterType: 'autocomplete',
            searchField: 'owners',
            labelField: 'email',
        },
        {
            ...tracker_list_actions,
            render: (row, cell, refresh, handlers) => {
                return (
                    <React.Fragment key={`tracker_list_actions-${row.id}`}>
                        <CustomIconButton
                            title={t('smartlinks.actions.copy_url')}
                            component={ContentCopyIcon}
                            onClick={() => handlers?.copyTrackerLink(row, cell, () => {
                            })}
                        />
                        <CustomIconButton
                          title={t('smartlinks.actions.duplicate')}
                          component={AddToPhotosIcon}
                          onClick={() => handleOpenDuplicate(row.id)}
                        />
                        {/*<CustomIconButton title={t('smartlinks.actions.statistic')} component={AssessmentIcon}/>*/}
                        <CustomIconButton
                            title={t('smartlinks.actions.edit')}
                            component={CreateIcon}
                            to={`/trackers/${row.id}`}
                        />
                    </React.Fragment>
                )
            }
        },
        {
            ...smartlink,
            render: (row, cell, refresh, handlers)=>{
                return <Tooltip url={row.url} styles={{color: '#009E69'}}/>;
            },
        }
    ];

    return (
        <div className="page-left-offset">
            {duplicateId && <DuplicateTracker handleClose={handleCloseDuplicate} oldId={duplicateId} />}
            <TablePage
                title={title}
                description={description}
            />
            <Table
                tableName='trackers'
                filtersConfig={filtersConfig}
                columnsConfig={trackersTableColumns}
                requestHandler={getTrackers}
                additionalSearches={additionalSearches}
                data={data}
                uniqueField='id'
                defaultSearchField="name"
                fieldHandlers={{
                    toggle: (tracker, cell, refresh) => async (checked) => {
                        setData(
                            {
                                trackerId: tracker.id,
                                refresh: refresh,
                                checked: checked,
                            }
                        )
                        const dialogText = tracker.enabled === true
                            ? `${transVarActive}${tracker.id}`
                            : `${transVarInActive}${tracker.id}`;

                        setDialogTrackerText(dialogText);
                        setOpenDialog(true)
                    },
                    copyTrackerLink: (tracker) => {
                        copyToClipboard(tracker?.shortlink?.shortlink_short ?? tracker.url)
                        setOpenCopyAlert(true);
                        setTimeout(function () {
                            setOpenCopyAlert(false);
                        }, 5000)
                    },
                }}
            />
            <Dialog
                className="deactivate_smartlink"
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    className="close-cross"
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle id="alert-dialog-title">

                    {!dataDispatch.checked ? transVarHeadActive : transVarHeadInActive}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogTrackerText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={"BtnPurple"} onClick={handleAccept} autoFocus>{!dataDispatch.checked ? t("common.forms.deactivate"):t("common.forms.activate") }</Button>
                    <Button className={"BtnGrey"} onClick={handleCloseDialog}>{t("statistic.trackers.cancel")}</Button>
                </DialogActions>
            </Dialog>
            <CustomAlert
                type="info"
                title={t('smartlinks.actions.link_copied')}
                onClose={handleCloseAlert}
                status={openCopyAlert}
            />
        </div>
    );
}

export default Trackers;