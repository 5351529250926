import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {fetchShortenerDomains} from "../../requests/shortenerDomains";
import {ShortenerDomain} from "./types";

export interface ShortenerDomainsState {
    shortenerDomains: PaginatedData<ShortenerDomain> | null;
    status: RequestStatuses;
}

const initialState: ShortenerDomainsState = {
    shortenerDomains: null,
    status: RequestStatuses.idle,
};

export const getShortenerDomains = createAsyncThunk(
    'companies/fetchShortenerDomains',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchShortenerDomains(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const shortenerDomainsSlice = createSlice({
    name: 'shortenerDomains',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getShortenerDomains.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getShortenerDomains.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.shortenerDomains = action.payload;
            })
    },
});

export const selectShortenerDomainsStatus = (state: RootState) => state.shortenerDomains.status;
export const selectShortenerDomains = (state: RootState) => state.shortenerDomains.shortenerDomains;

export default shortenerDomainsSlice.reducer;
