import {baseColumn} from "../Table/columns";
import {ColumnConfig} from "../Table/types";

export const shortener_name: ColumnConfig = {
    ...baseColumn,
    field: 'title',
    headerName: 'shortener.table.columns.title',
    sortable: true
};

export const short_link: ColumnConfig = {
    ...baseColumn,
    field: 'short_url',
    headerName: 'shortener.table.columns.short_url',
};

export const full_url: ColumnConfig = {
    ...baseColumn,
    field: 'target_url',
    headerName: 'shortener.table.columns.target_url',
};

export const owner: ColumnConfig = {
    ...baseColumn,
    field: 'createdBy.email',
    headerName: 'shortener.table.columns.owner',
    sortable: true,
    searchField: 'createdBy.email',
};

export const team: ColumnConfig = {
    ...baseColumn,
    field: 'createdBy.teams[0].name',
    headerName: 'shortener.table.columns.team',
    sortable: true
};

export const company: ColumnConfig = {
    ...baseColumn,
    field: 'company_name',
    headerName: 'products.table.columns.company',
    sortable: true
};

export const parameters: ColumnConfig = {
    ...baseColumn,
    field: 'name',
    headerName: 'shortener.table.columns.parameters'
};

export const shorteners_list_actions: ColumnConfig = {
    ...baseColumn,
    field: 'actions',
    headerName: 'common.tracker_filters.actions',
};

export const empty_col: ColumnConfig = {
    ...baseColumn,
    field: '',
    headerName: ''
};
