import React, {SyntheticEvent, useEffect, useState} from 'react';
import './SelectWithInputFilter.css';
import {AppFilterValue, DataRow, UrlParams} from "../../types";
import {SelectOption, SelectValue} from "../../filters/types";
import {BaseSelect} from "../../../inputs/BaseSelect";
import { useTranslation } from "react-i18next";
import {AutocompleteOption} from "../../../inputs/baseAutocomplete/types";
import BaseSelectWithInput from "../../../inputs/BaseSelectWithInput";
import {AsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import {useAppSelector} from "../../../../app/hooks";

interface FilterSelectProps {
    fieldName: string
    defaultOptionValue?: SelectValue;
    onChangeHandler: (event: SyntheticEvent, value: AutocompleteOption | AutocompleteOption[] | null) => void;
    placeholder: string;
    label?: string;
    value?: string;
    options?: AutocompleteOption[],
    handleInputCreate?: (value: string) => void;
    multiselect?: boolean;
    clearIcon?: boolean;
    sx?: object;
}

export function SelectWithInputFilter(props: FilterSelectProps) {
    const { t } = useTranslation();
    const {
        defaultOptionValue = '',
        handleInputCreate,
        onChangeHandler,
        options = [],
        placeholder,
        label = 'label',
        value = 'value',
        fieldName,
    } = props;

    const [inputValue, setInputValue] = useState(defaultOptionValue || '');

    return (
        <BaseSelectWithInput
            className={"autocompleteFilter"}
            key={fieldName}
            fieldName={fieldName}
            onChange={onChangeHandler}
            placeholder={placeholder}
            optionsList={options}
            handleInputCreate={handleInputCreate}
            componentsProps={{
                paper: {
                    sx: {
                        width: '167%'
                    }
                }
            }}
        />
    )
}
