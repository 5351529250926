import React, {SyntheticEvent} from 'react';
import {TextField, Autocomplete, Chip} from "@mui/material";
import {AutocompleteOption} from "./baseAutocomplete/types";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface BaseSelectWithInputProps {
    optionsList: AutocompleteOption[];
    placeholder: string;
    onChange: (event: SyntheticEvent, value: AutocompleteOption | AutocompleteOption[] | null) => void;
    disabled?: boolean;
    multiple?: boolean ;
    fieldName?: string;
    value?: AutocompleteOption[] | AutocompleteOption | null;
    checkboxes?: boolean;
    className?: string;
    abbreviateName?: boolean;
    disableCloseOnSelect?: boolean;
    handleInputCreate?: (value: string) => void;
    error?: boolean;
    helperText?: string | boolean;
    noOptionsText?: string;
    handleOnClose?: () => void;
    componentsProps?: {};
}

function BaseSelectWithInput(props: BaseSelectWithInputProps) {
    const {
        optionsList,
        placeholder,
        onChange,
        disabled,
        multiple,
        value,
        checkboxes,
        className,
        abbreviateName,
        handleInputCreate,
        disableCloseOnSelect,
        error,
        helperText,
        noOptionsText,
        handleOnClose,
        componentsProps
    } = props;

    if (checkboxes) {
        return (
            <Autocomplete
                getOptionLabel={(option: AutocompleteOption) => option.label || ""}
                className={className}
                value={value}
                multiple={multiple}
                disabled={disabled}
                options={optionsList}
                sx={{width: '100%'}}
                size={"small"}
                disableCloseOnSelect={disableCloseOnSelect}
                onChange={onChange}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option, {selected}) => (
                    <li {...props} style={{padding: "0 0 0 5px"}}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 0}}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={placeholder ?? ''}
                        onChange={(e) => {
                            handleInputCreate && handleInputCreate(e.target.value);
                        }}
                    />
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={abbreviateName ? option.value : option.label}
                            {...getTagProps({index})}
                        />
                    ))
                }
            />
        );
    }

    return (
        <Autocomplete
            getOptionLabel={(option: AutocompleteOption) => option.label || ""}
            className={className}
            value={value}
            multiple={multiple}
            disabled={disabled}
            options={optionsList}
            sx={{width: '100%'}}
            size={"small"}
            disableCloseOnSelect={disableCloseOnSelect}
            onChange={onChange}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField
                {...params}
                label={placeholder ?? ''}
                error={error}
                helperText={helperText}
                onChange={(e) => {
                    handleInputCreate && handleInputCreate(e.target.value);
                }}
            />}
            noOptionsText={noOptionsText}
            onClose={handleOnClose}
            componentsProps={componentsProps}
        />
    );
}

export default BaseSelectWithInput;
