import {Dialog} from "@mui/material";
import {styled} from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        textAlign: 'left',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '0 36px'
    },
    '& .MuiDialogActions-root': {
        textAlign: 'center'
    },
    '& .MuiDialog-paper': {
        maxWidth: '796px',
        paddingTop: '34px'
    },
    '&.change-redirect-type-modal .MuiDialogTitle-root': {
        padding: '0 24px',
        marginBottom: '16px'
    }
}));

export default StyledDialog;