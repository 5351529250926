import {Action, configureStore, Middleware, ThunkAction} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import {UrlParams} from "../componets/Table/types";
import trackerStatisticReducer from './reducers/prism/trackerStatisticSlice';
import brandsReducer from './reducers/prism/brandsSlice';
import trackersReducer from './reducers/prism/trackersSlice';
import axios from 'axios';
import sessionReducer, {TOKEN_STORAGE_KEY, logout} from './reducers/prism/sessionSlice';
import teamsReducer from './reducers/prism/teamsSlice';
import usersReducer from './reducers/prism/usersSlice';
import generalTrackersReducer from './reducers/prism/generalTrackersSlice';
import landingsReducer from './reducers/prism/landingsSlice';
import landingsListReducer from './reducers/landingsList/landingsSlice';
import landingsListProductsReducer from './reducers/landingsList/productsSlice';
import landingsListDomainsReducer from './reducers/landingsList/domainsSlice';
import landingsListCategoriesReducer from './reducers/landingsList/categoriesSlice';
import landingsListLanguagesReducer from './reducers/landingsList/languagesSlice';
import landingsListCategoryTypesReducer from './reducers/landingsList/categoryTypesSlice';
import {AsyncThunkPayloadCreator} from "@reduxjs/toolkit/src/createAsyncThunk";
import smartlinkNodesReducer from "./reducers/prism/smartlinkNodesSlice";
import shortUrlsReducer from './reducers/prism/shortUrlsSlice';
import companiesReducer from './reducers/prism/companiesSlice';
import shortenerDomainsReducer from './reducers/prism/shortenerDomainsSlice';
import reportReducer from './reducers/prism/reportSlice';
import dictionariesReducer from './reducers/prism/dictionariesSlice';

const authMiddleware: Middleware = (store) => (next) => (action) => {
  if (
    action?.meta?.requestStatus === "rejected"
    && action?.payload?.responseStatus === 401
    && store.getState().sessions.isAuthenticated === true
  ) {
    store.dispatch(logout())
  }
  return next(action)
}

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    statistic: trackerStatisticReducer,
    brands: brandsReducer,
    trackers: trackersReducer,
    sessions: sessionReducer,
    teams: teamsReducer,
    users: usersReducer,
    generalTrackers: generalTrackersReducer,
    landings: landingsReducer,
    landingsList: landingsListReducer,
    landingsListProduct: landingsListProductsReducer,
    landingsListDomains: landingsListDomainsReducer,
    landingsListCategories: landingsListCategoriesReducer,
    landingsListLanguages: landingsListLanguagesReducer,
    landingsListCategoryTypes: landingsListCategoryTypesReducer,
    smartlinkNodes: smartlinkNodesReducer,
    shortUrls: shortUrlsReducer,
    companies: companiesReducer,
    shortenerDomains: shortenerDomainsReducer,
    reports: reportReducer,
    dictionaries: dictionariesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(authMiddleware)
});

type SecondParam<T extends (...args: any) => any> = Parameters<T>[1] // number
export type ThunkApi = SecondParam<AsyncThunkPayloadCreator<any>>

// TODO implement with createAsyncThunk
class prismApiService {
    public apiEndpoint = process.env.REACT_APP_API_URL || '';

    get = async (path: string, params?: UrlParams) => {
        const searchParams = new URLSearchParams(params);
        const url = new URL(this.apiEndpoint);
        url.pathname = path;
        url.search = searchParams.toString();

        const token = localStorage.getItem(TOKEN_STORAGE_KEY) ?? ''

        const response = await axios.get(url.href, {
            params,
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': token ?? ''
            }
        });
        return response.data;
    };

    post = async (path: string, params?: UrlParams, body?: any) => {
        const searchParams = new URLSearchParams(params);
        const url = new URL(this.apiEndpoint);
        url.pathname = path;
        url.search = searchParams.toString();

        const token = localStorage.getItem(TOKEN_STORAGE_KEY) ?? ''

        const response = await axios.post(url.href, body, {
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': token
            }
        });
        return response.data;
    }

    put = async (path: string, params?: UrlParams, body?: any) => {
        const searchParams = new URLSearchParams(params);
        const url = new URL(this.apiEndpoint);
        url.pathname = path;
        url.search = searchParams.toString();

        const token = localStorage.getItem(TOKEN_STORAGE_KEY) ?? ''

        const response = await axios.put(url.href, body, {
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': token
            }
        });
        return response.data;
    }

    delete = async (path: string, params?: UrlParams) => {
        const searchParams = new URLSearchParams(params);
        const url = new URL(this.apiEndpoint);
        url.pathname = path;
        url.search = searchParams.toString();

        const token = localStorage.getItem(TOKEN_STORAGE_KEY) ?? ''

        const response = await axios.delete(url.href, {
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': token
            }
        });
        return response.data;
    }
}

export const prismApi = new prismApiService();

class landingsListApiService extends prismApiService {
    public apiEndpoint = process.env.REACT_APP_LANDING_LIST_API_URL || '';
}

export const landingsListApi = new landingsListApiService();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
