import React, {Dispatch, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import {AppFilters, UrlParams} from "../types";
import './AppliedFilterBar.scss';
import {useTranslation} from "react-i18next";
import {FilterConfig, FiltersFromObj} from "./types";
import {Filter} from "./Filter";
import {map} from "lodash";
import {FilterWithNotUrlData} from "./FilterWithNotUrlData";

interface FiltersProps {
    filtersConfig: FilterConfig[];
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    clearFilters: () => void;
    tableName?: string;
    filtersFromObj?: FiltersFromObj;
    setClearObjFilters: Dispatch<boolean>;
    setFiltersListToRemove?: Dispatch<FiltersFromObj>;
}

export function AppliedFilterBar(props: FiltersProps): JSX.Element {
    const {t} = useTranslation();
    const {filtersConfig, urlFilters, clearFilters, addFilters, tableName, filtersFromObj, setClearObjFilters, setFiltersListToRemove} = props;
    const [showBorder, setShowBorder] = useState<boolean>(false)

    const handleClearFilters = () => {
        if (tableName === 'statistics') {
            setClearObjFilters(true);
        } else {
            clearFilters();
            setShowBorder(false);
        }
    }

    useEffect(() => {
        if (Object.keys(urlFilters).length === 4) {
            setShowBorder(false);
        }
    }, [urlFilters])

    return (
        <div className={`filter-chip__bar ${showBorder && 'filter-chip__bar-border'}`}>
        {map(urlFilters, (urlFilter, field) => {
                return (<Filter
                    key={field}
                    filtersConfig={filtersConfig}
                    urlFilter={urlFilter}
                    fieldName={field}
                    addFilters={addFilters}
                    setShowBorder={setShowBorder}
                    tableName={tableName}
                ></Filter>)
            })}

            {map(filtersFromObj, (urlFilter, field) => {
                return (<FilterWithNotUrlData
                    key={field}
                    filtersList={urlFilter}
                    fieldName={field}
                    addFilters={addFilters}
                    setShowBorder={setShowBorder}
                    tableName={tableName}
                    setFiltersListToRemove={setFiltersListToRemove}
                />)
            })}

            <Button
                size="large"
                className="filter-chip__clear-filters-button"
                variant="contained"
                onClick={handleClearFilters}
            >
                <CloseIcon sx={{marginRight:"13px"}} fontSize="medium"/>
                <span>{t('common.filters.clear_all')}</span>
            </Button>
        </div>
    );
}
