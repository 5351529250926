import {ColumnConfig} from "../Table/types";
import {baseColumn} from "../Table/columns";

export const status: ColumnConfig = {
  ...baseColumn,
  field: 'status',
  headerName: 'landings.table.columns.status',
};

export const tag: ColumnConfig = {
  ...baseColumn,
  field: 'tag',
  headerName: 'landings.table.columns.tag',
};

export const company: ColumnConfig = {
  ...baseColumn,
  field: 'company',
  headerName: 'landings.table.columns.company',
};

export const lang: ColumnConfig = {
  ...baseColumn,
  field: 'language',
  headerName: 'landings.table.columns.language',
};

export const funnel: ColumnConfig = {
  ...baseColumn,
  field: 'funnel',
  headerName: 'landings.table.columns.funnel',
};

export const product: ColumnConfig = {
  ...baseColumn,
  field: 'product',
  headerName: 'landings.table.columns.productId',
};

export const url: ColumnConfig = {
  ...baseColumn,
  field: 'slug',
  headerName: 'landings.table.columns.slug',
  sortable: true,
};

export const domain: ColumnConfig = {
  ...baseColumn,
  field: 'domain',
  headerName: 'landings.table.columns.domain',
};

export const landing_name: ColumnConfig = {
  ...baseColumn,
  field: 'name',
  headerName: 'landings.table.columns.name',
  sortable: true,
};

export const landing_id: ColumnConfig = {
  ...baseColumn,
  field: 'id',
  headerName: 'landings.table.columns.id',
  sortable: true,
};

export const brand_name: ColumnConfig = {
  ...baseColumn,
  field: 'brand.name',
  headerName: 'common.tracker_filters.products',
  sortable: true,
};

export const team: ColumnConfig = {
  ...baseColumn,
  field: 'team.name',
  headerName: 'common.tracker_filters.team',
  sortable: true,
};

export const modified_by: ColumnConfig = {
  ...baseColumn,
  field: 'modified_by.email',
  headerName: 'common.tracker_filters.modified_by',
  sortable: true,
};

export const category: ColumnConfig = {
  ...baseColumn,
  field: 'category',
  headerName: 'Category',
};

export const type: ColumnConfig = {
  ...baseColumn,
  field: 'type',
  headerName: 'Type',
};

export const trackers: ColumnConfig = {
  ...baseColumn,
  field: 'trackers',
  headerName: 'Trackers',
};

export const landings_list_actions: ColumnConfig = {
  ...baseColumn,
  field: 'actions',
  headerName: 'common.tracker_filters.actions',
};