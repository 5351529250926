import {DataRow} from "../types";
import {get} from "lodash";

export const getBooleanTableValue = (row: DataRow, fieldName: string): boolean => {
    const booleanValue = get(row, fieldName);
    if (typeof booleanValue === "boolean") {
        return booleanValue;
    }
    throw new Error(`${fieldName} should be boolean to use here`);
};
