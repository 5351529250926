import React from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CancelIcon from '@mui/icons-material/Cancel';
import {ItemProps} from '../MultiSelect';
import './Chip.scss'
import {DraggableProvided} from "react-beautiful-dnd";

interface ChipProps {
    item: ItemProps;
    providedDraggable: DraggableProvided;
    handleRemoveItem: (id: string) => void;
}

function Chip(props: ChipProps) {
    const {item, providedDraggable, handleRemoveItem} = props;

    return (
        <div
            ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
            className="chip"
            onClick={e => {
                e.stopPropagation()
            }}
        >
            <DragIndicatorIcon className="chip-icon-move" />
            <p className="chip-title">{item.content}</p>
            <CancelIcon
                className="chip-icon-remove"
                onClick={() => handleRemoveItem(item.id)}
            />
        </div>
    );
}

export default Chip;
