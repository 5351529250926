import React, {Dispatch, useCallback, useState, useEffect} from 'react';
import Tree, {
    useTreeState,
    treeHandlers,
} from 'react-hyper-tree'
import './RuleTree.scss';
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TimerIcon from '@mui/icons-material/Timer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import theme from "../../UI/Theme";
import {DataFromBaseCompilation} from "./DataFromBaseCompilation";
import {Node, RelationsData, TransformedNode} from "../types";
import {TreeNode} from "react-hyper-tree/dist/helpers/node";
import DarkTooltip from "../../DarkTooltip/DarkTooltip";
import {useAppDispatch} from "../../../app/hooks";
import {deleteRuleFromGeneralTracker} from "../../../app/reducers/prism/generalTrackersSlice";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {countForwardSymbols, cutString} from "../../Helpers/stringHelpers";
import DeleteModal from "../../modals/DeleteModal/DeleteModal";
import Tooltip from "../../Tooltip/Tooltip";
import {typesRules} from "../types";

interface RuleTreeProps {
    treeData: TransformedNode;
    handleAddRule: (id: number, type: string) => void;
    handleEditRule: (node: Node) => void;
    trackerId: number;
    setUpdateTree: Dispatch<React.SetStateAction<number>>;
    setNodeId: Dispatch<React.SetStateAction<number>>;
}

const RuleTree: React.FC<RuleTreeProps> = (props): JSX.Element => {
    const {t} = useTranslation();
    const {treeData, handleAddRule, trackerId, setUpdateTree, handleEditRule, setNodeId} = props;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [rule, setRule] = useState<Node>();
    const dispatch = useAppDispatch();
    const {required, handlers} = useTreeState({
        id: 'tree',
        data: treeData,
        defaultOpened: true,
        multipleSelect: false,
    })

    let deleteFormTitle = t('smartlinks.modal_rule_configuration.delete_rule_title');

    const deleteRuleHandler = (ruleId: number, trackerId: number) => async () => {
        const response = await dispatch(deleteRuleFromGeneralTracker({ruleId: ruleId, trackerId: trackerId}));
        if (response) {
            setUpdateTree(prevValue => prevValue + 1);
        }
    }

    const treeRuleLinkBuilder = (node: TreeNode) => {
        const level = countForwardSymbols(node.options.path, '/');
        const details = node.data.details;
        const chartsNumber = Math.max(12, 40 - level * 6);
        if (details.redirect_type === "landing" && details.landing_url !== undefined) {
            return (
                <div className="linkBox">
                    <Tooltip url={details.landing_url} numberLetters={chartsNumber} styles={{color: '#009E69'}}/>
                </div>
            );
        }
        if (details.redirect_type === "url" && typeof details.url === "string") {
            return (
                <div className="linkBox">
                    <Tooltip url={details.url} numberLetters={chartsNumber} styles={{color: '#009E69'}}/>
                </div>
            );
        }

        if (details.redirect_type === "splitting" && details.visits === null && details.splitting !== null) {
            return (
                <div className="linkBox">
                    {details.splitting.map((item: { url: string }, id: number) => (
                        <Tooltip key={id} url={item.url} name={"Link" + (id + 1)} numberLetters={chartsNumber}
                                 styles={{color: '#009E69', marginLeft: id > 0 ? "16px" : 0}}/>
                    ))}
                </div>
            );
        }
    }

    const handleOpenDeleteModal = (node: Node) => {
        setRule(node);
        setOpenModal(true);
    }

    const handleOpenNode = (node: TreeNode) => {
        treeHandlers.trees.tree.handlers.setOpen(node);
    }

    const checkTypeName = (name: string) => (name === typesRules.geo ? t("smartlinks.tree.geo") : name);

    const checkValueLength = (value: string, node: TreeNode) => {
        const level = countForwardSymbols(node.options.path, '/');
        const chartsNumber = Math.max(5, 11 - level * 1);
        return value.length > chartsNumber ? value.slice(0, chartsNumber) + "..." : value;
    };

    useEffect(() => {
        setInterval(function () {
            const treeElements = document.getElementsByClassName('ht_collapsed');
            if (treeElements.length > 0) {
                Array.from(treeElements).forEach((element) => {
                    element.classList.remove('ht_collapsed');
                });
            }
        }, 500)
    }, []);

    const renderNode = useCallback((
        {node, onToggle}: { node: TreeNode, onToggle: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }
    ) => (
        <div>
            {node !== null &&
            <div className="tree-node" key={node.data.title}>
                <div
                    className="node-content-wrapper"
                    // onClick={() => handleOpenNode(node)}
                >
                    <div className="tree-node__left-part">
                        <div
                            // onClick={onToggle}
                            className={
                                "tree-icon " + (!node.hasChildren() ? "empty-icon " : "") +
                                (node.options.opened && node.hasChildren() ? "close-icon " : "") +
                                (!node.options.opened && node.hasChildren() ? "open-icon " : "")
                            }
                        ><KeyboardArrowDownIcon/></div>
                        <div className="titles">
                            <Box className="node-title">
                                <Box className="condition" sx={{color: theme.palette.text.secondary}}>{"if"}</Box>
                                <div className="type">{checkTypeName(node.data.details.type)}</div>
                                <Box className="condition" sx={{color: theme.palette.text.secondary}}>{"is"}</Box>
                                <div className="value">{checkValueLength(node.data.details.value, node)}</div>
                                {node.data.details.redirect_type !== "splitting" && <KeyboardBackspaceOutlinedIcon
                                    className="linkArrow"
                                    sx={{color: theme.palette.action.active}}
                                />}
                                {node.data.details.redirect_type === "splitting" && <CallSplitIcon
                                    className="linkSplitting"
                                    sx={{color: theme.palette.action.active}}
                                />}
                                {treeRuleLinkBuilder(node)}
                            </Box>
                            {node.data.title && (<div className="node-subtitle">{node.data.title}</div>)}
                        </div>
                    </div>
                    <div className="tree-node__controls">
                        {node.data.details.type !== typesRules.single &&
                        <div className="control-icon">
                            <DarkTooltip
                                title={node.data.details.schedule_count > 0
                                    ? t('smartlinks.modal_rule_configuration.tooltips.schedule_is_working')
                                    : t('smartlinks.modal_rule_configuration.tooltips.schedule_is_not_working')}
                                placement="top">
                                <TimerIcon
                                    className={node.data.details.schedule_count > 0 ? "IconActive" : ""}
                                    onClick={() => {
                                        handleAddRule(Number(node.data.details.id), 'schedule');
                                    }}
                                />
                            </DarkTooltip>
                        </div>}
                        <div className="control-icon">
                            <DarkTooltip title={t('smartlinks.modal_rule_configuration.tooltips.edit_rule')}
                                         placement="top">
                                <EditIcon
                                    onClick={() => {
                                        setNodeId(node.data.details.id);
                                        handleEditRule(node.data.details);
                                    }}
                                />
                            </DarkTooltip>
                        </div>
                        <div className="control-icon">
                            <DarkTooltip title={t('smartlinks.modal_rule_configuration.tooltips.delete_rule')}
                                         placement="top">
                                <DeleteForeverIcon
                                    onClick={() => handleOpenDeleteModal(node.data.details)}
                                />
                            </DarkTooltip>
                        </div>
                        {node.data.details.type !== typesRules.single && node.data.details.type !== typesRules.isEp &&
                        <div className="control-icon">
                            <DarkTooltip title={t('smartlinks.modal_rule_configuration.tooltips.add_new_rule')}
                                         placement="top">
                                <AddCircleIcon
                                    onClick={() => {
                                        setNodeId(node.data.details.id);
                                        handleAddRule(Number(node.data.details.id), node.data.details.type);
                                    }}
                                />
                            </DarkTooltip>
                        </div>}
                    </div>
                </div>
            </div>}
        </div>
    ), []);

    return (
        <>
            <Tree
                {...required}
                {...handlers}
                horizontalLineStyles={{stroke: theme.palette.primary.dark, strokeWidth: 1}}
                verticalLineStyles={{stroke: theme.palette.primary.dark}}
                draggable={false}
                gapMode={"padding"}
                depthGap={36}
                disableLines={false}
                disableHorizontalLines={false}
                disableVerticalLines={false}
                verticalLineTopOffset={-9}
                verticalLineOffset={18}
                renderNode={renderNode}
                staticNodeHeight={52}
            />

            {openModal && (<DeleteModal
                handleDelete={() => {
                    if (rule) {
                        const deleteFunction = deleteRuleHandler(Number(rule.id), trackerId);
                        deleteFunction();
                    }
                    setOpenModal(false);
                }}
                handleClose={() => setOpenModal(false)}
                modalTitle={deleteFormTitle.replace("%rule_type%", rule !== undefined ? rule.redirect_type : "")}
                modalSubtitle={t('smartlinks.modal_rule_configuration.delete_rule_subtitle')}
            />)}
        </>
    )
}

export default RuleTree;