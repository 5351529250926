import {prismApi} from "../store";
import {GeneralTrackerRequestBody} from "../../componets/Smartlink/ModalRuleConfiguration/type";

const fetchGeneralTrackers = async (trackerId: number) => {
    return prismApi.get(`/trackers/${trackerId}/general-trackers`);
}

const fetchGeneralTrackersTree = async (trackerId: number) => {
    return prismApi.get(`/trackers/${trackerId}/general-trackers/tree`);
}

const createRule = async (body: GeneralTrackerRequestBody, trackerId: number) =>{
    return prismApi.post(`/trackers/${trackerId}/general-trackers`, undefined, body);
}

const editRule = async (body: GeneralTrackerRequestBody, trackerId: number, ruleId: number) =>{
    return prismApi.put(`/trackers/${trackerId}/general-trackers/${ruleId}`, undefined, body);
}

const createSchedule = async (body: GeneralTrackerRequestBody, ruleId: number) =>{
    return prismApi.post(`/general-trackers/${ruleId}/schedule`, undefined, body);
}

const editSchedule = async (body: GeneralTrackerRequestBody, ruleId: number, scheduleId: number) =>{
    return prismApi.put(`/general-trackers/${ruleId}/schedule/${scheduleId}`, undefined, body);
}

const fetchSchedules = async (ruleId: number) => {
    return prismApi.get(`/general-trackers/${ruleId}/schedule`);
}

const DeleteRule = async (ruleId: number, trackerId: number) =>{
    return prismApi.delete(`/trackers/${trackerId}/general-trackers/${ruleId}/`, undefined);
}

const DeleteSchedule = async (ruleId: number, scheduleId: number) =>{
    return prismApi.delete(`/general-trackers/${ruleId}/schedule/${scheduleId}/`, undefined);
}

export {fetchGeneralTrackers, fetchGeneralTrackersTree, createRule, editRule, DeleteRule, createSchedule, fetchSchedules, DeleteSchedule, editSchedule};