import {PaginatedData} from "../../types";
import {TrackerData} from "./types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {RelationsData, Node} from "../../../componets/Smartlink/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createRule,
    editRule,
    DeleteRule,
    fetchGeneralTrackers,
    fetchGeneralTrackersTree,
    createSchedule,
    fetchSchedules,
    DeleteSchedule,
    editSchedule
} from "../../requests/generalTrackers";
import {RootState} from "../../store";
import {GeneralTrackerRequestBody} from "../../../componets/Smartlink/ModalRuleConfiguration/type";

export interface TrackersState {
    generalTrackers: PaginatedData<TrackerData> | null;
    schedules: PaginatedData<TrackerData> | null;
    generalTrackersTree: { nodes: Node[], relations: RelationsData[] } | null;
    generalTracker: PaginatedData<GeneralTrackerRequestBody> | null;
    status: RequestStatuses;
    getGeneralTrackersTreeStatus: RequestStatuses;
    getSchedulesStatus: RequestStatuses;
    createGeneralTrackerStatus: RequestStatuses;
    editGeneralTrackerStatus: RequestStatuses;
    createScheduleRowStatus: RequestStatuses;
    editScheduleRowStatus: RequestStatuses;
}

const initialState: TrackersState = {
    generalTrackers: null,
    schedules: null,
    generalTracker: null,
    generalTrackersTree: null,
    status: RequestStatuses.idle,
    getGeneralTrackersTreeStatus: RequestStatuses.idle,
    getSchedulesStatus: RequestStatuses.idle,
    createGeneralTrackerStatus: RequestStatuses.idle,
    editGeneralTrackerStatus: RequestStatuses.idle,
    createScheduleRowStatus: RequestStatuses.idle,
    editScheduleRowStatus: RequestStatuses.idle
};

export const getGeneralTrackers = createAsyncThunk(
    'generalTrackers/fetchGeneralTrackers',
    async (params: UrlParams, { rejectWithValue }) => {
        try {
            return await fetchGeneralTrackers(Number(params?.trackerId));
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const createGeneralTracker = createAsyncThunk(
    'generalTrackers/createGeneralTracker',
    async ({trackerId, body}: { trackerId: number, body: GeneralTrackerRequestBody }) => {
        return await createRule(body, trackerId);
    }
);


export const editGeneralTracker = createAsyncThunk(
    'generalTrackers/editGeneralTracker',
    async ({trackerId,ruleId, body}: { trackerId: number, ruleId: number, body: GeneralTrackerRequestBody }) => {
        return await editRule(body, trackerId, ruleId);
    }
);

export const createScheduleRow = createAsyncThunk(
    'generalTrackers/createScheduleRow',
    async ({ruleId, body}: { ruleId: number, body: GeneralTrackerRequestBody }, {rejectWithValue}) => {
        try {
        return await createSchedule(body, ruleId);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const editScheduleRow = createAsyncThunk(
    'generalTrackers/createScheduleRow',
    async ({ruleId, scheduleId, body}: { ruleId: number, body: GeneralTrackerRequestBody, scheduleId: number }, {rejectWithValue}) => {
        try {
        return await editSchedule(body, ruleId, scheduleId);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const deleteRuleFromGeneralTracker = createAsyncThunk(
    'generalTrackers/deleteRule',
    async ({ruleId, trackerId}: { ruleId: number, trackerId: number }, {rejectWithValue}) => {
        try {
            return await DeleteRule(ruleId, trackerId);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const deleteScheduleFromRule = createAsyncThunk(
    'generalTrackers/deleteSchedule',
    async ({ruleId, scheduleId}: { ruleId: number, scheduleId: number }, {rejectWithValue}) => {
        try {
            return await DeleteSchedule(ruleId, scheduleId);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getGeneralTrackersTree = createAsyncThunk(
    'generalTrackers/fetchGeneralTrackersTree',
    async (trackerId: number, { rejectWithValue }) => {
        try {
            return await fetchGeneralTrackersTree(trackerId);
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getSchedules = createAsyncThunk(
    'generalTrackers/fetchSchedules',
    async (ruleId: number, { rejectWithValue }) => {
        try {
            return await fetchSchedules(ruleId);
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const generalTrackersSlice = createSlice({
    name: 'generalTrackers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGeneralTrackers.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getGeneralTrackers.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.generalTrackers = action.payload;
            })
            .addCase(getSchedules.pending, (state) => {
                state.getSchedulesStatus = RequestStatuses.loading;
            })
            .addCase(getSchedules.fulfilled, (state, action) => {
                state.getSchedulesStatus = RequestStatuses.success;
                state.schedules = action.payload;
            })
            .addCase(createGeneralTracker.pending, (state) => {
                state.createGeneralTrackerStatus = RequestStatuses.loading;
            })
            .addCase(createGeneralTracker.fulfilled, (state, action) => {
                state.createGeneralTrackerStatus = RequestStatuses.success;
                state.generalTracker = action.payload;
            })
            .addCase(getGeneralTrackersTree.pending, (state) => {
                state.getGeneralTrackersTreeStatus = RequestStatuses.loading;
            })
            .addCase(getGeneralTrackersTree.fulfilled, (state, action) => {
                state.getGeneralTrackersTreeStatus = RequestStatuses.success;
                state.generalTrackersTree = action.payload;
            })
    },
});

export const selectGeneralTrackers = (state: RootState) => state.generalTrackers.generalTrackers;
export const selectSchedules = (state: RootState) => state.generalTrackers.schedules;
export const selectGeneralTrackersTree = (state: RootState) => state.generalTrackers.generalTrackersTree;
export const selectGeneralTrackersStatus = (state: RootState) => state.generalTrackers.status;


export default generalTrackersSlice.reducer;