import React from 'react';
import {Button, DialogActions, DialogContentText, DialogTitle, Divider} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import ResetDialog from "./ResetDialog";

export interface DeleteModalProps {
    handleClose: () => void;
    open: boolean;
}

function ResetPasswordConfirm({handleClose, open}: DeleteModalProps) {
    const {t} = useTranslation();

    return (
        <ResetDialog
            open={open}
            onClose={handleClose}
        >
            <CloseIcon
                className="close-button"
                onClick={handleClose}
            />
                <DialogTitle sx={{padding: 0, fontSize: "24px"}}>{t("login_form.restore_password")}</DialogTitle>
                <DialogContentText
                    sx={{fontSize: '16px', width: "285px", display: "inline-block", color: "#000", marginTop: "16px"}}>
                    {t("login_form.restore_password_subtitle")}
                </DialogContentText>
                <Divider/>
                <DialogActions>
                    <Button
                        sx={{width: "100%"}}
                        onClick={handleClose}
                        className="BtnPurple"
                    >
                        {t('login_form.btn_ok')}
                    </Button>
                </DialogActions>
        </ResetDialog>
    );
}

export default ResetPasswordConfirm;
