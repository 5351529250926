import React, {useState, useEffect} from 'react';
import {List, ListItem, ListItemIcon, ListItemText, Checkbox, TextField} from '@mui/material';
import './TransferList.scss';
import {useTranslation} from "react-i18next";
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import {AdditionalFiltersArr, AdditionalFilters} from "../../Table/ControlBarWrapper/ControlBarStatistics/types";
import { Virtuoso } from 'react-virtuoso';

interface ITransferListProps {
    filters: AdditionalFiltersArr[];
    setAdditionalFilters: (prevState: AdditionalFilters[]) => void;
    filterName: string;
    additionalFilters: AdditionalFilters[];
}

const CheckIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} >
            <path d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333ZM14.9917 7.5L13.8167 6.31667L8.325 11.8083L6.175 9.66667L4.99167 10.8417L8.325 14.1667L14.9917 7.5Z" fill="#7B756B"/>
        </SvgIcon>
    );
}

const TransferList: React.FC<ITransferListProps> = (props): JSX.Element => {
    const {t} = useTranslation();
    const {filters, setAdditionalFilters, filterName, additionalFilters} = props;
    const [left, setLeft] = useState<AdditionalFiltersArr[]>([]);
    const [right, setRight] = useState<AdditionalFiltersArr[]>([]);
    const [search, setSearch] = useState<string>('');

    const handleToggle = (value: AdditionalFiltersArr) => () => {
        const temp = additionalFilters.map((filterBlock) => {
            if (filterBlock.filterName === filterName) {
                filterBlock.filters = filterBlock.filters.map((filter) =>
                    filter.id === value.id ? { ...filter, checked: !filter.checked } : filter
                );
            }
            return filterBlock;
        });

        setAdditionalFilters(temp);
    };

    const handleUnselectAll = () => {
        const temp = additionalFilters.map((filterBlock) => {
            if (filterBlock.filterName === filterName) {
                filterBlock.filters = filterBlock.filters.map((filter) =>
                    ({ ...filter, checked: false })
                );
            }
            return filterBlock;
        });

        setAdditionalFilters(temp);
        setLeft([]);
        setRight([]);
    }

    const handleInput = (event: { target: { value: string; }; }) => {
        setSearch(event.target.value);
    };

    const findPartial = (arr: AdditionalFiltersArr[], str: string) => {
        if (str) {
            return arr.filter((item) =>
                item.name.toLowerCase().includes(str.toLowerCase())
            );
        } else {
            return [];
        }
    }

    useEffect(() => {
        if(!search) {
            setLeft([]);
            filters.forEach(filter => {
                if (!filter.checked) {
                    setLeft((prevState) => [...prevState, filter]);
                }
            });
        } else {
            setLeft([]);
            filters.forEach(filter => {
                if (!filter.checked) {
                    setLeft((prevState) => [...prevState, filter]);
                }
            });
            setLeft((prevState) => {
                const searchRes = findPartial(prevState, search);
                return searchRes
            })
        }
    }, [search]);

    useEffect(() => {
        setLeft([]);
        setRight([]);
        filters.forEach(filter => {
            if (!filter.checked) {
                setLeft((prevState: AdditionalFiltersArr[]) => {
                    return [...prevState, filter]
                })
            } else {
                setRight((prevState: AdditionalFiltersArr[]) => {
                    return [...prevState, filter]
                })
            }
        });
        if (search) {
            setLeft((prevState) => {
                const searchRes = findPartial(prevState, search);
                return searchRes
            })
        }
    }, [filters]);

    const renderList = (items: AdditionalFiltersArr[], position: string) => (
        <List dense component="div" role="list" className="transfer-list">
            <Virtuoso
                style={{ height: 246 }}
                data={items}
                itemContent={(index, item) => {
                    const labelId = `transfer-list-item-${item.id}-label`;
                    return (
                        <ListItem
                            className="transfer-list-item"
                            key={item.id}
                            role="listitem"
                            button
                            onClick={handleToggle(item)}
                        >
                            <ListItemIcon className="transfer-list-item-icon">
                                <Checkbox
                                    className="transfer-list-item-checkbox"
                                    checked={position === 'right'}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    checkedIcon={<CheckIcon viewBox="0 0 20 20"/>}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={item.name}
                                sx={{wordWrap: "break-word"}}
                            />
                        </ListItem>
                    );
                }}
            />
            <ListItem/>
        </List>
    );

    return (
        <div className="transfer-list-wrap">
            <div className="transfer-list-col">
                <TextField
                    size='small'
                    label={t('common.tracker_filters.search')}
                    value={search}
                    onChange={handleInput}
                    className="transfer-list-search-field"
                />
                {renderList(left, 'left')}
            </div>
            {right.length > 0 && <div className="transfer-list-col transfer-list-col-right">
                <ListItem
                    className="unselect-all-item"
                    role="listitem"
                    button
                    onClick={handleUnselectAll}
                >
                    <ListItemIcon sx={{minWidth: '28px'}}>
                        <Checkbox
                            className="unselect-all-item-checkbox"
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText
                        className="unselect-all-item-label"
                        primary="Unselect all"
                    />
                </ListItem>
                {renderList(right, 'right')}
            </div>}
        </div>
    );
}
export default TransferList;