import React, {Dispatch} from 'react';
import {useTranslation} from "react-i18next";
import {DateTimeInput} from "../../../Table/filterInputs/DateTimePicker/DateTimeInput";
import {Block} from "./styles";
import moment from "moment";
import {Box} from "@mui/material";
import BaseSelectWithInput from "../../../inputs/BaseSelectWithInput";
import {generateTimeOptions, scheduleIntervalError} from "../ruleHelpers";
import {dateRangesType} from "../type";
import {isArray} from "lodash";
import CustomAlert from "../../../Alert/CustomAlert";
import {RULE_LANDING, RULE_SPLITTING} from "../../types";
import {findOptionByValue} from "../../../Helpers/arrayHelper";

interface ScheduleIntervalsProps {
    dateRanges: dateRangesType;
    setDateRanges: Dispatch<React.SetStateAction<dateRangesType>>;
}

const ScheduleIntervals: React.FC<ScheduleIntervalsProps> = (props): JSX.Element => {
    const { t } = useTranslation();
    const {dateRanges, setDateRanges} = props;
    const timeVariants = generateTimeOptions();

    const onDateChange = (fromOrTo: 'dateFrom' | 'dateTo') => (value: string | null) => {
        if (value) {
            setDateRanges({...dateRanges, [fromOrTo]: value})
        }
    }

    return (
        <>
            <Block active={false}>
                <Box className="schedule-row">
                    <Box className="schedule-title">
                        {t('smartlinks.modal_rule_configuration.cap_range.from')}
                    </Box>
                    <DateTimeInput
                        key='from'
                        label={t('smartlinks.modal_rule_configuration.start_date')}
                        disableFuture={false}
                        minDate={moment()}
                        forceValue={moment(dateRanges.dateFrom)}
                        onChangeHandler={onDateChange('dateFrom')}
                        defaultDate={moment(dateRanges.dateFrom)}
                    />
                    <BaseSelectWithInput
                        fieldName="timeFrom"
                        optionsList={timeVariants}
                        placeholder={t('smartlinks.modal_rule_configuration.start_time')}
                        value={{label: dateRanges.timeFrom, value: dateRanges.timeFrom}}
                        onChange={(event, value) => {
                            if(value === null) {
                                setDateRanges({...dateRanges, timeFrom: ""})
                            }
                            if(value !== null && !isArray(value)) {
                                setDateRanges({...dateRanges, timeFrom: value.value !== null ? value.value.toString() : ""})
                            }
                        }}
                    />
                </Box>

                <Box className={"schedule-row"}>
                    <Box className="schedule-title">
                        {t('smartlinks.modal_rule_configuration.to')}
                    </Box>
                    <DateTimeInput
                        key='to'
                        label={t('smartlinks.modal_rule_configuration.end_date')}
                        disableFuture={false}
                        forceValue={moment(dateRanges.dateTo)}
                        onChangeHandler={onDateChange('dateTo')}
                        defaultDate={moment(dateRanges.dateTo)}
                    />
                    <BaseSelectWithInput
                        fieldName="timeTo"
                        optionsList={timeVariants}
                        placeholder={t('smartlinks.modal_rule_configuration.end_time')}
                        value={{label: dateRanges.timeTo, value: dateRanges.timeTo}}
                        onChange={(event, value) => {
                            if(value === null) {
                                setDateRanges({...dateRanges, timeTo: ""})
                            }
                            if(value !== null && !isArray(value)) {
                                setDateRanges({...dateRanges, timeTo: value.value !== null ? value.value.toString() : ""})
                            }
                        }}
                    />
                </Box>
                <CustomAlert
                    type="error"
                    title={t("smartlinks.modal_rule_configuration.from_less_to_error")}
                    status={scheduleIntervalError(dateRanges)}
                />
            </Block>
        </>
    );
}

export default ScheduleIntervals;
