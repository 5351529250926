import React, {useState} from 'react';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

interface DateTimeInputProps {
    label: string;
    onChangeHandler: (value: string | null) => void;
    defaultDate?: Moment;
    placeholder?: string;
    disableFuture?: boolean;
    minDate?: Moment;
    forceValue?: Moment;
}

export function DateTimeInput(props: DateTimeInputProps) {
    const { onChangeHandler, placeholder, label, defaultDate, disableFuture = true, minDate, forceValue} = props;
    const [value, setValue] = useState<Moment | null>(
        defaultDate ?? moment()
    );
    const [open, setOpen] = useState(false);

    const handleChange = (newValue: moment.Moment | null) => {
        setValue(newValue);
        onChangeHandler(newValue?.format('YYYY-MM-DD') ?? null)
    };
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                disableOpenPicker
                inputFormat="ddd, DD MMMM"
                disableFuture={disableFuture}
                minDate={minDate}
                label={label}
                openTo="day"
                views={['year', 'day']}
                value={forceValue ?? value}
                onChange={handleChange}
                renderInput={(params) => <TextField
                    {...params}
                    size='small'
                    sx={{marginLeft: '16px'}}
                    onClick={(e) => setOpen(true)}
                />}
            />
        </LocalizationProvider>
    );
}