import React from 'react';
import {Tooltip} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import './RadioGroupBlock.scss';
import {useTranslation} from "react-i18next";

interface RadioGroupBlockProps {
    activeRadio: string;
    handleRadioChange: (val: string) => void;
}

function RadioGroupBlock({activeRadio, handleRadioChange}: RadioGroupBlockProps) {
    const { t } = useTranslation();
    const radioGroupContent = [
        {
            value: t('smartlinks.modal_rule_configuration.radioGroup.landing.value'),
            name: t('smartlinks.modal_rule_configuration.radioGroup.landing.name'),
            hint: t('smartlinks.modal_rule_configuration.radioGroup.landing.hint')
        },
        {
            value: t('smartlinks.modal_rule_configuration.radioGroup.splitting.value'),
            name: t('smartlinks.modal_rule_configuration.radioGroup.splitting.name'),
            hint: t('smartlinks.modal_rule_configuration.radioGroup.splitting.hint')
        },
        {
            value: t('smartlinks.modal_rule_configuration.radioGroup.direct_url.value'),
            name: t('smartlinks.modal_rule_configuration.radioGroup.direct_url.name'),
            hint: t('smartlinks.modal_rule_configuration.radioGroup.direct_url.hint')
        }
    ];

    return (
        <div className="radio-group-block">
            <p className="radio-group-block-title">{t("smartlinks.modal_rule_configuration.redirect_to")}</p>
            {radioGroupContent.map((radio, i) => {
                return (
                    <div key={i} className="radio-block">
                        {activeRadio === radio.value
                            ? <RadioButtonCheckedIcon
                                className="radio-block-img"
                                onClick={() => handleRadioChange(radio.value)}
                            />
                            : <RadioButtonUncheckedIcon
                                className="radio-block-img"
                                onClick={() => handleRadioChange(radio.value)}
                            />
                        }
                        <p onClick={() => handleRadioChange(radio.value)}>{radio.name}</p>
                        <Tooltip sx={{position: "absolute", top: "-10px"}} title={radio.hint}>
                            <InfoOutlinedIcon className="hint"/>
                        </Tooltip>
                    </div>
                )
            })}
        </div>
    );
}

export default RadioGroupBlock;
