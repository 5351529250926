import { UrlParams } from "../../componets/Table/types";
import {prismApi} from "../store";

const fetchTeams = async (filters?: UrlParams) => {
  return prismApi.get('/teams', filters);
}

const fetchTeamsFromPrism = async (filters?: UrlParams) => {
  return prismApi.get('/teams/search/', filters);
}

const fetchTeamId = async (teamId: number) => {
  return prismApi.get(`/teams/${teamId}/`);
}

const searchTeams = async (name: string) => {
  return prismApi.get(`/teams/search/`, {name});
}

export { fetchTeams, fetchTeamId, searchTeams, fetchTeamsFromPrism };