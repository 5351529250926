import React, {useEffect, useState} from 'react';
import {AsyncThunk} from "@reduxjs/toolkit";
import {isArray, pick, map, flatten} from "lodash";

import {AppFilterValue, DataRow, RequestStatuses, UrlParams} from "../../types";
import {RootState} from "../../../../app/store";

import {BaseAutocomplete} from "../../../inputs/baseAutocomplete/BaseAutocomplete";
import './AutocompleteFilter.css';
import {dataToFilter, multiplyDataToFilter} from "../../../inputs/baseAutocomplete/helpers";

const transformParamsToOption = (data: UrlParams | null, optionKey: string): string[] | number[] => {
    if (!data) {
        return [];
    }

    return flatten(map(pick(data, optionKey), value => value.split(',')));
}


interface AutocompleteProps {
    optionKey: string;
    onChangeHandler: (option: AppFilterValue) => void;
    placeholder: string;
    optionLabel: string;
    optionValue?: string;
    asyncAction: AsyncThunk<any, UrlParams | undefined, any>
    dataSelector: (state: RootState) => DataRow[] | null
    multiselect?: boolean;
    statusSelector: (state: RootState) => RequestStatuses;
    defaultFilters: UrlParams,
    values: UrlParams;
}

// TODO optimize with https://mui.com/material-ui/react-autocomplete/#virtualization
export function AutocompleteFilter(props: AutocompleteProps) {
    const {
        onChangeHandler,
        optionKey,
        optionLabel,
        optionValue = 'id',
        dataSelector,
        statusSelector,
        asyncAction,
        placeholder,
        multiselect,
        defaultFilters,
        values,
    } = props;

    const [defaultIds, setDefaultIds] = useState<string[] | number[]>(transformParamsToOption(defaultFilters, optionKey));

    useEffect(() => {
        setDefaultIds(transformParamsToOption(values, optionKey));
    }, [optionKey, optionLabel, values]);


    return (
        <BaseAutocomplete
            onChange={(event, options) => {
                if (isArray(options)) {
                    onChangeHandler(multiplyDataToFilter(options));
                } else {
                    onChangeHandler(dataToFilter(options));
                }
            }}
            optionLabel={optionLabel}
            optionValue={optionValue}
            asyncAction={asyncAction}
            dataSelector={dataSelector}
            statusSelector={statusSelector}
            multiple={multiselect}
            placeholder={placeholder}
            defaultOptionValue={defaultIds}
        />
    );
}
