import React, {useEffect} from 'react';
import {Button, SvgIcon, TextField} from "@mui/material";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Box, Checkbox, Divider, Grid, Radio, Typography} from '@mui/material/';
import {FormikValues} from "formik/dist/types";
import {ObjectShape} from "yup/lib/object";
import FormLabel from '@mui/material/FormLabel';

import {ButtonConfig, Config, DividerConfig, SubmitHandler, TypographyConfig, EmptyConfig} from "./types";
import {FormSelect} from "./formInputs/Select/FormSelect";
import {FormAutocomplete} from "./formInputs/Autocomplete/FormAutocomplete";
import {useTranslation} from "react-i18next";
import {forEach} from "lodash";
import './Form.scss';
import {ReactComponent as QuestionIcon} from "../../app/assets/img/icons/questionGray.svg";
import Tooltip from "@mui/material/Tooltip";
import {
  TYPE_AUTOCOMPLETE,
  TYPE_BUTTON, TYPE_CHECKBOX, TYPE_DIVIDER,
  TYPE_RADIO,
  TYPE_SELECT,
  TYPE_TEXT,
  TYPE_TYPOGRAPHY,
  COLUMN_COUNT,
  TYPE_SELECT_WITH_INPUT
} from "./constants";
import {RootState} from "../../app/store";
import {useAppSelector} from "../../app/hooks";
import {AutocompleteOption} from "../inputs/baseAutocomplete/types";
import {isArray} from "lodash";
import FormGrid from "./FormGrid/FormGrid";
import BaseSelectWithInput from "../inputs/BaseSelectWithInput";

export interface FormProps<Values extends FormikValues = FormikValues> {
  configs: Config[],
  onSubmitHandler: SubmitHandler<Values>
  formName?: string,
  errorsSelector?: (state: RootState) => { [fieldName: string]: string | undefined }
  validateOnChange?: boolean
  validateOnBlur?: boolean
}

export function Form<Values extends FormikValues = FormikValues>(props: FormProps<Values>) {
  const {t} = useTranslation();
  const {configs, onSubmitHandler, formName, errorsSelector, validateOnChange, validateOnBlur} = props;
  const apiErrors = useAppSelector(errorsSelector ?? (() => ({})));

  const validationObject: ObjectShape = {};

  const isButton = (config: Config): config is ButtonConfig => config.type === 'button';
  const isLine = (config: Config): config is DividerConfig => config.type === 'divider';
  const isEmpty = (config: Config): config is EmptyConfig => config.type === 'empty';
  const isTypography = (config: Config): config is TypographyConfig => config.type === 'typography'

  configs.forEach(config => {
    if (!(isButton(config)) && !(isLine(config)) && !(isEmpty(config)) && !(isTypography(config)) && config.validation) {
      validationObject[config.field] = config.validation
    }
  })

  const validationSchema = Yup.object().shape(validationObject);

  const makeInitialValues = (configs: Config[]): Values => {
    return configs.reduce((resultConfig, currConfig) => {
      if (currConfig.type !== 'button' && currConfig.type !== 'divider' && currConfig.type !== 'empty' && currConfig.type !== 'typography') {
        return {...resultConfig, [currConfig.field]: currConfig.defaultValue ?? ''}
      }
      return resultConfig;
    }, {} as Values);
  }

  const formik = useFormik({
    initialValues: makeInitialValues(configs),
    validationSchema: validationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnBlur,
    onSubmit: onSubmitHandler,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldError
  } = formik;

  useEffect(() => {
    forEach(apiErrors, (error, field) => {
      if (error) {
        setFieldError(field, error);
      }
    })
  }, [apiErrors, setFieldError])

  useEffect(() => {
    configs.forEach(config => {
      if (config.type === TYPE_SELECT_WITH_INPUT && config.value) {
        setFieldValue(config.field, getAutocompleteValue(config.value));
      }
    })
  }, [])

  const classes = formName ? `${formName} form` : 'form';

  const getAutocompleteValue = (value: AutocompleteOption | AutocompleteOption[] | null) => {
    if (isArray(value)) {
      return value.map((item: AutocompleteOption) => item.value);
    }

    if (value === null) {
      return null;
    }

    return value.value;
  };

  return (
    <div className="form-container">
      <form className={classes} onSubmit={handleSubmit}>
        <Box sx={{flexGrow: 1}}>
          <Grid container spacing={2} className="form-fields" sx={{marginLeft: '-24px', width: 'calc(100% + 24px)'}}>
            {configs.map((config, index) => {
              if (config.type === TYPE_TEXT) {
                return (
                    <FormGrid config={config} key={config.field + index}>
                      <TextField
                        className="base-form-field"
                        id={config.field}
                        label={
                          <React.Fragment>
                            {t(config.label)}
                            {config.questionMark && config.tooltipTitle && <Tooltip
                              title={config.tooltipTitle}>
                              <SvgIcon
                                component={QuestionIcon}
                                sx={{verticalAlign: 'middle', paddingLeft: '4px'}}
                              />
                            </Tooltip>}
                          </React.Fragment>
                        }
                        variant='outlined'
                        size='small'
                        InputProps={config.props}
                        type={config.inputType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[config.field]}
                        error={Boolean(touched[config.field] && errors[config.field])}
                        helperText={touched[config.field] && <>{errors[config.field]}</>}
                        disabled={config.disabled}
                      />
                    </FormGrid>
                )
              }
              if (config.type === TYPE_BUTTON) {
                return (
                    <FormGrid config={config} key={config.label + config.type + index}>
                      <Button
                        fullWidth
                        type={config.buttonType}
                        disabled={config.buttonType === 'submit' && Object.keys(errors).length !== 0}
                        variant='contained'
                        color='secondary'
                        onClick={config.handleClick}
                        className={config.buttonClass}
                      >
                        {t(config.label)}
                      </Button>
                    </FormGrid>
                )
              }
              if (config.type === TYPE_RADIO) {
                const sx = config.labelFontSize ? {fontSize: config.labelFontSize} : {}
                return (
                    <FormGrid config={config} key={config.type + index}>
                      <FormLabel
                        id={config.field}
                        sx={sx}
                      >
                        {t(config.label)}
                        {config.questionMark && config.tooltipTitle && <Tooltip
                          title={config.tooltipTitle}>
                          <SvgIcon
                            component={QuestionIcon}
                            sx={{verticalAlign: 'middle', paddingLeft: '4px'}}
                          />
                        </Tooltip>}
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby={config.field}
                        row={config.row}
                        defaultValue={config.defaultValue}
                        name={config.field}
                      >
                        {config.radioButtons.map(option => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio/>}
                            label={option.label}
                            onChange={(event, checked) => {
                              if (config.handleClick) {
                                config.handleClick(option.value, checked);
                              }
                            }}
                          />
                        ))}
                      </RadioGroup>
                    </FormGrid>
                )
              }
              if (config.type === TYPE_TYPOGRAPHY) {
                return (
                    <FormGrid config={config} key={config.type + index}>
                      <Typography
                        variant={config.variant}
                        sx={config.styles}
                        align={config.align}
                        onClick={config.handleClick}
                      >
                        {config.label}
                      </Typography>
                    </FormGrid>
                )
              }
              if (config.type === TYPE_SELECT) {
                return (
                    <FormGrid config={config} key={config.type + index}>
                      <FormSelect
                        fieldName={config.field}
                        placeholder={config.placeholder}
                        options={config.options}
                        onBlur={handleBlur}
                        onChangeHandler={(value) => {
                          setFieldValue(config.field, value);
                          config.handleChange && config.handleChange(value);
                        }}
                        questionMark={config.questionMark}
                        tooltipTitle={config.tooltipTitle}
                        error={Boolean(touched[config.field] && errors[config.field])}
                        helperText={touched[config.field] && <>{errors[config.field]}</>}
                        defaultOptionValue={config.defaultValue}
                      />
                    </FormGrid>
                )
              }
              if (config.type === TYPE_AUTOCOMPLETE) {
                return (
                    <FormGrid config={config} key={config.type + index}>
                      <FormAutocomplete
                        id={config.field}
                        asyncAction={config.asyncAction}
                        dataSelector={config.dataSelector}
                        onChange={(event, value) => {
                          setFieldValue(config.field, getAutocompleteValue(value));
                          if (config.onChangeHandler) {
                            config.onChangeHandler(event, value);
                          }
                        }}
                        onBlur={handleBlur}
                        optionValue={config.optionValue}
                        optionLabel={config.optionLabel}
                        multiple={config.multiple}
                        placeholder={config.label}
                        statusSelector={config.statusSelector}
                        questionMark={config.questionMark}
                        tooltipTitle={config.tooltipTitle}
                        error={Boolean(touched[config.field] && errors[config.field])}
                        helperText={touched[config.field] && <>{errors[config.field]}</>}
                        defaultOptionValue={config.defaultValue}
                        disabled={config.disabled}
                      />
                    </FormGrid>
                );
              }
              if (config.type === TYPE_CHECKBOX) {
                return (
                    <FormGrid config={config} key={config.type + index}>
                    <FormControlLabel
                      sx={config.styles}
                      control={<Checkbox/>}
                      id={config.field}
                      label={config.label}
                      defaultValue={config.defaultValue}
                    />
                    </FormGrid>
                )
              }
              if (config.type === TYPE_DIVIDER) {
                return (
                    <FormGrid config={config} key={config.type + index}>
                      <Divider sx={{width: '100%'}}/>
                    </FormGrid>
                )
              }
              if (config.type === 'empty') {
                return (
                  <Grid key={`divider${index}`} item xs={config.col ?? COLUMN_COUNT} className={config.class}
                        sx={{paddingLeft: '24px!important'}}>
                  </Grid>
                )
              }
              if (config.type === TYPE_SELECT_WITH_INPUT) {
                const errorText = touched[config.field] && errors[config.field];
                const hasError = Boolean(touched[config.field] && errors[config.field]);
                return (
                    <FormGrid config={config} key={index}>
                      <BaseSelectWithInput
                          value={config.value}
                          optionsList={config.optionsList}
                          placeholder={config.label}
                          onChange={(event, value: AutocompleteOption[] | AutocompleteOption | null)=>{
                            setFieldValue(config.field, getAutocompleteValue(value));
                            config.onChangeHandler && config.onChangeHandler(event, value);
                          }}
                          disabled={config.disabled}
                          error={hasError}
                          helperText={hasError && String(errorText)}
                      />
                    </FormGrid>
                );
              }
              return null;
            })}
          </Grid>
        </Box>
      </form>
    </div>
  );
}
