import React, {useCallback, useEffect, useState} from 'react';
import TablePage from "../Page/TablePage";
import {useTranslation} from "react-i18next";
import LandingsModal from "./CreateLandingModal/CreateLandingModal";
import {getLandings, selectLanding, selectLandings, updateLanding} from "../../app/reducers/landingsList/landingsSlice";
import Table from "../Table/Table";
import {ColumnConfigWithRender, FieldHandler} from "../Table/types";
import {
    product,
    tag,
    domain,
    funnel,
    landing_id,
    landing_name,
    landings_list_actions,
    lang,
    company,
    status,
    url
} from "./columns";
import {Chip, Typography} from "@mui/material";
import {capitalize, isArray, upperCase} from "lodash";
import CustomIconButton from "../CustomIconButton";
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import IconArchive from "./IconArchive/IconArchive";
import {FilterConfig, FilterConfigType} from "../Table/AppliedFilterBar/types";
import {getSearchProducts, selectProductsStatus, selectSearchProducts} from "../../app/reducers/prism/brandsSlice";
import {getSearchTeams, selectSearchTeams, selectSearchTeamsStatus} from "../../app/reducers/prism/teamsSlice";
import {getSearchUsers, selectSearchUsers, selectUsersStatus} from "../../app/reducers/prism/usersSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    getLandingsListProducts,
    selectLandingsListProducts,
    selectLandingsListProductsObj
} from "../../app/reducers/landingsList/productsSlice";
import {getLandingsListCategories, selectLandingsListCategories} from "../../app/reducers/landingsList/categoriesSlice";
import {getLandingsListLanguages, selectLandingsListLanguages} from "../../app/reducers/landingsList/languagesSlice";
import {availableFunnels, availableStatuses} from "./constants";
import {selectSelfUser} from "../../app/reducers/prism/sessionSlice";
import "./LandingsPage.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import StyledDialog from "../modals/Dialog/StyledDialog";
import {NewLandingData, UpdateLandingBody} from "../../app/reducers/landingsList/types";
import CustomAlert from "../Alert/CustomAlert";

type LandingsPageHandlersList = {
    archiveLanding: FieldHandler<NewLandingData>;
};

function LandingsPage() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectLandings);
    const selfUser = useAppSelector(selectSelfUser);
    const isAdmin = selfUser?.role.alias === 'admin';
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [openMoveToArchiveDialog, setOpenMoveToArchiveDialog] = useState<boolean>(false);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('');
    const [landingId, setLandingId] = useState<string>('');
    const title = t('landings.landing_list');
    const description = t('brands.entity.edit.landings.page.description');
    const landingsListProductsObj = useAppSelector(selectLandingsListProductsObj);
    const landing = useAppSelector(state => selectLanding(state, landingId));
    const slugLength = 30;

    const handleClose = () => {
        setOpenModal(false);
    };

    const stringToColour = (str: string): string => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 12) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }

        return colour + '!important';
    };

    const statusChipColor = (value: string): string => {
        switch (value) {
            case 'active':
                return '#AAFF8C';
            case 'inactive':
                return '#FFA88C';
            case 'archived':
                return '#EAEAEA';
            default:
                return '#F5F4F1';
        }
    };

    const getAvailableFunnels = () => {
        if (!availableFunnels) {
            return [];
        }
        return availableFunnels?.map(funnel => ({name: funnel.name, id: funnel.id}))
    }

    const getAvailableStatuses = () => {
        if (!availableStatuses) {
            return [];
        }
        return availableStatuses?.map(status => ({name: status.name, id: status.id}))
    }

    const handleCloseDialog = () => {
        setOpenMoveToArchiveDialog(false);
    };

    const handleArchiveLanding = useCallback( async () => {
        const data: UpdateLandingBody = {
            id: landingId,
            slug: landing?.slug ?? '',
            productSubdomainId: landing?.productSubdomainId ?? '',
            productId: landing?.productId ?? '',
            funnel: landing?.funnel ?? '',
            language: landing?.language ?? null,
            status: 'archived',
            tag: landing?.tag ?? ''
        }
        const response = await dispatch(updateLanding(data));

        if (response) {
            setOpenMoveToArchiveDialog(false);
            dispatch(getLandings());
            showAlert(t('landings.alert.successfully_archived'));
        }
    }, []);

    const showAlert = (message: string) => {
        setAlertText(message);
        setOpenAlert(true);
        setTimeout(function () {
            setOpenAlert(false);
        }, 3000);
    }

    const buildDomain = (id: string, subdomain: string) => {
        const url = new URL(landingsListProductsObj[id].url);
        return subdomain
                ? url.protocol + '//' + subdomain + '.' + url.hostname
                : url.protocol + '//' + url.hostname;
    }

    useEffect(() => {
        dispatch(getLandingsListProducts());
    }, [dispatch]);

    const filtersConfig: FilterConfig[] = [
        {
            type: FilterConfigType.autocomplete,
            field: 'brands',
            placeholder: t('brands.entity.edit.landings.form.brand'),
            optionLabel: 'name',
            asyncAction: getSearchProducts,
            dataSelector: selectSearchProducts,
            statusSelector: selectProductsStatus,
            multiselect: true,
        },
        {
            type: FilterConfigType.autocomplete,
            field: 'teams',
            placeholder: t('brands.entity.edit.landings.form.team'),
            optionLabel: 'name',
            asyncAction: getSearchTeams,
            dataSelector: selectSearchTeams,
            statusSelector: selectSearchTeamsStatus,
            multiselect: true,
        },
        {
            type: FilterConfigType.autocomplete,
            field: 'modified_by',
            placeholder: t('brands.entity.edit.landings.form.modified_by'),
            optionLabel: 'email',
            asyncAction: getSearchUsers,
            dataSelector: selectSearchUsers,
            statusSelector: selectUsersStatus,
            multiselect: true,
        }
    ];
    const isAdminLandingsTableColumns: ColumnConfigWithRender<NewLandingData, LandingsPageHandlersList>[] = [
        {...landing_id, search: true, bold: true},
        {...landing_name, search: true},
        {
            ...domain,
            // filterable: true,
            idField: 'id',
            labelField: 'value',
            render: (row, cell, refresh, handlers) => {
                return <span>{buildDomain(row.productId, row.productSubdomain)}</span>;
            }
        },
        {
            ...url,
            search: true,
            sortable: false,
            render: (row, cell, refresh, handlers) => {
                const slug = row.slug.length > slugLength ? row.slug.slice(0, slugLength) + '...' : row.slug;
                return <Typography variant="body2" sx={{color: '#009E69'}}>{slug}</Typography>;
            }
        },
        {
            ...product,
            filterable: true,
            idField: 'id',
            labelField: 'name',
            field: 'productId',
            render: (row, cell, refresh, handlers) => {
                return <Chip sx={{
                    height: '24px',
                    lineHeight: '24px',
                    display: 'inline-block',
                    backgroundColor: stringToColour(row.product),
                    textTransform: 'upperCase'
                }} label={capitalize(row.product)}/>;
            },
            getOptionsAction: getLandingsListProducts,
            dataSelector: selectLandingsListProducts
        },
        // {
        //   ...category,
        //   idField: 'value',
        //   filterable: true,
        //   getOptionsAction: getLandingsListCategories,
        //   dataSelector: selectLandingsListCategories
        // },
        {
            ...lang,
            filterable: true,
            render: (row, cell, refresh, handlers) => {
                return row.language ? <span>{row.language.toUpperCase()}</span> : <span>-</span>
            },
            idField: 'value',
            labelField: 'name',
            getOptionsAction: getLandingsListLanguages,
            dataSelector: selectLandingsListLanguages
        },
        {
            ...funnel,
            filterable: true,
            filterOptions: getAvailableFunnels(),
            bold: true,
            render: (row, cell, refresh, handlers) => {
                const res = availableFunnels.find(item => item.id === row.funnel);
                return res?.name ? <span>{res!.name}</span> : <span>-</span>
            }
        },
        {
            ...company,
            idField: 'value',
            // filterable: true,
            getOptionsAction: getLandingsListCategories,
            dataSelector: selectLandingsListCategories,
            render: (row, cell, refresh, handlers) => {
                return row.company ? <span>{String(row.company)}</span> : <span>-</span>
            }
        },
        {
            ...tag,
            search: true,
            render: (row, cell, refresh, handlers) => {
                return row.tag ? <span>{row.tag}</span> : <span>-</span>
            }
        },
        {
            ...status,
            filterable: true,
            render: (row, cell, refresh, handlers) => {
                return <Chip sx={{
                    height: '24px',
                    lineHeight: '24px',
                    display: 'inline-block',
                    backgroundColor: statusChipColor(row.status)
                }} label={capitalize(row.status)}/>;
            },
            filterOptions: getAvailableStatuses()
        },
        {
            ...landings_list_actions,
            sortable: false,
            render: (row, cell, refresh, handlers) => {
                return (
                    <React.Fragment key={`tracker_list_actions-${row.id}`}>
                        <a
                            href={row.tag
                                ? buildDomain(row.productId, row.productSubdomain) + '/' + row.slug + '?' + row.tag
                                : buildDomain(row.productId, row.productSubdomain) + '/' + row.slug}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <CustomIconButton
                                title={t('brands.entity.edit.landings.page.new_window')}
                                component={LaunchIcon}
                            />
                        </a>
                        <CustomIconButton
                            title={t('brands.entity.edit.landings.page.edit')}
                            component={EditIcon}
                            onClick={() => {
                                setOpenModal(true);
                                setIsUpdate(true);
                                setLandingId(row.id);
                            }}
                        />
                        {/*<CustomIconButton*/}
                        {/*    title={t('brands.entity.edit.landings.page.statistic')}*/}
                        {/*    component={IconArchive}*/}
                        {/*    onClick={() => handlers?.archiveLanding(row, cell, () => {})}*/}
                        {/*/>*/}
                    </React.Fragment>
                )
            }
        }
    ];
    const isNotAdminLandingsTableColumns: ColumnConfigWithRender<NewLandingData, LandingsPageHandlersList>[] = [
        {...landing_id, search: true, bold: true},
        {...landing_name, search: true},
        {
            ...domain,
            // filterable: true,
            idField: 'id',
            labelField: 'url',
            render: (row, cell, refresh, handlers) => {
                return <span>{buildDomain(row.productId, row.productSubdomain)}</span>;
            }
        },
        {
            ...url,
            search: true,
            sortable: false,
            render: (row, cell, refresh, handlers) => {
                const slug = row.slug.length > slugLength ? row.slug.slice(0, slugLength) + '...' : row.slug;
                return <Typography variant="body2" sx={{color: '#009E69'}}>{slug}</Typography>;
            }
        },
        {
            ...product,
            filterable: true,
            render: (row, cell, refresh, handlers) => {
                return <Chip sx={{
                    height: '24px',
                    lineHeight: '24px',
                    display: 'inline-block',
                    backgroundColor: stringToColour(row.product),
                    textTransform: 'upperCase'
                }} label={capitalize(row.product)}/>;
            },
            getOptionsAction: getLandingsListProducts,
            dataSelector: selectLandingsListProducts
        },
        {
            ...funnel,
            filterable: true,
            filterOptions: getAvailableFunnels(),
            bold: true,
            render: (row, cell, refresh, handlers) => {
                return row.funnel ? <span>{row.funnel}</span> : <span>-</span>
            },
        },
        {
            ...status,
            filterable: true,
            render: (row, cell, refresh, handlers) => {
                return <Chip sx={{
                    height: '24px',
                    lineHeight: '24px',
                    display: 'inline-block',
                    backgroundColor: statusChipColor(row.status)
                }} label={capitalize(row.status)}/>;
            },
            filterOptions: getAvailableStatuses()
        },
        {
            ...landings_list_actions,
            sortable: false,
            render: (row, cell, refresh, handlers) => {
                return (
                    <React.Fragment key={`tracker_list_actions-${row.id}`}>
                        <a
                            href={row.tag
                                ? buildDomain(row.productId, row.productSubdomain) + '/' + row.slug + '?' + row.tag
                                : buildDomain(row.productId, row.productSubdomain) + '/' + row.slug}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <CustomIconButton
                                title={t('brands.entity.edit.landings.page.new_window')}
                                component={LaunchIcon}
                            />
                        </a>
                        {/*<CustomIconButton*/}
                        {/*    title={t('brands.entity.edit.landings.page.statistic')}*/}
                        {/*    component={IconArchive}*/}
                        {/*    onClick={() => handlers?.archiveLanding(row, cell, () => {})}*/}
                        {/*/>*/}
                    </React.Fragment>
                )
            }
        }
    ];

    return (
        <div className={isAdmin ? "page-left-offset landing-list-admin" : "page-left-offset landing-list-user"}>
            {openModal &&
                <LandingsModal
                    handleClose={handleClose}
                    isUpdate={isUpdate}
                    landingId={landingId}
                    showAlert={showAlert}
                />}
            <TablePage
                title={title}
                description={description}
                createButtonTitle={t('brands.entity.edit.landings.form.form_label')}
                ModalComponent={LandingsModal}
            />
            <Table
                tableName='landing_create'
                columnsConfig={isAdmin ? isAdminLandingsTableColumns : isNotAdminLandingsTableColumns}
                filtersConfig={filtersConfig}
                requestHandler={getLandings}
                data={data}
                uniqueField='id'
                showAlert={showAlert}
                onCreateClick={() => {
                    setOpenModal(true);
                }}
                fieldHandlers={{
                    archiveLanding: (landing, cell, refresh) => {
                        setOpenMoveToArchiveDialog(true);
                        setLandingId(landing.id);
                    }
                }}
            />
            <StyledDialog className="change-redirect-type-modal" open={openMoveToArchiveDialog}
                          onClose={handleCloseDialog}>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    className="close-cross"
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    {t('landings.modals.move_to_archive_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('landings.modals.move_to_archive_subtitle_start') + landing?.name + t('landings.modals.move_to_archive_subtitle_end')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={"BtnPurple"}
                        onClick={handleArchiveLanding}
                    >
                        {t('landings.save')}
                    </Button>
                    <Button
                        className={"BtnGrey"}
                        onClick={handleCloseDialog}
                    >
                        {t("landings.cancel")}
                    </Button>
                </DialogActions>
            </StyledDialog>
            <CustomAlert
                type="success"
                title={alertText}
                status={openAlert}
            />
        </div>
    );
}

export default LandingsPage;