import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {
    fetchProducts,
    fetchCreateLandingFormPackage,
    fetchDeleteLandingFormPackage,
    fetchLandingsByProducts,
    setActivity
} from "../../requests/products";
import {BrandData} from "./types";

export interface ProductsState {
    searchBrands: PaginatedData<BrandData> | null;
    brands: PaginatedData<BrandData> | null;
    searchStatus: RequestStatuses;
    status: RequestStatuses;
}

const initialState: ProductsState = {
    searchBrands: null,
    brands: null,
    searchStatus: RequestStatuses.idle,
    status: RequestStatuses.idle,
};

export const getProducts = createAsyncThunk(
    'products/fetchProducts',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchProducts(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getSearchProducts = createAsyncThunk(
    'products/fetchSearchProducts',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            const defaultFilters = {per_page: '1000'};
            return await fetchProducts({...defaultFilters, ...filters});
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const deleteLandingFromPackage = createAsyncThunk(
    'products/deleteProductPackage',
    async ({packageId, landingId}: { packageId: number, landingId: number }, {rejectWithValue}) => {
        try {
            return await fetchDeleteLandingFormPackage(packageId, landingId);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const createLandingFromPackage = createAsyncThunk(
    'products/createProductPackage',
    async ({packageId, landingId, body}: { packageId: number, landingId: number, body: any }, {rejectWithValue}) => {
        try {
            return await fetchCreateLandingFormPackage(packageId, landingId, body);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getLandingsByProduct = createAsyncThunk(
    'products/getLandingsByProduct',
    async ({brandId, filters}: { brandId: string, filters?: UrlParams }, {rejectWithValue}) => {
        try {
            return await fetchLandingsByProducts(brandId, filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

type SetBrandActivityArguments = { brandId: number, isActive: boolean, name: string, url: string, help_url: string };

export const setProductActivity = createAsyncThunk(
    'products/setProductActivity',
    async ({brandId, isActive, name, url, help_url}: SetBrandActivityArguments) => {
        return await setActivity(brandId, isActive, name, url, help_url);
    }
)

export const brandsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.brands = action.payload;
            })
            .addCase(getSearchProducts.pending, (state) => {
                state.searchStatus = RequestStatuses.loading;
            })
            .addCase(getSearchProducts.fulfilled, (state, action) => {
                state.searchStatus = RequestStatuses.success;
                state.searchBrands = action.payload;
            })
            .addCase(setProductActivity.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(setProductActivity.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
            })
    },
});

export const selectSearchProducts = (state: RootState) => state.brands.searchBrands?.data ?? [];
export const selectProductsStatus = (state: RootState) => state.brands.status;
export const selectProducts = (state: RootState) => state.brands.brands;

export default brandsSlice.reducer;
