export const additionalFilterIsEp = [
    {
        filterName: "isEp",
        filters: [
            {
                id: "true",
                name: "true",
                checked: false
            },
            {
                id: "false",
                name: "false",
                checked: false
            }
        ]
    }
]