import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {DataRow, RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {fetchTeams, searchTeams, fetchTeamsFromPrism} from "../../requests/teams";
import {UserTeam, SearchTeam} from "./types";

export interface TeamsState {
  searchTeams: PaginatedData<UserTeam> | null;
  searchTeamsFromPrism: DataRow[] | [];
  searchTeamsByName: SearchTeam[] | [];
  status: RequestStatuses;
  searchTeamsByNameStatus: RequestStatuses;
  searchTeamsFromPrismStatus: RequestStatuses;
}

const initialState: TeamsState = {
  searchTeams: null,
  searchTeamsByName: [],
  searchTeamsFromPrism: [],
  status: RequestStatuses.idle,
  searchTeamsByNameStatus: RequestStatuses.idle,
  searchTeamsFromPrismStatus: RequestStatuses.idle,
};

export const getSearchTeams = createAsyncThunk(
  'teams/fetchTeams',
  async (filters: UrlParams | undefined, { rejectWithValue }) => {
    try {
      return await fetchTeams(filters);
    } catch (err) {
      return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
    }
  }
);

export const getSearchTeamsFromPrism = createAsyncThunk(
  'teams/fetchTeamsFromPrism',
  async (filters: UrlParams | undefined, { rejectWithValue }) => {
    try {
        return await fetchTeamsFromPrism(filters);
    } catch (err) {
      return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
    }
  }
);

export const searchTeamsByName = createAsyncThunk(
    'teams/searchTeamsByName',
    async (name: string, { rejectWithValue }) => {
      try {
        return await searchTeams(name);
      } catch (err) {
        return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
      }
    }
);

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSearchTeams.pending, (state) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(getSearchTeams.fulfilled, (state, action) => {
        state.status = RequestStatuses.success;
        state.searchTeams = action.payload;
      })
      .addCase(getSearchTeamsFromPrism.pending, (state) => {
        state.searchTeamsFromPrismStatus = RequestStatuses.loading;
      })
      .addCase(getSearchTeamsFromPrism.fulfilled, (state, action) => {
        state.searchTeamsFromPrismStatus = RequestStatuses.success;
        state.searchTeamsFromPrism = action.payload;
      })
      .addCase(searchTeamsByName.pending, (state) => {
        state.searchTeamsByNameStatus = RequestStatuses.loading;
      })
      .addCase(searchTeamsByName.fulfilled, (state, action) => {
        state.searchTeamsByNameStatus = RequestStatuses.success;
        state.searchTeamsByName = action.payload;
      });

  },
});

export const selectSearchTeams = (state: RootState) => state.teams.searchTeams?.data ?? [];
export const selectSearchTeamsFromPrism = (state: RootState) => state.teams.searchTeamsFromPrism;
export const selectSearchTeamsStatus = (state: RootState) => state.teams.status;
export const selectSearchTeamsByName = (state: RootState) => state.teams.searchTeamsByName;
export const selectSearchTeamsByNameStatus = (state: RootState) => state.teams.searchTeamsByNameStatus;

export default teamsSlice.reducer;
