import {DataRow, RequestStatuses, UrlParams} from "../../../Table/types";
import {AsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import {BaseAutocomplete} from "../../../inputs/baseAutocomplete/BaseAutocomplete";
import React from "react";
import {AutocompleteOption} from "../../../inputs/baseAutocomplete/types";

interface AutocompleteFormProps {
    id: string;
    onChange: (
        event: React.SyntheticEvent,
        value: AutocompleteOption[] | AutocompleteOption | null
    ) => void;
    onBlur: (e: React.FocusEvent<any>) => void,
    placeholder: string;
    optionLabel: string;
    optionValue?: string;
    asyncAction: AsyncThunk<any, UrlParams | undefined, any>
    dataSelector: (state: RootState) => DataRow[] | null
    multiple?: boolean;
    statusSelector: (state: RootState) => RequestStatuses;
    questionMark?: boolean;
    tooltipTitle?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    defaultOptionValue?: Array<string | number | null>;
    disabled?: boolean;
    paperWidth?: number;
}

export function FormAutocomplete(props: AutocompleteFormProps) {
    const {
        onChange,
        optionLabel,
        optionValue = 'id',
        dataSelector,
        statusSelector,
        asyncAction,
        placeholder,
        multiple,
        onBlur,
        questionMark = false,
        tooltipTitle = '',
        error,
        helperText,
        defaultOptionValue,
        disabled,
        paperWidth
    } = props;

    return (
        <BaseAutocomplete
            onChange={onChange}
            onBlur={onBlur}
            optionLabel={optionLabel}
            optionValue={optionValue}
            asyncAction={asyncAction}
            dataSelector={dataSelector}
            statusSelector={statusSelector}
            multiple={multiple}
            placeholder={placeholder}
            questionMark={questionMark}
            tooltipTitle={tooltipTitle}
            error={error}
            helperText={helperText}
            sx={{width: '100%'}}
            defaultOptionValue={defaultOptionValue}
            disabled={disabled}
            paperWidth={paperWidth}
        />
    )
}