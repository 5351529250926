import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createVisits} from "../../requests/visits";
import {GeneralTrackerRequestBody} from "../../../componets/Smartlink/ModalRuleConfiguration/type";

export interface VisitsState {
    visitsCreateUpdateStatus: RequestStatuses;
    visitsCreateUpdate: { body :{ x_visits: GeneralTrackerRequestBody[] }} | null;
}

const initialState: VisitsState = {
    visitsCreateUpdate:  null,
    visitsCreateUpdateStatus: RequestStatuses.idle,
};

export const visitsCreateUpdate = createAsyncThunk(
    'visits/visitsCreateUpdate',
    async ({RuleId, body}: { RuleId: number, body: { x_visits: GeneralTrackerRequestBody[] } }) => {
        return await createVisits(body, RuleId);
    }
);


export const visitsSlice = createSlice({
    name: 'visits',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(visitsCreateUpdate.pending, (state) => {
                state.visitsCreateUpdateStatus = RequestStatuses.loading;
            })
            .addCase(visitsCreateUpdate.fulfilled, (state, action) => {
                state.visitsCreateUpdateStatus = RequestStatuses.success;
                state.visitsCreateUpdate = action.payload;
            })
    },
});

export default visitsSlice.reducer;