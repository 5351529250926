import {UrlParams} from "../../../componets/Table/types";

import {landingsListApi} from "../../store";
import {CreateLandingBody, UpdateLandingBody} from "../../reducers/landingsList/types";

const fetchLandings = async (filters?: UrlParams) => {
    if (filters?.hasOwnProperty('page')) {
        filters.pageNumber = filters.page;
        delete filters.page;
    }
    if (filters?.hasOwnProperty('per_page')) {
        filters.pageSize = filters.per_page;
        delete filters.per_page;
    }
    if (filters?.hasOwnProperty('sort_field')) {
        filters.sortField = filters.sort_field;
        delete filters.sort_field;
    }
    if (filters?.hasOwnProperty('sort_order')) {
        filters.sortOrder = filters.sort_order.toUpperCase();
        delete filters.sort_order;
    }
    return landingsListApi.get('/core/v1/landings', {...filters});
}

const createLandingRequest = async (body: CreateLandingBody) => {
    return createLanding(body);
}

const createLanding = async (params?: CreateLandingBody) => {
    return landingsListApi.post(`/core/v1/landings`, undefined, params);
};

const updateLandingRequest = async (body: UpdateLandingBody) => {
    return updateLanding(body);
}

const updateLanding = async (params?: UpdateLandingBody) => {
    return landingsListApi.put(`/core/v1/landings/${params?.id}`, undefined, params);
};

export { fetchLandings, createLandingRequest, updateLandingRequest };