import qs from "query-string";
import React from "react";
import {AsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {SelectOption} from "./filters/types";

export enum RequestStatuses {
    'idle' = 'idle',
    'loading' = 'loading',
    'success' = 'success',
    'failed' = 'failed'
}

export type Order = 'asc' | 'desc';

export type StringKeys<T> = Extract<keyof T, string>;

export type DataRow = {
    [key: string]: string | number | boolean | object | object[] | null | undefined;
}

export interface DataColumn<Data> {
    field: StringKeys<Data>;
    searchField?: StringKeys<Data>;
    headerName: string;
    sortable?: boolean;
    filterable?: boolean;
    filterType?: 'autocomplete' | 'select';
    tooltipTitle?: string;
    questionMark?: boolean;
    search?: boolean;
    noHeader?: boolean;
    bold?: boolean;
    getOptionsAction?: AsyncThunk<any, UrlParams | undefined, any>;
    dataSelector?: (state: RootState) => DataRow[] | null;
    idField?: string;
    labelField?: string;
    filterOptions?: SelectOption[];
}

export interface SearchConfig<Data> {
    field: StringKeys<Data>;
    placeholder?: string;
    headerName: string;
}

export interface FilterConfig<Data> {
    field: StringKeys<Data>;
    headerName: string;
}

export interface BaseColumn {
    numeric?: boolean;
    customisable: boolean;
}

export interface ColumnConfig<T = DataRow> extends DataColumn<T>, BaseColumn {
}

export type HandlerCondition<Data, Handlers> = Handlers extends undefined ? FieldHandlers<Data> : Handlers;

export interface ColumnConfigWithRender<Data, Handlers = undefined> extends DataColumn<Data>, BaseColumn {
    render?: (
        row: Data,
        cell: ColumnConfig<Data>,
        refresh: () => void,
        handlers?: HandlerCondition<Data, Handlers>,
    ) => JSX.Element
}

export type FieldHandler<Data, ClosureType = undefined> = (
        row: Data,
        cell: ColumnConfig<Data>,
        refreshHandler: () => void
    ) => ClosureType extends undefined ? void : ClosureType

export type FieldHandlers<Data = DataRow> = {
    [key: string]: FieldHandler<Data>
}

export type AppFilterValue = string | number | null;
export type AppFilters = { [key: string]: AppFilterValue }
export type ClearedParsedQuery = {
    [key: string]: Exclude<keyof qs.ParsedQuery, Array<string | null>>
}
export type UrlParams = { [key: string]: string };

export type MouseEvent = React.MouseEvent<HTMLButtonElement>;
export type SetState<T> = React.Dispatch<React.SetStateAction<T>>
