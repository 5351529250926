import {styled} from "@mui/material/styles";
import {Box, BoxProps} from "@mui/material";

export const SimpleTableStyled = styled(Box)<BoxProps>(({theme}) => ({
    "table": {
        borderCollapse: "collapse",
        border: "none",
        width: "calc(100% - 48px)",
        color: theme.palette.text.primary,
        margin: "0 24px 24px 24px",
    },
    "tr": {
        borderBottom: "1px solid",
        borderColor: theme.palette.secondary.main,
    },
    "thead tr": {
        borderTop: "1px solid",
        borderColor: theme.palette.secondary.main,
    },
    "td": {
        height: 55,
        borderLeft: "none",
        borderRight: "none",
        textAlign: "left",
        padding: "0 24px",
        boxSizing: "border-box",
        fontSize: "16px",
        fontWeight: 400,
        ".green": {
            color: theme.palette.success.main,
            textDecoration: "underline",
        }
    },
    "th": {
        height: 55,
        borderLeft: "none",
        borderRight: "none",
        textAlign: "left",
        boxSizing: "border-box",
        ".cell-container": {
            position: "relative",
            padding: "0 24px",
            fontWeight: 500,
        },
        " + th": {
            ".cell-container": {
                "::before": {
                    content: "''",
                    height: "14px",
                    width: "2px",
                    position: "absolute",
                    left: 0,
                    top: "36%",
                    backgroundColor: theme.palette.other.divider,
                }
            }
        }
    },

}));