export const COLUMN_COUNT = 12;
export const TYPE_TEXT = 'text';
export const TYPE_RADIO = 'radio';
export const TYPE_CHECKBOX = 'checkbox';
export const TYPE_TYPOGRAPHY = 'typography';
export const TYPE_SELECT = 'select';
export const TYPE_AUTOCOMPLETE = 'autocomplete';
export const TYPE_BUTTON = 'button';
export const TYPE_DIVIDER = 'divider';
export const TYPE_TOGGLE = 'toggle';
export const TYPE_SELECT_WITH_INPUT = 'selectWithInput';
