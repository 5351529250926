import React, {useState, Dispatch, useEffect} from 'react';
import {AppFilters, UrlParams} from "../../types";
import {ControlBarConfig} from "../types";
import {Box} from '@mui/material/';
import './ControlBar.scss';
import {useTranslation} from "react-i18next";
import Button from '@mui/material/Button';
import MultiSelect, {ItemProps} from "../../../MultiSelect/MultiSelect";
import DownloadIcon from '@mui/icons-material/Download';
import DropdownTransferList from "../../../DropdownTransferList/DropdownTransferList";
import BaseSelectWithInput from "../../../inputs/BaseSelectWithInput";
import {statisticsSearchPeriod, displayedFieldsList} from "../../../Statistics/constants";
import {AutocompleteOption} from "../../../inputs/baseAutocomplete/types";
import {createReport} from "../../../../app/reducers/prism/reportSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import moment from "moment";
import {DateTimeInput} from "../../filterInputs/DateTimePicker/DateTimeInput";
import {FiltersFromObj} from "../../AppliedFilterBar/types";
import {AdditionalFilters, ReportDataType} from "./types";
import {
    selectDictionaryCompanies,
    selectDictionaryProducts,
    selectDictionaryOs,
    selectDictionaryGeos,
    selectDictionaryRegions,
    selectDictionaryPlatforms,
    selectDictionaryBrowsers,
    selectDictionaryLanguages,
    selectDictionaryTrafficTypes,
    selectDictionaryTeams,
    selectDictionaryLandings,
    selectDictionarySmartlinks,
    getDictionaryRegions,
    getDictionaryPlatforms,
    getDictionaryBrowsers,
    getDictionaryLanguages,
    getDictionaryTrafficTypes,
    getDictionaryCompanies,
    getDictionaryProducts,
    getDictionaryTeams,
    getDictionaryOs,
    getDictionaryLandings,
    getDictionarySmartlink,
    getDictionaryGeos
} from "../../../../app/reducers/prism/dictionariesSlice";
import {Dictionary} from "../../../../app/reducers/prism/types";
import {additionalFilterIsEp} from "../../../DropdownTransferList/constants";
import {selectSelfUser} from "../../../../app/reducers/prism/sessionSlice";
import {CustomizeControlBar} from "../CustomizeControlBar/CustomizeControlBar";
import SettingsIcon from "@mui/icons-material/Settings";
import CustomAlert from "../../../Alert/CustomAlert";

interface FiltersProps<Data> {
    controlBarConfig: ControlBarConfig[];
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    tableColumns?: ItemProps[];
    setStatisticTableColumns?: Dispatch<ItemProps[]>;
    setIsHideTableBody?: Dispatch<boolean>;
    setObjFilters: Dispatch<FiltersFromObj>;
    objFilters: FiltersFromObj;
    setClearObjFilters: Dispatch<boolean>;
    clearObjFilters: boolean;
    filtersListToRemove: FiltersFromObj;
    setReportAdditionalFilters: Dispatch<{[key: string]: any}>;
    page: number;
    setPage: Dispatch<number>;
    resetControlBar: () => void;
    tableName: string;
    bufferColList: { [key: string]: number };
    customizeTable: () => void;
    state: string;
}

export function ControlBarStatistics<Data>(props: FiltersProps<Data>) {
    const {
        urlFilters,
        addFilters,
        tableColumns,
        setStatisticTableColumns,
        setIsHideTableBody,
        setObjFilters,
        objFilters,
        setClearObjFilters,
        clearObjFilters,
        filtersListToRemove,
        setReportAdditionalFilters,
        page,
        setPage,
        resetControlBar,
        tableName,
        bufferColList,
        customizeTable,
        state
    } = props;
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const selfUser = useAppSelector(selectSelfUser);
    const isAdmin = selfUser?.role.alias === 'admin';
    const [searchPeriodName, setSearchPeriodName] = useState<AutocompleteOption | AutocompleteOption[] | null>(null);
    const [searchPeriodValue, setSearchPeriodValue] = useState<string[]>([]);
    const [isCustomPeriod, setIsCustomPeriod] = useState<boolean>(false);
    const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilters[]>([...additionalFilterIsEp]);
    const [groupFields, setGroupFields] = useState<string[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [items, setItems] = useState<ItemProps[]>([]);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [showCustomizeSavedAlert, setShowCustomizeSavedAlert] = useState<boolean>(false);
    const dictionaryTrafficTypes = useAppSelector(selectDictionaryTrafficTypes);
    const dictionaryPlatforms = useAppSelector(selectDictionaryPlatforms);
    const dictionaryCompanies = useAppSelector(selectDictionaryCompanies);
    const dictionaryBrowsers = useAppSelector(selectDictionaryBrowsers);
    const dictionaryOs = useAppSelector(selectDictionaryOs);
    const dictionaryLanguages = useAppSelector(selectDictionaryLanguages);
    const dictionaryProducts = useAppSelector(selectDictionaryProducts);
    const dictionaryRegions = useAppSelector(selectDictionaryRegions);
    const dictionaryTeams = useAppSelector(selectDictionaryTeams);
    const dictionaryGeos = useAppSelector(selectDictionaryGeos);
    const dictionaryLandings = useAppSelector(selectDictionaryLandings);
    const dictionarySmartlinks = useAppSelector(selectDictionarySmartlinks);

    const onDateChange = (fromOrTo: 'dateFrom' | 'dateTo') => (value: string | null) => {
        if (value) {
            setSearchPeriodValue((prevState) => {
                return fromOrTo === 'dateFrom' ? [value, prevState[1]] : [prevState[0], value];
            });
        }
    };

    const changeSearchPeriod = () => {
        if (!searchPeriodName) {
            setIsCustomPeriod(false);
            setSearchPeriodValue([]);
            return;
        }

        const periodData: {today: string[], last_day: string[], last_7_days: string[], last_week: string[], last_30_days: string[], last_month: string[], custom_period: string[], default: never[]} = {
            today: [
                moment().format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            ],
            last_day: [
                moment().subtract(1, 'd').format('YYYY-MM-DD'),
                moment().subtract(1, 'd').format('YYYY-MM-DD')
            ],
            last_7_days: [
                moment().subtract(1, 'w').format('YYYY-MM-DD'),
                moment().subtract(1, 'd').format('YYYY-MM-DD')
            ],
            last_week: [
                moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
            ],
            last_30_days: [
                moment().subtract(30, 'd').format('YYYY-MM-DD'),
                moment().subtract(1, 'd').format('YYYY-MM-DD')
            ],
            last_month: [
                moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            ],
            custom_period: [
                moment().subtract(1, 'd').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            ],
            default: []
        };

        const periodValue = periodData[searchPeriodName['value' as keyof typeof searchPeriodName]] || periodData.default;
        setSearchPeriodValue(periodValue);
        setIsCustomPeriod(searchPeriodName['value' as keyof typeof searchPeriodName] === 'custom_period');
    };

    const createOptionsSearchPeriod = (arr: string[]) => {
        return arr.map(period => {
            return {value: period, label: t(`statistic.search_period.${period}`)}
        })
    }

    const transformToAdditionalFiltersData = (dictionary: Dictionary[]) => {
        return dictionary.map(item => {
            return {
                ...item,
                checked: false
            }
        })
    }

    const getCheckedFilters = (filterGroups: AdditionalFilters[]) => {
        const checkedFilters: FiltersFromObj = {};

        filterGroups.forEach((group) => {
            const groupName = group.filterName;
            checkedFilters[groupName] = [];

            group.filters.forEach((filter) => {
                if (filter.checked) {
                    checkedFilters[groupName].push(filter.name);
                    // checkedFilters[groupName].push(filter.id);
                }
            });
        });

        return checkedFilters;
    };

    const updateCheckedFilters =(filtersStage: AdditionalFilters[], checkedFilter: FiltersFromObj) => {
        return  filtersStage.map((filter) => {
            const name = filter.filters.find((f) => checkedFilter.name.includes(f.name));
            if (name) {
                return {
                    ...filter,
                    filters: filter.filters.map((f) =>
                        f.name === name.name ? { ...f, checked: false } : f
                    )
                };
            } else {
                return filter;
            }
        });
    }

    const transformDictionaryValues = (dictionary: Dictionary[]) => {
        return dictionary.map(item => ({id: item.id, name: item.name.toLowerCase()}));
    }

    const handleCustomizeTable = () => {
        setIsHideTableBody && setIsHideTableBody(true);
        customizeTable();
        setIsFirstLoad(false);
    }

    const handleResetControlBar = () => {
        resetControlBar();
        setIsHideTableBody && setIsHideTableBody(false);
    }

    const getDisplayedFields = () => {
        const tableList = JSON.parse(localStorage.getItem('tableList') || '[]');
        const curList = tableList[tableName] || {};
        const objProps = Object.keys(curList);

        if (Object.keys(curList).length === 0) {
            return null;
        }

        return displayedFieldsList.filter((field) => !objProps.includes(field));
    };

    const handleRunReport = async () => {
        setPage(1);
        const data: ReportDataType = {
            filters: {},
            displayedFields: getDisplayedFields(),
            groupFields: [],
            ...urlFilters
        };

        if(!!searchPeriodValue.length) {
            data.filters = {
                ...data.filters,
                date: searchPeriodValue
            }
        }

        for(let prop in objFilters) {
            if(!!objFilters[prop].length) {
                prop === 'isEp'
                    ? data.filters = {
                        ...data.filters,
                        [prop]: objFilters[prop].map(item => (item === 'true'))
                    }
                    : data.filters = {
                        ...data.filters,
                        [prop]: objFilters[prop]
                    }

            }
        }

        if (groupFields.length) {
            data.groupFields = groupFields;
        }

        setIsButtonDisabled(true);
        const response = await dispatch(createReport(data));
        if (response) {
            setIsButtonDisabled(false);
            setIsDataLoaded(true);
        }
        if (response.meta.requestStatus === 'fulfilled') {
            setIsHideTableBody && setIsHideTableBody(false);
        }
    }

    useEffect(() => {
        changeSearchPeriod();
    }, [searchPeriodName]);

    useEffect(() => {
        if (clearObjFilters) {
            setAdditionalFilters((prevState) => {
                return prevState.map(filterBlock => {
                    filterBlock.filters = filterBlock.filters.map(filter => {
                        filter.checked = false;
                        return filter;
                    })
                    return filterBlock
                })
            });
            setClearObjFilters(false);
        }
    }, [clearObjFilters])

    useEffect(() => {
        if (dictionaryProducts) {
            setAdditionalFilters(prevState => {
                const hasProductNameFilter = prevState.some(item => item.filterName === 'productName');
                const newFilters = transformToAdditionalFiltersData(dictionaryProducts);

                if (!hasProductNameFilter) {
                    return [
                        ...prevState,
                        {
                            filterName: 'productName',
                            filters: newFilters
                        }
                    ];
                } else {
                    return prevState.map(item => {
                        if (item.filterName === 'productName' && item.filters !== newFilters) {
                            return {
                                filterName: 'productName',
                                filters: newFilters
                            };
                        }
                        return item;
                    });
                }
            });
        }
        if (dictionaryCompanies && isAdmin) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'companyName').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'companyName',
                            filters: transformToAdditionalFiltersData(dictionaryCompanies)
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryOs) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'os').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'os',
                            filters: transformToAdditionalFiltersData(transformDictionaryValues(dictionaryOs))
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryGeos) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'countryCode').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'countryCode',
                            filters: transformToAdditionalFiltersData(dictionaryGeos)
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryRegions) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'region').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'region',
                            filters: transformToAdditionalFiltersData(dictionaryRegions)
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryPlatforms) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'platform').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'platform',
                            filters: transformToAdditionalFiltersData(transformDictionaryValues(dictionaryPlatforms))
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryBrowsers) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'browser').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'browser',
                            filters: transformToAdditionalFiltersData(dictionaryBrowsers)
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryLanguages) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'language').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'language',
                            filters: transformToAdditionalFiltersData(dictionaryLanguages)
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryTrafficTypes) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'trafficType').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'trafficType',
                            filters: transformToAdditionalFiltersData(transformDictionaryValues(dictionaryTrafficTypes))
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryTeams) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'teamName').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'teamName',
                            filters: transformToAdditionalFiltersData(dictionaryTeams)
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionaryLandings) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'landingName').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'landingName',
                            filters: transformToAdditionalFiltersData(dictionaryLandings)
                        }
                    ];
                }
                return [...prevState];
            });
        }
        if (dictionarySmartlinks) {
            setAdditionalFilters(prevState => {
                if (!prevState.filter(item => item.filterName === 'smartlinkName').length) {
                    return [
                        ...prevState,
                        {
                            filterName: 'smartlinkName',
                            filters: transformToAdditionalFiltersData(dictionarySmartlinks)
                        }
                    ];
                }
                return [...prevState];
            });
        }
    }, [
        dictionaryProducts,
        dictionaryCompanies,
        dictionaryOs,
        dictionaryGeos,
        dictionaryRegions,
        dictionaryPlatforms,
        dictionaryBrowsers,
        dictionaryLanguages,
        dictionaryTrafficTypes,
        dictionaryTeams,
        dictionaryLandings,
        dictionarySmartlinks
    ]);

    useEffect(() => {
        dispatch(getDictionaryProducts());
        dispatch(getDictionaryCompanies());
        dispatch(getDictionaryOs());
        dispatch(getDictionaryRegions());
        dispatch(getDictionaryPlatforms());
        dispatch(getDictionaryBrowsers());
        dispatch(getDictionaryLanguages());
        dispatch(getDictionaryTrafficTypes());
        dispatch(getDictionaryTeams());
        dispatch(getDictionaryLandings());
        dispatch(getDictionarySmartlink());
        dispatch(getDictionaryGeos());
    }, [dispatch]);

    useEffect(() => {
        setObjFilters(getCheckedFilters(additionalFilters));
    }, [additionalFilters]);

    useEffect(() => {
        if (!!Object.keys(filtersListToRemove).length) {
            setAdditionalFilters(updateCheckedFilters(additionalFilters, filtersListToRemove));
        }
    }, [filtersListToRemove]);

    useEffect(() => {
        const data: ReportDataType = {
            filters: {},
            displayedFields: null,
            groupFields: groupFields
        };

        if (!!searchPeriodValue.length) {
            data.filters = {
                ...data.filters,
                date: searchPeriodValue
            }
        }

        for (let prop in objFilters) {
            if (!!objFilters[prop].length) {
                data.filters = {
                    ...data.filters,
                    [prop]: objFilters[prop]
                }
            }
        }

        setReportAdditionalFilters(data)
    }, [groupFields, searchPeriodValue, objFilters]);

    useEffect(() => {
        if (isDataLoaded) {
            setStatisticTableColumns && setStatisticTableColumns(items);
            setIsDataLoaded(false);
        }
    }, [isDataLoaded]);

    useEffect(() => {
        if (showCustomizeSavedAlert) {
            setTimeout(function () {
                setShowCustomizeSavedAlert(false);
            }, 3000);
        }
    }, [showCustomizeSavedAlert]);

    if (state === "customize") {
        return (
            <CustomizeControlBar
                resetControlBar={handleResetControlBar}
                bufferColList={bufferColList}
                tableName={tableName}
                setShowSavedAlert={setShowCustomizeSavedAlert}
            />
        )
    }

    return (
        <Box>
            <Box className="statistics-filters ControlBar filters-bar">
                <div className="statistics-filters-left">
                    <BaseSelectWithInput
                        value={searchPeriodName}
                        className="statistics-filters-search-period"
                        optionsList={createOptionsSearchPeriod(statisticsSearchPeriod)}
                        placeholder={t("statistic.filters.select_period")}
                        onChange={(event, value) => {
                            setSearchPeriodName(value);
                        }}
                    />
                    {isCustomPeriod && (<>
                        <DateTimeInput
                            key='from'
                            label={t('common.filters.from')}
                            disableFuture={false}
                            onChangeHandler={onDateChange('dateFrom')}
                            defaultDate={moment().subtract(1, 'd')}
                            minDate={moment().subtract(6, 'months')}
                        />
                        <DateTimeInput
                            key='to'
                            label={t('common.filters.to')}
                            disableFuture={false}
                            onChangeHandler={onDateChange('dateTo')}
                            defaultDate={moment()}
                        />
                    </>)}
                    <DropdownTransferList
                        setAdditionalFilters={setAdditionalFilters}
                        additionalFilters={additionalFilters}
                    />
                </div>
                <div className="statistics-filters-actions">
                    <Button
                        className="BtnPurpleOutlined statistics-btn"
                        startIcon={<SettingsIcon/>}
                        onClick={handleCustomizeTable}
                    >
                        {t('brands.entity.edit.landings.page.customize_table')}
                    </Button>
                    {/*<Button*/}
                    {/*    className="BtnGrey statistics-btn-grey"*/}
                    {/*>*/}
                    {/*    {t('statistic.actions.export_report')}*/}
                    {/*    <DownloadIcon/>*/}
                    {/*</Button>*/}
                    <Button
                        className="BtnPurple statistics-btn"
                        onClick={handleRunReport}
                        disabled={isButtonDisabled}
                        type="button"
                    >
                        {t('statistic.actions.run_report')}
                    </Button>
                </div>
            </Box>
            <div className="group-by-wrap">
                <p className="group-by-wrap-label">Group by</p>
                <MultiSelect
                    optionsProps={tableColumns!}
                    maxCountChips={4}
                    onChangeEvent={setItems}
                    setGroupFields={setGroupFields}
                    isFirstLoad={isFirstLoad}
                    loadedOptions={items}
                />
            </div>
            <CustomAlert
                type="success"
                title={t('statistic.customize_saved')}
                status={showCustomizeSavedAlert}
                zIndex="11111"
            />
        </Box>
    )
}
