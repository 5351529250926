import React, {Dispatch} from 'react';
import {AppFilters} from "../types";
import './AppliedFilterBar.scss';
import Chip from "@mui/material/Chip";
import {FiltersFromObj} from "./types";

interface FiltersProps {
    fieldName: string;
    urlFilter: string;
    addFilters: (filters: AppFilters) => void;
    setFiltersListToRemove?: Dispatch<FiltersFromObj>;
}

export function FilterTitleChip(props: FiltersProps) {
    const {fieldName, urlFilter, addFilters, setFiltersListToRemove} = props;
    const keySet = urlFilter.split(',');
    const handleDelete = (name: string) => {
        setFiltersListToRemove ? setFiltersListToRemove({name: [urlFilter]}) : addFilters({[name]: null});
    }

    return (<div>
        <Chip
            className="filter-chip"
            label={urlFilter}
            onDelete={() => handleDelete(fieldName)}
        />
    </div>)
}
