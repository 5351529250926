import React, {Dispatch, useCallback, useEffect, useState} from 'react';
import './RuleTree.scss';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Node, RelationsData, TransformedNode} from "../types";
import DarkTooltip from "../../DarkTooltip/DarkTooltip";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {typesRules} from "../types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RuleTree from "./RuleTree";
import BaseSelectWithInput from "../../inputs/BaseSelectWithInput";
import {AutocompleteOption} from "../../inputs/baseAutocomplete/types";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {isSingle} from "../ModalRuleConfiguration/ruleHelpers";
import {DataFromBaseCompilation} from "./DataFromBaseCompilation";
import {AdditionalFiltersArr} from "../../Table/ControlBarWrapper/ControlBarStatistics/types";
import {TrackerData} from "../../../app/reducers/prism/types";
import SmartlinkRuleFormBlock from "../SmartlinkRuleFormBlock/SmartlinkRuleFormBlock";

interface RuleTreeProps {
    treeData: { nodes: Node[], relations: RelationsData[] } | null;
    handleAddRule: (id: number, type: string) => void;
    handleEditRule: (node: Node) => void;
    trackerId: number;
    setUpdateTree: Dispatch<React.SetStateAction<number>>;
    setSingle: Dispatch<React.SetStateAction<boolean>>;
    single: boolean;
    showRuleForm: boolean;
    parentType: string | null;
    parentId: number | null;
    activeRuleValue: AutocompleteOption | AutocompleteOption[] | null;
    setActiveRuleValue: Dispatch<React.SetStateAction<AutocompleteOption | AutocompleteOption[] | null>>;
    activeRule: string | null;
    setActiveRule: Dispatch<React.SetStateAction<string | null>>;
    isMultipleTypes: () => boolean;
    handleInputCreate: (value: string) => void;
    nodeTypesOptions: AutocompleteOption[];
    nodeTypesValue: AutocompleteOption | AutocompleteOption[] | null;
    setNodeTypesValue: Dispatch<React.SetStateAction<AutocompleteOption | AutocompleteOption[] | null>>;
    setNodeTypes: Dispatch<React.SetStateAction<string>>;
    ruleEdit: Node | null;
    scheduleEdit: TrackerData | null;
    isCreateNewRule: boolean;
}

const RuleTreeFlow: React.FC<RuleTreeProps> = (props): JSX.Element => {
    const {t} = useTranslation();
    const {
        treeData,
        handleAddRule,
        handleEditRule,
        trackerId,
        setUpdateTree,
        setSingle,
        single,
        showRuleForm,
        parentType,
        parentId,
        activeRuleValue,
        setActiveRuleValue,
        activeRule,
        setActiveRule,
        isMultipleTypes,
        handleInputCreate,
        nodeTypesOptions,
        nodeTypesValue,
        setNodeTypesValue,
        ruleEdit,
        scheduleEdit,
        setNodeTypes,
        isCreateNewRule
    } = props;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [flowState, setFlowState] = useState<boolean[]>([]);
    const [treeDataStructured, setTreeDataStructured] = useState<TransformedNode[]>([]);
    const [rule, setRule] = useState<Node>();
    const [nodeId, setNodeId] = useState<number>(0);

    useEffect(() => {
        setSingle(treeData === null ? false : isSingle(treeData.nodes))
        if (treeData !== null && treeData.nodes.length > 0) {
            const newData = DataFromBaseCompilation(treeData.nodes);
            if (JSON.stringify(newData) !== JSON.stringify(treeDataStructured)) {
                setTreeDataStructured(newData);
            }
        }
    }, [treeData]);

    const changeFlowState = (id: number) => {
        const newStates = [...flowState]
        newStates[id] = newStates[id] === undefined || newStates[id] === true ? false : true;
        setFlowState(newStates);
    }

    const isNodeIdPresent = (node: TransformedNode, id: number): boolean => {
        if (node.details && node.details.id === id) {
            return true;
        }

        if (node.children && node.children.length > 0) {
            return node.children.some((child) => isNodeIdPresent(child, id));
        }

        return false;
    };

    if (treeDataStructured.length === 0) {
        return <></>
    }

    return (
        <>
            {treeDataStructured.map((item: TransformedNode, index) => (
                <div key={index}
                     className={"flow-box " + (flowState[index] === undefined || flowState[index] === true ? "opened" : "closed")}>
                    <div className={"flow-box__controller" + (single ? " single" : "")}>
                        <div className="flow-box__title" onClick={() => changeFlowState(index)}>
                            <div className="flow-box__collapse-icon">
                                <KeyboardArrowDownIcon/>
                            </div>
                            <span
                                className="add-flow__text">{t('smartlinks.create_form.' + (flowState[index] === undefined || flowState[index] === true ? "collapse_flow" : "show_flow")) + " " + (index + 1)}</span>
                        </div>
                    </div>
                    <RuleTree
                        treeData={item}
                        handleAddRule={handleAddRule}
                        handleEditRule={handleEditRule}
                        trackerId={Number(trackerId)}
                        setUpdateTree={setUpdateTree}
                        setNodeId={setNodeId}
                    />
                    {(isNodeIdPresent(item, nodeId) && !isCreateNewRule && showRuleForm && parentType !== 'schedule') &&
                        <SmartlinkRuleFormBlock
                            treeData={treeData}
                            parentType={parentType}
                            parentId={parentId}
                            activeRuleValue={activeRuleValue}
                            setActiveRuleValue={setActiveRuleValue}
                            activeRule={activeRule}
                            setActiveRule={setActiveRule}
                            isMultipleTypes={isMultipleTypes}
                            handleInputCreate={handleInputCreate}
                            nodeTypesOptions={nodeTypesOptions}
                            nodeTypesValue={nodeTypesValue}
                            setNodeTypesValue={setNodeTypesValue}
                            setNodeTypes={setNodeTypes}
                            ruleEdit={ruleEdit}
                            scheduleEdit={scheduleEdit}
                        />
                    }
                </div>
            ))}
        </>
    )
}

export default RuleTreeFlow;