export const statisticsSearchPeriod = [
    "today",
    "last_day",
    "last_7_days",
    "last_week",
    "last_30_days",
    "last_month",
    "custom_period"
];

export const displayedFieldsList = [
    "reg2Fd",
    "click2Fd",
    "click2Reg",
    "secondDepositsCount",
    "firstDepositsCount",
    "registrationsCount",
    "uniqueClicksCount",
    "totalClicksCount",
    "date",
    "trafficType",
    "redirectUrl",
    "language",
    "browser",
    "os",
    "platform",
    "region",
    "countryCode",
    "smartLinkId",
    "product",
    "company",
    "team",
    "smartLinkName",
    "smartLinkInternalId"
];

export const statisticsColumnsWithoutTotal = [
    "reg2Fd",
    "click2Fd",
    "click2Reg"
];