import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import Menu from "../Menu/Menu";
import Sidebar from "../Sidebar/Sidebar";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getUserInfo, selectIsAuth} from "../../app/reducers/prism/sessionSlice";
import { Navigate } from 'react-router-dom';

import './App.css';

function App() {
    const isAuth = useAppSelector(selectIsAuth)
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getUserInfo())
    }, [dispatch])
    if (!isAuth) {
        return <Navigate to={{ pathname: '/login' }} replace />
    }
    return (
        <div className="App-container">
            <Sidebar/>
            <Menu/>
            <Outlet/>
        </div>
    );
}

export default App;
