import React, {ReactNode} from "react";
import {Grid} from "@mui/material/";
import {Config} from "../types";
import {COLUMN_COUNT} from "../constants";

export interface FormGridProps {
    children: ReactNode;
    config: Config;
}

function FormGrid({children, config}: FormGridProps) {
    return (
        <Grid
            children={children}
            item
            sx={{paddingLeft: '24px!important'}}
            className={config.class}
            xs={config.col ?? COLUMN_COUNT}
        />
    );
}

export default FormGrid;
