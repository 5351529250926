import React from 'react';
import DarkTooltip from "../../DarkTooltip/DarkTooltip";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import './RadioGroupBase.scss';

interface RadioGroupBaseProps {
    activeRadio: string;
    handleRadioChange?: (value: string, checked?: boolean) => void;
    radioGroupContent: {value: string, label: string, hint?: string}[]
}

function RadioGroupBase({activeRadio, handleRadioChange, radioGroupContent}: RadioGroupBaseProps) {
    return (
        <div className="radio-group-base">
            {radioGroupContent.map((radio, i) => {
                return (
                    <div key={i} className="radio-block">
                        {activeRadio === radio.value
                            ? <RadioButtonCheckedIcon
                                className="radio-block-img"
                                onClick={() => {
                                    handleRadioChange && handleRadioChange(radio.value)
                                }}
                            />
                            : <RadioButtonUncheckedIcon
                                className="radio-block-img"
                                onClick={() => {
                                    handleRadioChange && handleRadioChange(radio.value)
                                }}
                            />
                        }
                        <p
                            onClick={() => {
                                handleRadioChange && handleRadioChange(radio.value)
                            }}
                        >
                            {radio.label}
                        </p>
                        {radio.hint && <DarkTooltip title={radio.hint} placement="top">
                            <InfoOutlinedIcon className="hint"/>
                        </DarkTooltip>}
                    </div>
                )
            })}
        </div>
    );
}

export default RadioGroupBase;
