import React from "react";
import {ColumnConfigWithRender, DataRow, Order, StringKeys} from "../types";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import {visuallyHidden} from "@mui/utils";
import {useTranslation} from "react-i18next";
import {CellName} from "./CellName";
import {ReactComponent as FilterIcon} from '../../../app/assets/img/icons/filterIcon.svg';

export interface SortProps<Data extends DataRow, Handlers> {
  orderBy: string;
  headCell: ColumnConfigWithRender<Data, Handlers>;
  order: Order;
  createSortHandler: (property: StringKeys<Data>) => (event: React.MouseEvent<unknown>) => void;
  index: number;
}

export function Sort<Data extends DataRow, Handlers>(props: SortProps<Data, Handlers>) {
  const {t} = useTranslation();

  const {orderBy, headCell, order, createSortHandler, index} = props;
  return (
    <TableSortLabel
      sx={{":hover": {color: 'primary.main'}}}
      active={orderBy === headCell.field}
      direction={orderBy === headCell.field ? order : 'asc'}
      onClick={createSortHandler(headCell.field)}
      hideSortIcon={true}
      className="tHeadLabel"
      IconComponent={FilterIcon}
    >
      <CellName headCell={headCell}/>
      {orderBy === headCell.field
        ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>)
        : null}
    </TableSortLabel>
  );
}
