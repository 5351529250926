import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import {store} from './app/store';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {ThemeProvider} from "@mui/material";
import theme from "./componets/UI/Theme";
import "./i18n/i18n";
import {Router} from "./navigation/Router"
import axios from './componets/AxiosInterceptor/AxiosInterceptor';

const axiosErrors = axios;
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
