import {styled} from "@mui/material/styles";
import {Box, BoxProps} from "@mui/material";

export const ErrorPageStyled = styled(Box)<BoxProps>(({theme}) => ({
    "backgroundImage": "url(/error-bg.svg)",
    "width": "100%",
    "height": "100vh",
    "backgroundSize": "cover",
    "backgroundPosition": "center",
    "fontFamily": "Roboto, 'Circe', sans-serif",
    "overflow": "hidden",
    "color": "#fff",
    ".info": {
        "marginTop": "41.125vh",
        "width": "100vw",
        "textAlign": "center",
        ".number": {
            "fontSize": "16vh",
            "fontWeight": "600",
        },
        ".text": {
            "fontSize": "2.5vh",
            "marginTop": "-2vh"
        }
    },
    "@media screen and (max-width: 80vh)":  {
        ".info": {
            "marginTop": "34vh",
            ".number": {
                "width": "50vw"
            },
            ".text": {
                "width": "80vw",
                "marginLeft": "10vw"
            }
        }
    }
}));