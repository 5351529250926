import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Paper from '@mui/material/Paper';
import {Link, useParams} from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import './Smartlink.scss';
import Toggle from "../Toggle/Toggle";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    getAvailableUtmParams,
    getTracker,
    selectAvailableUtmParams,
    selectTracker,
    setTrackerActivity,
    setTrackerAlgorithm,
    updateTracker
} from "../../app/reducers/prism/trackersSlice";
import {getGeneralTrackersTree, selectGeneralTrackersTree} from "../../app/reducers/prism/generalTrackersSlice";
import {
    getSmartlinkNodesCountries,
    selectSmartlinkNodesCountries,
    getSmartlinkNodesLanguages,
    selectSmartlinkNodesLanguages,
    getSmartlinkNodesCity,
    selectSmartlinkNodesCity,
    getSmartlinkNodesRegions,
    selectSmartlinkNodesRegions,
    getSmartlinkNodesDevices,
    selectSmartlinkNodesDevices
} from "../../app/reducers/prism/smartlinkNodesSlice";
import {Box} from "@mui/material";
import * as Yup from "yup";
import StarIcon from '@mui/icons-material/Star';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import {useTranslation} from "react-i18next";
import {EditableText} from "../inputs/EditableText";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {selectSelfUser} from "../../app/reducers/prism/sessionSlice";
import RuleTreeFlow from "./RuleTree/RuleTreeFlow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ModalRuleConfiguration from "./ModalRuleConfiguration/ModalRuleConfiguration";
import BaseSelectWithInput from "../inputs/BaseSelectWithInput";
import {AutocompleteOption} from "../inputs/baseAutocomplete/types";
import {isArray} from "lodash";
import {AllNodeRulesNames, City, Country, Device, Language, Node, NodeRuleName, Region, typesRules} from "./types";
import CustomAlert from "../Alert/CustomAlert";
import DarkTooltip from "../DarkTooltip/DarkTooltip";
import SmartlinkEditModal from "./SmartlinkEditModal/SmartlinkEditModal";
import DeleteModal from "../modals/DeleteModal/DeleteModal";
import {getOptionListFromNodeStrings, isSingle, validateRuleList} from "./ModalRuleConfiguration/ruleHelpers";
import {findOptionByValue, filterOptionsByArray} from "../Helpers/arrayHelper";
import {TrackerData, UserTeam, SearchTeam} from "../../app/reducers/prism/types";
import {editSchedule} from "../../app/requests/generalTrackers";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import StyledDialog from "../modals/Dialog/StyledDialog";
import {getSearchTeams, selectSearchTeams, searchTeamsByName, selectSearchTeamsByName} from "../../app/reducers/prism/teamsSlice";
import Routes from "../../navigation/routes";
import SmartlinkRuleFormBlock from "./SmartlinkRuleFormBlock/SmartlinkRuleFormBlock";

interface FormState {
    traffic_back_url: string
}

export function Smartlink() {
    const {t} = useTranslation();
    const {trackerId} = useParams();
    const tracker = useAppSelector(selectTracker)
    const generalTrackerTree = useAppSelector(selectGeneralTrackersTree)
    const availableUtmParams = useAppSelector(selectAvailableUtmParams)
    const selfUser = useAppSelector(selectSelfUser);
    const params = useParams();
    const dispatch = useAppDispatch();
    const [ruleSuccessfullyAdded, setRuleSuccessfullyAdded] = useState<boolean>(false);
    const [nameInput, setNameInput] = useState<string>(tracker?.name ?? '');
    const [status, setStatus] = useState<boolean>(false);
    const [updateTree, setUpdateTree] = useState<number>(0);
    const [openEditSmartlinkModal, setOpenEditSmartlinkModal] = useState<boolean>(false);
    const [openSmartlinkStatusModal, setOpenSmartlinkStatusModal] = useState<boolean>(false);
    const [type, setType] = useState<'rules' | 'rotation'>('rules');
    const [ruleEdit, setRuleEdit] = useState<Node | null>(null);
    const [scheduleEdit, setScheduleEdit] = useState<TrackerData | null>(null);
    const [clearData, setClearData] = useState<boolean>(true);
    const [openOwnerDetailsModal, setOpenOwnerDetailsModal] = useState<boolean>(false);
    const [team, setTeam] = useState<AutocompleteOption | AutocompleteOption[] | null>(null);
    const [teams, setTeams] = useState<AutocompleteOption[]>([{label: 'No data', value: ''}]);
    const [noOptionsText, setNoOptionsText] = useState<string>(t('smartlinks.modal_owner_details.no_options_text'));
    const [disabledChangeOwnerDetailsButton, setDisabledChangeOwnerDetailsButton] = useState<boolean>(false);
    const adminTeams = useAppSelector(selectSearchTeams);
    const teamsFound: SearchTeam[] | [] = useAppSelector(selectSearchTeamsByName);
    const transVarActive = t("statistic.trackers.confirm_text_active");
    const transVarHeadActive = t("common.forms.deactivate_smartlink");
    const transVarInActive = t("statistic.trackers.confirm_text_inactive");
    const transVarHeadInActive = t("common.forms.activate_smartlink");

    useEffect(() => {
        if (trackerId && Number(trackerId) !== tracker?.id) {
            dispatch(getTracker(Number(trackerId)));
        }
        setStatus(tracker?.enabled ?? true);
    }, [dispatch, tracker, trackerId, openEditSmartlinkModal]);

    useEffect(() => {
        if (trackerId && Number(trackerId) === tracker?.id && !openEditSmartlinkModal) {
            dispatch(getTracker(Number(trackerId)));
        }
    }, [openEditSmartlinkModal]);

    useEffect(() => {
        if (ruleSuccessfullyAdded) {
            dispatch(getGeneralTrackersTree(Number(trackerId)));
        }
    }, [ruleSuccessfullyAdded]);

    useEffect(() => {
        dispatch(getGeneralTrackersTree(Number(trackerId)));
    }, []);

    useEffect(() => {
        if (updateTree > 0) {
            dispatch(getGeneralTrackersTree(Number(trackerId)));
        }
    }, [updateTree]);

    useEffect(() => {
        if (tracker) {
            setType(tracker.algorithm || 'rules');
            tracker!.team && setTeam({label: tracker!.team!.name , value: tracker!.team!.id})
        }
    }, [tracker])

    const validationSchema = Yup.object().shape({
        traffic_back_url: Yup.string()
            .required('Required')
            .url('ShouldBeUrl')
    });

    const resetName = (value: string) => {
        setNameInput(value)
    }

    const setAlgorithm = async (algorithm: string) => {
        if (tracker) {
            await dispatch(setTrackerAlgorithm({trackerId: params.trackerId ?? '0', algorithm: algorithm}));
            if (algorithm === 'rules' || algorithm === 'rotation') {
                setType(algorithm);
            }
            await dispatch(getTracker(tracker.id));
        }
    };

    useEffect(() => {
        setNameInput(tracker?.title ?? '');
    }, [tracker]);

    useEffect(() => {
        dispatch(getAvailableUtmParams())
    }, [dispatch]);

    const getOptionFromAvailableParam = () => {
        if (!availableUtmParams) {
            return [];
        }
        return availableUtmParams?.map(utmParam => ({label: utmParam, value: utmParam}))
    };

    const allNodeRulesNames = AllNodeRulesNames.map((nodeRuleName: NodeRuleName) => ({
        label: t(nodeRuleName.label),
        value: nodeRuleName.value
    }));

    const [ruleId, setRuleId] = useState<number | null>(null);
    const [parentId, setParentId] = useState<number | null>(null);
    const [parentType, setParentType] = useState<string | null>(null);
    const [showRuleForm, setShowRuleForm] = useState<boolean>(false);
    const [showRuleTreeController, setShowRuleTreeController] = useState<boolean>(false);
    const [activeRule, setActiveRule] = useState<string | null>(null);
    const [nodeTypes, setNodeTypes] = useState<string>("");
    const [nodeTypesValue, setNodeTypesValue] = useState<AutocompleteOption | AutocompleteOption[] | null>([]);
    const [nodeTypesOptions, setNodeTypesOptions] = useState<AutocompleteOption[]>([{label: 'No data', value: ''}]);
    const [search, setSearch] = useState<string>('');
    const [labelValues, setLabelValues] = useState<string>('');
    const [firstOptionsLoaded, setFirstOptionsLoaded] = useState<number>(0);
    const [activeRuleValue, setActiveRuleValue] = useState<AutocompleteOption | AutocompleteOption[] | null>(null);
    const [blockCounter, setBlockCounter] = useState<number[]>([0]);
    const [isCreateNewRule, setIsCreateNewRule] = useState<boolean>(false);
    const countries = useAppSelector(selectSmartlinkNodesCountries);
    const languages = useAppSelector(selectSmartlinkNodesLanguages);
    const regions = useAppSelector(selectSmartlinkNodesRegions);
    const cites = useAppSelector(selectSmartlinkNodesCity);
    const devices = useAppSelector(selectSmartlinkNodesDevices);
    const isEpNodeTypes = [
        {
            label: t('smartlinks.modal_rule_configuration.rules_names.isEp.existing_player'),
            value: 'isEp'
        },
        {
            label: t('smartlinks.modal_rule_configuration.rules_names.isEp.not_existing_player'),
            value: 'not_isEp'
        }
    ];
    const uniqueVisitorNodeTypes = [
        {
            label: t('smartlinks.modal_rule_configuration.rules_names.unique.unique_visitor'),
            value: t('smartlinks.modal_rule_configuration.rules_names.unique.unique_visitor_value')
        },
        {
            label: t('smartlinks.modal_rule_configuration.rules_names.unique.not_unique_visitor'),
            value: t('smartlinks.modal_rule_configuration.rules_names.unique.not_unique_visitor_value')
        }
    ]
    const multipleTypesRules = [typesRules.geo, typesRules.language, typesRules.city, typesRules.region];
    const isMultipleTypes = () => {
        return !!activeRule && multipleTypesRules.includes(activeRule)
    };

    const getOriginalValue = useMemo(
        () => (id: number, type: string): string => {
            const nodes = generalTrackerTree?.nodes;
            const node = nodes?.find((node) => Number(node.id) === id);
            if (node && node.type === type) {
                return node.original_value;
            } else if (node && node.parent_id) {
                return getOriginalValue(node.parent_id, type);
            } else {
                return "";
            }
        },
        [generalTrackerTree]
    );

    useEffect(() => {
        if (!search) {
            setNodeTypesOptions([{label: t('smartlinks.modal_rule_configuration.rules_names.no_rules.label'), value: ''}]);
        }

        if (search && activeRule === typesRules.city) {
            dispatch(getSmartlinkNodesCity({
                alternatenames: search,
                country_code: parentId ? getOriginalValue(parentId, typesRules.geo) : '',
                filter_region: parentId ? getOriginalValue(parentId, typesRules.region) : '',
            }));
        }

        if (search && activeRule === typesRules.region) {
            dispatch(getSmartlinkNodesRegions({
                asciiname: search,
                country_code: parentId ? getOriginalValue(parentId, typesRules.geo) : ''
            }));
        }
    }, [dispatch, search]);

    useEffect(() => {
        dispatch(getSmartlinkNodesCountries());
        dispatch(getSmartlinkNodesLanguages());
        dispatch(getSmartlinkNodesDevices());
    }, [dispatch]);

    useEffect(() => {
        if (cites) {
            const options = getCityNodeTypes(cites);
            options && setNodeTypesOptions(options);
        }
    }, [cites]);

    useEffect(() => {
        if (regions) {
            const options = getRegionNodeTypes(regions);
            options && setNodeTypesOptions(options);
        }
    }, [regions]);

    useEffect(() => {
        setShowRuleTreeController(!!generalTrackerTree?.nodes.length);
    }, [generalTrackerTree]);

    useEffect(() => {
        setNodeTypesValue([]);
        setNodeTypes("");
        setSearch("");
        let options;
        if (typeof activeRule === "string") {
            switch (activeRule) {
                case typesRules.geo:
                    options = getGeoLanguageDeviceNodeTypes(countries!.data);
                    options && setNodeTypesOptions(options);
                    break;
                case typesRules.language:
                    options = getGeoLanguageDeviceNodeTypes(languages!.data);
                    options && setNodeTypesOptions(options);
                    break;
                case typesRules.city:
                    setNodeTypesOptions([{label: t('smartlinks.modal_rule_configuration.rules_names.no_rules.label'), value: ''}]);
                    break;
                case typesRules.device:
                    options = getGeoLanguageDeviceNodeTypes(devices!.data);
                    options && setNodeTypesOptions(options);
                    setNodeTypesValue(null);
                    break;
                case typesRules.region:
                    setNodeTypesOptions([{label: t('smartlinks.modal_rule_configuration.rules_names.no_rules.label'), value: ''}]);
                    break;
                case typesRules.unique:
                    setNodeTypesOptions(uniqueVisitorNodeTypes);
                    setNodeTypesValue(null);
                    break;
                case typesRules.isEp:
                    setNodeTypesOptions(isEpNodeTypes);
                    setNodeTypesValue(null);
                    break;
                case typesRules.single:
                    setNodeTypesOptions([{
                        label: t('smartlinks.modal_rule_configuration.rules_names.single.label'),
                        value: typesRules.single
                    }]);
                    setNodeTypesValue({
                        label: t('smartlinks.modal_rule_configuration.rules_names.single.label'),
                        value: typesRules.single
                    });
                    setNodeTypes(typesRules.single);
                    break;
                default:
                    // setNodeTypesOptions([{label: 'No data', value: ''}]);
                    // setNodeTypesValue(null);
                    break;
            }
        }
    }, [activeRule]);

    useEffect(() => {
        if (ruleEdit !== null && activeRule !== null && nodeTypes !== "" && firstOptionsLoaded === 0) {
            let curNodeTypeOptions = null;
            if (activeRule === typesRules.geo || activeRule === typesRules.city) {
                curNodeTypeOptions = getOptionListFromNodeStrings(labelValues, nodeTypes);
            } else {
                curNodeTypeOptions = filterOptionsByArray(nodeTypesOptions, nodeTypes,isMultipleTypes())
            }

            if (curNodeTypeOptions !== []) {
                if (isMultipleTypes()) {
                    setNodeTypesValue(curNodeTypeOptions);
                    setFirstOptionsLoaded(1);
                } else {
                    if (isAutocompleteOptionArray(curNodeTypeOptions)) {
                        if (curNodeTypeOptions.length > 0) {
                            const firstOption = curNodeTypeOptions[0]; // Access the first element without type error
                            setNodeTypesValue(firstOption);
                        }
                    } else {
                        setNodeTypesValue(curNodeTypeOptions);
                    }
                }
            }
        }
    }, [nodeTypes]);

    const handleChangeRule = (value: AutocompleteOption | AutocompleteOption[] | null) => {
        if (!isArray(value)) {
            setActiveRule(String(value?.value));
        }

        if (value === null) {
            setActiveRule(null);
        }
    }

    const getGeoLanguageDeviceNodeTypes = (arr: Language[] | Device[] | Country[] | null) => {
        return arr?.map((obj) => {
            return {label: obj.name, value: obj.code}
        });
    }

    const getCityNodeTypes = (arr: City[] | null) => {
        return arr?.map((obj) => {
            return {label: obj.name + ' | ' + obj.regionName, value: String(obj.geonameId)}
        });
    }

    const getRegionNodeTypes = (arr: Region[] | null) => {
        return arr?.map((obj) => {
            return {label: obj.name, value: String(obj.geonameId)}
        });
    }

    const handleInputCreate = (value: string) => {
        if (activeRule === typesRules.city && value) {
            setSearch(value);
        }
        if (activeRule === typesRules.region && value) {
            setSearch(value);
        }
    };

    const transformValues = (values: AutocompleteOption | AutocompleteOption[] | null) => {
        if (isArray(values)) {
            return values.map((item: AutocompleteOption) => item.value).join();
        }

        if (values === null) {
            return "";
        }

        return String(values.value)
    }

    const handleAddRule = (id?: number, type?: string) => {
        setClearData(!clearData);
        setRuleEdit(null);
        setScheduleEdit(null);
        setParentType(type ? type : null);
        setBlockCounter([0]);
        setNodeTypesValue(null);
        if (id && type) {
            setParentId(id);
            setIsCreateNewRule(false);
        }
        setShowRuleForm(true);
        setNodeTypesValue([]);
        setActiveRule(null);
        setActiveRuleValue(null);
    }

    const handleCloseRuleForm = () => {
        setShowRuleForm(false);
        setNodeTypes("");
        setParentType(null);
        setIsCreateNewRule(false);
    }

    const handleEditRule = (node: Node) => {
        setIsCreateNewRule(false);
        setClearData(!clearData);
        setScheduleEdit(null);
        setParentType( null);
        setParentId(null);
        if(ruleId != node.id) {
            setRuleId(node.id);
            setBlockCounter([0]);
            setFirstOptionsLoaded(0);
            setNodeTypesValue(null);
            setNodeTypes("");
            setActiveRule(node.type);
            const curParentID = Number(node.parent_id) > 0 ? Number(node.parent_id) : null;
            setParentId(curParentID);
            setTimeout(function () {
                setRuleEdit(node);
                setActiveRuleValue(findOptionByValue(allNodeRulesNames, node.type));
                setLabelValues(node.value);
                setNodeTypes(node.original_value);
                setShowRuleForm(true);
                setParentType(null);
            }, 300)
        }
    }

    const handleEditSchedule = (row: TrackerData, type: string) => {
        setClearData(!clearData);
        setRuleEdit(null);
        setBlockCounter([0]);
        handleCloseRuleForm()
        setTimeout(function () {
            setScheduleEdit(row);
            setParentType(type);
        }, 300);
    }

    const showHideSuccessfullyAlert = () => {
        setRuleSuccessfullyAdded(true);
        setTimeout(function () {
            setRuleSuccessfullyAdded(false);
        }, 5000);
    }

    const [single, setSingle] = useState<boolean>(false);

    function isAutocompleteOptionArray(value: AutocompleteOption | AutocompleteOption[]): value is AutocompleteOption[] {
        return Array.isArray(value);
    }

    const handleCloseOwnerDetailsModal = () => {
        setOpenOwnerDetailsModal(false);
    }

    const sortOptions = (optios: AutocompleteOption[]) => {
        return optios.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)
    }

    useEffect(() => {
        if (selfUser?.role.alias === 'admin') {
            dispatch(getSearchTeams({}));
        } else {
            setTeams([{label: t('smartlinks.modal_owner_details.value_not_found'), value: ''}])
        }
    }, [selfUser?.role.alias, dispatch])

    useEffect(() => {
        if (adminTeams && adminTeams.length > 0 && selfUser?.role.alias === 'admin') {
            setTeams(sortOptions(adminTeams?.map(team => ({label: team.name, value: team.id}))));
        }
    }, [adminTeams])

    const handleSaveOwnersDetails = async () => {
        if (tracker) {
            const newTracker = {
                ...tracker,
                team_id: !isArray(team) ? team?.value : tracker.team_id,
            };
            const response = await dispatch(updateTracker(newTracker));

            if (response) {
                handleCloseOwnerDetailsModal();
                dispatch(getTracker(Number(trackerId)));
            }
        }
    }

    const handleOnClose = () => {
        setTeams(sortOptions(adminTeams?.map(team => ({label: team.name, value: team.id}))));
    }

    const onSearchHandler = async(value: string) => {
        setTeams(sortOptions(adminTeams?.map(team => ({label: team.name, value: team.id}))));
        value.length && dispatch(searchTeamsByName(value));
    }

    const checkSaveButton = () => {
        if (!isArray(team) && team) {
            return tracker?.team_id === team?.value;
        }
        return true;
    }

    useEffect(() => {
        if (!teamsFound) return;

        if (teamsFound.length) {
            let options: AutocompleteOption[] = [];
            teamsFound?.forEach(team => {
                options.push({label: team.name, value: team.id});
            })
            setTeams(sortOptions(options));
        }

        setNoOptionsText(
            teamsFound.length
                ? t('smartlinks.modal_owner_details.no_options_text')
                : t('smartlinks.modal_owner_details.value_not_found')
        );
    }, [teamsFound])

    useEffect(() => {
        if (!team) {
            setTeams(sortOptions(adminTeams?.map(team => ({label: team.name, value: team.id}))));
        }
        setDisabledChangeOwnerDetailsButton(checkSaveButton());
    }, [team])

    useEffect(() => {
        openOwnerDetailsModal && tracker!.team && setTeam({label: tracker!.team!.name , value: tracker!.team!.id});
    }, [openOwnerDetailsModal])

    return (
        <div className="tracker-list-container">
            <div className="tracker-list-title">
                <div className='column'>
                    <div className="breadcrumbs row">
                        <Link className="back" to={Routes.trackers}>
                            <StarIcon
                                className={"icon-star back"}/><span>{t('smartlinks.breadcrumbs.smartlinks')}</span>
                        </Link>
                        <span className="divider">/</span>
                        <Link to={`${Routes.trackers}/${trackerId}`}>
                            <StarIcon
                                className={"icon-star"}/><span>{t('smartlinks.breadcrumbs.cur_smartlink') + trackerId}</span>
                        </Link>
                    </div>
                    <h1 className='row'>
                        <Link className="back" to={Routes.trackers}>
                            <ArrowBackOutlined/>
                        </Link>
                        {t('smartlinks.breadcrumbs.cur_smartlink') + trackerId + " “" + nameInput + "”"}
                    </h1>
                </div>
            </div>
            <div className="tracker-settings">
                <div className="tracker-first-column">
                    <Paper className="tracker-main-info">
                        <div className="row space-between tracker-heading">
                            <FormControlLabel
                                control={
                                    <Toggle
                                        manualOnChange={true}
                                        isChecked={status}
                                        handler={() => setOpenSmartlinkStatusModal(true)}
                                    />
                                }
                                label={t('smartlinks.create_form.smartlink_status')}
                                labelPlacement="end"
                                className="switch-box status"
                            />
                            <Box>
                                {/*<Button variant="text">{t('smartlinks.create_form.change_log')}</Button>*/}
                                {/*<Button*/}
                                {/*    className="light diagnostic">{t('smartlinks.create_form.run_diagnostic')}</Button>*/}
                            </Box>
                        </div>
                        <div className='row tracker-name'>
                            <EditableText
                                fieldName="name"
                                onChangeHandler={(value: string) => resetName(value)}
                                placeholder={t('smartlinks.create_form.enter_smartlink_name')}
                                label={t('smartlinks.create_form.smartlink_s_name')}
                                value={nameInput ?? 'test'}
                                handleEditOnForm={setOpenEditSmartlinkModal}
                            />
                        </div>
                        <div className='row tracker-url'>
                            <EditableText
                                fieldName="shortlink"
                                placeholder={t('smartlinks.create_form.enter_smartlink_url')}
                                label={t('smartlinks.create_form.smartlink_customizible')}
                                value={tracker?.shortlink?.shortlink_short ?? tracker?.url ?? ''}
                                type="link"
                                tooltip={t('smartlinks.create_form.your_smartlink_tooltip')}
                                disabled={true}
                            />
                        </div>
                        <div className='row traffic_back_url'>
                            <EditableText
                                fieldName="traffic_back_url"
                                placeholder={t('smartlinks.create_form.enter_smartlink_url')}
                                label={t('smartlinks.create_form.smartlink_traffic_back_url')}
                                value={tracker?.settings?.traffic_back_url ?? ''}
                                type="link"
                                tooltip={t('smartlinks.create_form.smartlink_traffic_back_tooltip')}
                                linkIcon={true}
                                handleEditOnForm={setOpenEditSmartlinkModal}
                            />
                        </div>
                        <Box className="editable_text__label add-new-teg__label">
                            {t('smartlinks.create_form.smartlink_tegs')}
                            <DarkTooltip title={t('smartlinks.create_form.parameters_tooltip')} placement="top">
                                <InfoOutlinedIcon fontSize="small" className="editable_text__tooltip"/>
                            </DarkTooltip>
                        </Box>

                        <Box className="add-new-teg">
                            <AddCircleIcon className='add-plus-icon' sx={{width: 15, height: 15}}/>
                            <span className="add-new-teg__text">{t('smartlinks.create_form.smartlink_add_teg')}</span>
                        </Box>
                    </Paper>
                    <h1 className='row'>
                        {t('smartlinks.create_form.rules_for_smartlink')}
                    </h1>
                    <span className='row rules-heading-description'>
                        {t('smartlinks.create_form.rules_for_smartlink_description')}
                    </span>
                </div>
                <div className="smartlink-second-column">
                    {/*<Paper className="smartlink-default-rules">*/}
                    {/*    <div className="row smartlink-default-rules__status">*/}
                    {/*        <Box className="smartlink-default-rules__title">*/}
                    {/*            {t('smartlinks.create_form.default_rules')}*/}
                    {/*        </Box>*/}
                    {/*        <FormControlLabel*/}
                    {/*            control={*/}
                    {/*                <Toggle*/}
                    {/*                    manualOnChange={false}*/}
                    {/*                    isChecked={tracker?.enabled ?? true}*/}
                    {/*                    handler={(event) => {*/}
                    {/*                        // if (tracker) {*/}
                    {/*                        //     dispatch(setTrackerActivity({*/}
                    {/*                        //         trackerId: tracker.id,*/}
                    {/*                        //         isActive: event.target.checked*/}
                    {/*                        //     }))*/}
                    {/*                        // }*/}
                    {/*                    }}*/}
                    {/*                />*/}
                    {/*            }*/}
                    {/*            label={t('smartlinks.create_form.active')}*/}
                    {/*            labelPlacement="end"*/}
                    {/*            className="switch-box status"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <Box className="row smartlink-default-rules__subtitle">*/}
                    {/*        {t('smartlinks.create_form.default_rules_subtitle')}*/}
                    {/*    </Box>*/}
                    {/*    <div className='column rules-select-form'>*/}

                    {/*        <Form*/}
                    {/*            configs={[*/}
                    {/*                {*/}
                    {/*                    type: 'select',*/}
                    {/*                    field: 'utm',*/}
                    {/*                    placeholder: t('smartlinks.create_form.сhoose_default_rules'),*/}
                    {/*                    value: 'value',*/}
                    {/*                    label: 'label',*/}
                    {/*                    options: getOptionFromAvailableParam(),*/}
                    {/*                    handleChange: () => {*/}
                    {/*                    },*/}
                    {/*                },*/}
                    {/*                {*/}
                    {/*                    type: 'button',*/}
                    {/*                    buttonType: 'button',*/}
                    {/*                    label: t('smartlinks.create_form.see_rules_list'),*/}
                    {/*                    buttonClass: "rules-select-form__list BtnGrey",*/}
                    {/*                }*/}
                    {/*            ]}*/}
                    {/*            onSubmitHandler={(values => console.log(values))}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</Paper>*/}
                    <Paper className="smartlink-owner-data">
                        <div className='column rules-select-form'>
                            <Box className="smartlink-default-rules__title smartlink-default-rules__title-owner">
                                {t('smartlinks.create_form.owner_s_details')}
                            </Box>
                            <EditableText
                                fieldName="name"
                                onChangeHandler={(value: string) => resetName(value)}
                                placeholder={t('smartlinks.create_form.smartlink_owner')}
                                label={t('smartlinks.create_form.smartlink_owner')}
                                value={tracker?.owner?.email ?? ""}
                                copy={false}
                                disabled={true}
                                // disabled={selfUser?.role.alias === 'admin' ? true : false}
                            />
                            <div className="row team">
                                <EditableText
                                    fieldName="team"
                                    onChangeHandler={(value: string) => resetName(value)}
                                    placeholder={t('smartlinks.create_form.smartlink_owner_teem')}
                                    label={t('smartlinks.create_form.smartlink_owner_teem')}
                                    value={tracker?.team?.name ?? ""}
                                    copy={false}
                                    disabled={selfUser?.role.alias !== 'admin'}
                                    handleEditOnForm={setOpenOwnerDetailsModal}
                                />
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
            <div className="smartlinks-tree-part">
                <div className="smartlinks-tree-part__left">
                    {!single &&
                    <Button
                        className="add-flow"
                        onClick={() => {
                            setParentId(null)
                            setIsCreateNewRule(true);
                            handleAddRule();
                        }}
                    >
                        <AddCircleIcon className='add-plus-icon' sx={{width: 15, height: 15}}/>
                        <span className="add-flow__text">{t('smartlinks.create_form.add_redirection_flow')}</span>
                    </Button>
                    }
                    {showRuleTreeController &&
                    <RuleTreeFlow
                        treeData={generalTrackerTree}
                        handleAddRule={handleAddRule}
                        handleEditRule={handleEditRule}
                        trackerId={Number(trackerId)}
                        setUpdateTree={setUpdateTree}
                        setSingle={setSingle}
                        single={single}
                        showRuleForm={showRuleForm}
                        parentType={parentType}
                        parentId={parentId}
                        activeRuleValue={activeRuleValue}
                        setActiveRuleValue={setActiveRuleValue}
                        activeRule={activeRule}
                        setActiveRule={setActiveRule}
                        isMultipleTypes={isMultipleTypes}
                        handleInputCreate={handleInputCreate}
                        nodeTypesOptions={nodeTypesOptions}
                        setNodeTypes={setNodeTypes}
                        nodeTypesValue={nodeTypesValue}
                        setNodeTypesValue={setNodeTypesValue}
                        ruleEdit={ruleEdit}
                        scheduleEdit={scheduleEdit}
                        isCreateNewRule={isCreateNewRule}
                    />}
                    {(isCreateNewRule && showRuleForm && parentType !== 'schedule') &&
                        <SmartlinkRuleFormBlock
                            treeData={generalTrackerTree}
                            parentType={parentType}
                            parentId={parentId}
                            activeRuleValue={activeRuleValue}
                            setActiveRuleValue={setActiveRuleValue}
                            activeRule={activeRule}
                            setActiveRule={setActiveRule}
                            isMultipleTypes={isMultipleTypes}
                            handleInputCreate={handleInputCreate}
                            nodeTypesOptions={nodeTypesOptions}
                            nodeTypesValue={nodeTypesValue}
                            setNodeTypesValue={setNodeTypesValue}
                            setNodeTypes={setNodeTypes}
                            ruleEdit={ruleEdit}
                            scheduleEdit={scheduleEdit}
                        />
                    }
                </div>
                {((activeRule && nodeTypes.length !== 0) || parentType === 'schedule') &&
                <div className="smartlinks-tree-part__right">
                    <ModalRuleConfiguration
                        trackerId={Number(trackerId)}
                        nodeTypes={nodeTypes}
                        activeRule={activeRule}
                        parentId={parentId}
                        handleCloseRuleForm={handleCloseRuleForm}
                        showHideSuccessfullyAlert={showHideSuccessfullyAlert}
                        setUpdateTree={setUpdateTree}
                        updateTree={updateTree}
                        isSchedule={parentType === 'schedule'}
                        ruleEdit={ruleEdit}
                        setRuleEdit={setRuleEdit}
                        scheduleEdit={scheduleEdit}
                        setScheduleEdit={setScheduleEdit}
                        handleEditSchedule={handleEditSchedule}
                        blockCounter={blockCounter}
                        setBlockCounter={setBlockCounter}
                        clearData={clearData}
                        setClearData={setClearData}
                    />
                </div>
                }
            </div>
            <CustomAlert
                type="success"
                title={t('smartlinks.modal_rule_configuration.alert_rule_successfully_added')}
                status={ruleSuccessfullyAdded}
            />
            {tracker !== null && openEditSmartlinkModal &&
            <SmartlinkEditModal
                tracker={tracker}
                setOpen={setOpenEditSmartlinkModal}
            />}
            {openSmartlinkStatusModal && (<DeleteModal
                handleDelete={() => {
                    if (trackerId) {
                        dispatch(setTrackerActivity({
                            trackerId: Number(trackerId),
                            isActive: !status
                        }))
                    }
                    setStatus(!status);
                    setOpenSmartlinkStatusModal(false);
                }}
                handleClose={() => setOpenSmartlinkStatusModal(false)}
                modalTitle={status ? transVarHeadActive : transVarHeadInActive}
                modalSubtitle={status ? `${transVarActive}${trackerId}` : `${transVarInActive}${trackerId}`}
                rightBtnName={status ? t("common.forms.deactivate") : t("common.forms.activate")}
            />)}
            <StyledDialog className="owner-details-modal" open={openOwnerDetailsModal} onClose={handleCloseOwnerDetailsModal}>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseOwnerDetailsModal}
                    className="close-cross"
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    {t('smartlinks.modal_owner_details.title')}
                </DialogTitle>
                <DialogContent>
                    <BaseSelectWithInput
                        value={{label: tracker?.owner?.email ?? '' , value: null}}
                        className="owner-details-modal-autocomplete"
                        fieldName="owner"
                        optionsList={[]}
                        placeholder={t('smartlinks.modal_owner_details.smartlinks_owner')}
                        onChange={() => {}}
                        disabled={true}
                    />
                    <BaseSelectWithInput
                        value={team}
                        className="owner-details-modal-autocomplete"
                        fieldName="team"
                        optionsList={teams}
                        placeholder={t('smartlinks.modal_owner_details.smartlinks_owner_teem')}
                        onChange={(event, value) => {
                            setTeam(value);
                        }}
                        disabled={ruleEdit !== null || scheduleEdit !== null}
                        handleInputCreate={onSearchHandler}
                        noOptionsText={noOptionsText}
                        handleOnClose={handleOnClose}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        className={"BtnPurple"}
                        onClick={handleSaveOwnersDetails}
                        disabled={disabledChangeOwnerDetailsButton}
                    >
                        {t('smartlinks.modal_owner_details.save')}
                    </Button>
                    <Button
                        className={"BtnGrey"}
                        onClick={handleCloseOwnerDetailsModal}
                    >
                        {t("smartlinks.modal_owner_details.cancel")}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </div>
    )
}
