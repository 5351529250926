import React from 'react';
import {AppFilters, ColumnConfigWithRender, SearchConfig, UrlParams} from "../../types";
import './ProductsControlBar.scss';
import {useTranslation} from "react-i18next";
import {Box, Button} from '@mui/material/';
import Search from "../../Search/Search";
import AddIcon from '@mui/icons-material/Add';
import {useAppSelector} from "../../../../app/hooks";
import {selectSelfUser} from "../../../../app/reducers/prism/sessionSlice";

interface FiltersProps<Data, Handlers> {
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    chooseAllFavourites: () => void;
    columnsConfig: ColumnConfigWithRender<Data, Handlers>[];
    additionalSearches: SearchConfig<Data>[];
    tableName: string;
    defaultSearchField?: string;
}

export function ProductsControlBar<Data, Handlers>(props: FiltersProps<Data, Handlers>) {
    const {t} = useTranslation();
    const {
        urlFilters,
        addFilters,
        additionalSearches,
        columnsConfig,
        tableName,
        defaultSearchField
    } = props;

    const selfUser = useAppSelector(selectSelfUser);
    const isAdmin = selfUser?.role.alias === 'admin';

    return (
        <Box className={`control-bar-wrapper control-bar-wrapper-${tableName}`}>
            <div className="right-block">
                <Search
                    urlFilters={urlFilters}
                    addFilters={addFilters}
                    additionalSearches={additionalSearches}
                    columnsConfig={columnsConfig}
                    defaultSearchField={defaultSearchField}
                />
                {isAdmin && <Button
                    className="BtnPurple create-plus"
                    endIcon={<AddIcon/>}
                >
                    {t('products.table.actions.create_product')}
                </Button>}
            </div>
        </Box>
    )
}
