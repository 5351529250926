import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import {SetState} from "../types";

// TODO make hook for getting perPage from localStorage
export function usePerPage(): [number, SetState<number>] {
    const [searchParams] = useSearchParams();

    const defaultPerPage = Number(searchParams.get('per_page'));

    const [perPage, setPerPage] = useState(defaultPerPage || 10);

    return [perPage, setPerPage];
}