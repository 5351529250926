import {
    capRangeVal,
    dateRangesType,
    GeneralTrackerRequestBody,
    SplittingFormState
} from "./type";
import {Node, RelationsData, TransformedNode, typesRules} from "../types";
import moment from "moment";
import {AutocompleteOption} from "../../inputs/baseAutocomplete/types";
import {DataFromBaseCompilation} from "../RuleTree/DataFromBaseCompilation";

export const overlapDataValid = (starts: capRangeVal[], ends: capRangeVal[]): boolean => {
    if (starts === undefined || starts === [] || ends === []) {
        return false
    }
    return !(starts.some(s => s.value === '') || ends.some(e => e.value === ''));
}

export const checkRangesOverlap = (starts: capRangeVal[], ends: capRangeVal[]): boolean => {
    const startNums = starts.map(s => parseInt(s.value));
    const endNums = ends.map(e => parseInt(e.value));

    for (let i = 0; i < startNums.length; i++) {
        for (let j = i + 1; j < endNums.length; j++) {
            if (startNums[i] <= endNums[j] && startNums[j] <= endNums[i]) {
                return false;
            }
        }
    }

    return true;
};

export const prepareCappingDataForSave = (data: GeneralTrackerRequestBody[], parentId: number | null) => {
    return {
        "type": "unique",
        "value": "not_unique",
        "redirect_type": "landing",
        "parent_id": parentId,
        "package_id": null,
        "url": "",
        "landing_id": null,
        "rule_packages": null,
        "use_x_visits": true,
        "x_visits": data
    }
}

export const checkRangesChain = (starts: capRangeVal[], ends: capRangeVal[], blockId: number): boolean => {
    const index = starts.findIndex(obj => obj.key === blockId);
    const length = starts.length;
    const lastIndex = length - 1;
    if (length === 1) {
        return true;
    }
    if (starts[index] === undefined) {
        return false
    }
    if (starts[index].value === "" || ends[index].value === "") {
        return true;
    }
    if (index === 0) {
        if (starts[index + 1].value === "") {
            return true;
        }
        return parseInt(starts[index + 1].value) === parseInt(ends[index].value) + 1;
    }
    if (index === lastIndex) {
        if (starts[index - 1].value === "") {
            return true;
        }
        return parseInt(starts[index].value) === parseInt(ends[index - 1].value) + 1;
    }
    if (starts[index + 1].value === "" || ends[index + 1].value === "") {
        return true;
    }
    if (parseInt(starts[index + 1].value) !== parseInt(ends[index].value) + 1) {
        return false;
    }
    return parseInt(starts[index].value) === parseInt(ends[index - 1].value) + 1;
};

export const validateRangesChain = (starts: capRangeVal[], ends: capRangeVal[]): boolean => {
    const length = starts.length;
    const lastIndex = length - 1;
    for (let index = 0; index < lastIndex; index++) {
        if (length === 1) {
            return true;
        }
        if (index === 0) {
            if (parseInt(starts[index + 1].value) !== parseInt(ends[index].value) + 1) {
                return true;
            }
        }
        if (index === lastIndex) {
            if (parseInt(starts[index].value) !== parseInt(ends[index - 1].value) + 1) {
                return true
            }
        }
        if (parseInt(starts[index + 1].value) !== parseInt(ends[index].value) + 1) {
            return true
        }
        if (index !== 0) {
            if (parseInt(starts[index].value) !== parseInt(ends[index - 1].value) + 1) {
                return true
            }
        }
    }
    return false;
};

export const generateTimeOptions = (): { label: string, value: string | number | null }[] => {
    const options = [];

    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
            const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
            const timeStr = `${hourStr}:${minuteStr}`;
            options.push({label: timeStr, value: timeStr});
        }
    }

    return options;
};

export const scheduleIntervalError = (dateRanges: dateRangesType): boolean => {
    if (moment(dateRanges.dateFrom).unix() > moment(dateRanges.dateTo).unix()) {
        return true;
    }
    if (dateRanges.timeFrom === "" || dateRanges.timeTo === "") {
        return false;
    }
    if (dateRanges.dateFrom === dateRanges.dateTo) {
        return dateRanges.timeFrom >= dateRanges.timeTo;
    }
    return false;
};

export const scheduleIntervalValid = (dateRanges: dateRangesType): boolean => {
    if (dateRanges.timeFrom === "" || dateRanges.timeTo === "") {
        return false;
    }
    if (dateRanges.dateFrom === dateRanges.dateTo) {
        return dateRanges.timeFrom < dateRanges.timeTo;
    }
    return moment(dateRanges.dateFrom).unix() <= moment(dateRanges.dateTo).unix();

};

export function hasDuplicateSplittings(myArray: SplittingFormState[]): boolean {
    const seenPairs = new Set<string>();
    for (const obj of myArray) {
        const pair = `${obj.brand_id},${obj.landing_id}`;
        if (seenPairs.has(pair)) {
            return true;
        }
        seenPairs.add(pair);
    }
    return false;
}

export function hasEmptySplittings(myArray: SplittingFormState[]): boolean {
    for (const obj of myArray) {
        if (obj.brand_id === "" || obj.landing_id === "") {
            return true
        }
    }
    return false;
}

export function isSingle(nodes: Node[] | null): boolean {
    let result: boolean = false;
    if (nodes === null) {
        return false;
    }
    for (let i: number = 0; i < nodes.length; i += 1) {
        if (nodes[i]['type'] === typesRules.single) {
            return true;
        }
    }
    return result;
}

export function checkRedirectTypeInParentRow(nodes: Node[], parentId: number | string, redirectType: string): boolean {
    function traverse(node: Node | undefined): boolean {
        if (node && node.parent_id !== null) {
            const parentNode = nodes.find(n => n.id == node.parent_id);
            if (parentNode && parentNode.type == redirectType) {
                return true;
            }
            if (node.type == redirectType) {
                return true;
            }
            if (parentNode) {
                return traverse(nodes.find(n => n.id == parentNode.parent_id));
            }
        }
        return false;
    }

    const parentNode = nodes.find(n => n.id == parentId);
    if (parentNode) {
        return traverse(parentNode);
    }

    return false;
}


export function detectBranchByIndex(nodes: TransformedNode[], id: number | null): number | null {
    let result: number | null = null;

    function traverse(node: TransformedNode[], currentIndexes: number[]): boolean {
        for (let i = 0; i < node.length; i++) {
            const newPath = [...currentIndexes, i];

            if (node[i].id == id) {
                result = newPath[0];
                return true;
            }

            if (node[i].children && node[i].children.length > 0) {
                if (traverse(node[i].children, newPath)) {
                    return true;
                }
            }
        }

        return false;
    }

    traverse(nodes, []);

    return result;
}

function geoConditions(list: AutocompleteOption[], parentType: string) {
    if (parentType === typesRules.region) {
        list = list.filter(node => node.value !== typesRules.geo);
    }
    if (parentType === typesRules.city) {
        list = list.filter(node => node.value !== typesRules.geo && node.value !== typesRules.region);
    }
    return list;
}

export function validateRuleList(parentType: string | null, parentId: number | null, allNodeRulesNames: AutocompleteOption[], generalTrackerTree: {
    nodes: Node[],
    relations: RelationsData[]
} | null): AutocompleteOption[] {
    let list = allNodeRulesNames;
    if (parentType !== null && generalTrackerTree !== null) {
        const tree: TransformedNode[] = DataFromBaseCompilation(generalTrackerTree.nodes);
        const curBranchId = detectBranchByIndex(tree, parentId);
        if (curBranchId !== null && parentId !== null) {
            Object.entries(typesRules).forEach(([key, value]) => {
                if (checkRedirectTypeInParentRow(generalTrackerTree.nodes, parentId, value)) {
                    list = geoConditions(list, value);
                    list = list.filter(node => node.value !== value);
                }
            });
        }
        const neighbours: Node[] = generalTrackerTree.nodes.filter(node => node.parent_id === parentId);
        if (neighbours.length > 0) {
            list = allNodeRulesNames.filter(node => node.value === neighbours[0].type);
        }
        list = list.filter(node => node.value !== parentType && node.value !== typesRules.single);
        list = geoConditions(list, parentType);
    } else {
        list = allNodeRulesNames.filter(node => node.value !== typesRules.isEp);
        if (generalTrackerTree !== null) {
            if (generalTrackerTree.nodes.length !== 0) {
                const rootNode: Node[] = generalTrackerTree.nodes.filter(node => node.parent_id === null);
                list = list.filter(node => node.value !== typesRules.single);
                if (rootNode.length > 0) {
                    list = list.filter(node => node.value === rootNode[0].type);
                }
            }
        }
    }
    return list.map((node) => ({label: node.label, value: node.value}));
}

export function validateRuleTypeList(parentType: string | null, parentId: number | null, allNodeRulesNames: AutocompleteOption[], generalTrackerTree: {
    nodes: Node[],
    relations: RelationsData[]
} | null): AutocompleteOption[] {
    let list = allNodeRulesNames;
    if (generalTrackerTree !== null) {
        const neighbours: Node[] = generalTrackerTree.nodes.filter(node => node.parent_id === parentId);
        let used: string[] = [];
        for (const neighbour of neighbours) {
            used = neighbour.value.split(',')
            for (const item of used) {
                list = list.filter(node => node.value !== item);
            }
            if (used[0] === 'not_isEp' || used[0] === 'isEp') {
                list = list.filter(node => node.value !== 'not_isEp' && node.value !== 'isEp');
            }
        }
    }
    return list.map((node) => ({label: node.label, value: node.value}));
}


export const getOptionListFromNodeStrings = (str1: string, str2: string): { label: string, value: string }[] => {
    const arr1 = str1.split(',');
    const arr2 = str2.split(',');
    const result = [];
    for (let i = 0; i < arr1.length; i++) {
        result.push({label: arr1[i], value: arr2[i]});
    }
    return result;
};

export const divideLink = (link: string): [string, string] => {
    const regex = /^((?:https?:\/\/)?)([\w.]+)(\/.*)$/;
    const match = link.match(regex);
    if (!match) {
        throw new Error('Invalid link format');
    }
    const [, protocol, domain, slug] = match;
    return [protocol + domain, slug];
};