import React from "react";
import Link from "./Link/Link";
import IconButton from "@mui/material/IconButton";
import {SvgIcon} from "@mui/material";
import DarkTooltip from "./DarkTooltip/DarkTooltip";

interface CustomIconButtonProps {
    title: string;
    component: React.ElementType;
    onClick?: () => void;
    to?: string;
}

const CustomIconButton = ({title, component, onClick, to}: CustomIconButtonProps) => to
    ? (
        <Link to={to}>
            <DarkTooltip title={title}>
                <IconButton onClick={onClick} sx={{height: '36px', "&:hover": {background: '#EFEDE9'}}}>
                    <SvgIcon component={component} sx={{'width': '20px', 'height': '20px', 'color': '#7B756B', '&:hover': { 'color': '#575249' }}}/>
                </IconButton>
            </DarkTooltip>
        </Link>)
    : (
        <DarkTooltip title={title}>
            <IconButton onClick={onClick} sx={{height: '36px', "&:hover": {background: '#EFEDE9'}}}>
                <SvgIcon component={component} sx={{'width': '20px', 'height': '20px', 'color': '#7B756B', '&:hover': { 'color': '#575249' }}}/>
            </IconButton>
        </DarkTooltip>
    );

export default CustomIconButton;