import {UrlParams} from "../types";
import {AsyncThunk} from "@reduxjs/toolkit/src/createAsyncThunk";
import {useState} from "react";
import {useAppDispatch} from "../../../app/hooks";
import {useDidUpdateEffect} from "./useDidUpdateEffect";

export function useTableRequest<Data>(
    urlFilters: UrlParams,
    request:  AsyncThunk<Data, UrlParams | undefined, {}>,
    additionalFilters: {[key: string]: any}
):() => void {
    const dispatch = useAppDispatch();
    const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

    useDidUpdateEffect(() => {
        dispatch(request({...urlFilters, ...additionalFilters}));
    }, [dispatch, request, urlFilters, shouldRefresh])

    return () => setShouldRefresh(!shouldRefresh)
}