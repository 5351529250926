import {styled} from "@mui/material/styles";
import Box, {BoxProps} from "@mui/material/Box/Box";

export const SmartlinkRuleFormBlockStyled = styled(Box)<BoxProps>(({theme}) => ({
    ".smartlink-rule-form": {
        border: `1px solid ${theme.palette.text.secondary}`,
        width: "680px",
        minHeight: "50px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "7px 0 7px 24px",
        position: "relative",
        ".MuiFormControl-root": {
            margin: "0 16px",
            width: "180px",
            ".MuiOutlinedInput-root": {
                width: "180px"
            },
            ".MuiSelect-select": {
                paddingTop: "4.5px",
                paddingBottom: "4.5px"
            },
            ".MuiInputLabel-root": {
                lineHeight: "1em"
            }
        },
        ".autocomplete-rule": {
            width: "213px",
            ".MuiAutocomplete-input": {
                padding: "0 4px 0 6px"
            },
            ".MuiOutlinedInput-root": {
                padding: "4.5px 6px"
            },
            ".MuiInputLabel-shrink": {
                top: "3px"
            }
        },
        ".autocomplete-type": {
            margin: "0 16px",
            width: "242px",
            ".MuiOutlinedInput-root": {
                width: "242px",
                padding: "4.5px 54px 4.5px 6px"
            },
            ".MuiFormControl-root": {
                margin: "0",
                width: "224px"
            },
            ".MuiAutocomplete-input": {
                padding: "0 4px 0 6px"
            },
            ".MuiAutocomplete-input::-webkit-input-placeholder": {
                color: theme.palette.text.primary
            },
            ".MuiAutocomplete-input::-moz-placeholder": {
                color: theme.palette.text.primary
            },
            ".MuiAutocomplete-input:-ms-input-placeholder": {
                color: theme.palette.text.primary
            },
            ".MuiAutocomplete-input:-moz-placeholder": {
                color: theme.palette.text.primary
            },
            ".MuiAutocomplete-tag": {
                height: "24px",
                fontSize: "14px",
                ".MuiChip-deleteIcon": {
                    fontSize: "13px"
                }
            },
            ".MuiInputLabel-shrink": {
                top: "3px"
            }
        }
    },
    ".smartlink-rule-form__condition": {
        color: theme.palette.text.secondary,
        fontSize: "16px",
        "&.single": {
            position: "absolute",
            right: "16px"
        }
    },
    ".smartlink-rule-form__switch": {
        borderRadius: "4px",
        backgroundColor: theme.palette.secondary.main,
        display: "flex",
        overflow: "hidden",
        fontSize: "12px",
        fontWeight: "500",
    },
    ".smartlink-rule-form__switch-variant": {
        textTransform: "uppercase",
        padding: "9px 10px",
        ".active": {
            backgroundColor: theme.palette.primary.light
        }
    },
    ".smartlink-rule-form__arrow": {
        transform: "rotate(180deg)",
        marginLeft: "10px"
    }
}));

