import React from 'react';
import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";

interface FormFooterProps {
    handleSubmit: () => void;
    handleClose: () => void;
    disabled: boolean;
}

function FormFooter(props: FormFooterProps) {
    const {handleSubmit, handleClose, disabled} = props;
    const {t} = useTranslation();

    return (
        <Box className="modal-rule-configuration-footer">
            <Button
                className="btn-schedule BtnPurple"
                onClick={handleSubmit}
                disabled={disabled}
            >
                {t("smartlinks.modal_rule_configuration.button_save")}
            </Button>
            <Button
                className="btn-schedule BtnGrey"
                onClick={handleClose}
            >
                {t("smartlinks.modal_rule_configuration.button_cancel")}
            </Button>
        </Box>
    );
}

export default FormFooter;
