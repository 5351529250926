import {Dialog} from "@mui/material";
import {styled} from "@mui/material/styles";

const TableDialog = styled(Dialog)(({ theme }) => ({
    padding: "24px",
    '& .MuiDialogTitle-root': {
        textAlign: 'left',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '0 36px'
    },
    '& .MuiDialogContentText-root': {
        padding: "0 36px",
        width: "700px",
    },
    '& .MuiDialogActions-root': {
        textAlign: 'center'
    },
    '& .MuiDialogContent-root': {
        margin: "32px 0 24px 0",
        height: '622px',
        paddingTop: 0,
    },
    '& .MuiDialog-paper': {
        maxWidth: '920px',
        width: '920px',
        paddingTop: '34px'
    },
}));

export default TableDialog;