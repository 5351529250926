import {UrlParams} from "../../../componets/Table/types";
import {landingsListApi} from "../../store";

const fetchLandingsListCategoryTypes = async (filters?: UrlParams) => {
    return landingsListApi.get('/v1/types', filters);
}
const fetchLandingsListAllCategoryTypes = async () => {
    return landingsListApi.get('/v1/types/all');
}

export { fetchLandingsListCategoryTypes, fetchLandingsListAllCategoryTypes };