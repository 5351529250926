export const singleChipList = [
    'title',
    'url',
    'name',
    'general_tracker_url',
    'landings',
    'brands',
    'geo',
    'id',
    'short_url',
    'target_url',
    'slug',
    'funnel',
    'tag',
    'status',
    'productId',
    'language',
    'owners',
    'teams'
];

export const singleChipListFromObj = [
    'companyName',
    'productName',
    'os',
    'countryCode',
    'region',
    'platform',
    'browser',
    'language',
    'trafficType',
    'teamName',
    'isEp',
    'landingName',
    'smartlinkName'
];

export const urlSerchTitles: Record<string, string> = {
    products: 'products.table.columns.',
    shortener: 'shortener.table.columns.',
    landing_create: 'landings.table.columns.',
    trackers: 'common.tracker_filters.'
};

