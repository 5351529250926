import {prismApi} from "../store";
import {UrlParams} from "../../componets/Table/types";

const fetchCountries = async (filters?: UrlParams) => {
    return prismApi.get(`/countries`, {...filters});
}

const fetchLanguages = async (filters?: UrlParams) => {
    return prismApi.get(`/languages`, {...filters});
}

const fetchCity = async (alternatenames: string, country_code: string, filter_region: string) => {
    return prismApi.get(`cities/search/`, {alternatenames, country_code, filter_region});
}

const fetchDevices = async (filters?: UrlParams) => {
    return prismApi.get(`/devices`, {...filters});
}

const fetchRegions = async (asciiname: string, country_code: string) => {
    return prismApi.get(`regions/search/`, {asciiname, country_code});
}

export {fetchCountries, fetchLanguages, fetchCity, fetchDevices, fetchRegions};
