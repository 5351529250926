import {prismApi} from "../store";

const createReportRequest = async (params: any) => {
    if (params?.hasOwnProperty('page')) {
        params.pageNumber = Number(params.page);
        delete params.page;
    }

    if (params?.hasOwnProperty('per_page')) {
        params.pageSize = Number(params.per_page);
        delete params.per_page;
    }

    if (params?.hasOwnProperty('sort_field')) {
        params.sortField = params.sort_field ? params.sort_field : null;
        delete params.sort_field;
    }

    if (params?.hasOwnProperty('sort_order')) {
        params.sortOrder = params.sort_order ? params.sort_order : null;
        delete params.sort_order;
    }

    return prismApi.post('v1/report/general', undefined, params);
}

export {createReportRequest};
