import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CategoryData, DomainData, TypeData} from "./types";
import {RootState} from "../../store";
import {fetchLandingsListCategories} from "../../requests/landingsList/categories";
import {
    fetchLandingsListAllCategoryTypes,
    fetchLandingsListCategoryTypes
} from "../../requests/landingsList/categoryTypes";

export interface LandingsListCategoryTypesState {
    types: PaginatedData<TypeData> | null;
    status: RequestStatuses;
}

const initialState: LandingsListCategoryTypesState = {
    types: null,
    status: RequestStatuses.idle,
};

export const getLandingsListCategoryTypes = createAsyncThunk(
    'brands/fetchLandingsListCategoryTypes',
    async (filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            return await fetchLandingsListCategoryTypes({ ...filters });
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const getLandingsListAllCategoryTypes = createAsyncThunk(
    'brands/fetchLandingsListAllCategoryTypes',
    async (filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            return await fetchLandingsListAllCategoryTypes();
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const landingsListCategoryTypesSlice = createSlice({
    name: 'landingsListCategories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingsListCategoryTypes.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandingsListCategoryTypes.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.types = action.payload;
            })
            .addCase(getLandingsListAllCategoryTypes.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandingsListAllCategoryTypes.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.types = action.payload;
            });
    },
});

export const selectLandingsListCategoryTypes = (state: RootState) => state.landingsListCategoryTypes.types?.data ?? [];
export const selectLandingsListCategoryTypesStatus = (state: RootState) => state.landingsListCategoryTypes.status;
export const selectLandingsListAllCategoryTypes = (state: RootState) => state.landingsListCategoryTypes.types?.data ?? [];
export const selectLandingsListAllCategoryTypesStatus = (state: RootState) => state.landingsListCategoryTypes.status;


export default landingsListCategoryTypesSlice.reducer;