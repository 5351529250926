import React, {CSSProperties, useState} from 'react';
import './Tooltip.scss';
import LinkIcon from '@mui/icons-material/Link';
import {copyToClipboard} from "../Helpers/clipboard";

interface TooltipProps {
    url: string;
    styles?: CSSProperties;
    numberLetters?: number;
    name?: string;
}

export default function Tooltip(props: TooltipProps) {
    const {url, styles, numberLetters = 60, name} = props;
    const [show, setShow] = useState(false);
    const visibility = show ? 'visible' : '';

    const handleOnMouseEnter = () => {
        setShow(true);
    }

    const handleOnMouseLeave = () => {
        // if need a pause before closing tooltip
        // setTimeout(()=>{
        //     setShow(false);
        // }, 300);

        setShow(false);
    }

    const checkUrlLength = (url: string, length: number) => {
        return url.length > length ? url.slice(0, length) + '...' : url;
    }

    const handleClickCopy = (e: React.MouseEvent<HTMLButtonElement> ) => {
        e.stopPropagation();
        copyToClipboard(url);
    }

    return (
        <div
            className="tooltip-wrap"
            onMouseEnter={() => handleOnMouseEnter()}
            onMouseLeave={() => handleOnMouseLeave()}>
            <span className="tooltip-tab-url" style={styles}>{name ?? checkUrlLength(url, numberLetters)}</span>
            <div className={visibility + " tooltip"}>
                <div className="tooltip-icon">
                    <LinkIcon fontSize={"small"}/>
                </div>
                <div className="tooltip-link">
                    <p className="tooltip-link-text">{checkUrlLength(url, 96)}</p>
                </div>
                <a
                    href={url}
                    target="_blank"
                    className="tooltip-btn"
                    rel="noreferrer"
                >
                    Open link
                </a>
                <button
                    type="button"
                    className="tooltip-btn tooltip-btn-copy"
                    onClick={(e)=>handleClickCopy(e)}
                >
                    Copy
                </button>
                <span className="tooltip-arrow"/>
            </div>
        </div>
    );
}
