import React, {useCallback, useEffect, useState} from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {isNumber, isString, isBoolean} from "lodash";
import IconButton from "@mui/material/IconButton";
import CloseRounded from '@mui/icons-material/CloseRounded';
import {SelectOption, SelectValue} from "../Table/filters/types";
import {AppFilterValue} from "../Table/types";
import Tooltip from "@mui/material/Tooltip";
import {FormHelperText, InputAdornment,  SvgIcon} from "@mui/material";
import {ReactComponent as QuestionIcon} from "../../app/assets/img/icons/questionGray.svg";
import {useTranslation} from "react-i18next";
import './BaseSelect.scss';

interface BaseSelectProps {
    fieldName: string
    defaultOptionValue?: SelectValue;
    onChangeHandler: (option: AppFilterValue) => void;
    valueMutator?: (option: AppFilterValue) => AppFilterValue;
    placeholder: string;
    onBlur?: (e: React.FocusEvent<any>) => void;
    label?: string;
    value?: string;
    options: SelectOption[];
    questionMark?: boolean;
    tooltipTitle?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    sx?: object;
    clearIcon?: boolean;
}

export function BaseSelect(props: BaseSelectProps) {
    const { t } = useTranslation();

    const {
        defaultOptionValue = '',
        onChangeHandler,
        valueMutator,
        options,
        placeholder,
        label = 'label',
        value = 'value',
        onBlur,
        questionMark = false,
        tooltipTitle = '',
        error,
        helperText,
        clearIcon
    } = props;

    const [inputValue, setInputValue] = useState(defaultOptionValue || '');
    const [open, setOpen] = useState(false);

    const onInputChanged = useCallback((inputValue: string) => {
        let value: AppFilterValue = inputValue;
        if (valueMutator) {
            value = valueMutator(value);
        }
        setInputValue(inputValue);
        onChangeHandler(value === '' ? null : value)
    }, [])

    useEffect(() => {
        onInputChanged(String(defaultOptionValue));
    }, [defaultOptionValue, onInputChanged])

    const getValue = (value: SelectValue): string | number | undefined => {
        if (isString(value) || isNumber(value)) {
            return value
        }
        if (isBoolean(value)) {
            return String(value);
        }
        return '';
    }

    const getLabel = (option: SelectOption, label: string) => {
        const labelText = option[label];
        if (isString(labelText)) {
            return labelText;
        }
        return ''
    }

    const handleClickClear = () => {
        setInputValue('');
        setOpen(false);
        onInputChanged('');
    }

    return (
        <React.Fragment>
            <FormControl size="small" sx={props.sx ?? {width: 230}} error={error}>
                <InputLabel id='form-select-label'>{t(placeholder)}
                    {questionMark && tooltipTitle && <Tooltip
                        title={tooltipTitle}>
                        <SvgIcon
                            component={QuestionIcon}
                            sx={{verticalAlign: 'middle', paddingLeft: '4px'}}
                        />
                    </Tooltip>}
                </InputLabel>
                <Select
                    className='base-select'
                    sx={props.sx ?? {width: 230}}
                    onBlur={onBlur ? onBlur : undefined}
                    labelId='form-select-label'
                    defaultValue={defaultOptionValue ?? ''}
                    label={placeholder}
                    value={inputValue}
                    onChange={(event) => {
                        onInputChanged(String(event.target.value))
                    }}
                    error={error}
                    endAdornment={clearIcon && inputValue && open && <IconButton className="clear-btn" onClick={handleClickClear}><CloseRounded /></IconButton>}
                    onOpen={()=> {
                        setOpen(true)
                    }}
                    onClose={()=> {
                        setOpen(false)
                    }}
                    open={open}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={String(option[label])}
                            value={getValue(option[value])}
                        >
                            {t(getLabel(option, label))}
                        </MenuItem>
                    ))}
                </Select>
                {error && helperText &&
                <FormHelperText sx={{color: 'red'}}>{helperText}</FormHelperText>}
            </FormControl>
        </React.Fragment>
    )
}
