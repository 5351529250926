import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {Link, useParams} from 'react-router-dom';
import './Shortlink.scss';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import StarIcon from '@mui/icons-material/Star';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import {useTranslation} from "react-i18next";
import {EditableText} from "../inputs/EditableText";
import {getShortUrl, selectShortUrl, updateShortlink} from "../../app/reducers/prism/shortUrlsSlice";
import {Box} from "@mui/material";
import RadioGroupBase from "../inputs/RadioGroupBase/RadioGroupBase";
import {ShortUrlSettings, UserTeam} from "../../app/reducers/prism/types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CreateShortlink from "../Shortlinks/CreateShortlink/CreateShortlink";
import {Grid} from "@mui/material/";
import StyledDialog from "../modals/Dialog/StyledDialog";
import CustomAlert from "../Alert/CustomAlert";
import Routes from "../../navigation/routes";

const Shortlink = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {shortlinkId} = useParams();
    const shortlink = useAppSelector(selectShortUrl);
    const [shortlinkName, setShortlinkName] = useState<string>(shortlink?.title ?? '');
    const [activeRedirectType, setActiveRedirectType] = useState<string>(t('shortener.shortlink_page.redirect_type_301'));
    const [tempActiveRedirectType, setTempActiveRedirectType] = useState<string>('');
    const [teams, setTeams] = useState<string>('');
    const [openRedirectTypeDialog, setOpenRedirectTypeDialog] = useState<boolean>(false);
    const [isEditShortlink, setIsEditShortlink] = useState<boolean>(false);
    const [uploadData, setUploadData] = useState<boolean>(true);
    const [isSuccessfullySaved, setIsSuccessfullySaved] = useState<boolean>(false);
    const [dataDispatch, setDataDispatch] = useState<ShortUrlSettings>({
        short_url_id: 0,
        target_url: '',
        title: '',
        with_loader: false
    });

    const getTeamsNames  = () => {
        let teams = '';
        if (shortlink?.createdBy?.teams?.length) {
            teams = shortlink.createdBy.teams.map((team) => team.name).join(',');
        }
        return teams;
    }

    const handleRadioChange = (redirectType: string) => {
        setTempActiveRedirectType(redirectType);
        activeRedirectType !== redirectType && setOpenRedirectTypeDialog(true);
    }

    const handleChangeRedirectType = async () => {
        await dispatch(
            updateShortlink({
                short_url_id: dataDispatch.short_url_id,
                with_loader: !dataDispatch.with_loader,
                domain_id: String(shortlink?.domain_id),
                slug: shortlink?.slug,
            })
        );
        setActiveRedirectType(tempActiveRedirectType);
        setOpenRedirectTypeDialog(false);
        setUploadData(true);
        showNotification();
    }

    const handleCloseDialog = () => {
        setOpenRedirectTypeDialog(false);
    };

    const showNotification = () => {
        setIsSuccessfullySaved(true);
        setTimeout(function () {
            setIsSuccessfullySaved(false);
        }, 5000);
    }

    useEffect(() => {
        setShortlinkName(shortlink?.title ?? '');
        setTeams(getTeamsNames ());
        if (shortlink) {
            setDataDispatch(
                {
                    short_url_id: shortlink?.id,
                    target_url: shortlink?.target_url,
                    title: shortlink?.title,
                    with_loader: shortlink?.with_loader
                }
            );
            shortlink?.with_loader
            ? setActiveRedirectType(t('shortener.shortlink_page.redirect_type_302'))
            : setActiveRedirectType(t('shortener.shortlink_page.redirect_type_301'));
        }
    }, [shortlink]);

    useEffect(() => {
        if (uploadData) {
            dispatch(getShortUrl(Number(shortlinkId)));
            setUploadData(false);
        }
    }, [dispatch, uploadData]);

    const radioGroupContent = [
        {
            value: t('shortener.shortlink_page.redirect_type_301'),
            label: t('shortener.shortlink_page.redirect_type_301'),
            hint: t('shortener.shortlink_page.redirect_type_301_hint')
        },
        {
            value: t('shortener.shortlink_page.redirect_type_302'),
            label: t('shortener.shortlink_page.redirect_type_302'),
            hint: t('shortener.shortlink_page.redirect_type_302_hint')
        }
    ];

    return (
        <div className="tracker-list-container shortlink-list-container">
            <div className="tracker-list-title">
                <Grid container>
                    <Grid item xs={12}>
                        <div className="breadcrumbs row">
                            <Link className="back" to={Routes.shortener}>
                                <StarIcon className={"icon-star back"}/><span>{t('shortener.breadcrumbs.shortlinks')}</span>
                            </Link>
                            <span className="divider">/</span>
                            <Link to={`${Routes.shortener}/${shortlinkId}`}>
                                <StarIcon
                                    className={"icon-star"}/><span>{t('shortener.breadcrumbs.shortlink') + shortlinkId}</span>
                            </Link>
                        </div>
                        <h1 className='row'>
                        <Link className="back" to={Routes.shortener}>
                            <ArrowBackOutlined/>
                        </Link>
                        {t('shortener.shortlink_page.shortlink_page_title') + '"' + shortlinkName + '"'}
                    </h1>
                    </Grid>
                </Grid>
            </div>
            <div className="shortlink-settings">
                <div className="shortlink-list-left-col">
                    <div className='row product-row'>
                        <EditableText
                            fieldName="title"
                            placeholder={t('shortener.shortlink_page.enter_shortlink_name')}
                            label={t('shortener.shortlink_page.shortlink_name')}
                            value={shortlinkName}
                            handleEditOnForm={setIsEditShortlink}
                        />
                    </div>
                    <div className='row product-row'>
                        <EditableText
                            fieldName="short_url"
                            tooltip={'hint'}
                            placeholder={t('shortener.shortlink_page.enter_shortlink')}
                            label={t('shortener.shortlink_page.shortlink')}
                            value={shortlink?.short_url ?? ''}
                            linkIcon={true}
                            type="link"
                            handleEditOnForm={setIsEditShortlink}
                        />
                    </div>
                    {/*<Box className="editable_text__label">*/}
                    {/*    {t('shortener.shortlink_page.shortlinks_parameters')}*/}
                    {/*    <DarkTooltip*/}
                    {/*        title={"hint"}>*/}
                    {/*        <InfoOutlinedIcon fontSize="small" className="editable_text__tooltip"/>*/}
                    {/*    </DarkTooltip>*/}
                    {/*</Box>*/}
                    {/*<Box className="add-new-parameters">*/}
                    {/*    <AddCircleIcon className='add-plus-icon' sx={{width: 15, height: 15}}/>*/}
                    {/*    <span className="add-new-parameters__text">{t('shortener.shortlink_page.shortlinks_add_parameters')}</span>*/}
                    {/*</Box>*/}
                    <div className='row product-row'>
                        <EditableText
                            fieldName="target_url"
                            tooltip={'hint'}
                            placeholder={t('shortener.shortlink_page.enter_full_url')}
                            label={t('shortener.shortlink_page.full_url')}
                            value={shortlink?.target_url ?? ''}
                            linkIcon={true}
                            type="link"
                            handleEditOnForm={setIsEditShortlink}
                        />
                    </div>
                    <Box className="editable_text__label">
                        {t('shortener.form.redirect_type')}
                    </Box>
                    <RadioGroupBase
                        activeRadio={activeRedirectType}
                        handleRadioChange={handleRadioChange}
                        radioGroupContent={radioGroupContent}
                    />
                </div>
                <div className="shortlink-list-right-col">
                    <div className="owners-details-block owners-details-block__disabled">
                        <p className="owners-details-block__title">{t('shortener.shortlink_page.owners_details')}</p>
                        <EditableText
                            fieldName="company"
                            placeholder=""
                            label={t('shortener.shortlink_page.shortlinks_owner')}
                            value={shortlink?.createdBy?.email ? shortlink?.createdBy.email : ''}
                            copy={false}
                            disabled={true}
                        />
                        <EditableText
                            fieldName="teams_access"
                            placeholder=""
                            label={t('shortener.shortlink_page.owners_team')}
                            value={teams}
                            copy={false}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
            <StyledDialog className="change-redirect-type-modal" open={openRedirectTypeDialog} onClose={handleCloseDialog}>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    className="close-cross"
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    {t('shortener.modals.change_redirect_type')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('shortener.modals.change_redirect_type_description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={"BtnPurple"}
                        onClick={handleChangeRedirectType}
                    >
                        {t('shortener.modals.change')}
                    </Button>
                    <Button
                        className={"BtnGrey"}
                        onClick={handleCloseDialog}
                    >
                        {t("statistic.trackers.cancel")}
                    </Button>
                </DialogActions>
            </StyledDialog>
            {isEditShortlink && <CreateShortlink
                handleClose={() => {setIsEditShortlink(false)}}
                shortlinkData={shortlink}
                setUploadData={setUploadData}
                showNotification={showNotification}
                formEdit
            />}
            <CustomAlert
                type="success"
                title={t('shortener.shortlink_page.successfully_saved')}
                status={isSuccessfullySaved}
            />
        </div>
    )
}

export default Shortlink;
