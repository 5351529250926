import {UrlParams} from "../../componets/Table/types";
import {prismApi} from "../store";

const fetchDictionaryProducts = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/product', {...filters});
}

const fetchDictionaryCompany = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/company', {...filters});
}

const fetchDictionaryOs = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/os', {...filters});
}

const fetchDictionaryTeams = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/team', {...filters});
}

const fetchDictionaryGEOs = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/geo', {...filters});
}

const fetchDictionaryRegions = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/region', {...filters});
}

const fetchDictionaryPlatforms = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/platform', {...filters});
}

const fetchDictionaryBrowsers = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/browser', {...filters});
}

const fetchDictionaryLanguages = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/language', {...filters});
}

const fetchDictionaryLandings = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/landing', {...filters});
}

const fetchDictionarySmartlink = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/smartlink', {...filters});
}

const fetchDictionaryTrafficTypes = async (filters?: UrlParams) => {
    return prismApi.get('/v1/dictionary/trafficType', {...filters});
}

export {
    fetchDictionaryProducts,
    fetchDictionaryCompany,
    fetchDictionaryOs,
    fetchDictionaryGEOs,
    fetchDictionaryTeams,
    fetchDictionaryRegions,
    fetchDictionaryPlatforms,
    fetchDictionaryBrowsers,
    fetchDictionaryLanguages,
    fetchDictionaryTrafficTypes,
    fetchDictionaryLandings,
    fetchDictionarySmartlink
};
