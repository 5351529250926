import React, {useState, Dispatch, useEffect} from 'react';
import Box from '@mui/material/Box';
import BaseTable from '@mui/material/Table';
import {bulk_check} from "./columns";
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import {omit} from 'lodash';
import Typography from '@mui/material/Typography';

import {
    ColumnConfigWithRender,
    DataRow,
    HandlerCondition,
    MouseEvent,
    SearchConfig,
    StringKeys,
    UrlParams
} from "./types";
import {TableHead} from "./components/TableHead";
import {Row} from "./components/Row";
import {Filters} from "./filters/Filters";
import {FilterConfig, FiltersFromObj} from "./AppliedFilterBar/types";
import {ControlBarWrapper} from "./ControlBarWrapper/ControlBarWrapper";
import {AppliedFilterBar} from "./AppliedFilterBar/AppliedFilterBar";
import {ControlBarConfig} from "./ControlBarWrapper/types";
import Search from "./Search/Search";
import {PaginatedData} from "../../app/types";
import {AsyncThunk} from "@reduxjs/toolkit/src/createAsyncThunk";
import {useOrder} from "./hooks/useOrder";
import {useOrderBy} from "./hooks/useOrderBy";
import {usePage} from "./hooks/usePage";
import {usePerPage} from "./hooks/usePerPage";
import {useTableRequest} from "./hooks/useTableRequest";
import {useFiltersNavigate} from "./hooks/useFiltersNavigate";
import {useFilters} from "./hooks/useFilters";
import './Table.scss';
import {useTranslation} from "react-i18next";
import {Checkbox} from "@mui/material";
import {Sticky} from "./sticky/Sticky";
import {ItemProps} from "../MultiSelect/MultiSelect";
import _uniqueId from 'lodash/uniqueId';
import {ReportsData} from "../../app/reducers/prism/types";

export interface TableProps<Data, Handlers> {
    tableName: string;
    filtersConfig?: FilterConfig[]
    controlBarConfig?: ControlBarConfig[]
    columnsConfig: ColumnConfigWithRender<Data, Handlers>[]
    additionalSearches?: SearchConfig<Data>[];
    requestHandler: AsyncThunk<Data, UrlParams | undefined, {}>
    data: PaginatedData<Data> | null
    uniqueField: string,
    defaultSortCol?: string,
    fieldHandlers?: HandlerCondition<Data, Handlers>,
    onCreateClick?: (event?: React.MouseEvent<HTMLElement>) => void,
    hideOldFilters?: boolean
    ModalComponent?: any;
    modalComponentProps?: any;
    createButtonTitle?: string;
    defaultSearchField?: string;
    tableColumns?: ItemProps[];
    setStatisticTableColumns?: Dispatch<ItemProps[]>;
    isHideTableBody?: boolean;
    setIsHideTableBody?: Dispatch<boolean>;
    showAlert?: (message: string) => void;
    columnsConfigForCustomize?: ColumnConfigWithRender<ReportsData, Handlers>[];
    setFirstColOnTable?: Dispatch<string>;
}

export default function Table<Data extends DataRow, Handlers = undefined>(props: TableProps<Data, Handlers>) {
    const {t} = useTranslation();
    const {
        tableName,
        filtersConfig = [],
        controlBarConfig = [],
        columnsConfig,
        requestHandler,
        data,
        uniqueField,
        defaultSortCol,
        fieldHandlers,
        additionalSearches = [],
        onCreateClick,
        hideOldFilters = true,
        ModalComponent,
        tableColumns,
        setStatisticTableColumns,
        defaultSearchField,
        isHideTableBody,
        setIsHideTableBody,
        showAlert,
        columnsConfigForCustomize,
        setFirstColOnTable
    } = props;

    const ifNoDefaultSortCol = tableName === 'statistics' ? '' : 'id';
    const defaultSortColUsed = defaultSortCol ? defaultSortCol : ifNoDefaultSortCol;
    const [order, setOrder] = useOrder();
    const [orderBy, setOrderBy] = useOrderBy(defaultSortColUsed);
    const [page, setPage] = usePage();
    const [rowsPerPage, setRowsPerPage] = usePerPage();
    const [oldSortProp, setOldSortProp] = useState(defaultSortCol);
    const [selected, setSelected] = useState<Array<string | number>>([]);
    const [controlBarState, setControlBarState] = useState<'controls' | 'customize' | 'bulk'>("controls");
    const [dataBulk, setDataBulk] = useState<number[]>([]);
    const [bufferColList, setBufferColList] = useState<{ [key: string]: number }>({});
    const [customisationLoaded, setCustomisationLoaded] = useState<boolean>(false);
    const [objFilters, setObjFilters] = useState<FiltersFromObj>({});
    const [clearObjFilters, setClearObjFilters] = useState<boolean>(false);
    const [filtersListToRemove, setFiltersListToRemove] = useState<FiltersFromObj>({});
    const [additionalFilters, setAdditionalFilters] = useState<{[key: string]: any}>({})

    const [urlFilters, addFilters, clearFilters] = useFilters(filtersConfig, rowsPerPage, page, orderBy, order);
    const chooseAllFavourites = () => {
        console.log(filtersConfig, rowsPerPage, page, orderBy, order)
    }

    const buckMode = () => {
        setControlBarState("bulk")
    };

    const customizeTable = () => {
        setControlBarState("customize")
    }

    const resetControlBar = () => {
        setControlBarState("controls")
        setBufferColList({});
        setCustomisationLoaded(false);
    }

    useFiltersNavigate(urlFilters);
    const refreshData = useTableRequest(urlFilters, requestHandler, additionalFilters);

    const rows = data?.data ?? [];
    const count = data?.query_parameters?.count ?? 0;

    // const getUniqueFieldValue = (row: Data): string | number => {
    //     const uniqueFieldValue = row[uniqueField];
    //     if (typeof uniqueFieldValue !== 'number' && typeof uniqueFieldValue !== 'string') {
    //         throw new Error('selected value should be strung')
    //     }
    //     return uniqueFieldValue
    // }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: StringKeys<Data>,
    ) => {
        if (oldSortProp !== property) {
            setOrder('asc');
            setOrderBy(property);
            setOldSortProp(property);
        } else if (orderBy !== defaultSortCol && order === 'asc') {
            setOrder('desc');
            setOrderBy(property);
            setOldSortProp(property);
        } else if (defaultSortColUsed === property && order === 'desc') {
            setOrder('asc');
            setOrderBy(defaultSortColUsed);
            setOldSortProp(defaultSortColUsed);
        } else if (oldSortProp === property && order === 'desc') {
            setOrder('desc');
            setOrderBy(defaultSortColUsed);
            setOldSortProp(defaultSortColUsed);
        }
    };

    // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.checked) {
    //         const newSelected = rows.map<string | number>((row) => getUniqueFieldValue(row));
    //         setSelected(newSelected);
    //         return;
    //     }
    //     setSelected([]);
    // };

    const handleChangePage = (event: MouseEvent | null, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const perPage = parseInt(event.target.value, 10);
        setRowsPerPage(perPage);
        setPage(1);
    };
    const rowsPerPageOptions = [
        10, 20, 30, 40, 50, 100, 150
    ];

    const [showSearch, setShowSearch] = useState<boolean>(false);

    const handleShowSearch = () => {
        setShowSearch(!showSearch);
    }

    const bulkAction = (id: number) => {
        let bulkList: number[] = dataBulk;
        if (!bulkList.find(item => item === id)) {
            bulkList.push(id);
            setDataBulk(bulkList);
        } else {
            bulkList = bulkList.filter(selectedId => selectedId !== id);
            setDataBulk(bulkList);
        }
    }

    let columnsConfigSorted: any = [];
    if (controlBarState === 'bulk') {
        columnsConfigSorted = columnsConfig.filter(coll => coll.field !== "favorite");
        columnsConfigSorted = [
            {
                ...bulk_check,
                sortable: false,
                customisable: false,
                render: (row: any) => {
                    return (
                        <React.Fragment key={`landing_list_bulk-${row.id}`}>
                            <Checkbox
                                checked={dataBulk.find(item => item === row.id) === undefined ? false : true}
                                onChange={() => bulkAction(row.id)}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        </React.Fragment>
                    )
                }
            },
            ...columnsConfigSorted
        ];
    }

    if (columnsConfigSorted.length < 1) {
        columnsConfigSorted = columnsConfig;
    }
    localStorage.getItem('tableList') ?? localStorage.setItem('tableList', '{"landing_create":{"company":1,"status":1,"dao_tags":1,"funnel":1,"languages":1}}');
    const tableListString: string | null = localStorage.getItem('tableList');
    let tableList: any = tableListString === null ? {} : JSON.parse(tableListString);

    if (!tableList.hasOwnProperty(tableName)) {
        tableList = {
            ...tableList,
            [tableName]: {}
        };
        setBufferColList({});
        localStorage.setItem('tableList', JSON.stringify(tableList));
    }

    if (controlBarState === 'customize' && Object.keys(bufferColList).length === 0 && Object.keys(tableList[tableName]).length !== 0 && !customisationLoaded) {
        columnsConfigSorted = columnsConfigForCustomize;
        setBufferColList(tableList[tableName]);
        setCustomisationLoaded(true);
    }

    if (controlBarState !== 'customize') {
        Object.keys(tableList[tableName]).map((key) => {
            columnsConfigSorted = columnsConfigSorted.filter((coll: any) => coll.field !== key);
        });
    }

    const columnSwitch = (field: string) => {
        if (bufferColList && bufferColList[field]) {
            let listCopy = {...bufferColList};
            const fieldIndex = field as keyof typeof listCopy;
            setBufferColList(omit(listCopy, [fieldIndex]));
        } else {
            setBufferColList({
                [field]: 1,
                ...bufferColList
            })
        }
    }

    useEffect(() => {
        if (setFirstColOnTable && columnsConfigSorted.length) {
            setFirstColOnTable(columnsConfigSorted[0].field);
        }
    }, [columnsConfigSorted, setFirstColOnTable])

    return (
        <Box className={isHideTableBody ? `table-container table-container-${tableName} table-container-hide-body` : `table-container table-container-${tableName}`}>
            {!hideOldFilters && (
                <div className="filters-bar">
                    {filtersConfig.length > 0 &&
                    <Filters
                        urlFilters={urlFilters}
                        addFilters={addFilters}
                        clearFilters={clearFilters}
                        filtersConfig={filtersConfig}
                    />}
                    <Search
                        urlFilters={urlFilters}
                        addFilters={addFilters}
                        additionalSearches={additionalSearches}
                        defaultSearchField={defaultSearchField}
                        columnsConfig={columnsConfigSorted}
                    />
                    {/*<button type="button" onClick={handleShowSearch}>Click</button>*/}
                    {/*{showSearch && <Search_v2*/}
                    {/*    searchBy="url"*/}
                    {/*    urlFilters={urlFilters}*/}
                    {/*    addFilters={addFilters}*/}
                    {/*    additionalSearches={additionalSearches}*/}
                    {/*    columnsConfig={columnsConfig}*/}
                    {/*/>}*/}

                </div>)}

            {(filtersConfig.length > 0 || tableName === 'statistics') && <ControlBarWrapper
                urlFilters={urlFilters}
                addFilters={addFilters}
                chooseAllFavourites={chooseAllFavourites}
                state={controlBarState}
                resetControlBar={resetControlBar}
                customizeTable={customizeTable}
                buckMode={buckMode}
                buckList={dataBulk}
                bufferColList={bufferColList}
                controlBarConfig={controlBarConfig}
                tableName={tableName}
                additionalSearches={additionalSearches}
                columnsConfig={columnsConfigSorted}
                defaultSearchField={defaultSearchField}
                tableColumns={tableColumns}
                setStatisticTableColumns={setStatisticTableColumns}
                setIsHideTableBody={setIsHideTableBody}
                objFilters={objFilters}
                setObjFilters={setObjFilters}
                clearObjFilters={clearObjFilters}
                setClearObjFilters={setClearObjFilters}
                filtersListToRemove={filtersListToRemove}
                setReportAdditionalFilters={setAdditionalFilters}
                showAlert={showAlert}
                page={page}
                setPage={setPage}
            />}

            <AppliedFilterBar
                urlFilters={urlFilters}
                addFilters={addFilters}
                clearFilters={clearFilters}
                filtersConfig={filtersConfig}
                tableName={tableName}
                filtersFromObj={objFilters}
                setClearObjFilters={setClearObjFilters}
                setFiltersListToRemove={setFiltersListToRemove}
            />
            <Paper className="table_paper" sx={{width: '100%', mb: 2, position: "relative"}}>
                <Sticky rows={rows}
                        columnsConfig={columnsConfig}
                        refresh={refreshData}
                        fieldHandlers={fieldHandlers}
                        tableName={tableName}
                />

                <TableContainer>
                    <BaseTable>
                        <TableHead
                            cells={tableName === 'statistics' && controlBarState === 'customize' ? columnsConfigForCustomize : columnsConfigSorted}
                            order={order}
                            orderBy={orderBy}
                            onSortRequest={handleRequestSort}
                            additionalSearches={additionalSearches}
                            columnsConfig={columnsConfig}
                            addFilters={addFilters}
                            urlFilters={urlFilters}
                            rowCount={rows.length}
                            controlBarState={controlBarState}
                            columnSwitch={columnSwitch}
                            bufferColList={bufferColList}
                            tableName={tableName}
                        />
                        {!isHideTableBody && <TableBody>
                            {rows.map((row, index) => (
                                <Row
                                    // key={getUniqueFieldValue(row)}
                                    key={_uniqueId('id-')}
                                    refresh={refreshData}
                                    selected={selected}
                                    row={row}
                                    uniqueField={uniqueField}
                                    index={index}
                                    columnsConfig={tableName === 'statistics' && controlBarState === 'customize' ? columnsConfigForCustomize : columnsConfigSorted}
                                    setSelected={setSelected}
                                    fieldHandlers={fieldHandlers}
                                />
                            ))}
                            {rows.length === 0 && (<tr><td><Typography sx={{fontSize: '16px'}}>No data</Typography></td></tr>)}
                        </TableBody>}
                    </BaseTable>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={rows.length ? page - 1 : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('common.tables.pagination.rows_per_page')}
                />
            </Paper>
        </Box>);
}
