import {UrlParams} from "../../componets/Table/types";
import {prismApi} from "../store";
import {ShortUrlSettings} from "../reducers/prism/types";

const fetchShortUrls = async (filters?: UrlParams) => {
    const defaultFilters = {include: 'shortDomain,createdBy,createdBy.teams'};
    return prismApi.get('/short-urls', {...defaultFilters, ...filters});
}

const createShortUrl = async (params?: UrlParams) => {
    return prismApi.post('/short-urls', undefined, params);
}

const createShortUrlDefault = async (domain_id: string, slug: string, target_url: string, title: string, with_loader: boolean) => {
    return createShortUrl({
        domain_id: domain_id,
        slug: slug,
        target_url: target_url,
        title: title,
        with_loader: String(with_loader)
    });
}

const updateShortUrl = async (body: ShortUrlSettings) => {
    return prismApi.put(`/short-urls/${body.short_url_id}`, undefined, {
        target_url: body?.target_url,
        title: body?.title,
        with_loader: body?.with_loader,
        domain_id: body?.domain_id,
        slug: body?.slug
    })
        .then(data => data.json());
}

const fetchShortUrl = async (id: number, filters?: UrlParams) => {
    const defaultFilters = {include: 'shortDomain,createdBy,createdBy.teams'};
    return prismApi.get(`/short-urls/${id}`, {...defaultFilters, ...filters});
}

const deleteShortUrl = async (id: number, params?: UrlParams) => {
    return prismApi.delete(`/short-urls/${id}`, params)
        .then(data => data.json());
}

export {fetchShortUrls, createShortUrlDefault, updateShortUrl, fetchShortUrl, deleteShortUrl};
