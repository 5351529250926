import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {DomainData} from "./types";
import {RootState} from "../../store";
import {fetchLandingsListDomains} from "../../requests/landingsList/domains";

export interface LandingsListDomainsState {
    domains: PaginatedData<DomainData> | null;
    status: RequestStatuses;
}

const initialState: LandingsListDomainsState = {
    domains: null,
    status: RequestStatuses.idle,
};

export const getLandingsListDomains = createAsyncThunk(
    'brands/fetchLandingsListDomains',
    async ({productId, filters}: {productId: string, filters?: UrlParams | undefined}, { rejectWithValue }) => {
        try {
            const defaultFilters = { pageSize: '1000' };

            return await fetchLandingsListDomains(productId,{ ...defaultFilters, ...filters });
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const landingsListDomainsSlice = createSlice({
    name: 'landingsListDomains',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingsListDomains.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandingsListDomains.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.domains = action.payload;
            });
    },
});

export const selectLandingsListDomains = (state: RootState) => state.landingsListDomains.domains?.data ?? [];
export const selectLandingsListDomainsStatus = (state: RootState) => state.landingsListDomains.status;

export default landingsListDomainsSlice.reducer;