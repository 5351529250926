import {AsyncThunk} from "@reduxjs/toolkit";
import {AppFilterValue, DataRow, RequestStatuses, UrlParams} from "../types";
import {RootState} from "../../../app/store";

export enum ControlBarConfigType {
    autocomplete = 'autocomplete',
    select = 'select',
    dateRange = 'dateRange',
}

export interface ControlBarConfigBase {
    type: ControlBarConfigType;
    field: string;
    multiselect?: boolean;
}

export interface ControlBarConfigAutocomplete extends ControlBarConfigBase {
    type: ControlBarConfigType.autocomplete;
    placeholder: string;
    field: string;
    optionLabel: string;
    optionValue?: string;
    asyncAction: AsyncThunk<any, UrlParams | undefined, any>;
    dataSelector: (state: RootState) => DataRow[] | null;
    statusSelector: (state: RootState) => RequestStatuses;
}

export interface ControlBarConfigBaseRange {
    type: ControlBarConfigType.dateRange;
}

export interface ControlBarConfigDateRange extends ControlBarConfigBaseRange {
    type: ControlBarConfigType.dateRange;
    field: string;
}

export type SelectValue = string | number | boolean | null | undefined;

export type SelectOption = { [key: string]: SelectValue };

export interface ControlBarConfigSelect extends ControlBarConfigBase {
    type: ControlBarConfigType.select;
    defaultValue?: SelectValue;
    placeholder: string;
    options: SelectOption[];
    valueMutator?: (value: AppFilterValue) => AppFilterValue;
    label?: string;
    value?: SelectValue;
}
export interface ControlBarCheckbox extends ControlBarConfigBase {
    type: ControlBarConfigType.select;
    defaultValue?: SelectValue;
    placeholder: string;
    options: SelectOption[];
    valueMutator?: (value: AppFilterValue) => AppFilterValue;
    label?: string;
    value?: SelectValue;
}

export declare type ControlBarConfig =
    | ControlBarConfigAutocomplete
    | ControlBarConfigSelect
    | ControlBarConfigDateRange
    | ControlBarCheckbox
