import React, {MouseEvent, useState, Dispatch} from "react";
import {
  AppFilters,
  ColumnConfigWithRender,
  DataRow,
  Order,
  SearchConfig,
  StringKeys,
  UrlParams
} from "../types";
import BaseTableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from "@mui/material/Box";
import SearchIcon from '@mui/icons-material/Search';
import {SvgIcon, ThemeProvider} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../UI/Theme";
import './TableHead.scss';
// import {ReactComponent as FilterIcon} from '../../../app/assets/img/icons/filter.svg'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchModal from "../SearchModal/SearchModal";
import FilterModal from "../FilterModal/FilterModal"
import {Sort} from "./Sort";
import {CellName} from "./CellName";
import {ReactComponent as NoSortIcon} from '../../../app/assets/img/icons/noSortIcon.svg';

export interface EnhancedTableProps<Data extends DataRow, Handlers> {
  onSortRequest: (event: MouseEvent<unknown>, property: StringKeys<Data>) => void;
  order: Order;
  orderBy: string;
  cells: ColumnConfigWithRender<Data, Handlers>[];
  additionalSearches: SearchConfig<Data>[];
  columnsConfig: ColumnConfigWithRender<Data, Handlers>[];
  addFilters: (filters: AppFilters) => void;
  urlFilters: UrlParams;
  rowCount: number;
  controlBarState: string;
  columnSwitch: (field: string) => void;
  bufferColList: { [key: string]: {} };
  tooltipTitle?: string;
  tableName: string;
}

function TableHead<Data extends DataRow, Handlers>(props: EnhancedTableProps<Data, Handlers>) {
  const {
    order,
    orderBy,
    onSortRequest,
    cells,
    additionalSearches,
    columnsConfig,
    addFilters,
    urlFilters,
    controlBarState,
    columnSwitch,
    bufferColList,
    tooltipTitle,
    tableName
  } = props;

  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('');

  const createSortHandler =
      (property: StringKeys<Data>) => (event: React.MouseEvent<unknown>) => {
        onSortRequest(event, property);
      };

  const handleOpenSearch = (searchCategory: string) => {
    setShowSearch(true);
    setSearch(searchCategory)
  }


  const isFilterActive = (cell: ColumnConfigWithRender<Data, Handlers>) => {
    let searchBy = cell.searchField ?? cell.field;
    return urlFilters[searchBy] && urlFilters[searchBy] !== ''  ? ' IconActive' : ''
  }

  const handleOpenFilter = (cell: ColumnConfigWithRender<Data, Handlers>) => {
    let searchBy = cell.searchField ?? cell.field;
    setShowFilter(true);
    setFilter(searchBy)
  }

  const handleShowSearch = (field: StringKeys<Data>) => (event: React.MouseEvent<unknown>) => {
    console.log(field);
  }

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const isCustomize = (headCell: ColumnConfigWithRender<Data, Handlers>) =>
      (bufferColList[headCell.field] && controlBarState === 'customize' && headCell.customisable)
  const сellsFiltered = cells.filter(coll => coll.field !== 'actions');

  const isXl = useMediaQuery("(max-width:1540px)");
  const iconLg = {
    width: '20px',
    height: '20px'
  }
  const iconXl = {
    width: '16px',
    height: '16px'
  }

  return (
      <ThemeProvider theme={theme}>
        <BaseTableHead sx={{position: 'relative'}}>
          <TableRow sx={{backgroundColor: 'white.main'}}>
            {сellsFiltered.map((headCell, index) => (
                <TableCell
                    sx={{
                      verticalAlign: 'baseline',
                      color: 'text.primary',
                      position: 'relative',
                      ":after": {
                        content: '""',
                        height: '14px',
                        width: '2px',
                        position: 'absolute',
                        right: '0',
                        top: '36%',
                        backgroundColor: '#EAEAEA'
                      }
                    }}
                    key={headCell.field + index}
                    align={headCell.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === headCell.field ? order : false}
                    className={bufferColList?.[headCell.field] ? 'grey' : ''}
                >
                  <div className="cell-container">
                    {headCell.sortable && controlBarState !== 'customize'
                        ? (
                            <Sort
                                key={headCell.field}
                                orderBy={orderBy}
                                headCell={headCell}
                                order={order}
                                createSortHandler={createSortHandler}
                                index={index}
                            />
                        )
                        : <CellName headCell={headCell}/>
                    }
                    {headCell.sortable && orderBy !== headCell.field && tableName === 'trackers' && <SvgIcon
                        component={NoSortIcon}
                        sx={{marginLeft: '4px', paddingTop: '4px'}}
                    />}
                    {headCell.search && controlBarState !== 'customize' &&
                    <SearchIcon
                        sx={isXl ? iconXl : iconLg}
                        className="table-head-icon"
                        onClick={() => handleOpenSearch(headCell.field)}
                    />
                    }
                    {headCell.filterable && controlBarState !== 'customize' &&
                    <FilterAltIcon
                        sx={isXl ? iconXl : iconLg}
                        className={"table-head-icon" +  isFilterActive(headCell)}
                        onClick={() => handleOpenFilter(headCell)}
                    />
                    }
                    {headCell.customisable && controlBarState === 'customize' &&
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      {!bufferColList?.[headCell.field]
                          ? (
                              <DeleteForeverIcon
                                  className='test'
                                  sx={{color: '#555555', cursor: 'pointer', marginLeft: '6px'}}
                                  onClick={() => columnSwitch(headCell.field)}
                              />)
                          : (
                              <AddCircleIcon
                                  className='test2'
                                  sx={{color: '#555555', cursor: 'pointer', marginLeft: '4px'}}
                                  onClick={() => columnSwitch(headCell.field)}
                              />)
                      }
                    </Box>
                    }
                    {showSearch && search === headCell.field &&
                    <SearchModal
                        columnsConfig={columnsConfig}
                        additionalSearches={additionalSearches}
                        addFilters={addFilters}
                        urlFilters={urlFilters}
                        searchBy={search}
                        setShowSearch={setShowSearch}
                        label={headCell.headerName}
                    />
                    }
                    {showFilter && filter === (headCell.searchField ?? headCell.field) &&
                    <FilterModal
                        columnsConfig={columnsConfig}
                        additionalSearches={additionalSearches}
                        addFilters={addFilters}
                        urlFilters={urlFilters}
                        filterBy={filter}
                        setShowFilter={setShowFilter}
                        label={headCell.headerName}
                        filterType={headCell?.filterType}
                        getOptionsAction={headCell.getOptionsAction}
                        dataSelector={headCell.dataSelector}
                        filterOptions={headCell.filterOptions}
                        idField={headCell.idField}
                        labelField={headCell.labelField}
                    />
                    }
                  </div>
                </TableCell>
            ))}
          </TableRow>
        </BaseTableHead>
      </ThemeProvider>
  );
}

export {TableHead};
