import React from 'react';
import {ColumnConfigWithRender, HandlerCondition} from "../types";
import {Box} from '@mui/material/';
import './Sticky.scss';

interface StickyProps<Data, Handlers> {
    rows: Data[],
    columnsConfig: ColumnConfigWithRender<Data, Handlers>[],
    refresh: () => void,
    fieldHandlers?: HandlerCondition<Data, Handlers>,
    tableName: string
}


export function Sticky<Data, Handlers>(props: StickyProps<Data, Handlers>) {
    const {
        rows,
        columnsConfig,
        refresh,
        fieldHandlers,
        tableName
    } = props;

    const configRes = columnsConfig.filter(coll => coll.field === 'actions');
    const config = configRes?.[0];
    const render = config?.render;
    if (config && render !== undefined) {
        return (
            <Box className={"table_sticky " + tableName}>
                <Box className="row row__head">
                    Actions
                </Box>
                {rows.map((row, index) => (
                    <Box className="row row__actions" key={config.field + index} sx={{lineHeight: '20px'}}>
                        {render(row, config, refresh, fieldHandlers)}
                    </Box>
                ))}
            </Box>
        );
    }
    return <Box/>

}
