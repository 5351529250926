import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import {Order, SetState} from "../types";

// TODO make hook for getting order from localStorage
export function useOrder(): [Order, SetState<Order>] {
    const [searchParams] = useSearchParams();

    const urlOrder = searchParams.get('sort_order');
    const defaultOrder = urlOrder === 'asc' || urlOrder === 'desc' ? urlOrder : 'desc'

    const [order, setOrder] = useState<Order>(defaultOrder);

    return [order, setOrder];
}