import React, {useState} from 'react';
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import './TablePage.css';

export interface TablePageProps {
  title: string;
  description: string;
  ModalComponent?: any;
  modalComponentProps?: any;
  createButtonTitle?: string;
}

function TablePage (props: TablePageProps) {
    const {title, description} = props;

    return (
        <div className='table-page-header'>
            <div className='table-page-title'>
                <span>{title}</span>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default TablePage;