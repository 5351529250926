import React from 'react';
import {styled} from "@mui/material/styles";
import Button, {ButtonProps} from "@mui/material/Button";
import "./WhiteButton.scss";

interface WhiteButtonProps {
    name: string;
    onHandleClick: (event?: React.MouseEvent<HTMLElement>) => void;
    nameClass?: string;
    disabled?: boolean;
}

const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: "#000",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginLeft: "16px",
    backgroundColor: "#F5F4F1",
    '&:hover': {
        backgroundColor: "#F5F4F1",
    },
}));

function WhiteButton({name, onHandleClick, nameClass, disabled}: WhiteButtonProps) {
    return (
        <StyledButton
            className={nameClass}
            onClick={onHandleClick}
            disabled={disabled}
        >
            {name}
        </StyledButton>
    );
}

export default WhiteButton;
