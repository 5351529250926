import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from "./MenuItem/MenuItem";
import './Menu.css';
import LinkTabs from "../LinkTabs/LinkTabs";
import {useTranslation} from "react-i18next";

function Menu() {
  return (
      <Box  sx={{ flexGrow: 1, position: 'fixed', zIndex: '1200', width: '100%', top: '0'}}>
          <AppBar position="static" sx={{boxShadow: "none"}}>
              {/*<Toolbar disableGutters>*/}
              {/*    <IconButton*/}
              {/*        size="large"*/}
              {/*        edge="start"*/}
              {/*        color="inherit"*/}
              {/*        aria-label="menu"*/}
              {/*        sx={{ mr: 2 }}*/}
              {/*    >*/}
              {/*        <MenuIcon />*/}
              {/*    </IconButton>*/}
              {/*    <MenuItem text="Statistic" link='/statistic'/>*/}
              {/*    <MenuItem text="Statistic" link='/statistic'/>*/}
              {/*    <MenuItem text="Statistic" link='/statistic'/>*/}
              {/*    <MenuItem text="Statistic" link='/statistic'/>*/}
              {/*</Toolbar>*/}
              <LinkTabs />
          </AppBar>
      </Box>
  );
}

export default Menu;
