import React from "react";
import {Route, Routes as RoutesList} from "react-router-dom";
import Login from "../componets/Login/Login";
import App from "../componets/App/App";
import Statistics from "../componets/Statistics/Statistics";
import Trackers from "../componets/Trackers/Trackers";
import {Smartlink} from "../componets/Smartlink/Smartlink";
import Landings from "../componets/Landings/LandingsPage";
import Products from "../componets/Products/Products";
import Shortlinks from "../componets/Shortlinks/Shortlinks";
import Shortlink from "../componets/Shortlink/Shortlink";
import ErrorPage from "../componets/ErrorPage/ErrorPage";
import Routes from "./routes";

export const Router = () => {
return (
    <RoutesList>
        <Route path={Routes.login} element={<Login/>}/>
        <Route path={Routes.error} element={<ErrorPage/>}/>
        <Route path={Routes.root} element={<App/>}>
            <Route path={Routes.statistic} element={<Statistics/>}/>
            <Route path={Routes.trackers} element={<Trackers/>}/>
            <Route path={Routes.tracker_detail} element={<Smartlink/>}/>
            <Route path={Routes.default_trackers}/>
            <Route path={Routes.landings} element={<Landings/>}/>
            <Route path={Routes.products} element={<Products/>}/>
            <Route path={Routes.shortener} element={<Shortlinks/>}/>
            <Route path={Routes.shortener_detail} element={<Shortlink/>}/>
            <Route path={Routes.logout}/>
        </Route>
    </RoutesList>
    )
}