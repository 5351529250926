import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {fetchCompanies} from "../../requests/companies";
import {CompanyData} from "./types";

export interface CompaniesState {
    companies: PaginatedData<CompanyData> | null;
    status: RequestStatuses;
}

const initialState: CompaniesState = {
    companies: null,
    status: RequestStatuses.idle,
};

export const getCompanies = createAsyncThunk(
    'companies/fetchCompanies',
    async (filters: UrlParams | undefined, {rejectWithValue}) => {
        try {
            return await fetchCompanies(filters);
        } catch (err) {
            return rejectWithValue({...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompanies.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.companies = action.payload;
            })
    },
});

export const selectCompaniesStatus = (state: RootState) => state.companies.status;
export const selectCompanies = (state: RootState) => state.companies.companies;

export default companiesSlice.reducer;
