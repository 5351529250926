import React from 'react';
import {SimpleTableStyled} from "./styles";
import moment from "moment";

type Column = {
    title: string;
    dataIndex: string;
    type?: string;
};

type Props = {
    columns: Column[];
    rows: Record<string, any>[];
};

const SimpleTable: React.FC<Props> = ({ columns, rows }) => {
    return (
        <SimpleTableStyled>
            <table>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.dataIndex}>
                                <div className="cell-container">
                                    {column.title}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {rows.map((row) => (
                    <tr key={row.id}>
                        {columns.map((column) => {
                            if (column.type === "time") {
                                return <td key={column.dataIndex}>
                                    {moment(row[column.dataIndex]).format("DD.MM.YYYY")}
                                    &nbsp;&nbsp;&nbsp;
                                    {moment(row[column.dataIndex]).format("HH:mm")}
                                </td>
                            }
                            if (column.type === "green") {
                                return <td key={column.dataIndex}><span className="green">{row[column.dataIndex]}</span></td>
                            }
                            return <td key={column.dataIndex}>{row[column.dataIndex]}</td>
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </SimpleTableStyled>
    );
};

export default SimpleTable;