import { UrlParams } from "../../componets/Table/types";
import {prismApi} from "../store";

const fetchSelfUser = async (params?: UrlParams) => {
  const defaultParams: UrlParams = {
    include: 'role,settings,profile,teams'
  };
  return prismApi.get('/users/self', { ...defaultParams, ...params });
}

const fetchLogin = async (credentials: UrlParams) => {
  return prismApi.post('/login', undefined, credentials);
}

const fetchPasswordReset = async (credentials: UrlParams) => {
  return prismApi.post('/password/reset', undefined, credentials);
}

export { fetchSelfUser, fetchLogin, fetchPasswordReset };