import axios from "axios";
import {store} from "../../app/store";

axios.interceptors.request.use(function (config) {
    const locale = process.env.REACT_APP_DEFAULT_LOCALE ?? 'en';
    config.params = {...config.params, locale: locale}
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        const originalRequest = error.config
        if(error.response.status === 404 || error.response.status === 403 || error.response.status === 504 || error.response.status === 502) {
            window.location.href = '/error/' + error.response.status;
            return Promise.reject(error)
        }
        if(error.response.status === 401) {
            store.dispatch({type: 'session/logout'});
            return Promise.reject(error);
        }
    }
)

export default axios;