import React, {useState} from 'react';
import * as Yup from 'yup';

import {useAppDispatch} from "../../app/hooks";
import {login, resetPassword, selectLoginErrors, TOKEN_STORAGE_KEY} from "../../app/reducers/prism/sessionSlice";
import {useNavigate} from "react-router-dom";
import {Form} from "../Form/Form";
import {Config, SubmitHandler} from "../Form/types";

import './Login.scss';
import Paper from "@mui/material/Paper";
import {InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import Menu from "../Menu/Menu";
import Sidebar from "../Sidebar/Sidebar";
import ResetPasswordConfirm from "./ResetPasswordConfirm/ResetPasswordConfirm";

function Login() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false);
  const [showResetForm, setResetForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const onLoginSubmitHandler: SubmitHandler<{ email: string, password: string }> = async (values, {setSubmitting}) => {
    if (values.email !== undefined && values.password !== undefined) {
      const response = await dispatch(login(values));
      if (response.meta.requestStatus !== 'rejected') {
        localStorage.setItem(TOKEN_STORAGE_KEY, response.payload.token)
        setSubmitting(false);
        navigate('/trackers');
      }
    }
  };

  const onPasswordResetSubmitHandler: SubmitHandler<{ email: string }> = async (values, {setSubmitting}) => {
    if (values.email !== undefined) {
      const response = await dispatch(resetPassword(values));
      if (response.meta.requestStatus !== 'rejected') {
        setOpenDialog(true);
        setResetForm(false);
      }
    }
  };

  const passFieldType: string = showPass ? "text" : "password";

  const changeForm = function () {
    setResetForm(!showResetForm);
  }

  const handleCloseDialog = async () => {
    setOpenDialog(false);
    setResetForm(false);
  };

  const resetConfigs: Config[] = [
    {
      type: 'text',
      field: 'email',
      label: t("login_form.email"),
      inputType: 'login',
      validation: Yup.string()
        .email('Wrong format')
        .required('Required'),
    },
    {
      label: t("login_form.back_to_login"),
      type: 'typography',
      variant: 'body2',
      class: 'login-switch',
      col: 12,
      styles: {fontSize: '16px'},
      handleClick: changeForm
    },
    {
      type: 'button',
      label: t("login_form.reset"),
      col: 12,
      buttonClass: 'login-button BtnPurple',
      buttonType: 'submit'
    }
  ];

  const loginConfigs: Config[] = [
    {
      type: 'text',
      field: 'email',
      label: t("login_form.login"),
      inputType: 'login',
      validation: Yup.string()
        .email('Wrong format')
        .required('Required'),
    },
    {
      type: 'text',
      field: 'password',
      label: t("login_form.password"),
      props: {
        endAdornment: (
          <InputAdornment position="end" className="show-password">
            {!showPass
              ? <Visibility onClick={() => setShowPass(!showPass)}/>
              : <VisibilityOff onClick={() => setShowPass(!showPass)}/>
            }
          </InputAdornment>
        ),
        style: {
          paddingRight: 0
        }
      },
      inputType: passFieldType,
      validation: Yup.string().required('Required')
    },
    {
      label: t("login_form.forgot_password_q"),
      type: 'typography',
      variant: 'body2',
      class: 'forgot-password',
      align: 'right',
      col: 6,
      styles: {fontSize: '16px'},
      handleClick: changeForm
    },
    {
      type: 'button',
      label: t("login"),
      buttonClass: 'login-button login-submit BtnPurple',
      buttonType: 'submit'
    }
  ];

  return (
    <>
      <div className="App-container">
        <Sidebar/>
        <Menu/>
        <div className="login-form-container">
          <Paper className='login-form'>
            {!showResetForm && (
              <>
                <div className='login-title login-form-title'>{t("login")}</div>
                <Form
                  configs={loginConfigs}
                  onSubmitHandler={onLoginSubmitHandler}
                  errorsSelector={selectLoginErrors}
                  validateOnBlur={false}
                />
              </>
            )}
            {showResetForm && (
              <>
                <div className='forgot-password-title login-form-title'>{t("login_form.forgot_password")}</div>
                <div className='password-sub'>{t("login_form.forgot_password_sub")}</div>
                <Form
                  configs={resetConfigs}
                  onSubmitHandler={onPasswordResetSubmitHandler}
                  validateOnChange={false}
                  validateOnBlur={false}
                />
              </>
            )}
          </Paper>
        </div>
      </div>
      <ResetPasswordConfirm
          open={openDialog}
          handleClose={handleCloseDialog}
      />
    </>
  )
}

export default Login;
