import React, {useEffect, useState} from 'react';
import {AppFilters, UrlParams} from "../../types";
import {ControlBarConfig} from "../types";
import {FormControlLabel, Box} from '@mui/material/';
import {useTranslation} from "react-i18next";
import CustomIconButton from "../../../CustomIconButton";
import {ReactComponent as BulkChange} from "../../../../app/assets/img/icons/bulk-change.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button, {ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import {BulkControlBar} from "../BulkControlBar/BulkControlBar";
import {CustomizeControlBar} from "../CustomizeControlBar/CustomizeControlBar";
import FavouritesCheckbox from "../FavouritesCheckbox/FavouritesCheckbox";
import LandingsModal from "../../../Landings/CreateLandingModal/CreateLandingModal";
import AddIcon from "@mui/icons-material/Add";
import CustomAlert from "../../../Alert/CustomAlert";

interface LandingsControlBarProps {
    controlBarConfig: ControlBarConfig[];
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    chooseAllFavourites: () => void;
    state: 'controls' | 'customize' | 'bulk';
    customizeTable: () => void;
    resetControlBar: () => void;
    buckMode: () => void;
    buckList: number[];
    bufferColList: { [key: string]: number };
    tableName: string;
    onCreateClick?: (event?: React.MouseEvent<HTMLElement>) => void;
    showAlert?: (message: string) => void;
}


const DropButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: "#000",
    paddingLeft: "16px",
    paddingRight: "60px",
    marginLeft: "16px",
    backgroundColor: "#F2F740",
    '&:hover': {
        backgroundColor: "#F2F740",
    },
}));

export function LandingsControlBar(props: LandingsControlBarProps) {
    const {t} = useTranslation();
    const {
        controlBarConfig,
        urlFilters,
        addFilters,
        chooseAllFavourites,
        customizeTable,
        state,
        resetControlBar,
        buckMode,
        buckList,
        bufferColList,
        tableName,
        onCreateClick,
        showAlert
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openCreate, setOpenCreate] = useState(false);
    const [showCustomizeSavedAlert, setShowCustomizeSavedAlert] = useState<boolean>(false);

    const setCreateFromAgnesHandler = function () {
        setAnchorEl(null);
        if (onCreateClick) {
            onCreateClick();
        }
    }
    const setCreateFromExternalLpHandler = function () {
        setAnchorEl(null);
        if (onCreateClick) {
            onCreateClick();
        }
    }

    const handleCreateClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (showCustomizeSavedAlert) {
            setTimeout(function () {
                setShowCustomizeSavedAlert(false);
            }, 3000);
        }
    }, [showCustomizeSavedAlert]);

    if (state === "customize") {
        return (
            <CustomizeControlBar
                resetControlBar={resetControlBar}
                bufferColList={bufferColList}
                tableName={tableName}
                setShowSavedAlert={setShowCustomizeSavedAlert}
                />
        )
    }

    if (state === "bulk") {
        return (
            <BulkControlBar
                urlFilters={urlFilters}
                addFilters={addFilters}
                resetControlBar={resetControlBar}
                buckList={buckList}
                controlBarConfig={controlBarConfig}
                />
        )
    }

    const handleClickOpen = () => {
        setOpenCreate(true);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    return (
        <Box className="control-bar-wrapper">
            {/*<FavouritesCheckbox  onHandleChange={chooseAllFavourites} />*/}
            <div></div>
            <div className="right-block">
                {/*<FormControlLabel*/}
                {/*    label={t('brands.entity.edit.landings.page.bulk_change')}*/}
                {/*    sx={{textTransform: 'uppercase'}}*/}
                {/*    control={*/}
                {/*        <CustomIconButton*/}
                {/*            title=""*/}
                {/*            component={BulkChange}*/}
                {/*            onClick={buckMode}*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                <Button
                    className="BtnTransparent create-plus"
                    startIcon={<SettingsIcon/>}
                    onClick={customizeTable}
                >
                    {t('brands.entity.edit.landings.page.customize_table')}
                </Button>

                {/*<Button className="BtnGrey create-plus" endIcon={<FileDownloadIcon/>}>*/}
                {/*    {t('brands.entity.edit.landings.page.export')}*/}
                {/*</Button>*/}

                <Button
                    className="BtnPurple create-plus"
                    endIcon={<AddIcon/>}
                    onClick={handleClickOpen}
                >
                    {t('landings.create_btn')}
                </Button>

                {/*<DropButton variant="contained" onClick={handleCreateClick} className="drop-caret-btn"*/}
                {/*            endIcon={<ArrowDropDownIcon/>}>*/}
                {/*    {t('brands.entity.edit.landings.page.create')}*/}
                {/*</DropButton>*/}
                {/*<Menu*/}
                {/*    id="create-menu"*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    open={open}*/}
                {/*    onClose={() => setAnchorEl(null)}*/}
                {/*>*/}
                {/*    <MenuItem onClick={() => setCreateFromAgnesHandler()}>*/}
                {/*        <span>{t('brands.entity.edit.landings.page.form_agnes')}</span>*/}
                {/*    </MenuItem>*/}
                {/*    <MenuItem onClick={() => setCreateFromExternalLpHandler()}>*/}
                {/*        <span>{t('brands.entity.edit.landings.page.external_lp')}</span>*/}
                {/*    </MenuItem>*/}
                {/*</Menu>*/}
            </div>
            <CustomAlert
                type="success"
                title={t('statistic.customize_saved')}
                status={showCustomizeSavedAlert}
            />
            {openCreate && <LandingsModal handleClose={handleClose} showAlert={showAlert}/>}
        </Box>
    )
}
