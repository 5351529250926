import React from 'react';
import {ErrorPageStyled} from "./styles";
import {useParams} from "react-router-dom";

const ErrorPage: React.FC = () => {
    const {code} = useParams();

    const getErrorMessage = (errorCode: string | undefined): string => {
        switch (errorCode) {
            case "401":
                return 'Unauthorized';
            case "403":
                return 'Forbidden';
            case "404":
                return 'The page you requested doesn’t seem to exist.';
            case "502":
                return 'Bad Gateway Error';
            case "500":
                return 'Internal Server Error';
            case "504":
                return 'Gateway Timeout';
            default:
                return 'An unknown error occurred.';
        }
    }

    return (
        <ErrorPageStyled>
            <div className="info">
                <span className="number">{code}</span>
                <p className="text">{getErrorMessage(code)}</p>
            </div>
        </ErrorPageStyled>
    );
};

export default ErrorPage;