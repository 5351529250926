import {FilterConfig} from "../filters/types";
import {AppFilters, ClearedParsedQuery, DataRow, Order, StringKeys, UrlParams} from "../types";
import {useSearchParams} from "react-router-dom";
import qs from "query-string";
import {isArray, omitBy} from "lodash";
import {appFiltersToUrlFilters} from "../helpers";
import {useEffect, useState} from "react";
import {defaultDateRanges} from "../constants";

export function useFilters(
    filtersConfig: FilterConfig[],
    rowsPerPage: number,
    page: number,
    orderBy: StringKeys<DataRow>,
    order: Order,
): [UrlParams, (newFilters: AppFilters) => void, () => void] {
    const [searchParams] = useSearchParams();

    const currentSearch = qs.parse(searchParams.toString());
    // TODO try to delete as
    const clearedUrlParams = omitBy(currentSearch, (value) => isArray(value)) as ClearedParsedQuery;

    const isDateRangeFilter = filtersConfig.some((filter => filter.type === 'dateRange'));

    const dateFilters = isDateRangeFilter ? {
        'date[from]': clearedUrlParams['date[from]'] ?? defaultDateRanges.from,
        'date[to]': clearedUrlParams['date[to]'] ?? defaultDateRanges.to,
    } : null;

    const paginateParams = {
        per_page: String(rowsPerPage),
        page: String(page),
        sort_field: orderBy,
        sort_order: order
    }

    const currentFilters = appFiltersToUrlFilters({
        ...dateFilters,
        ...paginateParams,
        ...clearedUrlParams
    });
    const [urlFilters, setUrlFilters] = useState<UrlParams>(currentFilters);

    const addFilters = (newFilters: AppFilters) => {
        setUrlFilters(
            appFiltersToUrlFilters({
                ...urlFilters,
                ...newFilters,
            })
        )
    };

    const clearFilters = () => {
        setUrlFilters(appFiltersToUrlFilters({
            page: String(page),
            per_page: String(rowsPerPage),
            sort_field: orderBy,
            sort_order: order
        }));
    }

    useEffect(() => {
        addFilters({
            page: String(page),
            per_page: String(rowsPerPage),
            sort_field: orderBy,
            sort_order: order
        })
    }, [page, rowsPerPage, orderBy, order])

    return [urlFilters, addFilters, clearFilters]
}
