import React, {Dispatch, useEffect} from 'react';
import './AppliedFilterBar.scss';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import {FilterConfig, FilterConfigType} from "../filters/types";
import {FilterAutocompleteChip} from "./FilterAutocompleteChip";
import {AppFilters} from "../types";
import {FilterTitleChip} from "./FilterTitleChip";
import {singleChipList, urlSerchTitles} from "./constants";
import {useAppSelector} from "../../../app/hooks";
import {selectLandingsListProductsObj} from "../../../app/reducers/landingsList/productsSlice";
import {selectSearchTeams, selectSearchTeamsFromPrism} from "../../../app/reducers/prism/teamsSlice";
import {selectSearchUsers} from "../../../app/reducers/prism/usersSlice";
import {selectSelfUser} from "../../../app/reducers/prism/sessionSlice";

interface FiltersProps {
    filtersConfig: FilterConfig[];
    urlFilter: string;
    fieldName: string;
    addFilters: (filters: AppFilters) => void;
    setShowBorder: Dispatch<boolean>;
    tableName?: string;
}

export function Filter(props: FiltersProps) {
    const {t} = useTranslation();
    const selfUser = useAppSelector(selectSelfUser);
    const {filtersConfig, urlFilter, fieldName, addFilters, setShowBorder,tableName} = props;
    const curConfig = filtersConfig.find((config) => {
        return config.field === fieldName;
    });
    const landingsListProductsObj = useAppSelector(selectLandingsListProductsObj);
    const teamsList = useAppSelector(selectSearchTeams);
    const teamsListFromPrism = useAppSelector(selectSearchTeamsFromPrism);
    const usersList = useAppSelector(selectSearchUsers);

    const getChipLabel = (filter: string) => {
        switch (`${tableName}_${fieldName}`) {
            case 'landing_create_productId':
                return landingsListProductsObj[filter]?.name || filter;
            case 'trackers_teams':
                const teamList = selfUser?.role.alias === 'admin' ? teamsList : teamsListFromPrism;
                const team = teamList.find((team) => team.id === Number(filter));
                return team?.name || filter;
            case 'trackers_owners':
                const user = usersList.find(user => user.id === Number(filter));
                return user?.username || filter;
            default:
                return filter;
        }
    };

    const renderChipLabel = (filter: string) => {
        localStorage.getItem('filterChipLabels') ?? localStorage.setItem('filterChipLabels', '{}');
        const filterChipLabels: string = localStorage.getItem('filterChipLabels') ?? "{}";
        let lableList: any = JSON.parse(filterChipLabels);
        if(lableList[`${tableName}_${fieldName}`] && lableList[`${tableName}_${fieldName}`][filter]){
            return lableList[`${tableName}_${fieldName}`][filter];
        } else {
            const label = getChipLabel(filter)
            lableList = {
                ...lableList,
                [`${tableName}_${fieldName}`]: {
                    [filter]:label
                }
            };
            localStorage.setItem('filterChipLabels', JSON.stringify(lableList));
            return label;
        }
    };

    const titleG =  () => {
        if(curConfig?.placeholder) {
            return t( curConfig.placeholder);
        }
        if(tableName){
            return t((urlSerchTitles[tableName] ?? "") + fieldName);
        }
        return "";
    }

    useEffect(() => {
        setShowBorder(urlFilter.length > 0);
    }, [setShowBorder, urlFilter])


    if (singleChipList.includes(fieldName) && urlFilter.length > 0) {
        const label = renderChipLabel(urlFilter);
        return (
            <Box className="filter-chip__block">
                <Box className="filter-chip__block-title">
                    {titleG()}
                </Box>
                {<FilterTitleChip
                    fieldName={fieldName}
                    urlFilter={label}
                    addFilters={addFilters}
                />}
            </Box>)
    }

    if (!curConfig) {
        return null;
    }

    return (
        <Box className="filter-chip__block">
            {curConfig.type === FilterConfigType.autocomplete &&
            <>
                <Box className="filter-chip__block-title">{t(curConfig.placeholder)}</Box>
                <FilterAutocompleteChip
                    filterConfig={curConfig}
                    urlFilter={urlFilter}
                    addFilters={addFilters}
                />
            </>
            }
            {curConfig.type === FilterConfigType.title &&
            <Box className="filter-chip__block-title">{curConfig.placeholder}</Box>
            }
        </Box>
    )
}
