import React, {KeyboardEventHandler, useEffect, useState} from 'react';
import {Button, ClickAwayListener, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './SearchModal.scss';
import {AppFilters, ColumnConfigWithRender, SearchConfig, UrlParams} from "../types";
import {useTranslation} from "react-i18next";

export interface SearchProps<Data, Handlers> {
  columnsConfig: ColumnConfigWithRender<Data, Handlers>[];
  additionalSearches: SearchConfig<Data>[];
  addFilters: (filters: AppFilters) => void;
  urlFilters: UrlParams;
  searchBy: string;
  setShowSearch: (showSearch: boolean) => void;
  label: string;
}

type SearchesType<Data, Handlers> = Array<SearchConfig<Data> | ColumnConfigWithRender<Data, Handlers>>;

export default function Search<Data, Handlers>(props: SearchProps<Data, Handlers>) {
  const searchKey = 'search';
  const {t} = useTranslation();
  const {columnsConfig, addFilters, additionalSearches = [], urlFilters, searchBy, setShowSearch, label} = props;
  const [search, setSearch] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string | number>('');
  let searchArray: string[] = [];

  const handleInput = (event: { target: { value: string; }; }) => {
    setSearch(event.target.value);
    setSearchValue(event.target.value);
  };

  if (urlFilters?.search) {
    searchArray = urlFilters?.search.split(',');
  }

  const handleSearch: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      if (search === '') {
        addFilters({[searchBy]: null})
      } else {
        if (!searchArray.includes(searchBy)) {
          searchArray.push(searchBy);
        }
        addFilters({[searchBy]: search, [searchKey]: searchArray.join(',')})
      }
    }
  }

  const defaultColumns = columnsConfig.filter(columnConfig => columnConfig.search);
  const searchedColumns: SearchesType<Data, Handlers> = [...defaultColumns, ...additionalSearches]
  const urlSearchByConfig = searchedColumns.find(column => urlFilters[column.field]);
  const defaultValue: string | null = urlSearchByConfig ? urlFilters[urlSearchByConfig.field] : null;

  const handleClickAway = () => {
    setShowSearch(false);
  };

  const handleBtnSearch = (event: React.MouseEvent<unknown>) => {
    if (search === '') {
      addFilters({[searchBy]: null})
    } else {
      if (!searchArray.includes(searchBy)) {
        searchArray.push(searchBy);
      }
      addFilters({[searchBy]: search, [searchKey]: searchArray.join(',')})

    }
  }

  const resetSearch = () => {
    setSearch('');
    setSearchValue('');
    searchArray = searchArray.filter(item => item !== searchBy);
    addFilters({[searchBy]: null, [searchKey]: searchArray.join(',').length > 0 ? searchArray.join(',') : null})
  };

  useEffect(() => {
    if (defaultValue !== null) {
      setSearch(defaultValue);
    }
  }, [defaultValue])

  useEffect(() => {
    setSearchValue(urlFilters?.[searchBy]);
  }, [urlFilters]);

  if (!searchedColumns.length) {
    return null;
  }

  return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="search-container">
          <div className='search-content'>
            <TextField
                className="search-field"
                sx={{minWidth: '300px'}}
                size='small'
                label={t(label)}
                value={searchValue}
                onChange={handleInput}
                onKeyDown={handleSearch}
                InputProps={{
                  endAdornment: search ? <CloseIcon onClick={resetSearch} sx={{cursor: 'pointer'}}/> : <SearchIcon/>
                }}
            />
            <Button
                className="search-button"
                onClick={handleBtnSearch}
                disabled={!search}
            >
              search
            </Button>
          </div>
        </div>
      </ClickAwayListener>
  );

}