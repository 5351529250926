import React, {Dispatch, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import './EditableText.scss';
import InputAdornment from "@mui/material/InputAdornment";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import EditIcon from "@mui/icons-material/Edit";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Box from "@mui/material/Box";
import {copyToClipboard} from "../Helpers/clipboard";
import CustomAlert from "../Alert/CustomAlert";
import {useTranslation} from "react-i18next";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DarkTooltip from "../DarkTooltip/DarkTooltip";

interface EditableTextProps {
    fieldName: string;
    onChangeHandler?: (value: string) => void;
    placeholder: string;
    label: string;
    value: string;
    type?: "link";
    linkIcon?: boolean;
    error?: boolean;
    helperText?: string | false | undefined;
    onChange?: any;
    tooltip?: string;
    copy?: boolean;
    disabled?: boolean;
    handleEditOnForm?: Dispatch<React.SetStateAction<boolean>>;
}

export function EditableText(props: EditableTextProps) {
    const {t} = useTranslation();
    const {
        fieldName,
        onChangeHandler,
        placeholder,
        label,
        value,
        type,
        linkIcon,
        tooltip,
        copy = true,
        disabled = false,
        onChange,
        handleEditOnForm
    } = props;

    const [inputValue, setInputValue] = useState(value ?? "");
    const [editState, setEditState] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const openForEdit = () => {
        setEditState(true);
    };

    const saveEdit = () => {
        setEditState(false);
        if (onChangeHandler) {
            onChangeHandler(inputValue);
        }
    };

    const copyValue = () => {
        setOpenAlert(true);
        copyToClipboard(inputValue);
        setTimeout(function () {
            setOpenAlert(false);
        }, 5000)
    }

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleCloseAlert = async () => {
        setOpenAlert(false);
    };

    return (
        <Box className="editable-text">
            <Box className="editable-text__label">
                {label}
                {tooltip && (
                    <DarkTooltip title={tooltip ?? ""} placement="top">
                        <InfoOutlinedIcon fontSize="small" className="editable-text__tooltip"/>
                    </DarkTooltip>)}
            </Box>
            <span
                className={"editable-text__value " + (editState ? "editable-text__hidden " : "") + (type ?? "")}>{inputValue ? inputValue : placeholder}</span>
            {copy && (
                <DarkTooltip title={t('common.icons.copy')} placement="top">
                    <CopyAllIcon onClick={copyValue}
                         className={"editable-text__icon editable-text__icon-static " + (editState ? "editable-text__hidden" : "")}/>
                </DarkTooltip>)}
            {!disabled && (
                <DarkTooltip title={t('common.icons.edit')} placement="top">
                    <EditIcon onClick={() => handleEditOnForm ? handleEditOnForm(true) : openForEdit()}
                      className={"editable-text__icon editable-text__icon-static " + (editState ? "editable-text__hidden" : "")}/>
                </DarkTooltip>)}
            {disabled && (
            <EditIcon className={"editable-text__icon editable-text__icon-static disabled"}/>)}
            {linkIcon && !editState && (
                <DarkTooltip title={t('common.icons.preview')} placement="top">
                    <a className="editable-text__icon-link" target="_blank" rel="noreferrer"  href={inputValue}>
                        {<OpenInNewOutlinedIcon className="editable-text__icon-static"/>}
                    </a>
                </DarkTooltip>
            )}
            <TextField
                sx={{display: editState ? "inline-block" : "none"}}
                name={fieldName}
                value={inputValue}
                className="editable-text__input"
                fullWidth
                placeholder={placeholder}
                onChange={onChange ?? (event => setInputValue(event.target.value))}
                variant="standard"
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <CheckOutlinedIcon
                                onClick={saveEdit}
                                className="editable-text__icon-static"
                            />
                        </InputAdornment>,
                }}
            />
            <CustomAlert
                type="success"
                title={t('common.messages.copied_to_clipboard')}
                onClose={handleCloseAlert}
                status={openAlert}
            />
        </Box>
    )
}
