import React from 'react';
import {useTranslation} from "react-i18next";
import {
    DirectUrlFormState,
    LandingFormState,
    SplittingFormState,
    DirectUrlFormValidation,
    LandingFormValidation,
    SplittingFormValidation,
    toggleVariants, dateRangesType
} from "../../type";
import {Box, Button, Tooltip} from "@mui/material";
import FormRuleConfiguration from "../../FormRuleConfiguration/FormRuleConfiguration";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Toggle from "../../../../Toggle/Toggle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomAlert from "../../../../Alert/CustomAlert";
import "./TabPanel.scss";
import {Node} from "../../../types";
import {TrackerData} from "../../../../../app/reducers/prism/types";

interface TabPanelProps {
    blockId: number,
    handleInput: (name: string, value: string, index: number, formName: string) => void;
    handleSetFieldValue: (field: string, formName: string, value: string, index: number) => void;
    handleDeleteSplittingBlock: (index: number) => void;
    formName: string;
    formClassName: string;
    values: SplittingFormState[] | DirectUrlFormState[] | LandingFormState[];
    handlerToggle: () => void;
    errors?: SplittingFormValidation[] | DirectUrlFormValidation[] | LandingFormValidation[];
    handleAddSplittingBlock?: () => void;
    errorProbability?: boolean;
    toggleType: toggleVariants;
    openToggle: boolean;
    ruleEdit: Node | null;
    scheduleEdit: TrackerData | null;
}

function TabPanel(props: TabPanelProps) {
    const {
        blockId,
        handleInput,
        handleSetFieldValue,
        handleAddSplittingBlock,
        handleDeleteSplittingBlock,
        formName,
        formClassName,
        values,
        errors,
        handlerToggle,
        errorProbability,
        toggleType,
        openToggle,
        ruleEdit,
        scheduleEdit
    } = props;
    const {t} = useTranslation();

    return (
        <Box className={formClassName}>
            <FormRuleConfiguration
                formName={formName}
                values={values}
                handleInput={handleInput}
                handleSetFieldValue={handleSetFieldValue}
                errors={errors}
                handleDeleteSplittingBlock={handleDeleteSplittingBlock}
                ruleEdit={ruleEdit}
                scheduleEdit={scheduleEdit}
                blockId={blockId}
            />
            {formName === 'splitting' &&
                <CustomAlert
                    type="error"
                    title={t('smartlinks.modal_rule_configuration.error_probability')}
                    status={errorProbability!}
                />
            }
            {formName === 'splitting' &&
                <Button className="btn-add-delete btn-add-new-landing" onClick={handleAddSplittingBlock}>
                    <AddCircleIcon/>
                    {t('smartlinks.modal_rule_configuration.add_new_landing')}
                </Button>}
            { blockId === 0 && toggleType === 'capping' &&
                <Box className="modal-rule-configuration-toggle">
                    <Toggle handler={handlerToggle} isChecked={openToggle}/>
                    <p>{t('smartlinks.modal_rule_configuration.add_capping')}</p>
                    <Tooltip title={t("smartlinks.modal_rule_configuration.landing_toggle_label")}>
                        <InfoOutlinedIcon className="hint"/>
                    </Tooltip>
                </Box>
            }
        </Box>
    );
}

export default TabPanel;
