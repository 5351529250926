import { UrlParams } from "../../componets/Table/types";
import {prismApi} from "../store";

const fetchProducts = async (filters?: UrlParams) => {
  const defaultFilters = {include: 'mirrors'}
  return prismApi.get('/brands', {...defaultFilters, ...filters});
}

const fetchDeleteLandingFormPackage = async (packageId: number, landingId: number ) => {
  return prismApi.delete(`/packages/${packageId}/landings/${landingId}`);
}

const fetchCreateLandingFormPackage = async (packageId: number, landingId: number, body: any) => {
  return prismApi.post(`/packages/${packageId}/landings/${landingId}`, undefined, body);
}

const fetchLandingsByProducts = async (brandId: string, filters?: UrlParams) => {
  const defaultParams: UrlParams = {
    include: 'landings'
  };
  return prismApi.get(`/brands/${brandId}`, {...filters, ...defaultParams});
}

const setActivity = async (brandId: number, enabled: boolean, name: string, url: string, help_url: string) => {
  return prismApi.put('/brands/' + brandId, undefined, {enabled, name, url, help_url})
          .then(data => data.json());
}

export { fetchProducts, fetchDeleteLandingFormPackage, fetchCreateLandingFormPackage, fetchLandingsByProducts, setActivity };