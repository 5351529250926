import React, {useCallback, useEffect, useState} from 'react';
import {Button, ClickAwayListener, Paper} from "@mui/material";
import './FilterModal.scss';
import {AppFilters, AppFilterValue, ColumnConfigWithRender, DataRow, FilterConfig, UrlParams} from "../types";
import {SelectFilter} from "../filterInputs/Select/SelectFilter";
import {capitalize} from "lodash";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {AsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import {SelectOption} from "../filters/types";
import {useTranslation} from "react-i18next";
import {AutocompleteFilter} from "../filterInputs/Autocomplete/AutocompleteFilter";
import BaseSelectWithInput from "../../inputs/BaseSelectWithInput";
import {getLandingsByProduct, selectSearchProducts} from "../../../app/reducers/prism/brandsSlice";
import {SelectWithInputFilter} from "../filterInputs/SelectWithInput/SelectWithInputFilter";
import {typesRules} from "../../Smartlink/types";
import {AutocompleteOption} from "../../inputs/baseAutocomplete/types";

export interface FilterProps<Data, Handlers> {
    columnsConfig: ColumnConfigWithRender<Data, Handlers>[];
    additionalSearches: FilterConfig<Data>[];
    addFilters: (filters: AppFilters) => void;
    urlFilters: UrlParams;
    filterBy: string;
    setShowFilter: (showFilter: boolean) => void;
    label: string;
    getOptionsAction?: AsyncThunk<any, UrlParams | undefined, any>;
    dataSelector?: (state: RootState) => DataRow[] | null;
    filterOptions?: SelectOption[];
    idField?: string;
    labelField?: string;
    filterType?: 'autocomplete' | 'select';
}

type FilteredType<Data, Handlers> = Array<FilterConfig<Data> | ColumnConfigWithRender<Data, Handlers>>;

export default function Filter<Data, Handlers>(props: FilterProps<Data, Handlers>) {
    const {t} = useTranslation();
    const {
        columnsConfig,
        addFilters,
        additionalSearches = [],
        urlFilters,
        filterBy,
        setShowFilter,
        label,
        getOptionsAction,
        dataSelector,
        filterOptions = [],
        idField = 'id',
        labelField = 'name',
        filterType = 'select'
    } = props;
    const [filter, setFilter] = useState<string>('');
    const [curValue, setCurValue] = useState<AppFilterValue>();
    const [curOptions, setCurOptions] = useState<AutocompleteOption[]>();
    const dispatch = useAppDispatch();
    const handleInputCreate = async (value: string) => {
        if (getOptionsAction) {
            await dispatch(getOptionsAction({username: String(value)}));
        }
    };
    useEffect(() => {
        if (getOptionsAction !== undefined) {
            dispatch(getOptionsAction());
        }
    }, [dispatch]);

    // @ts-ignore
    const options = useAppSelector(dataSelector ?? (() => ([])));
    useEffect(() => {
        const autOptions = options?.map((obj) => {
            if (obj.id && obj[labelField]) {
                const label = obj[labelField]?.toString();
                const value = obj.id?.toString();
                if (label && value) {
                    return { label, value };
                }
            }
            return undefined;
        }).filter((obj) => obj !== undefined) as AutocompleteOption[];
        setCurOptions(autOptions);
    }, [options]);
    const addFiltersCallBack = useCallback(addFilters, [addFilters]);

    const handleClickAway = () => {
        setShowFilter(false);
    };

    const defaultColumns = columnsConfig.filter(columnConfig => columnConfig.search);
    const FilteredColumns: FilteredType<Data, Handlers> = [...defaultColumns, ...additionalSearches]
    const urlSearchByConfig = FilteredColumns.find(column => urlFilters[column.field]);
    const defaultValue: string | null = urlSearchByConfig ? urlFilters[urlSearchByConfig.field] : null;

    const handleBtnFilter = (event: React.MouseEvent<unknown>) => {
        if (typeof curValue === "string") {
            setFilter(curValue);
        }
    }

    useEffect(() => {
        if (filter) {
            addFilters({[filterBy]: filter});
            setShowFilter(false);
        }
    }, [filter])

    // useEffect(() => {
    //   if (defaultValue !== null) {
    //     setFilter(defaultValue);
    //   }
    // }, [defaultValue])

    if (!FilteredColumns.length) {
        return null;
    }

    const availableOptions = () => {
        if (options && labelField === 'enabled') {
            return [{value: true, label: t('common.tracker_filters.enabled')}, {
                value: false,
                label: t('common.tracker_filters.disabled')
            }];
        }

        if (options && filterOptions.length === 0) {
            return options.map((option) => {
                return ({value: String(option?.[idField]), label: option?.[labelField]});
            });
        }

        if (options?.length === 0 && filterOptions.length > 0) {
            return filterOptions.map((option) => {
                return ({value: String(option?.[idField]), label: option?.[labelField]});
            });
        }

        return [];
    };

    const selectChange = (option: AppFilterValue) => {
        setCurValue(option);
    };

    const autoCompleteChange = (event: any, option: AutocompleteOption | AutocompleteOption[] | null) => {
        if (option) {
            if (!Array.isArray(option)) {
                // Handle the case where a single option is selected
                setCurValue(option.value);
            }
        } else {
            // Handle the case where no option is selected
            setCurValue(null);
        }
    };

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
            <Paper className="filter-container">
                <div className='filter-content'>
                    <div className="filter-row">
                        {filterType === 'select' &&
                        <SelectFilter
                            sx={{width: '100%'}}
                            key={filter}
                            fieldName={filter}
                            defaultOptionValue=''
                            onChangeHandler={selectChange}
                            placeholder={capitalize(t(label))}
                            // @ts-ignore
                            options={availableOptions()}
                            urlFilters={urlFilters}
                        />}
                        {filterType === 'autocomplete' && getOptionsAction !== undefined && dataSelector !== undefined &&
                        <SelectWithInputFilter
                            sx={{width: '100%'}}
                            key={filter}
                            fieldName={filter}
                            defaultOptionValue=''
                            onChangeHandler={autoCompleteChange}
                            handleInputCreate={handleInputCreate}
                            placeholder={capitalize(t(label))}
                            // @ts-ignore
                            options={curOptions}
                        />
                        }
                    </div>
                    <div className="filter-row">
                        <Button
                            className="filter-button BtnPurple"
                            onClick={handleBtnFilter}
                        >
                            apply
                        </Button>
                        <Button
                            className="filter-button BtnGrey"
                            onClick={handleClickAway}
                        >
                            cancel
                        </Button>
                    </div>
                </div>
            </Paper>
        </ClickAwayListener>
    );
}