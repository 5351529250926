import * as React from 'react';
import {
    Link as RouterLink,
    LinkProps,
} from 'react-router-dom';
import MaterialLink, { LinkProps as MaterialLinkProps } from '@mui/material/Link';

// const LinkBehavior = React.forwardRef<any, Omit<LinkProps, 'to'>>(
//     (props, ref) => (
//         <RouterLink
//             ref={ref}
//             to="/material-ui/getting-started/installation/"
//             {...props}
//         />
//     ),
// );

export default function Link(props: LinkProps & MaterialLinkProps) {
    // if (false) {
    //     return <MaterialLink component={LinkBehavior} {...props}>{props.children}</MaterialLink>;
    // }
    return (
        <MaterialLink component={RouterLink} to={props.to}>
            {props.children}
        </MaterialLink>
    );

    // return <MaterialLink component={RouterLink} {...props}>{props.children}</MaterialLink>;
}
