import React, {useState} from 'react';
import {AppFilters, ColumnConfigWithRender, SearchConfig, UrlParams} from "../../types";
import './SmartlinksControlBar.scss';
import {useTranslation} from "react-i18next";
import {Box, Button} from '@mui/material/';
import Search from "../../Search/Search";
import AddIcon from '@mui/icons-material/Add';
import {CreateTracker} from "../../../Trackers/CreateTracker/CreateTracker";
import FavouritesCheckbox from "../FavouritesCheckbox/FavouritesCheckbox";

interface FiltersProps<Data, Handlers> {
    urlFilters: UrlParams;
    addFilters: (filters: AppFilters) => void;
    chooseAllFavourites: () => void;
    columnsConfig: ColumnConfigWithRender<Data, Handlers>[];
    additionalSearches: SearchConfig<Data>[];
    defaultSearchField?: string;
}

export function SmartlinksControlBar<Data, Handlers>(props: FiltersProps<Data, Handlers>) {
    const {t} = useTranslation();
    const {
        urlFilters,
        addFilters,
        chooseAllFavourites,
        additionalSearches,
        columnsConfig,
        defaultSearchField
    } = props;

    const [openCreate, setOpenCreate] = useState(false);

    const handleClickOpen = () => {
        setOpenCreate(true);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    return (
        <Box className="control-bar-wrapper">
            <FavouritesCheckbox  onHandleChange={chooseAllFavourites} />
            <div className="right-block">
                <Search
                    urlFilters={urlFilters}
                    addFilters={addFilters}
                    additionalSearches={additionalSearches}
                    columnsConfig={columnsConfig}
                    defaultSearchField={defaultSearchField}
                />
                <Button
                    className="BtnPurple create-plus"
                    endIcon={<AddIcon/>}
                    onClick={handleClickOpen}
                >
                    {t('smartlinks.actions.create_smartlink')}
                </Button>
            </div>
            {openCreate && <CreateTracker handleClose={handleClose}/>}
        </Box>
    )
}
