import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import {SetState} from "../types";

// TODO make hook for getting orderBy from localStorage
export function useOrderBy(defaultSort: string): [string, SetState<string>] {
    const [searchParams] = useSearchParams();

    // TODO check and probably fix
    const urlOrderBy = searchParams.get('sort_field');
    const [orderBy, setOrderBy] = useState<string>(urlOrderBy || defaultSort);

    return [orderBy, setOrderBy];
}