import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from "yup";
import "./CreateLandingModal.scss";
import {Dialog} from "@mui/material";
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Form} from "../../Form/Form";
import {Config} from "../../Form/types";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    getLandingsListProducts,
    selectLandingsListProducts,
    selectLandingsListProductsObj
} from "../../../app/reducers/landingsList/productsSlice";
import {getLandingsListDomains} from "../../../app/reducers/landingsList/domainsSlice";
import { getLandingsListLanguages, selectLandingsListLanguages} from "../../../app/reducers/landingsList/languagesSlice";
import {CreateLandingBody, UpdateLandingBody} from "../../../app/reducers/landingsList/types";
import {createLanding, getLandings, selectLanding, updateLanding} from "../../../app/reducers/landingsList/landingsSlice";
import {AutocompleteOption} from "../../inputs/baseAutocomplete/types";
import {isArray} from "lodash";
import {availableFunnels} from "../constants";
import {FormikValues} from "formik/dist/types";

export interface ICreateLandingModalProps {
    handleClose: () => void;
    landingId?: string;
    isUpdate?: boolean;
    showAlert?: (message: string) => void;
}

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogTitle-root': {
        textAlign: 'left',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '0 20px 32px'
    },
    '& .MuiDialogActions-root': {
        textAlign: 'center'
    },
    '& .MuiDialog-paper': {
        borderRadius: '0',
        maxWidth: '812px',
        padding: '16px'
    },
}));

const CreateLandingModal: React.FC<ICreateLandingModalProps> = (props: ICreateLandingModalProps): JSX.Element => {
    const {handleClose, landingId, isUpdate, showAlert} = props;
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const landing = useAppSelector(state => selectLanding(state, landingId));
    const availableLanguages = useAppSelector(selectLandingsListLanguages);
    const availableProducts = useAppSelector(selectLandingsListProducts);
    const [subdomainsList, setSubdomainsList] = useState([]);
    const [subdomainValue, setSubdomainValue] = useState<AutocompleteOption[] | AutocompleteOption | null>(null);
    const [funnelValue, setFunnelValue] = useState<AutocompleteOption[] | AutocompleteOption | null>(null);
    const [productValue, setProductValue] = useState<AutocompleteOption[] | AutocompleteOption | null>(null);
    const [languageValue, setLanguageValue] = useState<AutocompleteOption[] | AutocompleteOption | null>(null);
    const landingsListProductsObj = useAppSelector(selectLandingsListProductsObj);

    const getAvailableFunnels = () => {
        if (!availableFunnels) {
            return [];
        }
        return availableFunnels?.map(funnel => ({label: funnel.name, value: funnel.id}));
    }

    const getAvailableProducts = () => {
        if (!availableProducts) {
            return [];
        }
        return availableProducts?.map(product => ({label: product.name, value: product.id}));
    }

    const getAvailableLanguages = () => {
        if (!availableLanguages) {
            return [];
        }
        return availableLanguages?.map(language => ({label: language.name, value: language.value}));
    }

    const productChangeHandler = async (option: AutocompleteOption) => {
        const productId = String(option.value);
        const res = await dispatch(getLandingsListDomains({productId: productId}));
        const url = new URL(landingsListProductsObj[productId].url);
        const arr = res.payload.data.map((item: any) => {
            return {label: url.protocol + '//' + item.value + '.' + url.hostname, value: item.id};
        });
        setSubdomainsList(arr);
    }

    const fillInFormFields = () => {
        if (isUpdate && landing?.funnel) {
            const res = availableFunnels.find(item => item.id === landing?.funnel);
            setFunnelValue({label: res!.name, value: landing?.funnel});
        }
        if (isUpdate && landing?.productId) {
            setProductValue({label: landing?.product, value: landing?.productId});
            productChangeHandler({label: landing?.product, value: landing?.productId});
            const url = new URL(landingsListProductsObj[landing?.productId].url);
            landing.productSubdomain
            ? setSubdomainValue({label: url.protocol + '//' + landing.productSubdomain + '.' + url.hostname, value: landing.productSubdomainId})
            : setSubdomainValue(null);
        }
        if (isUpdate && landing?.language) {
            setLanguageValue({label: landing?.language.toUpperCase(), value: landing?.language});
        }
    }

    useEffect(() => {
        dispatch(getLandingsListLanguages());
        dispatch(getLandingsListProducts());
    }, [dispatch]);

    useEffect(() => {
        fillInFormFields();
    }, []);

    const onSubmitHandler = useCallback( async (values: FormikValues) => {
        if (isUpdate && landingId) {
            const data: UpdateLandingBody = {
                id: landingId,
                productId: values.productId,
                funnel: values.funnel ? values.funnel : null,
                language: values.language ? values.language : null,
                status: landing?.status ?? '',
                slug: values.slug,
                tag: values.label,
                productSubdomainId: values.productSubdomainId ? values.productSubdomainId : null,
            };
            const response = await dispatch(updateLanding(data));
            if (response.meta.requestStatus === 'fulfilled') {
                handleClose();
                dispatch(getLandings());
                showAlert && showAlert(t('landings.alert.successfully_saved'));
            }
        } else {
            const data: CreateLandingBody = {
                productId: values.productId,
                name: values.name,
                slug: values.slug[0] === '/' ? values.slug.substring(1) : values.slug
            };
            if (values.language) {
                data['language'] = values.language;
            }
            if (values.funnel) {
                data['funnel'] = values.funnel;
            }
            if (values.label) {
                data['tag'] = values.label;
            }
            if (values.productSubdomainId) {
                data['productSubdomainId'] = values.productSubdomainId;
            }
            const response = await dispatch(createLanding(data));
            if (response.meta.requestStatus === 'fulfilled') {
                showAlert && showAlert(t('landings.alert.successfully_created'));
                handleClose();
                dispatch(getLandings());
            }
        }
    }, []);

    const formConfig: Config[] = [
        {
            type: 'text',
            field: 'name',
            label: 'Name',
            col: 6,
            validation: Yup.string()
                .min(3, 'This value is too short. It should have 3 characters or more.')
                .max(30, 'This value is too long. It should have 30 characters or less.')
                .matches(/^[a-zA-Z0-9_ ]+$/, "This value can be made up of large and small characters, numbers, and _")
                .required('Required'),
            defaultValue: String(landing?.name ?? ''),
            disabled: isUpdate === true
        },
        {
            type: 'selectWithInput',
            field: 'productId',
            label: 'Product',
            col: 6,
            class: '',
            optionsList: getAvailableProducts(),
            onChangeHandler: (event, value) => {
                if (!isArray(value) && value !== null) {
                    productChangeHandler(value);
                    setProductValue(value);
                    setSubdomainValue(null);
                } else if (value === null) {
                    setSubdomainsList([]);
                    setSubdomainValue(null);
                    setProductValue(null);
                }
            },
            validation: Yup.string().nullable().required('Required'),
            value: productValue,
        },
        {
            type: 'selectWithInput',
            field: 'language',
            label: 'Languages',
            col: 6,
            class: '',
            optionsList: getAvailableLanguages(),
            value: languageValue,
            onChangeHandler: (event, value) => {
                if (!isArray(value) && value !== null) {
                    setLanguageValue(value);
                } else if (value === null) {
                    setLanguageValue(null);
                }
            }
        },
        {
            type: 'selectWithInput',
            field: 'productSubdomainId',
            label: 'Domain',
            col: 6,
            class: '',
            optionsList: subdomainsList,
            value: subdomainValue,
            onChangeHandler: (event, value) => {
                if (!isArray(value) && value !== null) {
                    setSubdomainValue(value);
                } else if (value === null) {
                    setSubdomainValue(null);
                }
            },
        },
        {
            type: 'selectWithInput',
            field: 'funnel',
            label: 'Funnel',
            col: 6,
            class: '',
            optionsList: getAvailableFunnels(),
            value: funnelValue,
            onChangeHandler: (event, value) => {
                if (!isArray(value) && value !== null) {
                    setFunnelValue(value);
                } else if (value === null) {
                    setFunnelValue(null);
                }
            },
        },
        {
            type: 'text',
            field: 'slug',
            label: 'Path',
            col: 6,
            validation: Yup.string().min(3, 'This value is too short. It should have 3 characters or more.').max(30, 'This value is too long. It should have 30 characters or less.').required('Required'),
            defaultValue: String(landing?.slug ?? '')
        },
        {
            type: 'text',
            field: 'label',
            label: 'Label',
            col: 6,
            validation: Yup.string().min(3, 'This value is too short. It should have 3 character or more.'),
            defaultValue: String(landing?.tag ?? '')
        },
        // {
        //     type: 'autocomplete',
        //     field: 'category',
        //     label: 'Category',
        //     optionValue: 'value',
        //     optionLabel: 'value',
        //     asyncAction: getLandingsListCategories,
        //     dataSelector: selectLandingsListCategories,
        //     statusSelector: selectLandingsListCategoriesStatus,
        //     validation: Yup.array(Yup.string().required('Required')).nullable(),
        //     onChangeHandler: (event, value) => {
        //         if (!isArray(value) && value !== null) {
        //             getTypesByCategory(value);
        //         }
        //     },
        //     col: 6,
        //     defaultValue: [String(landing?.category ?? '')]
        // }
    ];

    const buttonsConfig: Config[] = [
        {
            type: 'divider',
            label: '',
            col: 12,
        },
        {
            type: 'empty',
            label: '',
            col: 4,
        },
        {
            type: 'button',
            label: t('landings.save'),
            buttonType: 'submit',
            col: 4,
            buttonClass: 'BtnPurple',
            disabled: true
        },
        {
            type: 'button',
            label: t('landings.cancel'),
            buttonType: 'button',
            handleClick: handleClose,
            col: 4,
            buttonClass: 'BtnGrey',
        },
    ];

    const createFormConfig: Config[] = [...formConfig, ...buttonsConfig];

    return (
        <StyledDialog open={true} onClose={handleClose}>
            <div className="create-landing-modal-container">
                <div className='close-button'>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
                <DialogTitle>
                    {isUpdate
                        ? t('landings.title_update')
                        : t('landings.title_create')
                    }
                </DialogTitle>
                <DialogContent sx={{padding: '0 20px 16px', overflow: "unset"}}>
                    <Form
                        configs={createFormConfig}
                        onSubmitHandler={onSubmitHandler}
                        formName="create-landing-form"
                    />
                </DialogContent>
            </div>
        </StyledDialog>
    );
}

export default CreateLandingModal;