import {UrlParams} from "../../componets/Table/types";
import {prismApi} from "../store";
import {TrackerData, TrackerSettings} from "../reducers/prism/types";

const fetchTrackers = async (filters?: UrlParams) => {
  const defaultFilters = {include: 'team,owner,shortlink'}

  return prismApi.get('/trackers', {...defaultFilters, ...filters});
}

const fetchTracker = async (id: number, filters?: UrlParams) => {
  const defaultFilters = {include: 'settings,package.landings,defaultPackage,defaultPackage.landings,shortlink,owner,team'}
  return prismApi.get(`/trackers/${id}`, {...defaultFilters, ...filters});
}

const fetchAvailableUtmParams = async () => {
  return prismApi.get(`/trackers/available-params`);
}

const setActivity = async (trackerId: number, enabled: boolean) => {
  return prismApi.put('/trackers/' + trackerId + '/activity', undefined, {enabled}).then(data => data.json());
}
//TODO: change when tracker page will ready
const setAlgorithm = async (trackerId: number | string | undefined, algorithm: string) => {
  return prismApi.put('/trackers/' + trackerId + '/algorithm', undefined, {algorithm: algorithm});
}

const createTracker = async (params?: UrlParams) => {
  return prismApi.post('/trackers', undefined, params);
}

const fetchUpdateSettings = async (trackerId: number, settings: TrackerSettings) => {
  return prismApi.put(`/trackers/${trackerId}/settings`, undefined, settings);
}


const fetchUpdateTracker = async (trackerId: number, body: TrackerData) => {
  return prismApi.put(`/trackers/${trackerId}`, undefined, body);
}

const fetchShortlink = async (trackerId: number) => {
  return prismApi.get(`/trackers/${trackerId}/shortlink`);
}


const createTrackerByDefault = async (trackerName: string, teamId: string, enabled: boolean) => {
  return createTracker({
    name: trackerName,
    team_id: teamId,
    enabled: String(enabled)
  });
}

const duplicateTracker = async (oldId: number, newId: number) => {
  return prismApi.post(`trackers/${oldId}/general-trackers/copy/${newId}`)
}

export {
  fetchTrackers,
  setActivity,
  createTracker,
  createTrackerByDefault,
  fetchTracker,
  setAlgorithm,
  fetchAvailableUtmParams,
  fetchUpdateTracker,
  fetchUpdateSettings,
  fetchShortlink,
  duplicateTracker
};