import {PaginatedData} from "../../types";
import {RequestStatuses, UrlParams} from "../../../componets/Table/types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CategoryData} from "./types";
import {RootState} from "../../store";
import {fetchLandingsListCategories} from "../../requests/landingsList/categories";

export interface LandingsListCategoriesState {
    categories: PaginatedData<CategoryData> | null;
    status: RequestStatuses;
}

const initialState: LandingsListCategoriesState = {
    categories: null,
    status: RequestStatuses.idle,
};

export const getLandingsListCategories = createAsyncThunk(
    'brands/fetchLandingsListCategories',
    async (filters: UrlParams | undefined, { rejectWithValue }) => {
        try {
            const defaultFilters = { per_page: '1000' };

            return await fetchLandingsListCategories({ ...defaultFilters, ...filters });
        } catch (err) {
            return rejectWithValue({ ...err.response.data, responseStatus: err.response.request.status});
        }
    }
);

export const landingsListCategoriesSlice = createSlice({
    name: 'landingsListCategories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingsListCategories.pending, (state) => {
                state.status = RequestStatuses.loading;
            })
            .addCase(getLandingsListCategories.fulfilled, (state, action) => {
                state.status = RequestStatuses.success;
                state.categories = action.payload;
            });
    },
});

export const selectLandingsListCategories = (state: RootState) => state.landingsListCategories.categories?.data ?? [];
export const selectLandingsListCategoriesStatus = (state: RootState) => state.landingsListCategories.status;


export default landingsListCategoriesSlice.reducer;