import {styled} from "@mui/material/styles";
import Box, {BoxProps} from "@mui/material/Box/Box";

type CustomBoxProps = {
    active: boolean;
}

export const Block = styled(Box)<BoxProps & CustomBoxProps>(({theme,active}) => ({
    ".schedule-row": {
        display: "flex",
        flexDirection: "flex-start",
        marginTop: 24,
        "& + .schedule-row": {
            marginTop: 16
        }
    },
    ".schedule-title": {
        textTransform: "capitalize",
        width: 40,
        minWidth: 40,
        height: 40,
        display: "flex",
        alignItems: "center"
    },
    ".MuiFormControl-root": {
        minWidth: 230,
        boxSizing: "border-box",
    },
    ".MuiAutocomplete-root": {
        minWidth: 220,
        boxSizing: "border-box",
        marginLeft: 10
    },
    ".custom-alert": {
        position: "relative",
        top: 0,
        right: 0,
        width: "100%",
        zIndex: 1,
        marginTop: 24
    }
}));

export const ScheduleShortList = styled(Box)<BoxProps>(({theme}) => ({
    ".all": {
        textTransform: "uppercase",
        height: 32,
        lineHeight: "32px",
        marginTop: 24,
        cursor: "pointer",
        svg: {
            margin: "0 10px -6px 14px"
        }
    },
}))

export const ScheduleShortListRow = styled(Box)<BoxProps>(({theme}) => ({
    height: 88,
    borderBottom: "1px solid",
    borderColor: theme.palette.other.divider,
    marginTop: 24,
    ".timerBox": {
        marginRight: 8,
    },
    ".timer": {
        width: "20px",
        margin: "6px 10px",
    },
    ".links": {
        display: "inline-block",
        height: 40,
        lineHeight: "36px",
        fontWeight: 400,
        letterSpacing: "0.15px",
        marginRight: "40px",
        width: "370px",
        "div": {
            float: "none",
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            fontWeight: 400,
            ".label": {
                fontWeight: 500,
                letterSpacing: "0.15px",
            }
        },
        "a": {
            color: theme.palette.success.main,
        },
    },
    "div": {
        display: "inline-block",
        float: "left"
    },
    ".controls": {
        paddingTop: "6px",
        svg: {
            color: theme.palette.action.active,
            cursor: "pointer",
            width: 20,
            "+ svg": {
                marginLeft: 30,
            }
        }
    },
}));