import React, {Dispatch, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ScheduleShortList, ScheduleShortListRow} from "./styles";
import TimerIcon from '@mui/icons-material/Timer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TableDialog from "../../../modals/TableDialog/TableDialog";
import SimpleTable from "../../../SimpleTable/SimpleTable";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {
    deleteScheduleFromRule,
    getSchedules,
    selectSchedules
} from "../../../../app/reducers/prism/generalTrackersSlice";
import moment from "moment";
import DeleteModal from "../../../modals/DeleteModal/DeleteModal";
import {TrackerData} from "../../../../app/reducers/prism/types";



interface ScheduleListProps {
    ruleId: number;
    setUpdateTree: Dispatch<React.SetStateAction<number>>;
    updateTree: number;
    handleEditSchedule: (row: TrackerData,type: string) => void;
}

const ScheduleList: React.FC<ScheduleListProps> = (props): JSX.Element => {
    const { t } = useTranslation();
    const {ruleId, setUpdateTree, updateTree, handleEditSchedule} = props;
    const [openListModal, setOpenListModal] = useState<boolean>(false);
    const [deleteScheduleId, setDeleteScheduleId] = useState<number>(0);
    const schedules = useAppSelector(selectSchedules);
    const dispatch = useAppDispatch();
    const deleteScheduleHandler = () => async () => {
        const response = await dispatch(deleteScheduleFromRule({ ruleId: ruleId, scheduleId: deleteScheduleId }));
        if (response){
            setDeleteScheduleId(0);
            setUpdateTree(prevValue => prevValue + 1);
        }
    }
    const handleClose = () => {
        setOpenListModal(false);
    }
    const handleOpen = () => {
        setOpenListModal(true);
    }

    useEffect(() => {
        if (ruleId && Number(ruleId)) {
            dispatch(getSchedules(Number(ruleId)));
        }
    }, [dispatch,ruleId]);

    useEffect(() => {
        if (ruleId && Number(ruleId) && deleteScheduleId === 0) {
            dispatch(getSchedules(Number(ruleId)));
        }
    }, [deleteScheduleId, updateTree]);

    const columns = [
        {
            title: "Link",
            dataIndex: "redirect_type_value",
            type: "green",
        },
        {
            title: "From",
            dataIndex: "start",
            type: "time",
        },
        {
            title: "To",
            dataIndex: "end",
            type: "time",
        },
    ];


    if(schedules === null ){
        return <></>;
    }

    const schedulesQty =  schedules.data.length;
    const shortListCap = schedules.data.length > 3 ? 3 : schedules.data.length;
    const scheduleRows = [];

    for (let i = 0; i < shortListCap; i++) {
        const row = schedules.data[i];

        const redirectLink = (row.redirect_type_value !== null && row.redirect_type_value !== undefined)
            ? <a href={row.redirect_type_value.toString()}>{String(row.redirect_type_value)}</a>
            : null;

        const startDate = (row.start !== null && row.start !== undefined)
            ? moment(row.start.toString()).format("DD.MM.YYYY HH:mm")
            : null;

        const endDate = (row.end !== null && row.end !== undefined)
            ? moment(row.end.toString()).format("DD.MM.YYYY HH:mm")
            : null;

        const scheduleRow = (
            <ScheduleShortListRow key={row.id.toString()}>
                <Box>
                    <Box className="timerBox"><TimerIcon className="timer IconActive"/></Box>
                    <Box className="links">
                        {redirectLink}
                        <Box sx={{marginTop:1}}>
                            <Box className="interval">
              <span className="label">
                {t('smartlinks.modal_rule_configuration.cap_range.from')}:&nbsp;
              </span>
                                <span className="value">
                {startDate}
              </span>
                            </Box>
                            <Box className="interval">
              <span className="label">
                {t('smartlinks.modal_rule_configuration.to')}:&nbsp;
              </span>
                                <span className="value">
                {endDate}
              </span>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="controls">
                        <EditIcon
                            onClick={() => {
                                handleEditSchedule(row, 'schedule');
                            }}
                        />
                        <DeleteForeverIcon
                            onClick={() =>{setDeleteScheduleId(Number(row.id))}}
                        />
                    </Box>
                </Box>
            </ScheduleShortListRow>
        );

        scheduleRows.push(scheduleRow);
    }

    return (
        <ScheduleShortList>
            {scheduleRows}
            {schedulesQty > 2 &&
            <Box className="all" onClick={handleOpen}>
                <AccessTimeIcon/>{t('smartlinks.modal_rule_configuration.view_all_schedules')}
            </Box>
            }
            <TableDialog className="scheduleList" open={openListModal} onClose={handleClose}>
                <div className='close-button'>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogTitle>{t('smartlinks.modal_rule_configuration.schedule')}</DialogTitle>
                <DialogContentText className={"subheading"} sx={{fontSize: '16px', color: '#BDB8AD'}}>
                    {t('smartlinks.modal_rule_configuration.schedule_list_description')}
                </DialogContentText>
                <DialogContent>
                    <SimpleTable columns={columns} rows={schedules.data} />
                </DialogContent>
            </TableDialog>
            {deleteScheduleId > 0 &&  (<DeleteModal
                handleDelete={() => {
                    if(deleteScheduleId) {
                        const deleteFunction = deleteScheduleHandler();
                        deleteFunction();
                    }
                }}
                handleClose={() => setDeleteScheduleId(0)}
                modalTitle={t('smartlinks.modal_rule_configuration.delete_schedule_title')}
                modalSubtitle={t('smartlinks.modal_rule_configuration.delete_schedule_subtitle')}
            />)}
        </ScheduleShortList>
    );
}

export default ScheduleList;