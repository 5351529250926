import {UrlParams} from "../../componets/Table/types";
import {prismApi} from "../store";
import {landing_name} from "../../componets/Landings/columns";

const createLandingRequest = async (brandId: string, title: string, path: string, label: string, teamId: string) => {
    return createLanding({
        brand_id: brandId,
        title,
        path,
        label,
        team_id: teamId
    });
}

const updateLandingRequest = async (landingId: string, brandId: string, title: string, path: string, label: string, teamId: string) => {
    // TODO check bullshit with "null"
    return prismApi.put(`/landings/${landingId}`, undefined, {id: landingId, brand_id: brandId, title, path, label, team_id: teamId === 'null' ? null : teamId});
}

const fetchLandings = async (filters?: UrlParams) => {
    const defaultParams: UrlParams = {
        include: 'brand,team,modified_by'
    };

    return prismApi.get('/landings', {...filters, ...defaultParams});
}

const createLanding = async (params?: UrlParams) => {
    return prismApi.post(`/landings`, undefined, params);
};

export { fetchLandings, createLandingRequest, updateLandingRequest };