import React, {useCallback} from 'react';
import './Sidebar.scss';
import {useNavigate,useLocation} from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {MenuItemType} from "./types";
import {Box} from "@mui/material";

interface SideBarItemProps {
    menuItem: MenuItemType;
    open: boolean;
    active: { active: string, parent: string };
    setActive: (active: string, parent: string) => void;
}

function CustomListItem(props: SideBarItemProps) {
    const {
        menuItem,
        open,
        active,
        setActive,
    } = props;

    const dotIcon = menuItem.parent && !open ? <FiberManualRecordIcon className="dot"/> : null;
    const navigate = useNavigate();
    const changeRoute = useCallback(() => navigate(menuItem.link, {replace: true}), [navigate]);

    const handleOnClick = () => {
        if (menuItem.linkType === 'link') {
            window.location.href = menuItem.link;
        } else {
            changeRoute();
            setActive(menuItem.dataName, menuItem.parent ? menuItem.parent : "");
        }
    }

    const location = useLocation();

    return (
        <ListItem onClick={handleOnClick}
                  className={'list_item' + (active.active !== menuItem.parent && menuItem.parent && active.parent !== menuItem.parent ? ' hidden' : '')}
                  button>
            { menuItem.separated && (
                <><hr></hr></>
            )}
            <ListItemButton className={location.pathname === menuItem.link ? ' active' : ''}>
                <ListItemIcon className="simple_icon">
                    {menuItem.parent ? dotIcon : menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={menuItem.name} sx={{opacity: open ? 1 : 0, pl: menuItem.parent ? "24px" : '0'}}/>
            </ListItemButton>
        </ListItem>
    );
}

export default CustomListItem;
