import React from 'react';
import {AppFilters} from "../types";
import './AppliedFilterBar.scss';
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../app/hooks";
import Chip from "@mui/material/Chip";
import {FilterConfigAutocomplete} from "../filters/types";

interface FiltersProps {
    filterConfig: FilterConfigAutocomplete;
    urlFilter: string;
    addFilters: (filters: AppFilters) => void;
}

export function FilterAutocompleteChip(props: FiltersProps) {
    const {t} = useTranslation();
    const {filterConfig, urlFilter, addFilters} = props;
    const data = useAppSelector(filterConfig.dataSelector);
    if (data === null) {
        return null;
    }

    const keySet = urlFilter.split(',');

    const handleDelete = (id: number, name: string) => {
        const newKeys = keySet.filter(val => val !== dataId);
        const dataId:string = id.toString();
        const keyString = newKeys.join(',');
        addFilters({[name]: keyString})
    }

    return (<div>
        {keySet.map(val => {
            const dataRow = data.find((obj) => obj.id === Number(val));
            if (dataRow && typeof dataRow.id === "number") {
                const dataId: number = dataRow.id;
                return (
                    <Chip
                        className="filter-chip"
                        label={dataRow ? <>{dataRow[filterConfig.optionLabel]}</> : null}
                        onDelete={() => handleDelete(dataId, filterConfig.field)}
                    />)
            }
            return null;
        })}
    </div>)
}
