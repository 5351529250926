import React from 'react';
import {Button, Dialog, Divider} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './DeleteModal.scss';
import {useTranslation} from "react-i18next";

export interface DeleteModalProps {
    handleDelete: () => void;
    handleClose: () => void;
    modalTitle: string;
    modalSubtitle: string;
    rightBtnName?: string;
}

function DeleteModal({handleDelete, handleClose, modalTitle, modalSubtitle, rightBtnName}: DeleteModalProps) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            className="delete-modal"
        >
            <div className="delete-modal-header">
                <p className="delete-modal-title">{modalTitle}</p>
                <CloseIcon
                    className="delete-modal-close"
                    onClick={handleClose}
                />
            </div>
            <p className="delete-modal-subtitle">{modalSubtitle}</p>
            <Divider />
            <div className="delete-modal-footer">
                <Button
                    onClick={handleDelete}
                    className="delete-modal-btn BtnPurple"
                >
                    {rightBtnName ?? t('smartlinks.delete_modal.delete_button')}
                </Button>
                <Button
                    onClick={handleClose}
                    className="delete-modal-btn BtnGrey"
                >
                    {t('smartlinks.delete_modal.cancel_button')}
                </Button>
            </div>
        </Dialog>
    );
}

export default DeleteModal;
