import React, {Dispatch, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import TabsRuleConfiguration from "./../TabsRuleConfiguration/TabsRuleConfiguration";
import {
    GeneralTrackerRequestBody,
    RuleConfigurationData,
    ValidationRuleConfigurationData,
    toggleVariants,
    isSubmittingType,
    collectedDataTpl,
    capRangeVal,
    dateRangesType
} from "./../type";
import moment from "moment";
import {
    Node,
    RULE_LANDING,
    RULE_SPLITTING,
    RULE_DIRECT_URL,
    RULE_CAPPING,
    RULE_SCHEDULE
} from "../../types";
import {getTracker} from "../../../../app/reducers/prism/trackersSlice";
import {TrackerData} from "../../../../app/reducers/prism/types";

interface MainBlockMultiplierProps {
    blockId: number;
    trackerId: number;
    parentId: number | null;
    activeRule: string | null;
    nodeTypes: string | string[];
    handleCloseRuleForm: () => void;
    showHideSuccessfullyAlert: () => void;
    dataSetter: Dispatch<React.SetStateAction<collectedDataTpl[]>>;
    oldData: collectedDataTpl[];
    openToggle: boolean;
    capNumber: number;
    handlerToggle: () => void;
    handleAddCap: () => void;
    setIsSubmitting: Dispatch<React.SetStateAction<isSubmittingType>>;
    isSubmitting: isSubmittingType;
    setFrom: Dispatch<React.SetStateAction<capRangeVal[]>>;
    setTo: Dispatch<React.SetStateAction<capRangeVal[]>>;
    dateRanges: dateRangesType;
    setDateRanges: Dispatch<React.SetStateAction<dateRangesType>>;
    from: capRangeVal[];
    to: capRangeVal[];
    handleDeleteBlock: (blockId: number) => void;
    toggleType: toggleVariants;
    ruleEdit: Node | null;
    scheduleEdit: TrackerData | null;
    clearData: boolean;
    blockCounter: number[];
}

export const regexUrl = new RegExp('(^https://)');

function MainBlockMultiplier(props: MainBlockMultiplierProps) {
    const {
        blockId,
        activeRule,
        nodeTypes,
        parentId,
        dataSetter,
        oldData,
        capNumber,
        handlerToggle,
        openToggle,
        handleAddCap,
        setIsSubmitting,
        isSubmitting,
        from,
        to,
        dateRanges,
        setDateRanges,
        setFrom,
        setTo,
        handleDeleteBlock,
        toggleType,
        ruleEdit,
        scheduleEdit,
        clearData,
        blockCounter
    } = props;
    const {t} = useTranslation();
    let editForm = RULE_LANDING;
    if (ruleEdit !== null) {
        editForm = ruleEdit.redirect_type;
        if (toggleType === RULE_CAPPING && ruleEdit.visits !== null) {
            if (ruleEdit.visits[blockId]) {
                editForm = ruleEdit.visits[blockId].redirect_type;
            } else {
                editForm = RULE_LANDING;
            }
        }
    }
    if (scheduleEdit !== null) {
        editForm = scheduleEdit.redirect_type;
    }
    const [activeForm, setActiveForm] = React.useState<string>(editForm);
    const blocksCountForSplitting = 2;
    const [errorProbability, setErrorProbability] = useState<boolean>(false);
    const configuration = {splitting: [], url: [], landing: [], capping: []}
    const [ruleConfigurationData, setRuleConfigurationData] = useState<RuleConfigurationData>({...configuration});
    const [validationRuleConfigurationData, setValidationRuleConfigurationData] = useState<ValidationRuleConfigurationData>({...configuration});

    useEffect(() => {
        if (ruleEdit === null || ruleEdit.original_value === 'unique') {
            const newFrom = [...from];
            newFrom.push({key: blockId, value: ""});
            setFrom(newFrom);
            const newTo = [...to];
            newTo.push({key: blockId, value: ""});
            setTo(newTo);
        }
    }, []);

    useEffect(() => {
        if (ruleEdit === null && scheduleEdit === null) {
            setActiveForm(RULE_LANDING)
        }
    }, [clearData]);

    useEffect(() => {
        setActiveForm(editForm);
    }, [ruleEdit]);

    const getSplittingValues = () => {
        return ({
            probability: '',
            brand_id: '',
            domain: '',
            landing_id: '',
            url: ''
        });
    };

    const getSplittingInitialValues = () => {
        const splittingInitialValues = [];
        let counter: number = blocksCountForSplitting;
        if (ruleEdit !== null) {
            if (ruleEdit.splitting !== null && ruleEdit.splitting && ruleEdit.visits === null) {
                counter = ruleEdit.splitting.length;
            }
            if (ruleEdit.visits !== null && ruleEdit.visits[blockId]) {
                const curVisit = ruleEdit.visits[blockId];
                counter = Object.keys(typeof curVisit.splitting === 'object' && curVisit.splitting !== null ? curVisit.splitting : {}).length;
            }
        }
        if (scheduleEdit !== null) {
            if (scheduleEdit.splitting !== null && scheduleEdit.splitting) {
                counter = scheduleEdit.splitting.length;
            }
        }

        counter = counter < 2 ? 2 : counter;
        // counter = ruleConfigurationData.splitting.length < 2 ? 2 : counter;

        for (let i = 0; i < counter; i++) {
            splittingInitialValues.push(getSplittingValues());
        }
        return splittingInitialValues;
    }

    const getDirectUrlInitialValues = () => {
        return ([{
            url: '',
            brand_id: ''
        }]);
    };

    const getLandingInitialValues = () => {
        return ([{
            brand_id: '',
            domain: '',
            landing_id: ''
        }]);
    };

    const getDirectUrlValidationInitialValues = () => {
        return ([{
            url: true,
        }]);
    };

    const getLandingValidationInitialValues = () => {
        return ([{
            brand_id: true,
            domain: false,
            landing_id: true
        }]);
    };

    const getSplittingValidationValues = () => {
        if (ruleEdit === null && scheduleEdit === null) {
            return ({
                probability: true,
                brand_id: true,
                domain: false,
                landing_id: true
            });
        }
        return ({
            probability: false,
            brand_id: false,
            domain: false,
            landing_id: false
        });
    };

    const getSplittingValidationInitialValues = () => {
        const arr = [];
        let counter: number = blocksCountForSplitting;
        if (ruleEdit !== null) {
            if (ruleEdit.splitting !== null && ruleEdit.splitting && ruleEdit.visits === null) {
                counter = ruleEdit.splitting.length;
            }
            if (ruleEdit.visits !== null && ruleEdit.visits[blockId]) {
                const curVisit = ruleEdit.visits[blockId];
                counter = Object.keys(typeof curVisit.splitting === 'object' && curVisit.splitting !== null ? curVisit.splitting : {}).length;
            }
        }
        if (scheduleEdit !== null) {
            if (scheduleEdit.splitting !== null && scheduleEdit.splitting) {
                counter = scheduleEdit.splitting.length;
            }
        }

        counter = counter < 2 ? 2 : counter;

        for (let i = 0; i < counter; i++) {
            arr.push(getSplittingValidationValues());
        }
        return arr;
    }

    const checkErrorProbability = () => {
        const totalProbability = ruleConfigurationData.splitting.reduce((acc, item) => {
            return acc + Number(item.probability);
        }, 0);
        setErrorProbability(totalProbability > 100);
    }

    const handleAddSplittingBlock = () => {
        const tempData = ruleConfigurationData;
        tempData.splitting.push(getSplittingValues());
        setRuleConfigurationData({...tempData});

        const tempDataValidation = validationRuleConfigurationData;
        tempDataValidation.splitting.push(getSplittingValidationValues());
        setValidationRuleConfigurationData({...tempDataValidation});
    }

    const handleDeleteSplittingBlock = (index: number) => {
        const tempData = ruleConfigurationData;
        tempData.splitting.splice(index, 1);
        setRuleConfigurationData({...tempData});

        const tempDataValidation = validationRuleConfigurationData;
        tempDataValidation.splitting.splice(index, 1);
        setValidationRuleConfigurationData({...tempDataValidation});

        checkErrorProbability();
    }

    const handleValidate = (name: string, value: string) => {
        if (name === 'url' && !regexUrl.test(value)) {
            return true;
        }

        if (name === 'probability' && !value.length) {
            return true
        }

        if (name === 'probability') {
            checkErrorProbability();
        }

        if (value === '') {
            return true
        }

        return false;
    }

    const updatingUpcomingDataArray = () => {
        const newData = [...oldData];
        if(ruleConfigurationData[activeForm].length > 0) {

            dataSetter((prevState: collectedDataTpl[] )=> {
                const hasData = prevState.filter(obj => obj.key === blockId).length;
                if(hasData === 0) {
                    return [
                        ...prevState,
                        {key: blockId, value: createNode()}
                    ]
                } else {
                    const curState = [...prevState]
                    curState[blockId] = {key: blockId, value: createNode()};
                    return [...curState]
                }
            });
            // },500);

        }
    }

    const handleInput = (name: string, value: string, index: number) => {
        if (name === 'probability' && isNaN(Number(value))) {
            return
        }

        const tempValuesObj = ruleConfigurationData;
        const list = tempValuesObj[activeForm as keyof typeof tempValuesObj] as Array<any>;
        list[index][name] = value;
        setRuleConfigurationData({...tempValuesObj});
        updatingUpcomingDataArray();
        const tempValidationObj = validationRuleConfigurationData;
        const listValidation = tempValidationObj[activeForm as keyof typeof tempValidationObj] as Array<any>;
        listValidation[index][name] = handleValidate(name, value);
        setValidationRuleConfigurationData({...tempValidationObj});
    };

    const handleSetFieldValue = (field: string, formName: string, value: string, index: number) => {
        const tempValuesObj = ruleConfigurationData;
        const list = tempValuesObj[formName as keyof typeof tempValuesObj] as Array<any>;
        list[index][field] = value;
        setRuleConfigurationData({...tempValuesObj});
        updatingUpcomingDataArray();

        const tempValidationObj = validationRuleConfigurationData;
        const listValidation = tempValidationObj[formName as keyof typeof tempValidationObj] as Array<any>;
        listValidation[index][field] = handleValidate(field, value);
        if (field === 'brand_id') {
            listValidation[index]['landing_id'] = true;
        }
        setValidationRuleConfigurationData({...tempValidationObj});
    };

    const makeRulePackages = () => {
        return activeForm === RULE_SPLITTING ? ruleConfigurationData.splitting : null;
    };

    const getLandingId = () => {
        return activeForm === RULE_LANDING && ruleConfigurationData.landing[0] ? ruleConfigurationData.landing[0].landing_id : null;
    };

    const getBrandId = () => {
        return activeForm === RULE_LANDING && ruleConfigurationData.landing[0] ? ruleConfigurationData.landing[0].brand_id : null;
    };

    const getParentId = () => {
        return scheduleEdit === null ? parentId : null;
    };

    const getUrl = () => {
        return activeForm === RULE_DIRECT_URL && ruleConfigurationData.url[0] ? ruleConfigurationData.url[0].url : "";
    }

    const getValueByKey = (arr: capRangeVal[], key: number): string => {
        const item = arr.find(item => item.key === key);
        return item && item.value ? item.value : "";
    }

    const getInt = (value: string) => {
        return value !== "" ? parseInt(value) : 0;
    }

    const getStartEnd = (date: string, time: string) => {
        return date + " " + time + ":00";
    }

    const createNode = () => {
        if (toggleType === 'schedule') {
            const body: GeneralTrackerRequestBody = {
                type: activeRule,
                brand_id: getBrandId(),
                start: getStartEnd(dateRanges.dateFrom, dateRanges.timeFrom),
                end: getStartEnd(dateRanges.dateTo, dateRanges.timeTo),
                redirect_type: activeForm,
                package_id: null,
                url: getUrl(),
                landing_id: getLandingId(),
                rule_packages: makeRulePackages(),
            };
            return body;
        }
        if (toggleType === 'capping' && openToggle) {
            const body: GeneralTrackerRequestBody = {
                type: activeRule,
                value: nodeTypes,
                brand_id: getBrandId(),
                from: getInt(getValueByKey(from, blockId)),
                to: getInt(getValueByKey(to, blockId)),
                redirect_type: activeForm,
                parent_id: parentId,
                package_id: null,
                url: getUrl(),
                landing_id: getLandingId(),
                rule_packages: makeRulePackages(),
                use_x_visits: false,
                x_visits: null
            };
            return body;
        }
        const body: GeneralTrackerRequestBody = {
            type: activeRule,
            value: nodeTypes,
            from: getInt(getValueByKey(from, blockId)),
            to: getInt(getValueByKey(to, blockId)),
            redirect_type: activeForm,
            parent_id: getParentId(),
            package_id: null,
            url: getUrl(),
            landing_id: getLandingId(),
            rule_packages: makeRulePackages(),
            use_x_visits: false,
            x_visits: null
        };
        return body;
    }

    useEffect(() => {
        if (oldData.length > 0) {
            updatingUpcomingDataArray();
        }
    }, [nodeTypes]);

    useEffect(() => {
        if (from.length > 0 && to.length > 0) {
            updatingUpcomingDataArray();
        }
    }, [from, to]);

    useEffect(() => {
        updatingUpcomingDataArray();
    }, [dateRanges, ruleConfigurationData, blockCounter]);

    return (
        <TabsRuleConfiguration
            blockId={blockId}
            setRuleConfigurationData={setRuleConfigurationData}
            ruleConfigurationData={ruleConfigurationData}
            getSplittingInitialValues={getSplittingInitialValues}
            getDirectUrlInitialValues={getDirectUrlInitialValues}
            getLandingInitialValues={getLandingInitialValues}
            handleInput={handleInput}
            handleSetFieldValue={handleSetFieldValue}
            handleAddSplittingBlock={handleAddSplittingBlock}
            getDirectUrlValidationInitialValues={getDirectUrlValidationInitialValues}
            getLandingValidationInitialValues={getLandingValidationInitialValues}
            setValidationRuleConfigurationData={setValidationRuleConfigurationData}
            getSplittingValidationInitialValues={getSplittingValidationInitialValues}
            validationRuleConfigurationData={validationRuleConfigurationData}
            handleDeleteSplittingBlock={handleDeleteSplittingBlock}
            errorProbability={errorProbability}
            toggleType={toggleType}
            handlerToggle={handlerToggle}
            openToggle={openToggle}
            capNumber={capNumber}
            handleAddCap={handleAddCap}
            setIsSubmitting={setIsSubmitting}
            isSubmitting={isSubmitting}
            setFrom={setFrom}
            setTo={setTo}
            from={from}
            to={to}
            handleDeleteBlock={handleDeleteBlock}
            dateRanges={dateRanges}
            setDateRanges={setDateRanges}
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            ruleEdit={ruleEdit}
            scheduleEdit={scheduleEdit}
            clearData={clearData}
            blockCounter={blockCounter}
        />
    );
}

export default MainBlockMultiplier;