import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    capRangeVal,
    DirectUrlFormState,
    DirectUrlFormValidation,
    LandingFormState,
    LandingFormValidation,
    RuleConfigurationData,
    SplittingFormState,
    SplittingFormValidation,
    toggleVariants,
    ValidationRuleConfigurationData,
    isSubmittingType,
    dateRangesType
} from "../type";
import RadioGroupBlock from "../RadioGroupBlock/RadioGroupBlock";
import TabPanel from "./TabPanel/TabPanel";
import {Box, Button} from "@mui/material";
import BaseSelectWithInput from "../../../inputs/BaseSelectWithInput";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TextField from "@mui/material/TextField";
import CustomAlert from "../../../Alert/CustomAlert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {getDataByKey, getStringValueByKey} from "../../../Helpers/arrayHelper";
import {
    checkRangesChain,
    checkRangesOverlap,
    hasDuplicateSplittings,
    hasEmptySplittings,
    scheduleIntervalValid
} from "../ruleHelpers";
import ScheduleIntervals from "../Schedule/ScheduleIntervals";
import moment from "moment";
import DeleteModal from "../../../modals/DeleteModal/DeleteModal";
import {
    Node,
    RULE_LANDING,
    RULE_SPLITTING,
    RULE_DIRECT_URL,
    RULE_CAPPING,
    RULE_SCHEDULE
} from "../../types";
import {TrackerData} from "../../../../app/reducers/prism/types";

interface TabsRuleConfigurationProps {
    blockId: number
    setRuleConfigurationData: Dispatch<React.SetStateAction<RuleConfigurationData>>;
    getSplittingInitialValues: () => SplittingFormState[];
    getDirectUrlInitialValues: () => DirectUrlFormState[];
    getLandingInitialValues: () => LandingFormState[];
    ruleConfigurationData: RuleConfigurationData;
    handleInput: (name: string, value: string, index: number, formName: string) => void;
    handleSetFieldValue: (field: string, formName: string, value: string, index: number) => void;
    handleAddSplittingBlock: () => void;
    getDirectUrlValidationInitialValues: () => DirectUrlFormValidation[];
    getLandingValidationInitialValues: () => LandingFormValidation[];
    getSplittingValidationInitialValues: () => SplittingFormValidation[];
    setValidationRuleConfigurationData: Dispatch<React.SetStateAction<ValidationRuleConfigurationData>>;
    validationRuleConfigurationData: ValidationRuleConfigurationData;
    handleDeleteSplittingBlock: (index: number) => void;
    errorProbability?: boolean;
    capNumber: number;
    toggleType: toggleVariants;
    handlerToggle: () => void;
    openToggle: boolean;
    handleAddCap: () => void;
    setIsSubmitting: Dispatch<React.SetStateAction<isSubmittingType>>;
    isSubmitting: isSubmittingType;
    setFrom: Dispatch<React.SetStateAction<capRangeVal[]>>;
    setTo: Dispatch<React.SetStateAction<capRangeVal[]>>;
    from: capRangeVal[];
    to: capRangeVal[];
    handleDeleteBlock: (blockId: number) => void;
    dateRanges: dateRangesType;
    setDateRanges: Dispatch<React.SetStateAction<dateRangesType>>;
    setActiveForm: Dispatch<React.SetStateAction<string>>;
    activeForm: string;
    ruleEdit: Node | null;
    scheduleEdit: TrackerData | null;
    clearData: boolean;
    blockCounter: number[];
}

const REG_EXP_NUMBER = /^[0-9\b]+$/;

function TabsRuleConfiguration(props: TabsRuleConfigurationProps) {
    const {
        blockId,
        setRuleConfigurationData,
        getSplittingInitialValues,
        ruleConfigurationData,
        getDirectUrlInitialValues,
        getLandingInitialValues,
        handleInput,
        handleSetFieldValue,
        handleAddSplittingBlock,
        getDirectUrlValidationInitialValues,
        getLandingValidationInitialValues,
        getSplittingValidationInitialValues,
        setValidationRuleConfigurationData,
        validationRuleConfigurationData,
        handleDeleteSplittingBlock,
        errorProbability = true,
        toggleType,
        handlerToggle,
        openToggle,
        capNumber,
        handleAddCap,
        setIsSubmitting,
        isSubmitting,
        setFrom,
        setTo,
        from,
        to,
        handleDeleteBlock,
        dateRanges,
        setDateRanges,
        setActiveForm,
        activeForm,
        ruleEdit,
        scheduleEdit,
        clearData,
        blockCounter
    } = props;
    const {t} = useTranslation();
    const landing = t('smartlinks.modal_rule_configuration.radioGroup.landing.value');
    const splitting = t('smartlinks.modal_rule_configuration.radioGroup.splitting.value');
    const direct_url = t('smartlinks.modal_rule_configuration.radioGroup.direct_url.value');
    const [fromError, setFromError] = useState<0 | 1 | 2>(0);
    const [toError, setToError] = useState<0 | 1 | 2>(0);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const maxValueError = t('smartlinks.modal_rule_configuration.max_value_1000');
    const minValueError = t('smartlinks.modal_rule_configuration.min_value_2');
    const landingValid = [{
        brand_id: false,
        domain: false,
        landing_id: false
    }]

    const openDeleteModalHandler = () => {
        setOpenDeleteModal(true);
    }

    const helperText = (error: 0 | 1 | 2) => {
        if (error === 1) {
            return maxValueError;
        }
        return "";
    }

    const handleChangeRangeValue = (e: React.ChangeEvent<HTMLInputElement>, setValue: Dispatch<SetStateAction<capRangeVal[]>>, valueArray: capRangeVal[], SetError: Dispatch<SetStateAction<0 | 1 | 2>>) => {
        if (e.target.value === "" || REG_EXP_NUMBER.test(e.target.value)) {
            let targetValue: string = e.target.value;
            const targetIntValue: number = parseInt(targetValue) ? parseInt(targetValue) : 0;
            const newValueArray = [...valueArray];
            const item = getDataByKey(newValueArray, blockId);
            if (targetIntValue < 1001) {
                if (item) {
                    item.value = e.target.value;
                }
                SetError(0);
            } else {
                if (item) {
                    item.value = "1000";
                }
                SetError(1);
                setTimeout(function () {
                    SetError(0);
                }, 3000);
            }
            setValue(newValueArray);
        }
    };

    const handleDivBlur = () => {
        if (getStringValueByKey(from, blockId) === "") {
            return true;
        } else if (parseInt(getStringValueByKey(from, blockId)) < 2) {
            const newValueArray = [...from];
            const item = getDataByKey(newValueArray, blockId);
            if (item) {
                item.value = "2";
            }
            setFromError(2);
            setTimeout(function () {
                setFromError(0);
            }, 3000);
            setFrom(newValueArray);
        }
    };

    useEffect(() => {
        if (activeForm === RULE_SPLITTING) {
            setRuleConfigurationData({
                ...ruleConfigurationData,
                url:[], landing:[],
                splitting: getSplittingInitialValues()
            });
            setValidationRuleConfigurationData({
                ...validationRuleConfigurationData,
                url:[], landing:[],
                splitting: getSplittingValidationInitialValues()
            });
        }
        if (activeForm === RULE_DIRECT_URL) {
            setRuleConfigurationData({
                ...ruleConfigurationData,
                splitting:[], landing:[],
                url: getDirectUrlInitialValues()
            });
            setValidationRuleConfigurationData({
                ...validationRuleConfigurationData,
                splitting:[], landing:[],
                url: getDirectUrlValidationInitialValues()
            });
        }
        if (activeForm === RULE_LANDING) {
            setRuleConfigurationData({
                ...ruleConfigurationData,
                splitting:[], url:[],
                landing: getLandingInitialValues()
            });
            setValidationRuleConfigurationData({
                splitting:[], url:[],
                landing: getLandingValidationInitialValues()
            });
        }
    }, [activeForm, scheduleEdit, ruleEdit, clearData]);

    useEffect(() => {
        if (ruleEdit !== null && toggleType !== RULE_CAPPING && toggleType !== RULE_SCHEDULE) {
            if (ruleEdit.redirect_type === RULE_LANDING) {
                let newConfigData = {
                    ...ruleConfigurationData,
                    landing: getLandingInitialValues()
                };
                newConfigData.landing[0].brand_id = ruleEdit.brand_id;
                newConfigData.landing[0].brand_name = ruleEdit.brand_name;
                newConfigData.landing[0].landing_id = ruleEdit.landing_id;
                setRuleConfigurationData(newConfigData);
                setValidationRuleConfigurationData({
                    splitting:[], url:[],
                    landing: landingValid
                });
            }
            if (ruleEdit.redirect_type === RULE_SPLITTING) {
                let newConfigData = {
                    ...ruleConfigurationData,
                    splitting: getSplittingInitialValues()
                };
                if (ruleEdit.splitting !== null) {
                    ruleEdit.splitting.map((data, index) => {
                        newConfigData.splitting[index].brand_id = data.brand_id;
                        newConfigData.splitting[index].probability = data.probability;
                        newConfigData.splitting[index].landing_id = data.id;
                        newConfigData.splitting[index].url = data.url;
                    });
                    setRuleConfigurationData(newConfigData);
                }
            }

            if (ruleEdit.redirect_type === RULE_DIRECT_URL) {
                let newConfigData = {
                    ...ruleConfigurationData,
                    url: getDirectUrlInitialValues()
                };
                if (ruleEdit.url !== null && ruleEdit.url.length > 0) {
                    newConfigData.url[0].url = ruleEdit.url;
                }
                setRuleConfigurationData(newConfigData);
            }
        }

        if (ruleEdit !== null && toggleType === RULE_CAPPING) {
            if (ruleEdit.visits !== null && ruleEdit.visits[blockId]) {
                if (ruleEdit.visits[blockId].redirect_type === RULE_LANDING) {
                    let newConfigData = {
                        ...ruleConfigurationData,
                        landing: getLandingInitialValues()
                    };
                    newConfigData.landing[0].brand_id = ruleEdit.visits[blockId].brand_id;
                    newConfigData.landing[0].brand_name = ruleEdit.visits[blockId].brand_name;
                    newConfigData.landing[0].landing_id = ruleEdit.visits[blockId].landing_id;
                    setRuleConfigurationData(newConfigData);
                    setValidationRuleConfigurationData({
                        splitting:[], url:[],
                        landing: landingValid
                    });
                }
                if (ruleEdit.visits[blockId].redirect_type === RULE_SPLITTING) {
                    let newConfigData = {
                        ...ruleConfigurationData,
                        splitting: getSplittingInitialValues()
                    };

                    if (ruleEdit.visits[blockId]?.splitting !== null) {
                        ruleEdit.visits[blockId].splitting?.map((data, index) => {
                            newConfigData.splitting[index].brand_id = data.brand_id;
                            newConfigData.splitting[index].probability = data.probability;
                            newConfigData.splitting[index].landing_id = data.id;
                        });
                        setRuleConfigurationData(newConfigData);
                    }
                }
                if (ruleEdit.visits[blockId].redirect_type === RULE_DIRECT_URL) {
                    let newConfigData = {
                        ...ruleConfigurationData,
                        url: getDirectUrlInitialValues()
                    };
                    if (ruleEdit.visits[blockId].url !== null && typeof ruleEdit.visits[blockId].url === 'string') {
                        newConfigData.url[0].url = ruleEdit.visits[blockId].url ?? "";
                    }
                    setRuleConfigurationData(newConfigData);
                }
            }
        }
    }, [ruleEdit,toggleType]);

    useEffect(() => {
        if (scheduleEdit !== null && toggleType === RULE_SCHEDULE) {
            if (scheduleEdit) {
                if (scheduleEdit.start && scheduleEdit.end && typeof scheduleEdit.start === 'string' && typeof scheduleEdit.end === 'string') {
                    const dateFrom = moment(scheduleEdit.start).format("Y-MM-DD");
                    const timeFrom = moment(scheduleEdit.start).format("HH:mm");
                    const dateTo = moment(scheduleEdit.end).format("Y-MM-DD");
                    const timeTo = moment(scheduleEdit.end).format("HH:mm");
                    setDateRanges({ dateFrom, dateTo, timeFrom, timeTo });
                }
            }
            if (scheduleEdit.redirect_type === RULE_LANDING) {
                let newConfigData = {
                    ...ruleConfigurationData,
                    landing: getLandingInitialValues()
                };
                newConfigData.landing[0].brand_id = scheduleEdit.brand_id;
                newConfigData.landing[0].brand_name = scheduleEdit.brand_name;
                newConfigData.landing[0].landing_id = scheduleEdit.landing_id;
                setRuleConfigurationData(newConfigData);
                setValidationRuleConfigurationData({
                    splitting:[], url:[],
                    landing: landingValid
                });
            }
            if (scheduleEdit.redirect_type === RULE_SPLITTING) {
                let newConfigData = {
                    ...ruleConfigurationData,
                    splitting: getSplittingInitialValues()
                };
                if (scheduleEdit?.splitting !== null) {
                    scheduleEdit.splitting?.map((data, index) => {
                        newConfigData.splitting[index].brand_id = data.brand_id;
                        newConfigData.splitting[index].probability = data.probability;
                        newConfigData.splitting[index].landing_id = data.id;
                    });
                    setRuleConfigurationData(newConfigData);
                }

            }
            if (scheduleEdit.redirect_type === RULE_DIRECT_URL) {
                let newConfigData = {
                    ...ruleConfigurationData,
                    url: getDirectUrlInitialValues()
                };
                if (scheduleEdit.url !== null && typeof scheduleEdit.url === 'string') {
                    newConfigData.url[blockId].url = scheduleEdit.url ?? "";
                }
                setRuleConfigurationData(newConfigData);
            }
        }
    }, [scheduleEdit]);


    const handleRadioChange = (val: string) => {
        setActiveForm(val);
    };

    const capVariantOptions = [
        {
            label: t('smartlinks.modal_rule_configuration.cap_range.from'),
            value: 1
        },
        {
            label: t('smartlinks.modal_rule_configuration.cap_range.only'),
            value: 2
        }
    ]

    const getInt = (value: string) => {
        return value !== "" ? parseInt(value) : 0;
    }

    const fromLessTo = (from: string, to: string) => {
        return to !== "" && from !== "" && getInt(from) >= getInt(to);
    }

    const fromToValid = (from: string, to: string) => {
        return to !== "" && from !== "" && getInt(to) > getInt(from);
    }

    const canSubmit = (errorsArray: Array<SplittingFormValidation | DirectUrlFormValidation | LandingFormValidation>) => {
        let res: number = 0;

        errorsArray.forEach((errObj) => {
            for (let prop in errObj) {
                if (errObj[prop]) {
                    res++;
                }
            }
        });
        return res !== 0;
    }

    useEffect(() => {
        const isCapping = toggleType === RULE_CAPPING && openToggle;
        let standardFieldValidation: boolean = false;
        if (activeForm === RULE_DIRECT_URL) {
            standardFieldValidation = canSubmit(validationRuleConfigurationData.url);
        }
        if (activeForm === RULE_LANDING) {
            standardFieldValidation = canSubmit(validationRuleConfigurationData.landing);
        }
        if (activeForm === RULE_SPLITTING) {
            standardFieldValidation = (canSubmit(validationRuleConfigurationData.splitting) || errorProbability || hasDuplicateSplittings(ruleConfigurationData.splitting));
        }
        if (isCapping) {
            standardFieldValidation = !(fromToValid(getStringValueByKey(from, blockId), getStringValueByKey(to, blockId)) && !standardFieldValidation);
        }
        if (toggleType === RULE_SCHEDULE) {
            standardFieldValidation = !(scheduleIntervalValid(dateRanges) && !standardFieldValidation);
        }
        setIsSubmitting(prevState => ({
            ...prevState,
            [blockId]: standardFieldValidation
        }));

    }, [validationRuleConfigurationData, from, to, dateRanges])

    return (
        <Box className="modal-rule-configuration-block">
            {blockId > 1 &&
            <Box className="modal-rule-configuration__cap-head">
                <div className="modal-rule-configuration__cap-head-title">
                    {t("smartlinks.modal_rule_configuration.new_cap")}
                </div>
                <Button
                    startIcon={<DeleteForeverIcon/>}
                    className="modal-rule-configuration__remove-cap"
                    onClick={openDeleteModalHandler}
                >
                    {t("smartlinks.modal_rule_configuration.delete_cap")}
                </Button>
            </Box>
            }
            <RadioGroupBlock activeRadio={activeForm} handleRadioChange={handleRadioChange}/>
            {activeForm === RULE_SPLITTING && <TabPanel
                blockId={blockId}
                handleInput={handleInput}
                handleSetFieldValue={handleSetFieldValue}
                handleAddSplittingBlock={handleAddSplittingBlock}
                handleDeleteSplittingBlock={handleDeleteSplittingBlock}
                formName={RULE_SPLITTING}
                formClassName="form-splitting"
                values={ruleConfigurationData.splitting}
                errors={validationRuleConfigurationData.splitting}
                handlerToggle={handlerToggle}
                errorProbability={errorProbability}
                toggleType={toggleType}
                openToggle={openToggle}
                ruleEdit={ruleEdit}
                scheduleEdit={scheduleEdit}
            />}
            {activeForm === RULE_DIRECT_URL && <TabPanel
                blockId={blockId}
                handleInput={handleInput}
                handleSetFieldValue={handleSetFieldValue}
                handleAddSplittingBlock={handleAddSplittingBlock}
                handleDeleteSplittingBlock={handleDeleteSplittingBlock}
                formName={RULE_DIRECT_URL}
                formClassName="form-direct_url"
                values={ruleConfigurationData.url}
                errors={validationRuleConfigurationData.url}
                handlerToggle={handlerToggle}
                toggleType={toggleType}
                openToggle={openToggle}
                ruleEdit={ruleEdit}
                scheduleEdit={scheduleEdit}
            />}
            {activeForm === RULE_LANDING && <TabPanel
                blockId={blockId}
                handleInput={() => {
                }}
                handleSetFieldValue={handleSetFieldValue}
                handleDeleteSplittingBlock={handleDeleteSplittingBlock}
                formName={RULE_LANDING}
                formClassName="form-landing"
                values={ruleConfigurationData.landing}
                handlerToggle={handlerToggle}
                toggleType={toggleType}
                openToggle={openToggle}
                ruleEdit={ruleEdit}
                scheduleEdit={scheduleEdit}
            />}
            {toggleType === "capping" && openToggle &&
            <Box className="modal-rule-configuration__cap-body">
                <Box className="modal-rule-configuration__cap-range">
                    <BaseSelectWithInput
                        value={capVariantOptions[0]}
                        className="modal-rule-configuration__cap-type-select"
                        fieldName="cap-type-select"
                        optionsList={capVariantOptions}
                        placeholder=""
                        disabled={true}
                        onChange={(event, value) => {
                        }}
                    />
                    <TextField
                        error={fromError !== 0}
                        name="from"
                        className="modal-rule-configuration__cap-range-value"
                        fullWidth
                        placeholder="2"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRangeValue(e, setFrom, from, setFromError)}
                        onBlur={handleDivBlur}
                        variant="outlined"
                        value={getStringValueByKey(from, blockId)}
                        helperText={helperText(fromError)}
                    />
                    <Box
                        className="modal-rule-configuration__to-label">{t("smartlinks.modal_rule_configuration.to")}</Box>
                    <TextField
                        error={toError !== 0}
                        name="to"
                        className="modal-rule-configuration__cap-range-value"
                        fullWidth
                        placeholder="4"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRangeValue(e, setTo, to, setToError)}
                        variant="outlined"
                        value={getStringValueByKey(to, blockId)}
                        helperText={helperText(toError)}
                    />
                    <Box
                        className="modal-rule-configuration__visit-label">{t("smartlinks.modal_rule_configuration.visit")}
                    </Box>
                </Box>
                <CustomAlert
                    type="error"
                    title={t("smartlinks.modal_rule_configuration.from_less_to_error")}
                    status={fromLessTo(getStringValueByKey(from, blockId), getStringValueByKey(to, blockId))}
                />
                <CustomAlert
                    type="error"
                    title={t("smartlinks.modal_rule_configuration.ranges_should_be_chained")}
                    status={!checkRangesChain(from, to, blockId) && checkRangesOverlap(from, to)}
                />
                <CustomAlert
                    type="error"
                    title={minValueError}
                    status={fromError === 2}
                />
            </Box>
            }
            {toggleType === "schedule" &&
            <ScheduleIntervals
                dateRanges={dateRanges}
                setDateRanges={setDateRanges}
            />
            }
            {capNumber > 0 && capNumber === blockId &&
            <Box className="new-cap-block" onClick={handleAddCap}>
                <AddCircleIcon className='add-plus-icon' sx={{width: 18, height: 18}}/>
                <span className="new-cap-block__text">{t('smartlinks.modal_rule_configuration.add_new_cap')}</span>
            </Box>
            }
            <CustomAlert
                type="error"
                title={t("smartlinks.modal_rule_configuration.splitting_land_duplicate_error")}
                status={(!hasEmptySplittings(ruleConfigurationData.splitting) && hasDuplicateSplittings(ruleConfigurationData.splitting))}
            />
            {openDeleteModal && (<DeleteModal
                handleDelete={() => handleDeleteBlock(blockId)}
                handleClose={() => setOpenDeleteModal(false)}
                modalTitle={t('smartlinks.modal_rule_configuration.delete_cap_title')}
                modalSubtitle={t('smartlinks.modal_rule_configuration.delete_cap_subtitle')}
            />)}
        </Box>
    );
}

export default TabsRuleConfiguration;
